import { toJS } from 'mobx';

import type { Campaign } from '@feathr/blackbox';

import type { ICampaignValidationErrors } from '../../../CampaignSummary';

export function validateStepOptimize(campaign: Campaign): ICampaignValidationErrors {
  const attributes = [
    'bidding_strategy.freq_cap',
    'bidding_strategy.freq_period',
    'bidding_strategy.base_bid',
  ];

  const errors = {
    ...(toJS(campaign.validate(attributes, false, 'grouped').errors) as Record<string, string[]>),
  };

  return errors;
}
