import { faEdit, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import {
  Button,
  Popover,
  Section,
  SectionGroup,
  Spinner,
  Toolbar,
  Value,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import AchInfo from '@feathr/extender/components/AchInfo';
import BillableCard from '@feathr/extender/components/BillableCard';
import BillListTable from '@feathr/extender/components/BillTable/BillListTable';
import { useLocalUrl, useStore } from '@feathr/extender/state';

import BillingFunds from './BillingFunds';
import BillingPeriodTable from './BillingPeriodTable';

import * as styles from './EventSettingsBilling.css';

function EventSettingsBilling(): JSX.Element {
  const { Billables, Events } = useStore();
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const { t } = useTranslation();

  const event = Events.get(eventId);

  if (event.isPending) {
    return <Spinner />;
  }

  const billing = event.get('billing');
  const billableId = billing && billing.billable_id ? billing.billable_id : undefined;
  const billable = billableId ? Billables.get(billableId) : undefined;

  const hasPaymentMethod = billable && billable.get('source') && billable.get('source').id;

  const actions = (
    <Toolbar>
      <Button
        link={localUrl(event.getItemUrl('/settings/billing/edit'))}
        prefix={<FontAwesomeIcon icon={faEdit} />}
      >
        Edit
      </Button>
    </Toolbar>
  );

  const mediaBillFilters = billing
    ? {
        event_id: eventId,
        _id__ne: billing.current_bill_id,
      }
    : { event_id: eventId };

  const referenceIDLabel = (
    <>
      Reference ID
      <Popover toggle={'onMouseOver'}>
        <span className={styles.info}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        <div className={styles.popover}>
          <p>
            Optional reference identification field (such as a PO number) that will be included on
            media invoices.
          </p>
        </div>
      </Popover>
    </>
  );

  return (
    <Page actions={actions} title={'Media Billing'}>
      <SectionGroup>
        <Section
          description={
            <>
              <p>
                {t(
                  'Your media billing settings tell us how to bill you for media spend incurred by Ad Campaigns in this Project.',
                )}
              </p>
              <p>
                <a
                  href={
                    'https://help.feathr.co/hc/en-us/articles/360036855794-How-To-Pay-For-Ad-Campaign-Media'
                  }
                  target={'_blank'}
                >
                  {t('Learn more')}
                </a>
              </p>
            </>
          }
          title={t('Settings')}
        >
          <BillableCard allowEdit={true} billable={billable} />
          {!!billing && !!billing.reference_id && (
            <Value label={referenceIDLabel} value={billing.reference_id} />
          )}
          <BillingFunds billable={billable} event={event} />
        </Section>
        <Section
          description={t(
            'Use this information to pay with domestic ACH, international wire transfer, or check.',
          )}
          title={t('Payment Information')}
        >
          <AchInfo />
        </Section>
        <Section
          description={
            <Trans t={t}>
              <p>
                <em>(Based on usage)</em>
              </p>
              {hasPaymentMethod ? (
                <p>
                  Your credit card will be charged on the 5th of the month following the end of the
                  billing period.
                </p>
              ) : (
                <p>
                  Billing will be finalized on the 5th of the month following the end of the billing
                  period.
                </p>
              )}
            </Trans>
          }
          title={t('Current Bill Estimate')}
        >
          <BillingPeriodTable event={event} />
        </Section>
        <Section title={t('Media Bills')}>
          <BillListTable filters={mediaBillFilters} />
        </Section>
      </SectionGroup>
    </Page>
  );
}

export default observer(EventSettingsBilling);
