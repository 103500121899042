import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Segment } from '@feathr/blackbox';
import { ArchiveModalV1, ContextMenu, Input, ModalV1, toast } from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { getIconForAction, useToggle } from '@feathr/hooks';
import { RerunModal } from '@feathr/report_components';

import SegmentExportModalWrapper from '../SegmentExportModalWrapper';

interface IProps {
  className?: string;
  showRecalculationOption?: boolean;
  model: Segment;
}

function DataSegmentOptions({ className, model, showRecalculationOption }: IProps): JSX.Element {
  const { Conversions } = useStore();

  const localUrl = useLocalUrl();
  const [isExportModalOpen, toggleIsExportModalOpen] = useToggle(false);
  const [isRenameModalOpen, toggleIsRenameModalOpen] = useToggle(false);
  const [isArchiveModalOpen, toggleIsArchiveModalOpen] = useToggle(false);
  const [isRerunModalOpen, toggleRerunModalOpen] = useToggle(false);

  const { t } = useTranslation();

  async function handleRename(): Promise<void> {
    if (!model.isValid()) {
      return;
    }

    try {
      await model.save();
      toast(t('Group renamed to {{name}}.', { name: model.get('name') }), {
        type: 'success',
      });
      toggleIsRenameModalOpen();

      // If err is instance of Error, it should be of type any.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast(
        t('Something went wrong while saving your changes:\n{{error}}', {
          error: error.message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  async function handleConfirmRerun(): Promise<void> {
    const params = new URLSearchParams({
      segment: model.id,
    });
    try {
      await Conversions.rerun(params);
      model.set({ rerun: true });
    } catch (error) {
      toast(t('Something went wrong when trying to recalculate conversions.'), {
        type: ToastType.ERROR,
      });
      throw error;
    }
  }

  const { canEdit } = model.permissions;

  return (
    <>
      <ContextMenu buttonType={'icon'} className={className} position={'left-start'}>
        <ContextMenu.Item
          link={localUrl(model.getItemUrl('analytics'))}
          prefix={getIconForAction('analytics')}
        >
          {t('Analytics')}
        </ContextMenu.Item>
        <ContextMenu.Item onClick={toggleIsExportModalOpen} prefix={getIconForAction('export')}>
          {t('Export')}
        </ContextMenu.Item>
        <ContextMenu.Item
          disabled={!canEdit}
          onClick={toggleIsRenameModalOpen}
          prefix={getIconForAction('rename')}
          tooltip={!canEdit && t('You do not have permission to rename this group.')}
        >
          {t('Rename')}
        </ContextMenu.Item>
        <ContextMenu.Item
          disabled={!canEdit}
          onClick={toggleIsArchiveModalOpen}
          prefix={getIconForAction('archive')}
          theme={'danger'}
          tooltip={!canEdit && t('You do not have permission to archive this group.')}
        >
          {t('Archive')}
        </ContextMenu.Item>
        {showRecalculationOption && (
          <ContextMenu.Item onClick={toggleRerunModalOpen} prefix={getIconForAction('recalculate')}>
            {t('Recalculate conversions')}
          </ContextMenu.Item>
        )}
      </ContextMenu>
      {isExportModalOpen && (
        <SegmentExportModalWrapper segment={model} toggleIsModalOpen={toggleIsExportModalOpen} />
      )}
      {isRenameModalOpen && (
        <ModalV1
          confirmButtonText={t('Save')}
          controlled={true}
          onClose={toggleIsRenameModalOpen}
          onConfirm={handleRename}
          t={t}
          title={t('Rename Group')}
        >
          <Input attribute={'name'} model={model} type={'text'} />
        </ModalV1>
      )}
      {isArchiveModalOpen && (
        <ArchiveModalV1 model={model} onClose={toggleIsArchiveModalOpen} t={t} />
      )}
      {isRerunModalOpen && (
        <RerunModal
          context={'Group'}
          id={model.id}
          name={model.name}
          onClose={toggleRerunModalOpen}
          onConfirm={handleConfirmRerun}
        />
      )}
    </>
  );
}

export default DataSegmentOptions;
