import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons/faCaretLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { IObservableArray } from 'mobx';
import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import type { RowRenderProps } from 'react-table';

import type {
  Conversion,
  ICampaignAttributes,
  ICampaignAttribution,
  IEvent,
  IFlight,
  IGoal,
  Targeting,
  TAttributionModel,
} from '@feathr/blackbox';
import { Campaign, CampaignClass, Event, Flight } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { AvatarV2 as Avatar, Button } from '@feathr/components';
import { moment, TimeFormat, useRedirect } from '@feathr/hooks';
import type { DisplayModel } from '@feathr/rachis';
import { useStore } from '@feathr/report_components/state';

import CampaignChip from '../CampaignChip';
import PartnerChip from '../PartnerChip';
import SegmentChip from '../SegmentChip';
import TemplateChip from '../TemplateChip';
import PersonName from './PersonName';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './ConversionTableColumns.css';

interface IPersonCellProps extends RowRenderProps {
  localUrl?: (url: string) => string;
  original: Conversion;
}

function PersonCell({ localUrl, original }: Readonly<IPersonCellProps>): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { Persons } = useStore();
        if (original.get('person')) {
          const model = Persons.get(original.get('person'));
          if (model && !model.isPending) {
            const person = model.toJS();
            return (
              <div className={styles.container}>
                <Avatar name={person.name} placeholder={person.placeholder} />
                <div className={styles.content}>
                  <PersonName localUrl={localUrl} person={person} />
                  {person.external_id && <span>{person.external_id}</span>}
                </div>
              </div>
            );
          }
        }
        return <div className={styles.container}>Anonymous</div>;
      }}
    </Observer>
  );
}

export const defaultColumnIds = [
  'd_c',
  'person',
  'category',
  'goals',
  'value',
  'window',
  'timeline',
];

export const ConversionTableColumns = (
  model: DisplayModel<ICampaignAttributes | IEvent | IFlight>,
  goals: IGoal[],
  targetings: IObservableArray<Targeting>,
  attributionModel: TAttributionModel,
  campaigns?: Campaign[],
  localUrl?: (url: string) => string,
  columnIds?: string[],
): Array<IColumn<Conversion>> => {
  const isEvent = model instanceof Event;
  const isFlight = model instanceof Flight;
  const columns: Array<IColumn<Conversion>> = [
    {
      Header: 'Date',
      id: 'd_c',
      checkboxLabel: 'Date',
      width: 120,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return (
                <>
                  {moment
                    .utc(original.get('d_c'), moment.ISO_8601)
                    .utc()
                    .format(TimeFormat.shortDate)}
                </>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'person',
      Header: 'Name',
      checkboxLabel: 'Name',
      sortable: false,
      className: classNames(tableStyles.cell, styles.person),
      Cell(row): JSX.Element {
        return <PersonCell {...row} localUrl={localUrl} />;
      },
    },
    {
      id: 'category',
      Header: 'Category',
      checkboxLabel: 'Category',
      sortable: true,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return <>{original.get('category')}</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'campaigns',
      Header: 'Campaigns',
      checkboxLabel: 'Campaigns',
      width: 150,
      sortable: false,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const campaignChips = original
                .get('attribution')
                [
                  attributionModel
                ]?.map((campaignAttribution: ICampaignAttribution) => <CampaignChip id={campaignAttribution.campaign} key={campaignAttribution.campaign} localUrl={localUrl} />);
              if ((campaignChips?.length || 0) > 0) {
                return <>{campaignChips}</>;
              }
              return <>-</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'source_target_segments',
      Header: 'Source',
      checkboxLabel: 'Source',
      width: 150,
      sortable: false,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const includedTargetSegments = targetings
                .filter((targeting) => targeting.get('included') === true)
                .map((targeting) => targeting.get('target_data'));
              const segmentChips = original
                .get('source_target_segments')
                .filter((segmentId: string) => includedTargetSegments.includes(segmentId))
                .map((segmentId: string) => (
                  <SegmentChip id={segmentId} key={segmentId} localUrl={localUrl} />
                ));
              if (segmentChips.length > 0) {
                return <>{segmentChips}</>;
              }
              return <>-</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'partner',
      Header: 'Partners',
      checkboxLabel: 'Partners',
      sortable: false,
      Cell({ original }): JSX.Element {
        const partnerIds = original.get('objects_touched').p_id;
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return (
                <>
                  {partnerIds
                    ? partnerIds.map((partnerId: string) => (
                        <PartnerChip id={partnerId} key={partnerId} localUrl={localUrl} />
                      ))
                    : ''}
                </>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'template',
      Header: 'Templates',
      checkboxLabel: 'Templates',
      sortable: false,
      Cell({ original }): JSX.Element {
        const templateIds = original.get('objects_touched').t_id;
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return (
                <>
                  {templateIds
                    ? templateIds.map((templateId: string) => (
                        <TemplateChip id={templateId} key={templateId} localUrl={localUrl} />
                      ))
                    : ''}
                </>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'goals',
      Header: 'Goals',
      checkboxLabel: 'Goals',
      sortable: false,
      width: 150,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              // Filter to goal objects that are in the conversion's goals list, deduplicated by group
              const conversionGoals = goals.filter(
                (g, i, arr) =>
                  original.get('goals', []).includes(g.id) &&
                  arr.map((v) => v.segment).indexOf(g.segment) === i,
              );
              if (conversionGoals) {
                return (
                  <>
                    {conversionGoals.map((conversionGoal) => (
                      <SegmentChip
                        id={conversionGoal.segment!}
                        key={conversionGoal.id}
                        localUrl={localUrl}
                      />
                    ))}
                  </>
                );
              }
              return <>-</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'value',
      Header: 'Value',
      checkboxLabel: 'Value',
      sortable: false,
      width: 100,
      className: tableStyles.cellRight,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const conv = original;
              if (conv.get('value')) {
                return <>{numeral(conv.get('value')).format('$0,0.00')}</>;
              }
              const attributions = conv.get('attribution')[attributionModel];
              const attributedCampaigns = attributions?.map((a) => a.campaign);
              const conversionGoals = goals.filter((goal) => {
                const convIsForGoal = conv.get('goals', []).includes(goal.id);
                if (goal.kind === 'campaign') {
                  // Is the conversion attributed to the campaign for this goal?
                  return attributedCampaigns?.includes(goal.parent) && convIsForGoal;
                } else if (goal.kind === 'flight') {
                  const flightCampaignIds = (campaigns || [])
                    .filter((campaign) => campaign.get('flight') === goal.parent)
                    .map((campaign) => campaign.id);
                  // Is the conversion attributed to a campaign in this flight/project?
                  return (
                    (attributedCampaigns || []).filter((campaignId) =>
                      flightCampaignIds.includes(campaignId),
                    ).length > 0 && convIsForGoal
                  );
                }
                return false;
              });
              const attributionScores = attributions?.reduce(function (obj, a) {
                obj[a.campaign] = a.score;
                return obj;
              }, {});
              if (conversionGoals.length) {
                return (
                  <>
                    {numeral(
                      conversionGoals.reduce(
                        (acc, goal) =>
                          goal.kind === 'flight'
                            ? acc + goal.conv_value
                            : acc + goal.conv_value * (attributionScores?.[goal.parent] || 0),
                        0,
                      ),
                    ).format('$0,0.00')}
                  </>
                );
              }
              return <>$0</>;
            }}
          </Observer>
        );
      },
    },
    {
      Header: 'Window',
      id: 'window',
      checkboxLabel: 'Window',
      sortable: false,
      width: 100,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const startTS = original.get('date_enter_funnel');
              const endTS = original.get('d_c');
              const start = moment.utc(startTS, moment.ISO_8601);
              const end = moment.utc(endTS, moment.ISO_8601);
              return <>{moment.duration(start.diff(end)).humanize()}</>;
            }}
          </Observer>
        );
      },
    },
    {
      Header: 'Timeline',
      id: 'timeline',
      checkboxLabel: 'Timeline',
      sortable: false,
      expander: true,
      width: 80,
      className: classNames(tableStyles.cellCenter, styles.timeline),
      Expander: ({ isExpanded }): JSX.Element => (
        <>
          {isExpanded ? (
            <>
              <span>Hide</span>
              <FontAwesomeIcon icon={faCaretDown} />
            </>
          ) : (
            <>
              <span>Show</span>
              <FontAwesomeIcon icon={faCaretLeft} />
            </>
          )}
        </>
      ),
    },
    {
      Header: 'Details',
      id: 'details',
      checkboxLabel: 'Details',
      sortable: false,
      width: 80,
      className: tableStyles.cellCenter,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              // Use setRedirect to construct return path.
              const [, setRedirect] = useRedirect();
              const conversionId = original.id;
              const campaignId = original.get('campaigns')[0];
              const link =
                isEvent || isFlight
                  ? model.getItemUrl(`campaigns/${campaignId}/conversions/${conversionId}`)
                  : model.getItemUrl(`conversions/${conversionId}`);
              return (
                <Button
                  className={styles.button}
                  link={localUrl ? setRedirect(localUrl(link)) : ''}
                >
                  Details
                </Button>
              );
            }}
          </Observer>
        );
      },
    },
  ];

  const filteredColumnIds = [...defaultColumnIds];

  if (localUrl) {
    filteredColumnIds.push('details');
  }
  if (model instanceof Event || model instanceof Flight) {
    filteredColumnIds.push('campaigns');
  }
  if (targetings.length > 0) {
    filteredColumnIds.push('source_target_segments');
  }
  if (model instanceof Campaign && model.get('_cls') === CampaignClass.Referral) {
    filteredColumnIds.push('partner', 'template');
  }

  return columnIds
    ? columns.filter((column) => columnIds.includes(column.id!))
    : columns.filter((column) => filteredColumnIds.includes(column.id!));
};
