import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useParams } from 'react-router';

import { Button, List, ListItem, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import noData from '@feathr/extender/images/nodata.svg';
import { useLocalUrl, useStore } from '@feathr/extender/state';

import Flight from './Flight';

import * as styles from './FlightsListPage.css';

function FlightsListPage(): JSX.Element {
  const { Events, Flights } = useStore();
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();

  const event = Events.get(eventId);
  const flights = Flights.list({
    filters: {
      event_id: eventId,
      is_archived: false,
    },
  });

  const actions =
    flights.models.length > 0 ? (
      <Toolbar>
        <Button
          link={localUrl(`/projects/${eventId}/flights/add`)}
          prefix={<FontAwesomeIcon icon={faPlus} />}
          type={'primary'}
        >
          Add flight
        </Button>
      </Toolbar>
    ) : undefined;

  return (
    <Page
      actions={actions}
      pageTitle={event.get('name') ? `${event.get('name')}: Flights` : undefined}
      title={'Flights'}
      width={'wide'}
    >
      <List>
        {flights.models.map((flight) => (
          <ListItem className={styles.item} key={flight.id}>
            <Flight flight={flight} />
          </ListItem>
        ))}
        {flights.isPending === false && flights.models.length === 0 && (
          <div className={styles.noFlightsContainer}>
            <svg height={320} width={320}>
              <image width={320} xlinkHref={noData} />
            </svg>
            <h4>No flights yet!</h4>
            <p>Using flights offers a number of benefits to your marketing workflow:</p>
            <ul style={{ listStyle: 'disc' }}>
              <li>Organize your marketing activities around a single goal.</li>
              <li>Reproduce your marketing success automatically, initiative after initiative.</li>
              <li>Customize one of our battle-tested flight paths or design your own.</li>
              <li>
                Gain and share valuable insights into how your campaigns are contributing to your
                goals.
              </li>
            </ul>
            <Button link={localUrl(`/projects/${eventId}/flights/add`)} type={'primary'}>
              Take flight!
            </Button>
          </div>
        )}
      </List>
    </Page>
  );
}

export default observer(FlightsListPage);
