import React from 'react';

import type { IFlight } from '@feathr/blackbox';
import { useStore } from '@feathr/extender/state';

import PathPreview from './PathPreview';

interface IProps {
  path: IFlight;
}

function CustomPathPreview(props: IProps) {
  const { path } = props;
  const { Campaigns } = useStore();
  const campaigns = Campaigns.list({
    filters: {
      flight: props.path.id,
    },
  });

  if (campaigns.isPending && campaigns) {
    return null;
  }

  const pathWithFullCampaigns = {
    ...path,
    legs: path.legs.map((leg) => ({
      ...leg,
      campaigns: leg.campaigns.map((campaign_id) => {
        const campaign = campaigns.models.find((c) => c.id === campaign_id);
        return campaign && campaign.toJS();
      }),
    })),
  };

  return <PathPreview path={pathWithFullCampaigns as any} />;
}

export default CustomPathPreview;
