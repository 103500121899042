import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer, observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ToastType } from 'react-toastify';

import type { IButtonProps } from '@feathr/components';
import { Button, Fieldset, Input, ModalV1, toast } from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

function CreatePersonButton({ ...buttonProps }: IButtonProps): JSX.Element {
  const { Persons } = useStore();
  const { t } = useTranslation();
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const person = Persons.create();
  const localUrl = useLocalUrl();

  function toggleModalButton(): JSX.Element {
    return (
      <Button
        {...buttonProps}
        name={'create-person'}
        onClick={toggleIsModalOpen}
        prefix={<FontAwesomeIcon icon={faPlus} />}
        type={'primary'}
      >
        {t('New person')}
      </Button>
    );
  }

  function toastMessage(message: string, id: string): JSX.Element {
    return (
      <>
        {message} <Link to={localUrl(`/data/people/${id}`)}>{t('View')}</Link>
      </>
    );
  }

  async function handleCreatePerson(): Promise<void> {
    // Retrieve person data
    const email = person.get('email');
    const firstName = person.get('first_name');
    const lastName = person.get('last_name');

    try {
      // Open modal to indicate action
      toggleIsModalOpen();

      // Create the person
      const { id, create } = await person.createPerson();

      // Construct toast message based on creation result
      let message: string;
      if (create) {
        message =
          firstName && lastName
            ? t('{{firstName}} {{lastName}} has been added.', { firstName, lastName })
            : t('{{email}} has been added.', { email });
      } else {
        message =
          firstName && lastName
            ? t('{{firstName}} {{lastName}} already exists.', { firstName, lastName })
            : t('{{email}} already exists.', { email });
      }

      // Determine toast type based on creation result
      const toastType = create ? ToastType.SUCCESS : ToastType.INFO;

      // Show toast message
      toast(toastMessage(message, id), { type: toastType });
    } catch (error) {
      // Show error toast message
      toast(t('There was an error creating a new person.\n{{- error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
  }

  function personFields(): JSX.Element {
    return (
      <Fieldset>
        <Input
          attribute={'email'}
          label={t('Primary email')}
          model={person}
          required={true}
          title={t('Primary email')}
          type={'email'}
        />
        <Input
          attribute={'first_name'}
          label={t('First name')}
          model={person}
          title={t('First name')}
          type={'text'}
        />
        <Input
          attribute={'last_name'}
          label={t('Last name')}
          model={person}
          title={t('Last name')}
          type={'text'}
        />
      </Fieldset>
    );
  }

  function createPersonModal(): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => (
          <ModalV1
            confirmButtonText={t('Add')}
            confirmButtonType={'primary'}
            confirmDisabled={person.get('email', '') === '' || !person.isValid(['email'], false)}
            controlled={true}
            description={t(
              "Once this person is created, you'll have the ability to add more data.",
            )}
            onClose={toggleIsModalOpen}
            onConfirm={handleCreatePerson}
            size={'sm'}
            t={t}
            title={t('Add new person')}
          >
            {personFields()}
          </ModalV1>
        )}
      </Observer>
    );
  }

  return (
    <>
      {toggleModalButton()}
      {isModalOpen && createPersonModal()}
    </>
  );
}

export default observer(CreatePersonButton);
