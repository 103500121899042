import type { DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import { DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { FieldDataType, Form, IRowItem } from '@feathr/blackbox';
import { defaultPersonAttributes, FieldCollection } from '@feathr/blackbox';
import { toast } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import Builder from './Builder';
import ConfigurationPanel from './ConfigurationPanel';
import type { IDragOverEvent } from './FormEditor.utils';
import { onDragOver } from './FormEditor.utils';
import Field from './FormFields/Field';

import * as styles from './FormEditor.css';

interface IProps {
  form: Form;
}

function FormEditor({ form }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { CustomFields } = useStore();
  const [allFields, setAllFields] = useState<IRowItem[]>([]);
  const [availableFields, setAvailableFields] = useState<IRowItem[]>([]);
  const [usedFields, setUsedFields] = useState<IRowItem[]>([]);
  const [activeField, setActiveField] = useState<UniqueIdentifier | undefined>(undefined);
  const [focusedField, setFocusedField] = useState<IRowItem | undefined>(undefined);
  const mouseSensor = useSensor(MouseSensor);
  const sensors = useSensors(mouseSensor);

  useEffect(() => {
    async function init(): Promise<void> {
      const defaultFieldOptions = defaultPersonAttributes.map((field) => ({
        id: field.u_key,
        label: field.u_key,
        name: field.u_key,
        type: field.data_type as FieldDataType,
      }));
      try {
        const customFields = CustomFields.list({ filters: { collection: FieldCollection.Person } });
        await when(() => !customFields.isPending);
        if (customFields.isErrored) {
          toast(t('Failed to load custom fields: {{- error}}', { error: customFields.error }), {
            type: ToastType.ERROR,
          });
          setAllFields(defaultFieldOptions);
          return;
        }
        const customFieldOptions = customFields.models.map((field) => ({
          id: field.get('u_key'),
          label: field.get('u_key'),
          name: field.get('u_key'),
          type: field.get('data_type'),
        }));

        const allOptions = [...defaultFieldOptions, ...customFieldOptions];

        const usedOptions = form.usedFields;
        const usedIds = usedOptions.map((field) => field.id);
        const availableOptions = allOptions.filter((field) => !usedIds.includes(field.id));
        setAllFields(allOptions);
        setUsedFields(usedOptions);
        setAvailableFields(availableOptions);
      } catch (error) {
        toast(t('Failed to load custom fields: {{- error}}', { error }), {
          type: ToastType.ERROR,
        });
        setAllFields(defaultFieldOptions);
      }
    }

    init();
  }, []);

  function handleDragStart(event: DragStartEvent): void {
    setActiveField(event.active.id);
  }

  function handleDragOver(event: IDragOverEvent): void {
    onDragOver({
      availableFields,
      setAvailableFields,
      usedFields,
      setUsedFields,
      ...event,
    });
  }

  function updateFieldProperties(key: UniqueIdentifier, value: unknown): void {
    if (focusedField !== undefined) {
      const updatedField = { ...focusedField, [key]: value };
      setFocusedField(updatedField);

      function updateField(prevField: IRowItem): IRowItem {
        if (prevField.id === focusedField!.id) {
          return updatedField;
        }
        return prevField;
      }

      setUsedFields((prevFields) => prevFields.map(updateField));
    }
  }

  return (
    <div className={styles.root}>
      <DndContext onDragOver={handleDragOver} onDragStart={handleDragStart} sensors={sensors}>
        <SortableContext
          id={'configuration-panel'}
          items={availableFields}
          strategy={rectSortingStrategy}
        >
          <ConfigurationPanel
            fields={availableFields}
            focusedField={focusedField}
            onFocusField={setFocusedField}
            updateFieldProperties={updateFieldProperties}
          />
        </SortableContext>
        <SortableContext id={'form-builder'} items={usedFields} strategy={rectSortingStrategy}>
          <Builder fields={usedFields} form={form} onFocusField={setFocusedField} />
        </SortableContext>
        <DragOverlay className={styles.dragOverlay}>
          {activeField && allFields.find((field) => field.id === activeField) ? (
            <Field
              field={allFields.find((field) => field.id === activeField)!}
              isDragOverlay={true}
            />
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
}

export default observer(FormEditor);
