import type { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import moment from 'moment-timezone';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type {
  Campaign,
  CustomField,
  ICampaignAttributes,
  IPinpointTrigger,
  TComparison,
} from '@feathr/blackbox';
import { CampaignClass, FieldCollection } from '@feathr/blackbox';
import { FormSummaryItem, Value } from '@feathr/components';
import type { IAttrOption } from '@feathr/extender/components/EditFilters/PredicateInputs';
import { useStore, useUser } from '@feathr/extender/state';
import { flattenErrors, TimeFormat } from '@feathr/hooks';

import type { ICampaignValidationErrors } from '../CampaignSummary.types';

import * as styles from './TriggerSummary.css';

import defaultBreadcrumbFields from '@feathr/blackbox/fixtures/defaultBreadcrumbFields.json';
import defaultPersonFields from '@feathr/blackbox/fixtures/defaultPersonFields.json';

interface IProps {
  campaign: Campaign<ICampaignAttributes>;
  /** Only shows the triggers summary */
  showMinimal?: boolean;
  validationErrors?: ICampaignValidationErrors;
}

interface IComparisonOption {
  id: TComparison;
  name: string;
}

function getComparisons(t: TFunction, attr: IPinpointTrigger): IComparisonOption[] {
  const baseOptions: IComparisonOption[] = [
    { id: 'exists', name: t('exists') },
    { id: 'nexists', name: t('does not exist') },
  ];
  const numberOptions: IComparisonOption[] = [
    ...baseOptions,
    { id: 'eq', name: t('equals') },
    { id: 'ne', name: t('does not equal') },
    { id: 'gt', name: t('is greater than') },
    { id: 'lt', name: t('is less than') },
  ];
  switch (attr.attr_type) {
    case 'string':
      if (attr.attr_against?.includes('_id')) {
        return [...baseOptions, { id: 'eq', name: t('is') }, { id: 'ne', name: t('is not') }];
      }
      return [
        ...baseOptions,
        { id: 'eq', name: t('is') },
        { id: 'ne', name: t('is not') },
        { id: 'starts_with', name: t('starts with') },
        { id: 'wildcard', name: t('matches pattern') },
        { id: 'regexp', name: t('matches the expression') },
        { id: 'contains_substring', name: t('contains') },
      ];

    case 'integer':

    case 'float':
      return numberOptions;

    case 'boolean':
      return [
        { id: 'eq', name: t('is') },
        { id: 'ne', name: t('is not') },
      ];

    case 'date':
      return [
        ...baseOptions,
        { id: 'eq_date_r', name: t('is') },
        { id: 'ne_date_r', name: t('is not') },
        { id: 'gt_date_r', name: t('is after') },
        { id: 'lt_date_r', name: t('is before') },
      ];

    case 'list':
      return [
        ...baseOptions,
        { id: 'in', name: t('contains') },
        { id: 'nin', name: t('does not contain') },
      ];

    default:
      return baseOptions;
  }
}

const TriggerSummary = observer(({ campaign, showMinimal = false, validationErrors }: IProps) => {
  const user = useUser();
  const { t } = useTranslation();
  const { CustomFields } = useStore();
  const customFields = CustomFields.list({
    filters: { collection__in: [FieldCollection.Person, FieldCollection.Breadcrumb] },
    pagination: { page: 0, page_size: 1000 },
  });

  function customFieldToOption(field: CustomField): IAttrOption {
    return {
      id: `custom.${field.get('f_key')}`,
      name: field.get('u_key'),
      type: field.get('data_type'),
      kind: field.get('collection') as 'Person' | 'Breadcrumb',
      helpText: field.get('description'),
    };
  }

  const allFields = [
    ...defaultPersonFields,
    ...defaultBreadcrumbFields,
    ...customFields.models.map((field) => customFieldToOption(field)),
  ];

  const delayUnitMap = {
    seconds: t('seconds'),
    minutes: t('minutes'),
    hours: t('hours'),
    days: t('days'),
    weeks: t('weeks'),
  };

  const isDrip = campaign.get('_cls') === CampaignClass.Drip;
  const actions = campaign.get('actions', []);
  const stepSpecs = isDrip ? campaign.get('step_specs', []) : [];

  const dripStep1 = stepSpecs?.[0] ?? undefined;
  const subType = isDrip ? dripStep1.subtype : campaign.get('subtype');
  const delayValue = isDrip ? dripStep1.delay_value : campaign.get('delay_value', 5);
  const delayUnit = isDrip ? dripStep1.delay_unit : campaign.get('delay_unit', 'seconds');

  function renderSubtypeSpecificSummary(): JSX.Element {
    if (subType === 'time') {
      const timeToSend = isDrip ? dripStep1.time_to_send : campaign.get('time_to_send');
      const userTimezone = user.get('timezone');
      const localTimeToSend = moment
        .utc(timeToSend)
        .tz(userTimezone)
        .format(TimeFormat.timeAndTimeZone);

      return <FormSummaryItem label={t('Time of day')} value={localTimeToSend} />;
    }
    /**
     * 5 seconds = "immediately" because that's the soonest we can send
     * an email in response to a trigger.
     */
    const delayValueFormatted =
      delayValue <= 5 && delayUnit === 'seconds'
        ? t('Immediately')
        : `${delayValue} ${delayUnitMap[delayUnit]}`;

    return <FormSummaryItem label={t('Delay')} value={delayValueFormatted} />;
  }

  // Minimal triggers should not risplay in a numbered list
  const WrapperEl = showMinimal ? 'ul' : 'ol';

  const triggersSummary = (
    <FormSummaryItem
      label={!showMinimal ? t('Trigger', { count: actions.length }) : undefined}
      value={
        actions.length > 0 ? (
          <WrapperEl className={styles.triggerList}>
            {actions.map((action, i) => {
              const error = validationErrors?.actions ?? ([] as Array<Record<string, string[]>>);
              const attrLabel =
                allFields.find((field) => field.id === action.attr_against)?.name ?? '-';
              const comparison =
                getComparisons(t, action).find((option) => option.id === action.comparison)?.name ??
                '-';

              function getValueLabel(
                value?: string | number,
                comparison?: string,
              ): JSX.Element | string | number | null {
                if (['exists', 'nexists'].includes(action.comparison)) {
                  return null;
                }

                if (value === undefined) {
                  return <em>{t('empty')}</em>;
                }

                if (comparison?.includes('_r') && typeof value === 'number') {
                  if (value === 0) {
                    return t('today');
                  }

                  if (value >= 1) {
                    return t('{{count}} day from now', { count: value });
                  }

                  if (value < 0) {
                    return t('{{count}} day ago', { count: Math.abs(value) });
                  }
                }

                return value;
              }

              const value =
                showMinimal &&
                attrLabel === '-' &&
                comparison === '-' &&
                action.value === undefined ? (
                  t('Not set')
                ) : (
                  <>
                    {attrLabel} {comparison} {getValueLabel(action.value, action.comparison)}
                  </>
                );

              return (
                // index is ok as a key here because we aren't rearranging these elements
                // eslint-disable-next-line react/no-array-index-key
                <li key={i}>
                  <Value
                    className={styles.triggerActivity}
                    validationError={error[i] ? flattenErrors(error[i]) : undefined}
                    value={value}
                  />
                </li>
              );
            })}
          </WrapperEl>
        ) : (
          t('(None)')
        )
      }
    />
  );

  return showMinimal ? (
    triggersSummary
  ) : (
    <>
      {triggersSummary}
      {renderSubtypeSpecificSummary()}
      {campaign.get('_cls') === CampaignClass.AutoPinpointEmail && (
        <FormSummaryItem label={t('Repeat')} value={campaign.get('repeat') ? t('Yes') : t('No')} />
      )}
      {isDrip && <FormSummaryItem label={t('Steps')} value={stepSpecs.length} />}
    </>
  );
});

TriggerSummary.displayName = 'TriggerSummary';

export default TriggerSummary;
