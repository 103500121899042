import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { DataRequest } from '@feathr/blackbox';
import { Button, Form, FormSummary, FormSummaryItem } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import type { TValidateGrouped } from '@feathr/rachis';

import ButtonSubmit from './ButtonSubmit';
import { validateStepFour } from './DataRequestFormStepFour';
import { validateStepOne } from './DataRequestFormStepOne';
import { validateStepThree } from './DataRequestFormStepThree';
import { validateStepTwo } from './DataRequestFormStepTwo';
import GTMPermissionDisplay from './GTMPermissionDisplay';
import RequestFieldSummary from './RequestFieldSummary';

import * as styles from './DataRequestFormStepSix.css';

interface IProps {
  dataRequest: DataRequest;
  onSave: () => void;
  onPrevious: () => void;
}

function validateStepSix(dataRequest: DataRequest): TValidateGrouped {
  return {
    ...validateStepOne(dataRequest),
    ...validateStepTwo(dataRequest),
    ...validateStepFour(dataRequest),
    request_fields: validateStepThree(dataRequest),
  };
}

function DataRequestFormStepSix({
  dataRequest,
  onSave,
  onPrevious,
}: Readonly<IProps>): JSX.Element {
  const { Segments } = useStore();
  const { t } = useTranslation();

  const segmentId = dataRequest.get('conversion_pixel');
  const segment = segmentId ? Segments.get(segmentId) : undefined;

  const completed = dataRequest.get('state') !== 'draft' && dataRequest.get('state') !== 'canceled';

  const actions = [
    <Button disabled={completed} key={'previous'} onClick={onPrevious}>
      {t('Previous')}
    </Button>,
    <ButtonSubmit
      dataRequest={dataRequest}
      disabled={dataRequest.get('state') !== 'draft'}
      key={'submit'}
      onNext={onSave}
      type={'success'}
      validate={validateStepSix}
    >
      {t('Submit')}
    </ButtonSubmit>,
  ];

  return (
    <Form
      actions={actions}
      description={
        !completed && t('Check the summary below to make sure everything is configured correctly.')
      }
      label={t('Edit Message: Review')}
    >
      <FormSummary>
        <FormSummaryItem key={'field_name'} label={t('Name')} value={dataRequest.get('name')} />
        <FormSummaryItem
          key={'gtm_container'}
          label={t('GTM Container')}
          value={dataRequest.get('gtm_container')}
        />
        <GTMPermissionDisplay dataRequest={dataRequest} displayType={'short'} />
        <FormSummaryItem
          className={styles.preformatted}
          key={'context'}
          label={t('Context')}
          value={dataRequest.get('context')}
        />
        <FormSummaryItem
          key={'promo_code'}
          label={t('Promo code')}
          value={dataRequest.get('promo_code')}
        />
        <FormSummaryItem
          key={'url_start'}
          label={t('URL start')}
          value={dataRequest.get('url_start')}
        />
        <FormSummaryItem
          className={styles.preformatted}
          key={'instructions'}
          label={t('Instructions')}
          value={dataRequest.get('instructions')}
        />
        <RequestFieldSummary dataRequest={dataRequest} key={'request_fields'} />
        {!!segment ? (
          <>
            <FormSummaryItem
              key={'conversion_pixel'}
              label={t('Conversion pixel')}
              value={segment.name || t('(None)')}
            />
            <FormSummaryItem
              key={'conversion_category'}
              label={t('Conversion category')}
              value={dataRequest.get('conversion_category') || t('(None)')}
            />
          </>
        ) : (
          <>
            <FormSummaryItem
              key={'form_submission_field'}
              label={t('Form Submit Field')}
              value={dataRequest.get('form_submission_field') || t('(None)')}
            />
            <FormSummaryItem
              key={'form_submission_value'}
              label={t('Form Submit Value')}
              value={dataRequest.get('form_submission_value') || t('(None)')}
            />
          </>
        )}
      </FormSummary>
    </Form>
  );
}

export default observer(DataRequestFormStepSix);
