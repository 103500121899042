import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Domain } from '@feathr/blackbox';
import { DebouncedInput, Input, Table } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import domainsColumns from './domainsColumns';

interface IFilters {
  content_domain?: { $regex?: string };
  email_domain?: { $regex?: string };
}

interface IQuery {
  __raw__: {
    $or: IFilters[];
  };
  // TODO: Filter by Content Serving and Email Sending status
}

function DomainsTable(): JSX.Element {
  const { Domains } = useStore();
  const { t } = useTranslation();
  const [filters, setFilters] = useState<IQuery>({
    __raw__: {
      $or: [{ email_domain: { $regex: '' } }, { content_domain: { $regex: '' } }],
    },
  });

  function handleChangeNameQuery(newValue?: string): void {
    setFilters({
      __raw__: {
        $or: [
          { email_domain: { $regex: newValue || '' } },
          { content_domain: { $regex: newValue || '' } },
        ],
      },
    });
  }

  const filterElements = [
    <DebouncedInput<string> key={'debounce-name'} onChange={handleChangeNameQuery}>
      {(liveValue, onChangeLiveValue): JSX.Element => (
        <Input
          isClearable={true}
          key={'name'}
          label={t('Name')}
          onChange={onChangeLiveValue}
          placeholder={t('Search by name...')}
          type={'text'}
          value={liveValue}
        />
      )}
    </DebouncedInput>,
  ];

  return (
    <Table<Domain>
      collection={Domains}
      columns={domainsColumns(t)}
      filterElements={filterElements}
      filters={filters}
      initialSort={[{ id: 'date_created', desc: true }]}
      noDataText={t('You have not registered any domains yet.')}
    />
  );
}

export default observer(DomainsTable);
