import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import { Chip } from '@feathr/components';
import { useStore } from '@feathr/report_components/state';
import { templateTypeColor, templateTypeIcon } from '@feathr/report_components/styles/template';

interface IProps {
  id: string;
  localUrl?: (url: string) => string;
}

function TemplateChip({ id, localUrl }: Readonly<IProps>): JSX.Element | null {
  const { Templates } = useStore();

  const template = Templates.get(id);
  if (template.isErrored) {
    return null;
  }

  if (template.isPending) {
    return <Chip isLoading={true} />;
  }

  const cls = template.get('_cls');
  if (!cls) {
    return null;
  }
  const content = (
    <Chip prefix={<FontAwesomeIcon icon={templateTypeIcon(cls)} />} theme={templateTypeColor(cls)}>
      {template.get('name')}
    </Chip>
  );

  if (localUrl) {
    return <Link to={localUrl(template.getItemUrl())}>{content}</Link>;
  }
  return content;
}

export default observer(TemplateChip);
