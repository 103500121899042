import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormSummaryItem } from '@feathr/components';

import type { ISummaryProps } from './CampaignSummary.types';

const EmailSummary = observer(({ campaign, validationErrors }: ISummaryProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <FormSummaryItem
      errors={validationErrors?.from_address}
      label={t('From address')}
      value={campaign.get('from_address')}
    />
  );
});

EmailSummary.displayName = 'EmailSummary';

export default EmailSummary;
