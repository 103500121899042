import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { IMergeField, ITemplate, PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { CampaignClass, TemplateClass } from '@feathr/blackbox';
import { Button, ButtonValid, Card, Fieldset, Form, Input, Toolbar } from '@feathr/components';
import MergetagSelect from '@feathr/extender/components/MergetagSelect';
import TemplateSelect from '@feathr/extender/components/TemplateSelect';
import TemplatesTable from '@feathr/extender/components/TemplatesTable';
import TemplateColumnName from '@feathr/extender/components/TemplatesTable/TemplateColumnName';
import TemplateColumnPinpointOptions from '@feathr/extender/components/TemplatesTable/TemplateColumnPinpointOptions';
import TemplateColumnThumbnail from '@feathr/extender/components/TemplatesTable/TemplateColumnThumbnail';
import { useStore } from '@feathr/extender/state';
import { flattenErrors, useToggle } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface IButtonProps {
  campaign: PinpointEmailBaseCampaign;
  onNext: () => void;
}

interface IProps extends IButtonProps {
  onPrev: () => void;
  disabled: boolean;
}

export function validateStepFour(campaign: PinpointEmailBaseCampaign): TValidateGrouped {
  return campaign.validate(['template_id', 'subject'], false, 'grouped').errors;
}

const NextStepButton = observer(({ campaign, onNext }: IButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const errors = validateStepFour(campaign);
  return (
    <ButtonValid errors={flattenErrors(errors)} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function PinpointEmailCampaignStepFour({
  campaign,
  disabled,
  onNext,
  onPrev,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const { Templates } = useStore();
  const [isTemplateSelectVisible, toggleTemplateSelectVisible] = useToggle(false);
  const [newTemplate, setNewTemplate] = useState<ITemplate | undefined>();
  const [mergetagTemplate] = useState(
    Templates.create({ _cls: TemplateClass.PinpointEmail, account: campaign.get('account') }),
  );
  const isPartnerMessageCampaign = campaign.get('_cls') === CampaignClass.PinpointPartnerMessage;

  useEffect(() =>
    autorun(() => {
      const templateId = campaign.get('template_id');
      const subject = campaign.get('subject');
      if (templateId) {
        const template = Templates.get(templateId);
        template.set({ subject });
      }
    }),
  );

  const handleCancel = useCallback(() => {
    setNewTemplate(undefined);
    toggleTemplateSelectVisible();
  }, [toggleTemplateSelectVisible]);

  const handleAdd = useCallback(async () => {
    // Button is disabled if newTemplate is not set.
    if (!newTemplate || !newTemplate.id) {
      return;
    }
    await campaign.patchDirty();
    const duplicatedTemplate = await Templates.clone(newTemplate.id, {
      campaign_id: campaign.id,
      event_id: campaign.get('event')!,
      _cls: TemplateClass.PinpointEmail,
    });
    campaign.addTemplate(duplicatedTemplate);
    handleCancel();
  }, [Templates, campaign, handleCancel, newTemplate]);

  const handleSelectOption = useCallback(
    (option: IMergeField) => {
      const subject = campaign.get('subject', '');
      campaign.set({ subject: subject.concat(option.value) });
    },
    [campaign],
  );

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <NextStepButton campaign={campaign} key={'next'} onNext={onNext} />,
      ]}
      description={
        <Trans t={t}>
          <p>
            Choose a subject, template, and submit a plain text body for browsers that don't support
            HTML.
          </p>
        </Trans>
      }
      label={t('Edit Campaign: Template')}
    >
      <Fieldset label={t('Email Subject')}>
        <Input
          attribute={'subject'}
          disabled={disabled}
          helpText={t('The subject line for the email your audience will receive.')}
          model={campaign}
          name={'email_subject'}
          required={true}
          type={'text'}
        />
        <MergetagSelect
          disabled={disabled}
          isPartnerMessageCampaign={isPartnerMessageCampaign}
          name={'merge_tags'}
          onChange={handleSelectOption}
          template={mergetagTemplate}
          tooltip={
            <Trans t={t}>
              <p>
                You can use merge tags to personalize the subject line of your message. Choose the
                data you want to merge in from this dropdown to insert it at the end of your current
                subject line.
              </p>
            </Trans>
          }
        />
      </Fieldset>
      <Fieldset label={t('Template')}>
        <TemplatesTable
          columns={[
            TemplateColumnThumbnail,
            TemplateColumnName,
            TemplateColumnPinpointOptions({
              t,
              editMetadata: false,
              editTemplate: false,
              removeTemplate: disabled,
            }),
          ]}
          isPaginated={false}
          model={campaign}
          noDataText={t('Choose a template to get started.')}
        />
        {!campaign.get('template_id') && (
          <>
            {!isTemplateSelectVisible ? (
              <Toolbar align={'left'}>
                <Button
                  disabled={disabled}
                  key={'add'}
                  name={'add_template'}
                  onClick={toggleTemplateSelectVisible}
                  prefix={<FontAwesomeIcon icon={faPlus} />}
                  type={'primary'}
                >
                  {t('Add template')}
                </Button>
              </Toolbar>
            ) : (
              <Card>
                <TemplateSelect
                  onChange={setNewTemplate}
                  templateClass={TemplateClass.PinpointEmail}
                  value={newTemplate}
                />
                <Toolbar>
                  <Button key={'cancel'} name={'save_template_cancel'} onClick={handleCancel}>
                    {t('Cancel')}
                  </Button>
                  <Button
                    disabled={!newTemplate || disabled}
                    key={'select'}
                    name={'save_template'}
                    onClick={handleAdd}
                  >
                    {t('Add template')}
                  </Button>
                </Toolbar>
              </Card>
            )}
          </>
        )}
      </Fieldset>
    </Form>
  );
}

export default observer(PinpointEmailCampaignStepFour);
