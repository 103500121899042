import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ToastType } from 'react-toastify';

import type { IPartnerExport } from '@feathr/blackbox';
import { Button, toast, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useStore, useUser } from '@feathr/extender/state';

import EventPartnerExportCard from './EventPartnerExportCard';

import * as styles from './EventPartnersExportsPage.css';

function EventPartnersExportsPage(): JSX.Element {
  const { eventId } = useParams<{ eventId: string }>();
  const { PartnerExports } = useStore();
  const partnerExports = PartnerExports.list({
    filters: { event: eventId, is_archived__ne: true },
    ordering: ['-date_created'],
  });
  const user = useUser();
  const { t } = useTranslation();

  function addExport(): void {
    try {
      const exportAttrs: Record<keyof Pick<IPartnerExport, 'event' | 'created_by'>, string> = {
        event: eventId,
        created_by: user.id,
      };
      const partnerExport = PartnerExports.create(exportAttrs);
      partnerExports.models.unshift(partnerExport);
    } catch (e) {
      toast(t('There was an error creating the export.'), { type: ToastType.ERROR });
    }
  }

  const exportPartnersButton: JSX.Element = (
    <Button onClick={addExport} prefix={<FontAwesomeIcon icon={faPlus} />} type={'primary'}>
      {t('New export')}
    </Button>
  );

  const actions: JSX.Element = <Toolbar>{exportPartnersButton}</Toolbar>;
  const hasExports = !!partnerExports.models.length;

  const noExportsMessage: JSX.Element = (
    <div className={styles.noExports}>
      <h4>{t('No Exports')}</h4>
      <p>{t('You have not created any exports.')}</p>
      {exportPartnersButton}
    </div>
  );

  return (
    <Page actions={hasExports && actions} loading={partnerExports.isPending} title={t('Exports')}>
      {partnerExports.models.map((partnerExport) => (
        <EventPartnerExportCard key={partnerExport.id} partnerExport={partnerExport} />
      ))}
      {!partnerExports.isPending && !hasExports && noExportsMessage}
    </Page>
  );
}

export default observer(EventPartnersExportsPage);
