import { faCaretDown, faCloudUpload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { History } from 'history';
import type { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { Importers as ImportersCollection, IOverride, User } from '@feathr/blackbox';
import { Button, Dropdown, Menu, MenuItem, toast } from '@feathr/components';
import { useLocalUrl, useStore, useUser } from '@feathr/extender/state';
import { logUserEvents } from '@feathr/hooks';

interface IButtonProps {
  optOut: boolean;
}

async function addImporter(
  t: TFunction,
  user: User,
  history: History,
  Importers: ImportersCollection,
  localUrl: (url: string) => string,
  optOut: boolean,
): Promise<void> {
  try {
    const importerAttrs: Record<string, string | IOverride[]> = {
      created_by: user.id,
      kind: 'person',
      state: 'draft',
      overrides: optOut ? [{ feathr_attr: 'pp_opt_outs.all', attr_type: 'bool', value: true }] : [],
    };

    const importer = Importers.create(importerAttrs);
    const response = await Importers.add(importer, { validate: false });
    logUserEvents({
      [`Created ${optOut ? 'opt out' : 'contact list'} import`]: {
        account_id: user.get('account'),
        importer_id: response.id,
      },
    });
    history.push(localUrl(response.getItemUrl()));
  } catch (e) {
    toast(t('There was an error importing the data.'), { type: 'error' });
  }
}

const AddPersonImportButton = observer(({ optOut }: IButtonProps) => {
  const { t } = useTranslation();
  const { Importers } = useStore();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const user = useUser();

  async function handleAddPersonImporter(): Promise<void> {
    await addImporter(t, user, history, Importers, localUrl, optOut);
  }

  return (
    <MenuItem id={'addNewImporter'} onClick={handleAddPersonImporter}>
      {optOut ? t('Import opt out list') : t('Import contact data')}
    </MenuItem>
  );
});

function AddImporterButton(): JSX.Element {
  const { t } = useTranslation();

  const addImporterMenu = (
    <Menu>
      <AddPersonImportButton optOut={false} />
      <AddPersonImportButton optOut={true} />
    </Menu>
  );
  return (
    <Dropdown content={addImporterMenu} key={'addImporter'}>
      <Button
        id={'add'}
        prefix={<FontAwesomeIcon icon={faCloudUpload} />}
        suffix={<FontAwesomeIcon icon={faCaretDown} />}
        type={'primary'}
      >
        {t('Import data')}
      </Button>
    </Dropdown>
  );
}

export default observer(AddImporterButton);
