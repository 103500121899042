import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { CustomField } from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import { DebouncedInput, Input, Table } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import CustomFieldsCollectionSelect from './CustomFieldsCollectionSelect';
import FieldColumns, { ValuesTable } from './FieldColumns';

interface IFilters {
  u_key__icontains?: string;
  collection?: FieldCollection;
}

interface IProps {
  collection?: FieldCollection;
  showSelect?: boolean;
}

function CustomFieldsTable({ collection, showSelect = false }: IProps) {
  const { CustomFields } = useStore();
  const { t } = useTranslation();

  const [filters, setFilters] = useState<IFilters>({
    collection: collection !== undefined ? collection : FieldCollection.Person,
    u_key__icontains: undefined,
  });

  function handleDebouncedSearchChange(newValue?: string) {
    setFilters({
      ...filters,
      u_key__icontains: newValue,
    });
  }

  useEffect(() => {
    setFilters({
      collection: collection !== undefined ? collection : FieldCollection.Person,
      u_key__icontains: undefined,
    });
  }, [collection]);

  const filterElements = [
    showSelect ? (
      <CustomFieldsCollectionSelect
        onChange={(newValue) => {
          setFilters({
            ...filters,
            collection: newValue ? newValue : undefined,
          });
        }}
        value={filters.collection}
      />
    ) : undefined,
    <>
      <DebouncedInput<string>
        defaultValue={filters.u_key__icontains}
        onChange={handleDebouncedSearchChange}
      >
        {(liveValue, onChangeLiveValue) => (
          <Input
            isClearable={true}
            onChange={onChangeLiveValue}
            placeholder={t('Search by name...')}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
    </>,
  ];

  return (
    <Table<CustomField>
      collection={CustomFields}
      columns={FieldColumns(t)}
      filterElements={filterElements}
      filters={filters}
      initialSort={[{ id: 'u_key' }]}
      noDataText={t('No custom fields were found.')}
      SubComponent={ValuesTable}
    />
  );
}

export default observer(CustomFieldsTable);
