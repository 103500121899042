import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import type { ReferralCampaign } from '@feathr/blackbox';
import { Button, ButtonValid, Form } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import type { ILinkErrors } from '../../../CampaignSummary';
import CampaignLinksInput from './CampaignLinksInput';

interface IProps {
  campaign: ReferralCampaign;
  onNext: () => void;
  onPrev: () => void;
}

interface IErrors extends TValidateGrouped {
  links: ILinkErrors[];
}

export function validateStepFive(campaign: ReferralCampaign): IErrors {
  return campaign.validate<IErrors>(['links'], false, 'grouped').errors;
}

const NextStepButton = observer(({ campaign, onNext }: Omit<IProps, 'onPrev'>) => {
  const errors = validateStepFive(campaign);
  return (
    <ButtonValid errors={flattenErrors(errors)} onClick={onNext}>
      Next
    </ButtonValid>
  );
});

function StepFive({ campaign, onNext, onPrev }: IProps) {
  const errors = validateStepFive(campaign);

  const links = campaign.get('links');

  function handleChange(newLinks) {
    campaign.set({ links: newLinks });
  }

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          Previous
        </Button>,
        <NextStepButton campaign={campaign} key={'next'} onNext={onNext} />,
      ]}
      description={
        <>
          <p>
            Here you can add links to distribute to the partners participating in this campaign
            through their partner dashboard.
          </p>
          <p>
            This is a practical way to give your partners guidance on how to use their dashboard, or
            give them access to media kits or other downloadable resources you want them to have as
            part of their partnership with you.
          </p>
          <p>
            Click "Add link" to add a link. Then, put the URL of the resource you want to link to in
            the URL field, and give it a title in the Title field so your partners will recognize it
            when they see it in their dashboard.
          </p>
        </>
      }
      label={'Edit Campaign: Links'}
    >
      <CampaignLinksInput errors={errors.links} onChange={handleChange} value={toJS(links)} />
    </Form>
  );
}

export default observer(StepFive);
