import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import type { RowRenderProps } from 'react-table';

import type { Account } from '@feathr/blackbox';
import UserTLDR from '@feathr/extender/components/UserTLDR';
import { useStore } from '@feathr/extender/state';

interface IRow extends RowRenderProps {
  className?: string;
  original: Account;
}

function UserCell({ className, original }: IRow): JSX.Element {
  const { Users } = useStore();

  const csm = original.get('csm');

  if (csm) {
    const user = Users.get(
      csm,
      undefined,
      { only: ['email', 'id', 'name', 'picture'] },
      { fetchOptions: { headers: Users.getHeaders(false) } },
    );
    return <UserTLDR className={className} user={user} />;
  }
  return <>-</>;
}

export default observer(UserCell);
