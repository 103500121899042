import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { Template } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import { DebouncedInput, Input, Table } from '@feathr/components';
import TemplateGroupSelect from '@feathr/extender/components/TemplateGroupSelect';
import TemplateTypeSelect from '@feathr/extender/components/TemplateTypeSelect';
import { useAccount, useStore } from '@feathr/extender/state';
import type { TTemplateGroup } from '@feathr/extender/styles/template';
import type { IObject } from '@feathr/rachis';

import EventTemplatesPage from '../EventTemplatesPage';
import eventTemplatesGlobalColumns from './eventTemplatesGlobalColumns';

import * as styles from './EventTemplatesGlobalPage.css';

interface IFilters {
  name__icontains?: string;
  _cls?: TemplateClass;
  _cls__nin: TemplateClass[];
  group?: TTemplateGroup;
}

function EventTemplatesGlobalPage(): JSX.Element {
  const account = useAccount();
  const { Templates } = useStore();
  const { eventId } = useParams<{ eventId: string }>();
  const [filters, setFilters] = useState<IFilters>({
    _cls__nin: account?.isFinchLegacy
      ? [TemplateClass.Link]
      : [
          TemplateClass.Link,
          TemplateClass.ReferralPage,
          TemplateClass.ReferralEmail,
          TemplateClass.ReferralBanner,
        ],
  });
  const { t } = useTranslation();

  function handleDebouncedSearchChange(newValue?: string): void {
    setFilters({
      ...filters,
      name__icontains: newValue,
    });
  }

  function onChangeType(id?: TemplateClass): void {
    setFilters({
      ...filters,
      _cls: id,
    });
  }

  function onChangeGroup(id?: TTemplateGroup): void {
    setFilters({
      ...filters,
      group: id,
    });
  }

  const filterElements = [
    <>
      <TemplateTypeSelect
        onChange={onChangeType}
        value={filters._cls}
        wrapperClassName={styles.filter}
      />
      <TemplateGroupSelect
        onChange={onChangeGroup}
        value={filters.group}
        wrapperClassName={styles.filter}
      />
    </>,
    <>
      <DebouncedInput<string>
        defaultValue={filters.name__icontains}
        onChange={handleDebouncedSearchChange}
      >
        {(liveValue, onChangeLiveValue) => (
          <Input
            isClearable={true}
            onChange={onChangeLiveValue}
            placeholder={'Search by name...'}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
    </>,
  ];

  const rawFilter: IObject = {
    __raw__: {
      is_archived: false,
      $or: [],
    },
  };

  if (!filters.group || filters.group === 'default') {
    rawFilter.__raw__.$or.push({
      default: true,
    });
  }
  if (!filters.group || filters.group === 'account') {
    rawFilter.__raw__.$or.push({
      parent_kind: 'account',
      parent: `ObjectId('${account.id}')`,
      default: { $ne: true },
    });
  }
  if (!filters.group || filters.group === 'project') {
    rawFilter.__raw__.$or.push({
      parent_kind: 'event',
      parent: `ObjectId('${eventId}')`,
      default: { $ne: true },
    });
  }

  if (filters._cls) {
    rawFilter.__raw__._cls = filters._cls;
  } else if (filters._cls__nin) {
    rawFilter.__raw__._cls = { $nin: filters._cls__nin };
  }

  if (filters.name__icontains) {
    rawFilter.__raw__.name = { $regex: filters.name__icontains, $options: 'i' };
  }

  return (
    <EventTemplatesPage sectionTitle={t('Templates')} title={t('Saved Templates')}>
      <Table<Template>
        collection={Templates}
        columns={eventTemplatesGlobalColumns(t)}
        filterElements={filterElements}
        filters={rawFilter}
        initialSort={[{ id: 'date_last_modified', desc: true }]}
      />
    </EventTemplatesPage>
  );
}

export default EventTemplatesGlobalPage;
