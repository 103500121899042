import { faMedal, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { ICampaignPartnerStats } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader, Tooltip } from '@feathr/components';
import { useStore } from '@feathr/report_components/state';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './PartnersStatsColumns.css';

import noImg from 'images/no-img.png';

interface IProps {
  localUrl: ((url: string) => string) | undefined;
  pagination: Record<string, number>;
}

interface IRow {
  index: number;
  original: ICampaignPartnerStats;
}

export default function PartnersStatsColumns({
  localUrl,
  pagination,
}: IProps): Array<IColumn<ICampaignPartnerStats>> {
  const { t } = useTranslation();
  const { Partners } = useStore();

  function getRank(rawIndex): number {
    const page = pagination.page;
    const pageSize = pagination.page_size;
    const index = rawIndex + 1;
    if (page === 0) {
      return index;
    } else {
      return index + pageSize * page;
    }
  }

  return [
    {
      id: 'rank',
      Header: () => <>Rank</>,
      className: tableStyles.cellCenter,
      width: 70,
      Cell({ index }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return (
                <div
                  className={classNames(styles.rank, {
                    [styles.primary]: index >= 3 || pagination.page > 0,
                  })}
                >
                  {pagination.page === 0 && index <= 2 ? (
                    <FontAwesomeIcon
                      className={classNames({
                        [styles.gold]: index === 0,
                        [styles.silver]: index === 1,
                        [styles.bronze]: index === 2,
                      })}
                      icon={faMedal}
                    />
                  ) : (
                    <>{getRank(index)}</>
                  )}
                </div>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: 'Name',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              const partner = Partners.get(original.p_id);
              const logo = partner.get('logo') || noImg;
              return localUrl ? (
                <div className={styles.nameColumn}>
                  <Link className={styles.link} to={localUrl(partner.getItemUrl())}>
                    <div
                      className={styles.logo}
                      style={{ ['--l-background-image' as any]: `url('${logo}')` }}
                    />
                    <span>{partner.get('name')}</span>
                  </Link>
                  {original.error && (
                    <Tooltip
                      className={styles.tooltip}
                      title={t(
                        "This partner's stats are under-reporting because this partner generated more activity than can be tracked.",
                      )}
                    >
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        // 1.5em
                        size={'lg'}
                      />
                    </Tooltip>
                  )}
                </div>
              ) : (
                <div className={styles.nameColumn}>
                  <div className={styles.link}>
                    <div
                      className={styles.logo}
                      style={{ ['--l-background-image' as any]: `url('${logo}')` }}
                    />
                    <span>{partner.get('name')}</span>
                  </div>
                  {original.error && (
                    <Tooltip
                      className={styles.tooltip}
                      title={t(
                        "This partner's stats are under-reporting because this partner generated more activity than can be tracked.",
                      )}
                    >
                      <FontAwesomeIcon icon={faTriangleExclamation} size={'lg'} />
                    </Tooltip>
                  )}
                </div>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'clicks',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Clicks',
      }),
      checkboxLabel: 'Clicks',
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      width: 70,
      Cell({ original }: IRow): JSX.Element {
        return <>{original.clicks}</>;
      },
    },
    {
      id: 'views',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Views',
      }),
      checkboxLabel: 'Views',
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      width: 70,
      Cell({ original }: IRow): JSX.Element {
        return <>{original.views}</>;
      },
    },
    {
      id: 'leads',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Leads',
      }),
      checkboxLabel: 'Leads',
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      width: 70,
      Cell({ original }: IRow): JSX.Element {
        return <>{original.leads}</>;
      },
    },
    {
      id: 'conversions',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Conversions',
      }),
      checkboxLabel: 'Conversions',
      headerClassName: tableStyles.sort,
      className: tableStyles.cellRight,
      width: 120,
      Cell({ original }: IRow): JSX.Element {
        return <>{original.conversions}</>;
      },
    },
  ];
}
