import { runInAction, toJS, when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Option } from 'react-select/src/filters';
import { ToastType } from 'react-toastify';

import { EUserRoleIDs, type UserRole } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { AsyncSelect, toast } from '@feathr/components';
import { useAccount, useRole, useStore, useUser } from '@feathr/extender/state';
import type { ListResponse } from '@feathr/rachis';

interface IRoleSelectProps {
  /** ID to disable from the options menu. */
  disableById?: string;
  /** ID to exclude from the options menu. */
  excludeById?: string;
  helpText?: ReactNode;
  label?: ReactNode;
  menuPortalTarget?: HTMLElement;
  userId?: string;
  value?: string;
  onChange?: (id: string) => void;
}

function RoleSelect({
  disableById,
  helpText,
  excludeById,
  userId,
  value,
  onChange,
  ...additionalProps
}: Readonly<IRoleSelectProps>): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const { UserRoles } = useStore();
  const [loading, setLoading] = useState<boolean>(false);
  const [roleOptions, setRoleOptions] = useState<ISelectOption[]>([]);
  const { id: currentUserId } = useUser();
  const userRoles = account.get('user_roles');
  const { isInternalOnboarding } = useRole();

  function convertModelToOptions(roles: ListResponse<UserRole>): ISelectOption[] {
    return roles.models.map(({ id, name }: UserRole): ISelectOption => ({ id: id, name: name }));
  }

  function isOptionDisabled(): (option: ISelectOption) => boolean {
    return ({ id }: ISelectOption): boolean => {
      return id === disableById;
    };
  }

  async function getRoleOptions(): Promise<ISelectOption[]> {
    setLoading(true);
    try {
      const roles = UserRoles.list();
      await when(() => !roles.isPending);
      const roleOptions: ISelectOption[] = convertModelToOptions(roles);
      if (isInternalOnboarding) {
        roleOptions.push(
          { id: EUserRoleIDs.CSM, name: t('CSM') },
          { id: EUserRoleIDs.Strategist, name: t('Strategist') },
        );
      }
      setRoleOptions(roleOptions);
      return roleOptions;
    } catch (error) {
      toast(t('Failed to load roles: {{- error}}', { error }), { type: ToastType.ERROR });
      return [];
    } finally {
      setLoading(false);
    }
  }

  async function updateUserRole({ id: roleId }: ISelectOption): Promise<void> {
    if (userId) {
      runInAction((): void => {
        account.set({
          user_roles: [
            ...userRoles.filter((i) => i.user !== userId),
            { user: userId, role: roleId },
          ],
        });
      });

      try {
        await account.patchDirty();
        toast(
          t("User's role was reassigned to {{roleName}}.", {
            roleName: roleOptions.find((option) => option.id === roleId)?.name,
          }),
          { type: ToastType.SUCCESS },
        );
      } catch (error: any) {
        toast(t("Something went wrong while updating the user's role: {{- error}}", { error }), {
          type: ToastType.ERROR,
        });
      }
    }
  }

  async function handleRoleSelected(selectedRole: ISelectOption): Promise<void> {
    const { id: roleId } = selectedRole;
    if (userId) {
      await updateUserRole(selectedRole);
    }
    onChange?.(roleId);
  }

  function getValue(): ISelectOption | undefined {
    if (userId) {
      const userRole = toJS(userRoles.find((i) => i.user === userId));
      const defaultOption = roleOptions.find((option) => option.name === 'User');
      return roleOptions.find((option) => userRole?.role === option.id) ?? defaultOption;
    }
    return roleOptions.find(({ id }: ISelectOption): boolean => id === value);
  }

  function filterOption({ label, value }: Option, rawInput: string): boolean {
    const input = rawInput.toLowerCase();
    return !(label.toLowerCase().includes(input) && value === excludeById);
  }

  return (
    <AsyncSelect
      {...additionalProps}
      cacheOptions={true}
      defaultOptions={true}
      disabled={currentUserId === userId}
      filterOption={filterOption}
      isLoading={loading}
      isOptionDisabled={disableById ? isOptionDisabled() : undefined}
      loadOptions={getRoleOptions}
      name={'role-select'}
      onSelectSingle={handleRoleSelected}
      options={roleOptions}
      placeholder={t('Select a role...')}
      value={getValue()}
    />
  );
}

export default observer(RoleSelect);
