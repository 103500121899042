import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { useStore } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IUserRoleLabelProps {
  readonly className?: string;
  readonly id: string;
}

function UserRoleLabel({ className, id }: Readonly<IUserRoleLabelProps>): JSX.Element {
  // @ts-ignore - UserRoles hasn't been implemented yet. TODO: Implement UserRoles.
  const { UserRoles } = useStore();
  const userRole = UserRoles.get(id);
  return (
    <div className={classNames({ [skeleton]: userRole.isPending }, className)}>
      {userRole.get('name', 'placeholder') as string}
    </div>
  );
}

export default observer(UserRoleLabel);
