import { useDisclosure } from '@mantine/hooks';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import {
  ActionBar,
  Button,
  ButtonValid,
  CardV2 as Card,
  Form,
  Modal,
  toast,
  Toggle,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useAccount } from '@feathr/extender/state';
import { flattenErrors, getIconForAction } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

interface IErrors extends TValidateGrouped {
  settings: {
    allow_early_access?: string[];
  };
  ttd: {
    allow_cross_device?: string[];
  };
}

function GeneralSettingsPage(): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const hasEarlyAccess = account.getSetting('allow_early_access');

  const [opened, { open: openModal, close: closeModal }] = useDisclosure(false);

  const modalAction = hasEarlyAccess ? t('Enable') : t('Disable');
  const toastAction = hasEarlyAccess ? t('Enabled') : t('Disabled');

  useEffect(() => {
    // Reset changed account settings if they weren't saved so
    // the user doesn't see the changes if they navigate away
    // and back to this page.
    return () => {
      if (account.isDirty) {
        account.discardDirty();
      }
    };
  }, [account]);

  async function handleSave(): Promise<void> {
    openModal();
  }

  function handleOnChange(newValue?: boolean): void {
    account.setSetting('allow_early_access', newValue);
    account.set({
      ttd: {
        ...account.get('ttd'),
        allow_cross_device: newValue,
      },
    });
  }

  async function handleConfirm(): Promise<void> {
    try {
      await account.patchDirty();

      toast(t('{{action}} early access to features in beta', { action: toastAction }), {
        type: ToastType.SUCCESS,
      });
    } catch (error) {
      toast(t('There was an error saving your settings. {{- error}}', { error }), {
        type: ToastType.ERROR,
      });
    } finally {
      closeModal();
    }
  }

  const errors: IErrors = account.validate<IErrors>(
    ['settings.allow_early_access', 'ttd.allow_cross_device'],
    false,
    'grouped',
  ).errors;

  return (
    <Page title={t('General')}>
      <Form label={t('Account general settings')}>
        <Card>
          <Card.Header title={t('Early Access')} />
          <Card.Content>
            <Toggle
              label={t('Get early access to features in beta')}
              layout={'well'}
              onChange={handleOnChange}
              value={hasEarlyAccess}
            />
          </Card.Content>
        </Card>
      </Form>
      <Modal
        description={t(
          'This will affect how important Feathr features like campaigns and reports perform.',
        )}
        onClose={closeModal}
        opened={opened}
        rightActions={
          <>
            <Button onClick={closeModal}>{t('Cancel')}</Button>
            <Button onClick={handleConfirm} type={hasEarlyAccess ? 'primary' : 'danger'}>
              {modalAction}
            </Button>
          </>
        }
        title={t('{{action}} early access to features in beta', { action: modalAction })}
      />

      <ActionBar
        right={
          <ButtonValid
            disabled={!account.isDirty || undefined}
            errors={flattenErrors(errors)}
            onClick={handleSave}
            prefix={getIconForAction('save')}
          >
            {t('Save')}
          </ButtonValid>
        }
        usePortal={true}
      />
    </Page>
  );
}

export default observer(GeneralSettingsPage);
