import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { Template } from '@feathr/blackbox';
import { TemplateClass, templateTypeLabel } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import { DebouncedInput, Input, Select, Table } from '@feathr/components';
import { useAccount, useStore } from '@feathr/extender/state';
import { templateTypeIcon } from '@feathr/extender/styles/template';

import EventTemplatesPage from '../EventTemplatesPage';
import eventTemplatesCampaignColumns from './eventTemplatesCampaignColumns';

import * as styles from './EventTemplatesCampaignPage.css';

interface IFilters {
  name__icontains?: string;
  _cls?: TemplateClass;
  _cls__nin: TemplateClass[];
}

function TypeSelectLabel({ id, name }: ISelectOption): JSX.Element {
  return (
    <>
      <FontAwesomeIcon icon={templateTypeIcon(id as TemplateClass)} /> {name}
    </>
  );
}

function EventTemplatesCampaignPage(): JSX.Element {
  const account = useAccount();
  const { Templates } = useStore();
  const { eventId } = useParams<{ eventId: string }>();
  const [filters, setFilters] = useState<IFilters>({
    _cls__nin: account?.isFinchLegacy
      ? [TemplateClass.Link]
      : [
          TemplateClass.Link,
          TemplateClass.ReferralPage,
          TemplateClass.ReferralEmail,
          TemplateClass.ReferralBanner,
        ],
  });
  const { t } = useTranslation();

  function handleDebouncedSearchChange(newValue?: string) {
    setFilters({
      ...filters,
      name__icontains: newValue,
    });
  }

  function filterOption({ label }, inputValue) {
    return label.toLowerCase().includes(inputValue.toLowerCase());
  }

  function onClearType() {
    setFilters({
      ...filters,
      _cls: undefined,
    });
  }

  function onSelectType({ id }) {
    setFilters({
      ...filters,
      _cls: id as TemplateClass,
    });
  }

  const typeOptions = Object.keys(TemplateClass)
    // Filter out account-level template options.
    .filter((key) => !['Email', 'Page', 'Link', 'Banner'].includes(key as TemplateClass))
    .filter((key) => {
      if (account && !account.isFinchLegacy) {
        return !(key.includes('Referral') || key.includes('Partner'));
      }
      return true;
    })
    .map<ISelectOption>((key: any) => {
      const value = TemplateClass[key];
      return {
        id: value,
        name: templateTypeLabel(value as TemplateClass),
      };
    })
    .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

  const filterElements = [
    <>
      <Select
        filterOption={filterOption}
        formatOptionLabel={TypeSelectLabel}
        isClearable={true}
        name={'template-type'}
        onClear={onClearType}
        onSelectSingle={onSelectType}
        options={typeOptions}
        placeholder={t('Type')}
        value={typeOptions.find((option) => {
          return option.id === filters._cls;
        })}
        wrapperClassName={styles.filter}
      />
    </>,
    <>
      <DebouncedInput<string>
        defaultValue={filters.name__icontains}
        onChange={handleDebouncedSearchChange}
      >
        {(liveValue, onChangeLiveValue) => (
          <Input
            isClearable={true}
            onChange={onChangeLiveValue}
            placeholder={'Search by name...'}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
    </>,
  ];

  return (
    <EventTemplatesPage sectionTitle={t('Templates')} title={t('From Campaigns')}>
      <Table<Template>
        collection={Templates}
        columns={eventTemplatesCampaignColumns()}
        filterElements={filterElements}
        filters={{
          ...filters,
          default_neq: true,
          event: eventId,
          account: account.id,
          parent_kind: 'campaign',
        }}
        initialSort={[{ id: 'date_last_modified', desc: true }]}
      />
    </EventTemplatesPage>
  );
}

export default EventTemplatesCampaignPage;
