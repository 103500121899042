import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Segment, Targetable, Targeting } from '@feathr/blackbox';
import { TargetableClass } from '@feathr/blackbox';
import { Button, Card, Fieldset, Tooltip } from '@feathr/components';
import SegmentSelectSimple from '@feathr/extender/components/SegmentSelectSimple';
import { useStore } from '@feathr/extender/state';

import * as styles from './SeedSegmentTargeting.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  onRemove: (targeting: Targeting) => void;
}

function SeedSegmentTargeting({ campaign, targeting, onRemove }: IProps): JSX.Element {
  const { Segments, Targetables } = useStore();
  const { t } = useTranslation();

  let targetable: Targetable;
  const targetableId = targeting.get('target_data');
  if (targetableId) {
    targetable = Targetables.get(targetableId);
  } else {
    targetable = Targetables.create({
      _cls: TargetableClass.seed_segment,
      name: t('Seed Group Targeting'),
      category_id: 468,
      partner: campaign.get('parent_kind') === 'partner' ? campaign.get('parent') : undefined,
    });
    targeting.set({ target_data: targetable.get('id') });
  }
  let segment: Segment | undefined;
  const segmentId = targetable.get('seed_segment');
  if (segmentId) {
    segment = Segments.get(segmentId);
  }

  const handleRemoveSegment = useCallback(() => onRemove(targeting), [targeting, onRemove]);

  const handleSegmentChange = useCallback(
    async ({ id }) => {
      if (id) {
        const newSegment = Segments.get(id);
        await when(() => !newSegment.isPending);
        targetable.set({ name: newSegment.get('name'), seed_segment: id });
      }
    },
    [targetable, Segments],
  );

  return (
    <>
      <Card
        actions={[
          <Tooltip key={'remove'} title={t('Remove')}>
            <Button
              className={styles.cardButton}
              name={'remove_segment'}
              onClick={handleRemoveSegment}
              type={'naked'}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>,
        ]}
      >
        <Fieldset>
          <SegmentSelectSimple
            filters={{
              stats__num_cookies_total__gte: 30000,
              is_conversion_segment__ne: true,
            }}
            helpText={t(
              'Choose a group to use to derive your lookalike data for this target. Groups must have at least 30,000 reachable people to be eligible.',
            )}
            hideCreate={true}
            label={t('Group')}
            name={'segment_select'}
            onChange={handleSegmentChange}
            required={true}
            value={segment?.id}
          />
        </Fieldset>
      </Card>
    </>
  );
}

export default observer(SeedSegmentTargeting);
