import type { ITTDCampaignAttributes } from './ttd_base';
import { TTDCampaign } from './ttd_base';
import type { CampaignClass } from './types';

// Legacy Lookalike
export type TLookalikeCampaign = ITTDCampaignAttributes & {
  readonly _cls: CampaignClass.Lookalike;
};

export class LookalikeCampaign extends TTDCampaign<TLookalikeCampaign> {}
