import { faCog } from '@fortawesome/pro-light-svg-icons';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { TableCellRenderer } from 'react-table';

import {
  Button,
  Checkbox,
  DebouncedInput,
  Input,
  Label,
  Popover,
  Tooltip,
} from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import type { IObject } from '@feathr/rachis';

import PartnersColumns from '../PartnersColumns';

import * as styles from './PartnersFilters.css';

interface IFilters extends IObject {
  name: string;
}

interface IProps<T extends IFilters> {
  filters: T;
  setFilters: (newFilters: T) => void;
  columnIds: string[];
  updateColumnIds: (newColumnIds: string[]) => void;
}

export interface IPartnersColumn {
  checkboxLabel?: string;
  Header: (() => JSX.Element) | string;
  id: string;
  sortable?: boolean;
  Cell?: TableCellRenderer;
  module?: string;
}

function PartnersFilters<T extends IFilters>({
  filters,
  setFilters,
  columnIds,
  updateColumnIds,
}: IProps<T>) {
  function handleDebouncedSearchChange(newValue?: string) {
    setFilters({ ...filters, name: newValue ?? '' });
  }

  return (
    <Observer>
      {function useAnonymousFunction() {
        const { Tags } = useStore();
        const { t } = useTranslation();

        const getColumnSetter = (columnId: string) => {
          return () => {
            const newColumnIds = [...columnIds];
            const index = newColumnIds.indexOf(columnId);
            const columnVisible = index >= 0;
            if (columnVisible) {
              newColumnIds.splice(index, 1);
            } else {
              newColumnIds.push(columnId);
            }
            updateColumnIds(newColumnIds);
          };
        };

        const columns = PartnersColumns(Tags) as IPartnersColumn[];

        return (
          <>
            <DebouncedInput<string>
              defaultValue={filters.name}
              onChange={handleDebouncedSearchChange}
            >
              {(liveValue, onChangeLiveValue) => (
                <Input
                  isClearable={true}
                  onChange={onChangeLiveValue}
                  placeholder={t('Search by name...')}
                  prefix={<FontAwesomeIcon icon={faSearch} />}
                  type={'text'}
                  value={liveValue}
                />
              )}
            </DebouncedInput>
            <Tooltip position={'top-end'} title={t('Customize columns')}>
              <Popover toggleOnClick={false}>
                <Button type={'icon-outlined'}>
                  <FontAwesomeIcon icon={faCog} />
                </Button>
                <div className={styles.content}>
                  <section>
                    <Label>{t('Columns')}</Label>
                    <div className={styles.columns}>
                      {columns.map((column) => {
                        return (
                          <Checkbox
                            id={`checkbox-${column.id}`}
                            key={column.id}
                            label={column.checkboxLabel}
                            name={column.id}
                            onChange={getColumnSetter(column.id)}
                            value={columnIds.includes(column.id)}
                          />
                        );
                      })}
                    </div>
                  </section>
                </div>
              </Popover>
            </Tooltip>
          </>
        );
      }}
    </Observer>
  );
}

export default PartnersFilters;
