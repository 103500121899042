import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FacebookCampaign } from '@feathr/blackbox';
import { FormSummaryItem } from '@feathr/components';
import { countries } from '@feathr/hooks';

import type { ISummaryProps } from './CampaignSummary.types';

const TargetedCountriesSummary = observer(
  ({ campaign, validationErrors }: ISummaryProps): JSX.Element | null => {
    const { t } = useTranslation();
    const targetedCountries = campaign.get('targeted_countries');

    if (!(campaign instanceof FacebookCampaign)) {
      return null;
    }

    return (
      <FormSummaryItem
        errors={validationErrors?.subject}
        label={t('Targeted Country', { count: targetedCountries.length })}
        value={
          <ol>
            {targetedCountries
              // Cannot sort observable in place, so make a copy using slice().
              .slice()
              .sort((a, b) => a.localeCompare(b))
              .map((targetedCountry) => (
                <li key={targetedCountry}>{countries[targetedCountry] ?? targetedCountry}</li>
              ))}
          </ol>
        }
      />
    );
  },
);

TargetedCountriesSummary.displayName = 'TargetedCountriesSummary';

export default TargetedCountriesSummary;
