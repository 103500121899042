import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { Fragment, type JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { CampaignClass } from '@feathr/blackbox';
import { FormSummaryItem, Skeleton } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import type { ISummaryProps } from './CampaignSummary.types';

const TemplatesSummary = observer(({ campaign, validationErrors }: ISummaryProps): JSX.Element => {
  const { t } = useTranslation();
  const { Templates } = useStore();

  const templateId = campaign.get('template_id');
  // Don't include undefined templateId
  const templateIds: string[] = [
    CampaignClass.PinpointEmail,
    CampaignClass.SmartPinpointEmail,
    CampaignClass.AutoPinpointEmail,
  ].includes(campaign.get('_cls'))
    ? templateId
      ? [templateId]
      : []
    : [].concat(
        toJS(campaign.get('banner_templates', [])),
        toJS(campaign.get('email_templates', [])),
        toJS(campaign.get('page_templates', [])),
      );

  const templates = Templates.list({
    filters: {
      id__in: templateIds,
    },
    pagination: {
      page_size: 100,
    },
  });

  return (
    <FormSummaryItem
      errors={validationErrors?.template}
      label={t('Templates')}
      value={
        <>
          {!templateIds.length ? (
            <>({t('None')})</>
          ) : templates.isPending ? (
            templateIds.map((templateId, i) => (
              <Fragment key={templateId}>
                <Skeleton width={100 + Math.random() * 60} />
                {i < templates.models.length - 1 && <br />}
              </Fragment>
            ))
          ) : (
            templates.models
              // Cannot sort observable in place, so make a copy using slice().
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((template, i) => (
                <Fragment key={template.id}>
                  {template.name}
                  {i < templates.models.length - 1 && <br />}
                </Fragment>
              ))
          )}
          {templates.pagination.count > 10
            ? t(' and {{count}} more.', { count: templates.pagination.count - 10 })
            : ''}
        </>
      }
    />
  );
});

TemplatesSummary.displayName = 'TemplatesSummary';

export default TemplatesSummary;
