import { Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { runInAction, set } from 'mobx';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import numeral from 'numeral';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import type { TTDCampaign } from '@feathr/blackbox';
import { AlertV2, Button, DatePicker, EAlertV2Type, Modal, NumberInput } from '@feathr/components';
import { momentToDate, TimeFormat, timezoneAbbr } from '@feathr/hooks';

import type { ICampaignValidationErrors } from '../../../CampaignSummary';
import SaveCampaignButton from '../../SaveCampaignButton/index';

import * as styles from './TTDRecommendedOptimizationModal.css';

interface IProps {
  campaign: TTDCampaign;
  validate: () => ICampaignValidationErrors;
}

function getDays(currentDate: string, newDate: string): number {
  const startMoment = moment.utc(currentDate, moment.ISO_8601);
  const endMoment = moment.utc(newDate, moment.ISO_8601);
  const duration = moment.duration(endMoment.diff(startMoment));
  return duration.asDays();
}

function TTDRecommendedOptimizationModal({ campaign, validate }: Readonly<IProps>): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const additionalBudgetParam = Number(queryParams.get('additionalBudget'));
  const newEndDateParam = queryParams.get('newEndDate');

  const [opened, { close }] = useDisclosure(!!additionalBudgetParam);
  const { t } = useTranslation();
  const [additionalBudget, setAdditionalBudget] = useState<number>(additionalBudgetParam);

  // Time
  const endTimeStamp = campaign.get('date_end');
  const endMoment = moment.utc(endTimeStamp).local();
  const endMomentInitial = moment.utc(campaign.initialAttributes.date_end).local();
  const endTimeStampInitial = campaign.initialAttributes.date_end ?? campaign.get('date_end');
  const endTimeInitialFormatted = moment
    .utc(campaign.initialAttributes.date_end)
    .local()
    .format(TimeFormat.longDateTime);

  // Budget
  const currentBudget = campaign.initialAttributes.exposure_settings?.target_value ?? 0;
  const currentBudgetFormatted = numeral(currentBudget).format('$0');
  const newBudgetFormatted = numeral(currentBudget + Number(additionalBudget)).format('$0');

  // Recommendations
  const budgetRecommendation = t('Increase your total budget by {{budget}}', {
    budget: numeral(additionalBudgetParam).format('$0'),
  });
  const recommendations = [budgetRecommendation];
  if (newEndDateParam) {
    recommendations.push(
      t('Increase campaign duration by {{count}} days', {
        count: getDays(endTimeStampInitial, newEndDateParam),
      }),
    );
  }

  const errors = validate();

  // Set the new end date and budget on initial render to trigger a dirty save changes button
  useEffect(() => {
    if (additionalBudgetParam) {
      handleTargetValueChange(additionalBudgetParam);
    }
    if (newEndDateParam) {
      handleOnChangeDateEnd(newEndDateParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleClose(): Promise<void> {
    // Await the reloading of the campaign details so there aren't weird content shifts
    await campaign.reload();

    // Remove the query parmas from the URL so the modal doesn't get retriggered
    history.push({ ...location, search: '' });
    close();
  }

  function handleOnChangeDateEnd(newValue?: string): void {
    if (!newValue) {
      return;
    }

    campaign.set({
      date_end: newValue,
    });
  }

  function handleTargetValueChange(newValue?: number): void {
    if (!newValue) {
      return;
    }
    setAdditionalBudget(newValue);

    const exposureSettings = campaign.get('exposure_settings');
    if (exposureSettings) {
      runInAction((): void => {
        const budget = currentBudget + newValue;
        set(exposureSettings, {
          target_value: budget,
        });
      });
      campaign.setAttributeDirty('exposure_settings');
    }
  }

  return (
    <Modal
      closeOnClickOutside={false}
      description={t(
        'These intelligent recommendations may increase the performance of this campaign.',
      )}
      onClose={handleClose}
      opened={opened}
      rightActions={
        <>
          <Button onClick={handleClose} type={'secondary'}>
            {t('No thanks')}
          </Button>
          <SaveCampaignButton
            campaign={campaign}
            childModels={[]}
            postSave={handleClose}
            showIcon={true}
            type={'primary'}
            validate={validate}
          />
        </>
      }
      title={t('Recommended campaign optimization')}
    >
      <AlertV2
        description={recommendations}
        title={t('Applying our reccomendations will:')}
        type={EAlertV2Type.recommendation}
      />
      <Stack>
        {newEndDateParam && (
          <DatePicker
            autoComplete={'off'}
            dateFormat={'MMM d, yyyy h:mm aa'}
            helpPlacement={'bottom'}
            helpText={t('Current end date is {{-date}}', { date: endTimeInitialFormatted })}
            isFullWidth={true}
            label={t('New end date')}
            minDate={momentToDate(endMomentInitial)}
            name={'date_end'}
            onDateStrChange={handleOnChangeDateEnd}
            showTimeSelect={true}
            suffix={timezoneAbbr(endMoment.toDate())}
            timeIntervals={5}
            validationError={errors.date_end}
            value={campaign.get('date_end')}
          />
        )}
        <NumberInput
          className={styles.leftAlign}
          helpPlacement={'bottom'}
          helpText={t('Increase the budget from {{before}} to {{after}}', {
            before: currentBudgetFormatted,
            after: newBudgetFormatted,
          })}
          label={'Additional budget'}
          min={additionalBudgetParam}
          name={'budget'}
          onChange={handleTargetValueChange}
          prefix={'$'}
          validationError={errors.budget}
          value={additionalBudget}
        />
      </Stack>
    </Modal>
  );
}

export default observer(TTDRecommendedOptimizationModal);
