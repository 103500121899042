import type { GoogleAdsCustomer } from '@feathr/blackbox';
import { useStore } from '@feathr/extender/state';

import useGoogleAdsPermissions from './useGoogleAdsPermissions';

/** Return the connected Google Ads customer for the account */
function useGoogleAdsCustomer(): GoogleAdsCustomer | undefined {
  const { GoogleAdsCustomers } = useStore();
  const showGoogleAds = useGoogleAdsPermissions();

  /*
   * If we don't have permissions via flag or connected customer,
   * don't even try to pull the customer.
   */
  if (!showGoogleAds) {
    return undefined;
  }

  const customers = GoogleAdsCustomers.list();
  const customer = customers.models.find((customer) =>
    ['connected', 'pending'].includes(customer.get('invite_status')),
  );

  return customer;
}

export default useGoogleAdsCustomer;
