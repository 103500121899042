import type { TFunction } from 'i18next';
import type { IObservableArray } from 'mobx';
import { observable, reaction, toJS, when } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { IImportColumn, IPreviewRow } from '@feathr/blackbox';
import type { Importer } from '@feathr/blackbox';
import { Button, ButtonValid, Form, Spinner } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';

import DataImportColumnConfig from './DataImportColumnConfig';
import DataImportPreview from './DataImportPreview';
interface IProps {
  disabled?: boolean;
  onPrev: () => void;
  onNext: () => void;
  importer: Importer;
}

interface IButtonProps {
  onNext: () => void;
  importer: Importer;
}

interface IPreviewState {
  rows?: IPreviewRow[];
  setRows: (rows?: IPreviewRow[]) => void;
}

export const validateStepTwo = (importer: Importer, t: TFunction) => {
  function validateColumn(column: IImportColumn): string | undefined {
    if (!column.feathr_attr) {
      return t('{{columnName}} must map to a field.', { columnName: column.column_name });
    }
    if (!column.attr_type) {
      return t('Field for "{{columnName}}" needs a data type.', { columnName: column.column_name });
    }

    if (column?.u_key === '') {
      return t('Custom field mapped to "{{columnName}}" needs a name.', {
        columnName: column.column_name,
      });
    }
    return undefined;
  }

  const mappers: IImportColumn[] = importer.get('column_mappers');
  const hasEmail = mappers.some((m) => m.feathr_attr === 'email');
  const errors = toJS(importer.validate(['column_mappers']).errors);
  if (!hasEmail) {
    errors.push(t('Exactly one column must map to the Email field.'));
  }
  return [
    ...errors,
    ...(mappers
      .filter((column) => column.column_name !== '')
      .map(validateColumn)
      .filter((error?: string) => !!error) as string[]),
  ];
};

const NextStepButton = observer(({ onNext, importer }: IButtonProps) => {
  const { CustomFields } = useStore();
  const [fieldExists, setFieldExists] = useState<string[]>([]);
  const { t } = useTranslation();

  const validationErrors = validateStepTwo(importer, t);
  fieldExists.forEach((entry) => validationErrors.push(entry));

  const defaults = ['email'];

  useEffect(() => {
    reaction(
      () => importer.getEphemeralCustomFields(CustomFields).map((cf) => cf.get('u_key')),
      async (fieldNames) => {
        const existingFields = CustomFields.list({
          filters: {
            u_key__in: fieldNames,
            is_archived__ne: true,
            collection: 'Person',
          },
        });
        await when(() => !existingFields.isPending);
        const builtinFieldErrs = fieldNames
          .filter((fn) => defaults.includes(fn?.toLowerCase()))
          .map((fn) =>
            t('{{fieldName}} is a built-in field. Use the built in field instead.', {
              fieldName: fn,
            }),
          );
        const existingFieldErrs = existingFields.models.map((f) =>
          t(
            '{{fieldName}} is a custom field that already exists. Choose a different name or use the existing field.',
            { fieldName: f.get('u_key') },
          ),
        );
        setFieldExists([...builtinFieldErrs, ...existingFieldErrs]);
      },
      { delay: DEFAULT_DEBOUNCE_WAIT },
    );
  });

  return (
    <ButtonValid errors={validationErrors} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function DataImportStepTwo({ disabled = false, importer, onNext, onPrev }: IProps): JSX.Element {
  const { t } = useTranslation();

  const state = useLocalObservable<IPreviewState>(() => ({
    rows: [],
    setRows: (rows?: IPreviewRow[]): void => {
      state.rows = rows;
    },
  }));

  const columnMappersChanged = importer.completedColumnMappers;

  useEffect(() => {
    state.setRows(undefined);
  }, [columnMappersChanged, state]);

  useEffect(() => {
    const getPreviewRows = async () => {
      const previewRows = await importer.preview(columnMappersChanged);
      state.setRows(previewRows);
    };

    if (state.rows === undefined) {
      getPreviewRows();
    }
  }, [columnMappersChanged, importer, state, t]);

  function addColumn(): void {
    const name = (importer.get('column_names') as string[]).filter(
      (columnName) =>
        !(importer.get('column_mappers') as IObservableArray<IImportColumn>)
          .map((column) => column.column_name)
          .includes(columnName),
    )[0];
    const obj = observable({
      column_name: name,
      feathr_attr: undefined,
      attr_type: undefined,
    } as IImportColumn);
    importer.get('column_mappers').push(obj);
  }

  const actions = [
    <Button key={'prev'} onClick={onPrev}>
      {t('Previous')}
    </Button>,
    <Button
      disabled={
        disabled || importer.get('column_names').length === importer.get('column_mappers').length
      }
      key={'add'}
      onClick={addColumn}
    >
      {t('Add column')}
    </Button>,
    <NextStepButton importer={importer} key={'next'} onNext={onNext} />,
  ];

  // Filter out empty columns
  const columnMappers = importer
    .get('column_mappers')
    .filter((column) => column.column_name !== '') as IObservableArray<IImportColumn>;

  return (
    <>
      <Form
        actions={actions}
        description={
          <Trans t={t}>
            <p>Map the columns from your spreadsheet to fields in Feathr.</p>
            <p>
              In order for your data to be properly imported into Feathr, each column of your
              spreadsheet must be mapped to a field on every person that is being imported. You can
              map each column to a field that already exists, or to a new custom field you create.
            </p>
            <p>
              Note that External ID and Email Address will be used to find existing records. If an
              existing record is found, its content will be overwritten by the import. For this
              reason, these fields should be unique.
            </p>
          </Trans>
        }
        label={t('Map')}
      >
        {columnMappers.map((column, index) => (
          <DataImportColumnConfig
            column={column}
            disabled={disabled}
            importer={importer}
            index={index}
            key={column.column_name}
          />
        ))}
      </Form>
      {state.rows && columnMappers ? (
        <DataImportPreview
          columnMappers={columnMappers}
          rows={state.rows}
          tagIds={importer.get('tag_ids', [])}
        />
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default observer(DataImportStepTwo);
