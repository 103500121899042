import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';
import type { Option } from 'react-select/src/filters';

import type { IPredicate, TPredicateKind, TPredicateMode } from '@feathr/blackbox';
import { Popover, Select } from '@feathr/components';
import { fieldDataTypeIcon } from '@feathr/extender/styles/fieldDataType';
import { cssVar } from '@feathr/hooks';

import type { IAttrOption, TOptionsGroup } from '../utils';

import * as styles from './RuleSelect.css';

interface IProps {
  activities: IAttrOption[];
  attributes: IAttrOption[];
  disabled?: boolean;
  mode?: TPredicateMode;
  optionsGroup: TOptionsGroup;
  predicate: IPredicate;
  rule?: IAttrOption;
  handleSelectRule: (newRule: any) => void;
  triggers?: IPredicate[];
}

interface IOptionGroup {
  key: TPredicateKind;
  label: string;
  options: IAttrOption[];
}

function FieldOption(props: OptionProps<IAttrOption>): Readonly<JSX.Element> {
  const { name, type } = props.data;
  const icon = fieldDataTypeIcon(type);

  return (
    <components.Option {...props}>
      <div className={styles.container}>
        <div className={styles.iconContainer}>{icon && <FontAwesomeIcon icon={icon} />}</div>
        <div className={styles.nameContainer}>
          <span>{name}</span>
        </div>
      </div>
    </components.Option>
  );
}

function RuleSelect({
  activities,
  attributes,
  disabled = false,
  mode,
  optionsGroup,
  predicate: { kind, group },
  predicate,
  rule,
  handleSelectRule,
  triggers,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();

  const optionGroups: IOptionGroup[] = [
    { key: 'activity', label: t('Activity'), options: activities },
    { key: 'attribute', label: t('Person Attributes'), options: attributes },
  ];

  const options = (function (): IOptionGroup['options'] | IOptionGroup[] | undefined {
    if (group?.length && kind) {
      return optionGroups.find(({ key }) => key === predicate.kind)?.options;
    }

    if (optionsGroup === 'pinpoint') {
      if (Number(triggers?.length) >= 2 && mode === 'match_all') {
        const triggerKind = triggers![0].kind;
        const optionsKey = triggerKind === 'update' ? 'attribute' : triggerKind;
        return optionGroups.find(({ key }) => key === optionsKey)?.options;
      }
    }

    return optionGroups;
  })();

  function filterOption({ data, label }: Option, inputValue: string): boolean {
    if (optionsGroup.toLowerCase().includes('facebook')) {
      return data.id === 'loc_url';
    }
    return label.toLowerCase().includes(inputValue.toLowerCase());
  }

  const selectStyles = {
    /*
     * Margin-right here spaces the attr_against container from
     * the comparison operator container
     */
    container: (provided) => ({ ...provided, marginRight: cssVar('--spacing-1') }),
    menu: (provided) => ({ ...provided, width: 'auto' }),
    singleValue: (provided) => ({
      ...provided,
      transform: 'none',
      position: 'relative',
      top: 0,
      maxWidth: '100%',
      cursor: 'pointer',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      transform: 'none',
      position: 'relative',
      top: 0,
    }),
  };

  return (
    <Popover toggle={'onMouseOver'}>
      <span>
        <Select<IAttrOption>
          className={styles.select}
          components={{ Option: FieldOption }}
          disabled={disabled}
          filterOption={filterOption}
          name={'predicate_attr'}
          onSelectSingle={handleSelectRule}
          options={options}
          styles={selectStyles}
          value={rule}
        />
      </span>
      {/* Old typing on <Popover> throws an error if an element is possibly undefined, so we can't use "rule &&" */}
      {rule ? <span>{rule?.helpText}</span> : <></>}
    </Popover>
  );
}

export default observer(RuleSelect);
