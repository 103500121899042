import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast, ToastType } from 'react-toastify';

import type {
  Conversion,
  ICampaignAttributes,
  IEvent,
  IFlight,
  IGoal,
  Targeting,
  TAttributionModel,
} from '@feathr/blackbox';
import { Campaign, CampaignClass, Event, Flight, reportModuleLabels } from '@feathr/blackbox';
import type { ISorted } from '@feathr/components';
import { Table, TableStatsCard, Tooltip } from '@feathr/components';
import { moment, TimeFormat, useToggle } from '@feathr/hooks';
import type { DisplayModel, IRachisMessage } from '@feathr/rachis';
import { useStore } from '@feathr/report_components/state';

import { attributionModelLabels } from '../AttributionModel/AttributionModel';
import ExportModal from '../ExportModal';
import ConversionTableCardToolbar from './ConversionTableCardToolbar';
import ConversionTableCategorySelect from './ConversionTableCategorySelect';
import { ConversionTableColumns, defaultColumnIds } from './ConversionTableColumns';
import { ConversionTimeline } from './ConversionTimeline';
import type { TContext } from './RerunModal';
import RerunModal from './RerunModal';

import * as styles from './ConversionTableCard.css';

interface IOption {
  label: string;
  value: string;
}
interface IFilters {
  d_c__gte?: string;
  d_c__lte?: string;
  state?: string;
  goals__in?: string[];
  events?: string;
  flights?: string;
  campaigns?: string;
  category__in?: string[];
}
interface IProps {
  attributionModel: TAttributionModel;
  campaigns?: Campaign[];
  end: string;
  goals: IGoal[];
  model: DisplayModel<ICampaignAttributes> | DisplayModel<IEvent> | DisplayModel<IFlight>;
  start: string;
  targetings: IObservableArray<Targeting>;
  localUrl?: (url: string) => string;
}

type TConversionTrackingModel = 'Single' | 'Mixed' | 'Multi';

export function addDayToDate(dateString: string): string {
  // pattern for ISO date format (YYYY-MM-DD)
  const isoDatePattern = /^\d{4}-\d{2}-\d{2}$/;
  const isIsoDatePattern = isoDatePattern.test(dateString);

  return isIsoDatePattern
    ? moment.utc(dateString, TimeFormat.isoDate).add(1, 'day').format(TimeFormat.isoDate)
    : dateString;
}

function ConversionTableCard({
  attributionModel,
  campaigns = [],
  end,
  goals,
  model,
  start,
  targetings,
  localUrl,
}: Readonly<IProps>): JSX.Element {
  const { Conversions } = useStore();
  const { t } = useTranslation();
  const [isExportModalOpen, toggleExportModalOpen] = useToggle(false);
  const [isRerunModalOpen, toggleRerunModalOpen] = useToggle(false);

  const getAttributionModelQuery = useCallback(
    (attrModel: TAttributionModel) => {
      const isCampaign = model instanceof Campaign;

      function getModelKey(): string {
        if (model instanceof Event) {
          return 'events';
        } else if (model instanceof Flight) {
          return 'flights';
        } else if (model instanceof Campaign) {
          return 'campaigns';
        } else {
          throw new Error('Model is invalid.');
        }
      }

      return {
        [`attribution__${attrModel}__campaign${isCampaign ? '' : '__in'}`]: isCampaign
          ? model.id
          : campaigns.map((c) => c.id),
        [getModelKey()]: model.id,
      };
    },
    [model, campaigns],
  );
  const defaults = {
    filters: {
      d_c__gte: start,
      d_c__lte: addDayToDate(end),
      state: 'done',
      goals__in: goals.map((g) => g.id),
      ...getAttributionModelQuery(attributionModel),
    } as IFilters,
    pagination: { page: 0, page_size: 10 },
    sort: [{ id: 'd_c' }],
    columnIds: [...defaultColumnIds],
  };
  const presets = sessionStorage.getItem(`${model.id}_conversions`);
  if (!presets) {
    if (localUrl) {
      defaults.columnIds.push('details');
    }
    if (model instanceof Event || model instanceof Flight) {
      defaults.columnIds.splice(2, 0, 'campaigns');
    }
    if (model instanceof Campaign && model.get('_cls') === CampaignClass.Referral) {
      defaults.columnIds.splice(2, 0, 'partner', 'template');
    }
  } else {
    const parsed = JSON.parse(presets);
    defaults.filters = parsed.filters;
    defaults.pagination = parsed.pagination;
    defaults.sort = parsed.sort;
    defaults.columnIds = parsed.columnIds;
  }

  const getConversions = useCallback(
    (filters, pagination, sort) => {
      return Conversions.list({
        filters,
        pagination,
        ordering: [`${sort[0].desc ? '-' : ''}${sort[0].id}`],
      });
    },
    [Conversions],
  );

  const state = useLocalObservable(() => ({
    conversions: getConversions(defaults.filters, defaults.pagination, defaults.sort),
    setConversions: (conversions): void => {
      state.conversions = conversions;
    },
    columnIds: defaultColumnIds,
    setColumnIds: (columnIds): void => {
      state.columnIds = columnIds;
    },
    filters: defaults.filters,
    setFilters: (filters): void => {
      state.filters = filters;
    },
    pagination: defaults.pagination,
    setPagination: (pagination): void => {
      state.pagination = pagination;
    },
    sort: defaults.sort,
    setSort: (sort): void => {
      state.sort = sort;
    },
    attributionModel: attributionModel,
    setAttributionModel: (attrModel): void => {
      state.attributionModel = attrModel;
    },
  }));

  const handleChangePresets = useCallback(
    (filters: IFilters, pagination, sort, columnIds) => {
      sessionStorage.setItem(
        `${model.id}_conversions`,
        JSON.stringify({ filters, pagination, sort, columnIds }),
      );
      state.setConversions(getConversions(filters, pagination, sort));
    },
    [model.id, state, getConversions],
  );

  useEffect(() => {
    if (attributionModel !== state.attributionModel) {
      const currentFilters = { ...state.filters };

      // Remove old computed key based on former attr model
      delete currentFilters[Object.keys(getAttributionModelQuery(state.attributionModel))[0]];
      state.setAttributionModel(attributionModel);

      // Generate and set new computed key on filters
      const updatedFilters = { ...currentFilters, ...getAttributionModelQuery(attributionModel) };
      state.setFilters(updatedFilters);
      state.setConversions(getConversions(updatedFilters, state.pagination, state.sort));

      handleChangePresets(updatedFilters, state.pagination, state.sort, state.columnIds);
    } else {
      handleChangePresets(state.filters, state.pagination, state.sort, state.columnIds);
    }
  }, [
    attributionModel,
    getAttributionModelQuery,
    getConversions,
    handleChangePresets,
    state,
    state.filters,
    state.pagination,
    state.sort,
    state.columnIds,
  ]);

  useEffect(() => {
    const updatedFilters = {
      ...state.filters,
      d_c__gte: start,
      d_c__lte: addDayToDate(end),
    };
    state.setFilters(updatedFilters);
  }, [state, start, end]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isCampaign(object: any): object is DisplayModel<ICampaignAttributes> {
    return 'rerun' in object.attributesType && 'flight' in object.attributesType;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isFlight(object: any): object is DisplayModel<IFlight> {
    return 'rerun' in object.attributesType && 'legs' in object.attributesType;
  }

  if ((isCampaign(model) && model.get('rerun')) || (isFlight(model) && model.get('rerun'))) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          Recalculating conversions, please check again in a few minutes!
        </div>
      </div>
    );
  }

  async function onConfirmExport(email: string): Promise<IRachisMessage> {
    const formattedEndDay = addDayToDate(end);
    const params = new URLSearchParams({
      [model.className.toLowerCase()]: model.id,
      attribution_model: attributionModel,
      start,
      end: formattedEndDay,
      email,
    });
    return Conversions.export(params);
  }

  async function handleConfirmRerun(): Promise<void> {
    const params = new URLSearchParams({
      [model.className.toLowerCase()]: model.id,
    });
    try {
      await Conversions.rerun(params);
      model.set({ rerun: true });
    } catch (e) {
      toast(t('Something went wrong when trying to recalculate conversions.'), {
        type: ToastType.ERROR,
      });
      throw e;
    }
  }

  function onSelectCategory(categories: IOption[] = []): void {
    const newFilters = {
      ...state.filters,
    };
    if (categories.length) {
      newFilters.category__in = categories.map((c) => c.value);
    } else {
      delete newFilters.category__in;
    }
    state.setFilters(newFilters);
    state.setPagination({ page: 0, page_size: state.pagination.page_size });
  }

  function onPageSizeChange(newPageSize: number, newPage: number): void {
    state.setPagination({ page: newPage, page_size: newPageSize });
  }

  function onPageChange(newPage: number): void {
    state.setPagination({ page: newPage, page_size: state.pagination.page_size });
  }

  function onSortChange(newSort: ISorted[]): void {
    state.setSort(newSort);
  }

  const columns = ConversionTableColumns(
    model,
    goals,
    targetings,
    attributionModel,
    campaigns,
    localUrl,
  );

  function getConversionTrackingModel(): TConversionTrackingModel | undefined {
    if (goals.length < 1) {
      return;
    }

    const modes = [...new Set(goals.map((goal) => goal.multi_conversions))];
    // Modes will be [true], [false], or [true, false]

    if (modes.length === 1) {
      return modes[0] ? 'Multi' : 'Single';
    }

    return 'Mixed';
  }

  const conversionTrackingModel = getConversionTrackingModel();

  const conversionTrackingTooltips = new Map<string, string>([
    [
      'Single',
      'Only includes the first conversion of each person, even if they converted more than once.',
    ],
    ['Multi', 'Includes multiple conversions by the same person if converted more than once.'],
    [
      'Mixed',
      'Includes conversions from goals set up to track both single and multiple conversions per person.',
    ],
  ]);

  return (
    <>
      <TableStatsCard title={reportModuleLabels.includeConversionsTable}>
        <div className={styles.cardHeader}>
          <ConversionTableCategorySelect
            filters={state.filters}
            key={'category-select'}
            onSelectCategory={onSelectCategory}
            values={state.filters.category__in}
          />
          <ConversionTableCardToolbar
            columnIds={state.columnIds}
            columns={columns}
            localUrl={localUrl}
            model={model}
            toggleExportModal={toggleExportModalOpen}
            toggleRerunModal={toggleRerunModalOpen}
            updateColumnIds={state.setColumnIds}
          />
        </div>
        <div className={styles.modelLabel}>
          <span className={styles.label}>Attribution model: </span>
          {attributionModelLabels[attributionModel].label}
          <span className={styles.separator}> | </span>
          {goals.length > 0 && (
            /*
             * ConversionTrackingModel is only relevant if goals.length > 0, and
             * getConversionTrackingModel returns early to prevent errors in case
             * there are no goals
             */
            <>
              <span className={styles.label}> Conversion tracking model: </span>
              {conversionTrackingModel}{' '}
              <Tooltip
                className={styles.tooltip}
                title={conversionTrackingTooltips.get(conversionTrackingModel!)}
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Tooltip>
            </>
          )}
        </div>
        <Table<Conversion>
          columns={columns.filter((column) => state.columnIds.includes(column.id!))}
          idKey={'id'}
          initialPagesize={10}
          initialSort={[{ id: 'd_c' }]}
          isLoading={state.conversions.isPending}
          isPaginated={true}
          items={state.conversions.models}
          noDataText={'No Conversions'}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortChange={onSortChange}
          page={state.pagination.page}
          pages={state.conversions.pagination.pages}
          pageSize={state.pagination.page_size}
          sort={state.sort}
          SubComponent={state.columnIds.includes('timeline') ? ConversionTimeline : undefined}
        />
      </TableStatsCard>
      {isExportModalOpen && (
        <ExportModal
          onClose={toggleExportModalOpen}
          onConfirm={onConfirmExport}
          title={'Export Conversions'}
        />
      )}
      {isRerunModalOpen && (
        <RerunModal
          context={model.className === 'Event' ? 'Project' : (model.className as TContext)}
          id={model.id}
          name={model.name}
          onClose={toggleRerunModalOpen}
          onConfirm={handleConfirmRerun}
        />
      )}
    </>
  );
}

export default observer(ConversionTableCard);
