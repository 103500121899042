import { ObjectId } from 'bson';
import type { TFunction } from 'i18next';
import capitalize from 'lodash.capitalize';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { LabelProps } from 'recharts';
import { Cell, Label, Pie, PieChart, Tooltip } from 'recharts';
import type { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import type { ContentType as LabelContentType } from 'recharts/types/component/Label';
import type {
  ContentType as TooltipContentType,
  TooltipProps,
} from 'recharts/types/component/Tooltip';

import type {
  DailyStat,
  ICampaignAttributes,
  IFlight,
  IGoal,
  Segment,
  Segments as SegmentsCollection,
  TAttributionModel,
} from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { colors, StatBox, StatsCardV1 } from '@feathr/components';
import { cssVar, moment } from '@feathr/hooks';
import type { ReportStore } from '@feathr/report_components/state';
import { useStore } from '@feathr/report_components/state';

import * as styles from './ROICard.css';

const { shade } = colors;

interface IProps {
  attributionModel?: TAttributionModel;
  campaigns?: ICampaignAttributes[];
  dailyStats: IObservableArray<DailyStat>;
  end: string;
  flights?: IFlight[];
  goals: IGoal[];
  start: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
function getGoalSegments(goals: IGoal[], Segments: SegmentsCollection): Segment[] {
  return goals.filter((g) => !!g.segment && !g.is_archived).map((g) => Segments.get(g.segment!));
}

interface IChartData {
  id: string;
  name?: string;
  roi: number;
  num: number;
  segmentId: string;
  goalId?: string;
  category?: string;
}

function getInnerRadius(
  index: number,
  size: 'sm' | 'lg',
  showCats: boolean,
  showGoals: boolean,
  showSegs: boolean,
): number {
  const charts = [showCats, showGoals, showSegs];
  const numChartsShowing = charts.filter((flag) => flag).length;
  const sizeFactor = { sm: 1.2, lg: 1.4 }[size];
  let radius = 30;
  if (numChartsShowing === 3) {
    if (index === 1) {
      radius = 60;
    } else if (index === 2) {
      radius = 90;
    }
  } else if (numChartsShowing === 2) {
    if ((index === 1 && charts[0]) || index === 2) {
      // This is the outermost chart
      radius = 75;
    }
  }
  return radius * sizeFactor;
}

function getOuterRadius(
  index: number,
  size: 'sm' | 'lg',
  showCats: boolean,
  showGoals: boolean,
  showSegs: boolean,
): number {
  const charts = [showCats, showGoals, showSegs];
  const numChartsShowing = charts.filter((flag) => flag).length;
  const sizeFactor = { sm: 1.2, lg: 1.4 }[size];
  let radius = 120;
  if (numChartsShowing === 3) {
    if (index === 0) {
      radius = 60;
    } else if (index === 1) {
      radius = 90;
    }
  } else if (numChartsShowing === 2) {
    if (index === 0 || (index === 1 && charts[2])) {
      // This is the innermost chart
      radius = 75;
    }
  }
  return radius * sizeFactor;
}

function getCenterX(index: number, size: 'sm' | 'lg'): number {
  if (size === 'sm') {
    return index ? 560 : 210;
  }
  return 350;
}

const LeftLabelContent: LabelContentType = ({ value }: LabelProps) => {
  return (
    <text textAnchor={'middle'} x={210} y={375}>
      {value}
    </text>
  );
};

const CenterLabelContent: LabelContentType = ({ value }: LabelProps) => {
  return (
    <text textAnchor={'middle'} x={350} y={375}>
      {value}
    </text>
  );
};

const RightLabelContent: LabelContentType = ({ value }: LabelProps) => {
  return (
    <text textAnchor={'middle'} x={560} y={375}>
      {value}
    </text>
  );
};

function breakdownTooltipRenderer({
  Campaigns,
  Flights,
  Goals,
  Segments,
}: Readonly<ReportStore>): TooltipContentType<ValueType, NameType> {
  return function ({ active, payload: payloadProp }: TooltipProps<ValueType, NameType>) {
    if (!active || !payloadProp?.length) {
      return null;
    }

    const payload = payloadProp[0].payload;
    const ttsegment = payload?.segmentId ? Segments.get(payload.segmentId) : undefined;
    const ttgoal = payload?.goalId ? Goals.get(payload.goalId) : undefined;
    const ttcampaign =
      ttgoal && ttgoal.get('kind') !== 'flight' ? Campaigns.get(ttgoal.get('parent')) : undefined;
    const ttflight =
      ttgoal && ttgoal.get('kind') === 'flight' ? Flights.get(ttgoal.get('parent')) : undefined;
    const category = payload?.category;
    const ttroi = payload?.roi;
    const ttnum = payload?.num;
    return (
      <div className={styles.chartTooltip}>
        <dl>
          {!!ttsegment && (
            <>
              <dt>Group</dt>
              <dd>{ttsegment.get('name')}</dd>
            </>
          )}
          {!!ttgoal && !!ttcampaign ? (
            <>
              <dt>Campaign</dt>
              <dd>{ttcampaign.get('name')}</dd>
            </>
          ) : (
            <></>
          )}
          {!!ttgoal && !!ttflight ? (
            <>
              <dt>Flight</dt>
              <dd>{ttflight.get('name')}</dd>
            </>
          ) : (
            <></>
          )}
          {!!category && (
            <>
              <dt>Category</dt>
              <dd>{category}</dd>
            </>
          )}
          {!!ttroi && (
            <>
              <dt>Conversion value</dt>
              <dd>{numeral(ttroi).format('$0,0.00')}</dd>
            </>
          )}
          {!!ttnum && (
            <>
              <dt>Conversions</dt>
              <dd>{numeral(ttnum).format('0,0')}</dd>
            </>
          )}
        </dl>
      </div>
    );
  };
}

function getConversionsSecondaryText(
  segment: Segment | undefined,
  numConversions: number,
  t: TFunction,
): string {
  if (segment?.count) {
    const percentage = numeral(numConversions / segment.count).format('0.0%');
    const segmentTotal = numeral(segment.count).format('0,0');
    return t('{{percentage}} of {{segmentTotal}} people in goal group', {
      percentage,
      segmentTotal,
    });
  } else {
    return t('0.0% of 0 people in goal group');
  }
}

function ROICardComponent({
  attributionModel = 'full',
  dailyStats,
  goals,
  start,
  end,
}: Readonly<IProps>): JSX.Element {
  const reportStore = useStore();
  const { Segments } = reportStore;
  const { t } = useTranslation();

  const startDate = moment.utc(start);
  const endDate = moment.utc(end);

  const { roi, filteredNumConversions } = dailyStats.reduce(
    (acc, stat) => {
      const dailyStatDate = moment.utc(stat.get('metadata').date);
      const isWithinDateRange = dailyStatDate.isBetween(startDate, endDate, null, '[]');

      if (isWithinDateRange) {
        const conversions = stat.get('conversions');
        const attributionModelStats = conversions?.[attributionModel];

        if (attributionModelStats) {
          acc.roi += attributionModelStats.roi || 0;
          acc.filteredNumConversions += attributionModelStats.num || 0;
        }
      }

      return acc;
    },
    { roi: 0, filteredNumConversions: 0 },
  );

  const cellColors = [
    cssVar('--color-primary-3'),
    cssVar('--color-brand-teal'),
    cssVar('--color-brand-green'),
    cssVar('--color-brand-yellow'),
    cssVar('--color-brand-orange'),
    cssVar('--color-brand-purple'),
  ];
  const goalPieData: Record<string, IChartData> = {};
  const categoryPieData: Record<string, IChartData> = {};
  const segmentPieData: Record<string, IChartData> = {};
  dailyStats.forEach((stat) => {
    const conversions = stat.get('conversions');
    if (conversions) {
      const segmentsStats = conversions[attributionModel]?.segments_breakdown;
      if (!segmentsStats) {
        return;
      }
      segmentsStats.forEach((segmentStats) => {
        if (segmentPieData[segmentStats.goal_segment_id]) {
          segmentPieData[segmentStats.goal_segment_id].num += segmentStats.num;
          segmentPieData[segmentStats.goal_segment_id].roi += segmentStats.roi;
        } else {
          segmentPieData[segmentStats.goal_segment_id] = {
            id: segmentStats.goal_segment_id,
            num: segmentStats.num,
            roi: segmentStats.roi,
            segmentId: segmentStats.goal_segment_id,
          };
        }
        const goalsStats = segmentStats.goals;
        if (!goalsStats) {
          return;
        }
        goalsStats.forEach((goalStats) => {
          if (goalPieData[goalStats.goal_id]) {
            goalPieData[goalStats.goal_id].num += goalStats.num;
            goalPieData[goalStats.goal_id].roi += goalStats.roi;
          } else {
            goalPieData[goalStats.goal_id] = {
              id: goalStats.goal_id,
              num: goalStats.num,
              roi: goalStats.roi,
              goalId: goalStats.goal_id,
              segmentId: segmentStats.goal_segment_id,
            };
          }
          const categoriesStats = goalStats.categories;
          if (!categoriesStats) {
            return;
          }
          categoriesStats.forEach((catStats): void => {
            const formattedCat = capitalize(catStats.category);
            const key = `${formattedCat}.${goalStats.goal_id}.${segmentStats.goal_segment_id}`;
            if (categoryPieData[key]) {
              categoryPieData[key].num += catStats.num;
              categoryPieData[key].roi += catStats.roi;
            } else {
              categoryPieData[key] = {
                id: formattedCat,
                name: formattedCat,
                num: catStats.num,
                roi: catStats.roi,
                segmentId: segmentStats.goal_segment_id,
                goalId: goalStats.goal_id,
                category: formattedCat,
              };
            }
          });
        });
      });
    }
  });

  function sortFn(a: IChartData, b: IChartData): number {
    const aId = ObjectId.createFromHexString(a.segmentId);
    const bId = ObjectId.createFromHexString(b.segmentId);
    return aId.generationTime - bId.generationTime;
  }

  const segmentPieDataFormatted = Object.values(segmentPieData)
    .filter((d) => d.num > 0)
    .sort(sortFn);
  const goalPieDataFormatted = Object.values(goalPieData)
    .filter((d) => d.num > 0)
    .sort(sortFn);
  const categoryPieDataFormatted = Object.values(categoryPieData)
    .filter((d) => d.num > 0)
    .sort(sortFn);
  const roiFormatted = numeral(roi).format('$0,0.00');
  const conversionsFormatted = numeral(filteredNumConversions).format('0,0');
  const multiGoal = goals.length > 1;
  const segment = getGoalSegments([goals[0]], Segments)[0];
  const conversionsSecondary = multiGoal
    ? ''
    : getConversionsSecondaryText(segment, filteredNumConversions, t);
  const showSegsChart = segmentPieDataFormatted.length > 1;
  const showGoalsChart = goalPieDataFormatted.length > 1;
  // Only show category donut if there are categories to show other than "Conversion"
  const showCatsChart =
    categoryPieDataFormatted.reduce((arr, d) => {
      if (d.category && !arr.includes(d.category)) {
        arr.push(d.category);
      }
      return arr;
    }, [] as string[]).length > 1;
  const showConversionBreakdown = filteredNumConversions > 0 && (showSegsChart || showCatsChart);
  const showROIBreakdown = roi > 0 && (showGoalsChart || showCatsChart);
  const breakdownSize = showConversionBreakdown && showROIBreakdown ? 'sm' : 'lg';
  const showBreakdowns = showConversionBreakdown || showROIBreakdown;
  const segmentColors = segmentPieDataFormatted.map((item, index) => ({
    segment: item.segmentId,
    color: cellColors[index % cellColors.length],
  }));
  const conversionsLabelContent = showROIBreakdown ? LeftLabelContent : CenterLabelContent;
  const roiLabelContent = showConversionBreakdown ? RightLabelContent : CenterLabelContent;
  const goalColors = goalPieDataFormatted.map((item, index) => {
    const parentColor = segmentColors.find((seg) => seg.segment === item.segmentId);
    return {
      segment: item.segmentId,
      color: shade(parentColor ? parentColor.color : cellColors[index % cellColors.length], -0.1),
    };
  });
  const catColors = categoryPieDataFormatted.map((item, index) => {
    const parentColor = segmentColors.find((seg) => seg.segment === item.segmentId);
    return {
      segment: item.segmentId,
      color: shade(parentColor ? parentColor.color : cellColors[index % cellColors.length], -0.2),
    };
  });
  return (
    <StatsCardV1 title={reportModuleLabels.includeROI}>
      <StatBox
        label={'Conversion value'}
        primary={roiFormatted}
        tooltip={'The total monetary value of all conversions generated by this campaign.'}
      />
      <StatBox
        label={'Conversions'}
        primary={conversionsFormatted}
        secondary={conversionsSecondary}
      />
      {showBreakdowns && (
        <div className={styles.chartsBox}>
          <Label>Breakdown</Label>
          <PieChart height={380} width={750}>
            {showConversionBreakdown && showSegsChart ? (
              <Pie
                cx={getCenterX(0, breakdownSize)}
                cy={180}
                data={segmentPieDataFormatted}
                dataKey={'num'}
                innerRadius={getInnerRadius(
                  2,
                  breakdownSize,
                  showCatsChart,
                  showGoalsChart,
                  showSegsChart,
                )}
                outerRadius={getOuterRadius(
                  2,
                  breakdownSize,
                  showCatsChart,
                  showGoalsChart,
                  showSegsChart,
                )}
              >
                <Label
                  content={conversionsLabelContent}
                  position={'center'}
                  value={'Conversions'}
                />
                {segmentColors.map((item, index) => (
                  <Cell fill={item.color} key={`cell-${index}`} />
                ))}
              </Pie>
            ) : (
              <></>
            )}
            {showConversionBreakdown && showGoalsChart ? (
              <Pie
                cx={getCenterX(0, breakdownSize)}
                cy={180}
                data={goalPieDataFormatted}
                dataKey={'num'}
                innerRadius={getInnerRadius(
                  1,
                  breakdownSize,
                  showCatsChart,
                  showGoalsChart,
                  showSegsChart,
                )}
                outerRadius={getOuterRadius(
                  1,
                  breakdownSize,
                  showCatsChart,
                  showGoalsChart,
                  showSegsChart,
                )}
              >
                <Label
                  content={conversionsLabelContent}
                  position={'center'}
                  value={'Conversions'}
                />
                {goalColors.map((item, index) => (
                  <Cell fill={item.color} key={`cell-${index}`} />
                ))}
              </Pie>
            ) : (
              <></>
            )}
            {showConversionBreakdown && showCatsChart ? (
              <Pie
                cx={getCenterX(0, breakdownSize)}
                cy={180}
                data={categoryPieDataFormatted}
                dataKey={'num'}
                innerRadius={getInnerRadius(
                  0,
                  breakdownSize,
                  showCatsChart,
                  showGoalsChart,
                  showSegsChart,
                )}
                outerRadius={getOuterRadius(
                  0,
                  breakdownSize,
                  showCatsChart,
                  showGoalsChart,
                  showSegsChart,
                )}
              >
                <Label
                  content={conversionsLabelContent}
                  position={'center'}
                  value={'Conversions'}
                />
                {catColors.map((item, index) => (
                  <Cell fill={item.color} key={`cell-${index}`} />
                ))}
              </Pie>
            ) : (
              <></>
            )}
            {showROIBreakdown && showSegsChart ? (
              <Pie
                cx={getCenterX(1, breakdownSize)}
                cy={180}
                data={segmentPieDataFormatted}
                dataKey={'roi'}
                innerRadius={getInnerRadius(
                  2,
                  breakdownSize,
                  showCatsChart,
                  showGoalsChart,
                  showSegsChart,
                )}
                outerRadius={getOuterRadius(
                  2,
                  breakdownSize,
                  showCatsChart,
                  showGoalsChart,
                  showSegsChart,
                )}
              >
                <Label content={roiLabelContent} position={'center'} value={'Conversion value'} />
                {segmentColors.map((item, index) => (
                  <Cell fill={item.color} key={`cell-${index}`} />
                ))}
              </Pie>
            ) : (
              <></>
            )}
            {showROIBreakdown && showGoalsChart ? (
              <Pie
                cx={getCenterX(1, breakdownSize)}
                cy={180}
                data={goalPieDataFormatted}
                dataKey={'roi'}
                innerRadius={getInnerRadius(
                  1,
                  breakdownSize,
                  showCatsChart,
                  showGoalsChart,
                  showSegsChart,
                )}
                outerRadius={getOuterRadius(
                  1,
                  breakdownSize,
                  showCatsChart,
                  showGoalsChart,
                  showSegsChart,
                )}
              >
                <Label content={roiLabelContent} position={'center'} value={'Conversion value'} />
                {goalColors.map((item, index) => (
                  <Cell fill={item.color} key={`cell-${index}`} />
                ))}
              </Pie>
            ) : (
              <></>
            )}
            {showROIBreakdown && showCatsChart ? (
              <Pie
                cx={getCenterX(1, breakdownSize)}
                cy={180}
                data={categoryPieDataFormatted}
                dataKey={'roi'}
                innerRadius={getInnerRadius(
                  0,
                  breakdownSize,
                  showCatsChart,
                  showGoalsChart,
                  showSegsChart,
                )}
                outerRadius={getOuterRadius(
                  0,
                  breakdownSize,
                  showCatsChart,
                  showGoalsChart,
                  showSegsChart,
                )}
              >
                <Label content={roiLabelContent} position={'center'} value={'Conversion value'} />
                {catColors.map((item, index) => (
                  <Cell fill={item.color} key={`cell-${index}`} />
                ))}
              </Pie>
            ) : (
              <></>
            )}
            <Tooltip content={breakdownTooltipRenderer(reportStore)} />
          </PieChart>
        </div>
      )}
    </StatsCardV1>
  );
}

const ROICard = observer(ROICardComponent);

export { ROICard };
