import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AdvertisersPage from '@feathr/extender/App/Settings/Account/AdvertisersPage';
import AdvertiserPage from '@feathr/extender/App/Settings/Account/AdvertisersPage/AdvertiserPage';
import AllowListPage from '@feathr/extender/App/Settings/Account/AllowListPage';
import ConversionsPage from '@feathr/extender/App/Settings/Account/ConversionsPage';
import DomainsPage from '@feathr/extender/App/Settings/Account/DomainsPage';
import EmailsPage from '@feathr/extender/App/Settings/Account/EmailsPage';
import EmailEditPage from '@feathr/extender/App/Settings/Account/EmailsPage/EmailEditPage';
import FontsPage from '@feathr/extender/App/Settings/Account/FontsPage';
import FontEditPage from '@feathr/extender/App/Settings/Account/FontsPage/FontEditPage';
import GeneralSettingsPage from '@feathr/extender/App/Settings/Account/GeneralSettingsPage';
import GoogleAdsCustomersPage from '@feathr/extender/App/Settings/Account/GoogleAdsCustomersPage';
import ImisIntegrationPage from '@feathr/extender/App/Settings/Account/ImisIntegrationPage';
import IPFilteringPage from '@feathr/extender/App/Settings/Account/IPFilteringPage';
import AccountProfilePage from '@feathr/extender/App/Settings/Account/ProfilePage';
import RaisersEdgeIntegrationPage from '@feathr/extender/App/Settings/Account/RaisersEdgeIntegrationPage';
import UsersAndRolesPage from '@feathr/extender/App/Settings/Account/UsersAndRolesPage';
import EditRolePage from '@feathr/extender/App/Settings/Account/UsersAndRolesPage/Roles/EditRolePage/EditRolePage';
import BillablePage from '@feathr/extender/App/Settings/Billing/BillablePage';
import BillableAddPage from '@feathr/extender/App/Settings/Billing/BillablePage/BillableAddPage';
import BillableEditPage from '@feathr/extender/App/Settings/Billing/BillablePage/BillableEditPage';
import InvoicesPage from '@feathr/extender/App/Settings/Billing/InvoicesPage';
import LicensePage from '@feathr/extender/App/Settings/Billing/LicensePage';
import LicenseEditPage from '@feathr/extender/App/Settings/Billing/LicensePage/LicenseEditPage';
import NotificationManagementPage from '@feathr/extender/App/Settings/User/NotificationManagementPage';
import UserProfilePage from '@feathr/extender/App/Settings/User/ProfilePage';
import ResetPasswordPage from '@feathr/extender/App/Settings/User/ResetPasswordPage';
import { useGoogleAdsPermissions } from '@feathr/extender/hooks';
import { useAccount, useFlags, useRole } from '@feathr/extender/state';

import APIKeyEditPage from '../Settings/Account/APIKeysPage/APIKeyEditPage';
import APIKeysPage from '../Settings/Account/APIKeysPage/APIKeysPage';
import ConversionsSettingsPage from '../Settings/Account/ConversionsSettingsPage/ConversionsSettingsPage';
import DomainDetailPage from '../Settings/Account/DomainDetailPage/DomainDetailPage';
import DomainEditPage from '../Settings/Account/DomainsPage/DomainEditPage';
import FacebookIntegrationPage from '../Settings/Account/FacebookIntegrationPage';
import IntegrationsDashboard from '../Settings/Account/IntegrationsDashboardPage/IntegrationsDashboardPage';
import SubscriptionsPage from '../Settings/Account/SubscriptionsPage';
import ProResourcesPage from '../Settings/ProResourcesPage';
import RedirectTo404 from './RedirectTo404';
import RouteWithPermissions from './RouteWithPermissions';

function SettingsRoutes(): JSX.Element {
  const {
    hasBilling,
    hasConversions,
    hasDomains,
    hasIntegrations,
    isAdmin: isAdminUser,
    isGlobal,
    isSudoer,
    hasPermissionsLicenseOrFlag,
  } = useRole();
  const account = useAccount();
  const showAPIKeys = account.isFalcon;
  const { hasImis, hasBlackbaudRaisersEdge, showConversionsSettingsV2 } = useFlags();
  const { isPro, isPlatformAdvanced } = account;

  const showImis = isPlatformAdvanced || hasImis;
  const showBlackbaudRaisersEdge = isPlatformAdvanced || hasBlackbaudRaisersEdge;
  const showGoogleAds = useGoogleAdsPermissions({ requireConnectedCustomer: false });

  const isAdmin = isSudoer || isAdminUser;
  const isAgencyOrAdmin = account.get('is_agency') || isSudoer;

  const activePackage = account.activePackage;
  if (!activePackage && !isSudoer) {
    return (
      <Switch>
        <RouteWithPermissions
          condition={hasBilling}
          path={'/:accountId/settings/billing/configurations/add'}
        >
          <BillableAddPage />
        </RouteWithPermissions>
        <RouteWithPermissions
          condition={hasBilling}
          path={'/:accountId/settings/billing/configurations/:billingId/edit'}
        >
          <BillableEditPage />
        </RouteWithPermissions>
        <RouteWithPermissions
          condition={hasBilling}
          path={'/:accountId/settings/billing/configurations'}
        >
          <BillablePage />
        </RouteWithPermissions>
        <RouteWithPermissions condition={hasBilling} path={'/:accountId/settings/billing/invoices'}>
          <InvoicesPage />
        </RouteWithPermissions>
        <RouteWithPermissions
          condition={hasBilling}
          path={'/:accountId/settings/billing/license/edit'}
        >
          <LicenseEditPage />
        </RouteWithPermissions>
        <RouteWithPermissions condition={hasBilling} path={'/:accountId/settings/billing/license'}>
          <LicensePage />
        </RouteWithPermissions>
      </Switch>
    );
  }

  return (
    <Switch>
      <Redirect
        exact={true}
        from={'/:accountId/settings'}
        to={'/:accountId/settings/user/profile'}
      />
      <Redirect
        exact={true}
        from={'/:accountId/settings/user'}
        to={'/:accountId/settings/user/profile'}
      />
      {isSudoer ? (
        <Redirect
          exact={true}
          from={'/:accountId/settings/account'}
          to={'/:accountId/settings/account/profile'}
        />
      ) : isAdmin ? (
        <Redirect
          exact={true}
          from={'/:accountId/settings/account'}
          to={'/:accountId/settings/account/users'}
        />
      ) : (
        <Redirect
          exact={true}
          from={'/:accountId/settings/account'}
          to={'/:accountId/settings/account/emails'}
        />
      )}
      <Redirect
        exact={true}
        from={'/:accountId/settings/billing'}
        to={'/:accountId/settings/billing/configurations'}
      />
      <Redirect
        exact={true}
        from={'/:accountId/settings/account/billing/:billingId/edit'}
        to={'/:accountId/settings/billing/configurations/:billingId/edit'}
      />
      {showConversionsSettingsV2 && (
        <Redirect
          exact={true}
          from={'/:accountId/settings/account/conversions'}
          to={'/:accountId/settings/account/conversions/general'}
        />
      )}
      <RouteWithPermissions
        condition={isAgencyOrAdmin}
        path={'/:accountId/settings/account/advertisers/:advertiserId(add)'}
      >
        <AdvertiserPage />
      </RouteWithPermissions>
      <RouteWithPermissions
        condition={isAgencyOrAdmin}
        path={'/:accountId/settings/account/advertisers/:advertiserId'}
      >
        <AdvertiserPage />
      </RouteWithPermissions>
      <RouteWithPermissions
        condition={isAgencyOrAdmin}
        path={'/:accountId/settings/account/advertisers'}
      >
        <AdvertisersPage />
      </RouteWithPermissions>
      <RouteWithPermissions condition={hasDomains} path={'/:accountId/settings/account/allow-list'}>
        <AllowListPage />
      </RouteWithPermissions>

      {showConversionsSettingsV2 ? (
        <RouteWithPermissions
          condition={hasConversions}
          path={'/:accountId/settings/account/conversions/:tab(general|attribution-windows)'}
        >
          <ConversionsSettingsPage />
        </RouteWithPermissions>
      ) : (
        <RouteWithPermissions
          condition={hasConversions}
          path={'/:accountId/settings/account/conversions'}
        >
          <ConversionsPage />
        </RouteWithPermissions>
      )}

      <RouteWithPermissions
        condition={hasDomains}
        path={'/:accountId/settings/account/domains/:domainId/edit'}
      >
        <DomainEditPage />
      </RouteWithPermissions>
      <RouteWithPermissions
        condition={hasDomains}
        path={'/:accountId/settings/account/domains/:domainId'}
      >
        <DomainDetailPage />
      </RouteWithPermissions>
      <RouteWithPermissions condition={hasDomains} path={'/:accountId/settings/account/domains'}>
        <DomainsPage />
      </RouteWithPermissions>

      <Route exact={true} path={'/:accountId/settings/account/emails'}>
        <EmailsPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/account/emails/:emailId(add)'}>
        <EmailEditPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/account/emails/:emailId/edit'}>
        <EmailEditPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/account/fonts'}>
        <FontsPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/account/fonts/:fontId(add)'}>
        <FontEditPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/account/fonts/:fontId/edit'}>
        <FontEditPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/account/subscriptions'}>
        <SubscriptionsPage />
      </Route>
      <Route
        component={IPFilteringPage}
        exact={true}
        path={'/:accountId/settings/account/ip-filtering'}
      />

      <RouteWithPermissions
        condition={hasBilling}
        path={'/:accountId/settings/billing/configurations/add'}
      >
        <BillableAddPage />
      </RouteWithPermissions>
      <RouteWithPermissions
        condition={hasBilling}
        path={'/:accountId/settings/billing/configurations/:billingId/edit'}
      >
        <BillableEditPage />
      </RouteWithPermissions>

      <RouteWithPermissions
        condition={hasBilling}
        path={'/:accountId/settings/billing/configurations'}
      >
        <BillablePage />
      </RouteWithPermissions>
      <RouteWithPermissions condition={hasBilling} path={'/:accountId/settings/billing/invoices'}>
        <InvoicesPage />
      </RouteWithPermissions>
      <RouteWithPermissions
        condition={hasBilling}
        path={'/:accountId/settings/billing/license/edit'}
      >
        <LicenseEditPage />
      </RouteWithPermissions>
      <RouteWithPermissions condition={hasBilling} path={'/:accountId/settings/billing/license'}>
        <LicensePage />
      </RouteWithPermissions>
      <Route exact={true} path={'/:accountId/settings/user/notifications'}>
        <NotificationManagementPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/user/profile'}>
        <UserProfilePage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/user/reset-password'}>
        <ResetPasswordPage />
      </Route>

      <RouteWithPermissions
        condition={hasIntegrations}
        path={'/:accountId/settings/integrations/meta'}
      >
        <FacebookIntegrationPage />
      </RouteWithPermissions>
      <RouteWithPermissions
        condition={hasIntegrations && showGoogleAds}
        path={'/:accountId/settings/integrations/google-ads'}
      >
        <GoogleAdsCustomersPage />
      </RouteWithPermissions>
      <RouteWithPermissions
        condition={hasIntegrations && showAPIKeys}
        path={'/:accountId/settings/integrations/api-keys/:apiKeyId/add'}
      >
        <APIKeyEditPage />
      </RouteWithPermissions>
      <RouteWithPermissions
        condition={hasIntegrations && showAPIKeys}
        path={'/:accountId/settings/integrations/api-keys'}
      >
        <APIKeysPage />
      </RouteWithPermissions>
      <RouteWithPermissions
        condition={hasIntegrations && showImis}
        path={'/:accountId/settings/integrations/imis'}
      >
        <ImisIntegrationPage />
      </RouteWithPermissions>
      <RouteWithPermissions
        condition={hasIntegrations && showBlackbaudRaisersEdge}
        path={'/:accountId/settings/integrations/raisers-edge/:tab(overview|unsubscribes)?'}
      >
        <RaisersEdgeIntegrationPage />
      </RouteWithPermissions>
      <RouteWithPermissions condition={hasIntegrations} path={'/:accountId/settings/integrations'}>
        <IntegrationsDashboard />
      </RouteWithPermissions>

      <RouteWithPermissions condition={isSudoer} path={'/:accountId/settings/account/profile'}>
        <AccountProfilePage />
      </RouteWithPermissions>

      <RouteWithPermissions condition={isAdmin} path={'/:accountId/settings/account/general'}>
        <GeneralSettingsPage />
      </RouteWithPermissions>

      <RouteWithPermissions
        condition={(isGlobal || isAdmin) && hasPermissionsLicenseOrFlag}
        path={'/:accountId/settings/account/users/roles/:action(create|edit)'}
      >
        <EditRolePage />
      </RouteWithPermissions>
      <RouteWithPermissions
        condition={(isGlobal || isAdmin) && hasPermissionsLicenseOrFlag}
        path={'/:accountId/settings/account/users/roles/:roleId/:action(create|edit)?'}
      >
        <EditRolePage />
      </RouteWithPermissions>
      {/* Allow access to all user & roles routes with a license that has permissions access */}
      <RouteWithPermissions
        condition={isGlobal || isAdmin}
        path={'/:accountId/settings/account/users/:tab(roles|authentication)?'}
      >
        <UsersAndRolesPage />
      </RouteWithPermissions>

      <RouteWithPermissions condition={isPro} path={'/:accountId/settings/pro-resources'}>
        <ProResourcesPage />
      </RouteWithPermissions>

      <RedirectTo404 />
    </Switch>
  );
}

export default observer(SettingsRoutes);
