import { useTranslation } from 'react-i18next';

import { ELinkedCampaignSetAction } from '@feathr/blackbox';

type TDripStepActionRecord = Record<ELinkedCampaignSetAction, string>;

interface IUseDripTextReturn {
  builder: {
    targetingMessage: TDripStepActionRecord;
    targetingDelayTooltip: TDripStepActionRecord;
    targetingDelayLabel: TDripStepActionRecord;
    targetingDelayHelpText: TDripStepActionRecord;
  };
}

function useDripText(): IUseDripTextReturn {
  const { t } = useTranslation();

  const targetingMessage: TDripStepActionRecord = {
    [ELinkedCampaignSetAction.previous_email_delivered]: t('Send to everyone'),
    [ELinkedCampaignSetAction.not_opened_previous_email]: t('Not opened the previous email'),
    [ELinkedCampaignSetAction.opened_previous_email]: t('Opened the previous email'),
    [ELinkedCampaignSetAction.not_clicked_link_previous_email]: t('Not clicked the previous email'),
    [ELinkedCampaignSetAction.clicked_link_previous_email]: t('Clicked the previous email'),
  };

  const targetingDelayTooltip: TDripStepActionRecord = {
    [ELinkedCampaignSetAction.previous_email_delivered]: t(
      'Set the amount of time that should pass between delivering the previous email and sending this email',
    ),
    [ELinkedCampaignSetAction.not_opened_previous_email]: t(
      'Set the amount of time that should pass between delivering the previous email and sending this email',
    ),
    [ELinkedCampaignSetAction.opened_previous_email]: t(
      'Set the amount of time that should pass between opening the previous email and sending this email',
    ),
    [ELinkedCampaignSetAction.not_clicked_link_previous_email]: t(
      'Set the amount of time that should pass between delivering the previous email and sending this email',
    ),
    [ELinkedCampaignSetAction.clicked_link_previous_email]: t(
      'Set the amount of time that should pass between clicking the previous email and sending this email',
    ),
  };

  const targetingDelayLabel: TDripStepActionRecord = {
    [ELinkedCampaignSetAction.previous_email_delivered]: t('Delay from delivery'),
    [ELinkedCampaignSetAction.not_opened_previous_email]: t('Delay from delivery'),
    [ELinkedCampaignSetAction.opened_previous_email]: t('Delay from open'),
    [ELinkedCampaignSetAction.not_clicked_link_previous_email]: t('Delay from delivery'),
    [ELinkedCampaignSetAction.clicked_link_previous_email]: t('Delay from click'),
  };

  const targetingDelayHelpText: TDripStepActionRecord = {
    [ELinkedCampaignSetAction.previous_email_delivered]: t(
      'Set the amount of time that should pass between delivering the previous email and sending this email.',
    ),
    [ELinkedCampaignSetAction.not_opened_previous_email]: t(
      'Set the amount of time that should pass between delivering the previous email and sending this email.',
    ),
    [ELinkedCampaignSetAction.opened_previous_email]: t(
      'Set the amount of time that should pass between opening the previous email and sending this email.',
    ),
    [ELinkedCampaignSetAction.not_clicked_link_previous_email]: t(
      'Set the amount of time that should pass between delivering the previous email and sending this email.',
    ),
    [ELinkedCampaignSetAction.clicked_link_previous_email]: t(
      'Set the amount of time that should pass between clicking a link in the previous email and sending this email.',
    ),
  };

  return {
    builder: {
      targetingMessage,
      targetingDelayTooltip,
      targetingDelayLabel,
      targetingDelayHelpText,
    },
  };
}

export default useDripText;
