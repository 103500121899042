import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { JSX, ReactNode } from 'react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import type { RowInfo } from 'react-table';

import type { Template, TemplateClass } from '@feathr/blackbox';
import { DebouncedInput, Input, Label, Table } from '@feathr/components';
import { useAccount, useStore } from '@feathr/extender/state';
import type { TTemplateGroup } from '@feathr/extender/styles/template';

import TemplateGroupSelect from '../TemplateGroupSelect';
import templateSelectColumns from './templateSelectColumns';
import { templatesFilters } from './templateSelectShared';

import * as styles from './TemplateSelectTable.css';

interface IFilter {
  group?: TTemplateGroup;
  name?: string;
}

export interface ITemplateSelectTableProps {
  label?: ReactNode;
  onChange: (newValue?: string) => Promise<void> | void;
  templateClass: TemplateClass;
  /** ID of selected template */
  value?: string;
}

function TemplateSelectTable({
  label,
  onChange,
  templateClass,
  value,
}: ITemplateSelectTableProps): JSX.Element {
  const { Templates } = useStore();
  const account = useAccount();
  const { eventId } = useParams<{ eventId: string }>();
  const [filter, setFilter] = useState<IFilter>({});
  const { t } = useTranslation();

  const filters = templatesFilters({
    accountId: account!.id,
    eventId,
    group: filter.group,
    name: filter.name,
    templateClass,
  });

  const rowHandler = useCallback(
    (_, rowInfo?: RowInfo) => {
      if (rowInfo?.row) {
        return {
          onClick: async (): Promise<void> => {
            await onChange(rowInfo.original.id);
          },
          className: classNames(styles.row, { [styles.selected]: rowInfo.original.id === value }),
        };
      }
      return {};
    },
    [onChange, value],
  );

  function onChangeGroup(id?: TTemplateGroup): void {
    setFilter({ ...filter, group: id });
  }

  function handleDebouncedSearchChange(search?: string): void {
    setFilter({ ...filter, name: search });
  }

  const filterElements = [
    <>
      <TemplateGroupSelect
        onChange={onChangeGroup}
        value={filter.group}
        wrapperClassName={styles.filter}
      />
    </>,
    <>
      <DebouncedInput<string> defaultValue={filter.name} onChange={handleDebouncedSearchChange}>
        {(liveValue, onChangeLiveValue): JSX.Element => (
          <Input
            isClearable={true}
            onChange={onChangeLiveValue}
            placeholder={'Search by name...'}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
    </>,
  ];

  // TODO: Limit fetched templates to used fields once backend is fixed for computed field
  return (
    <>
      {!!label && <Label>{label ?? t('Choose a template')}</Label>}
      <Table<Template>
        collection={Templates}
        columns={templateSelectColumns(t)}
        filterElements={filterElements}
        filters={filters}
        getTrProps={rowHandler}
        initialPagesize={20}
        initialSort={[{ id: 'name' }]}
        noDataText={t('No templates were found.')}
      />
    </>
  );
}

export default TemplateSelectTable;
