import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { DisplayCreative } from '@feathr/blackbox';
import { FormSummaryItem, Skeleton } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import type { ListResponse } from '@feathr/rachis';

import type { ISummaryProps } from './CampaignSummary.types';

const CreativesSummary = observer(({ campaign, validationErrors }: ISummaryProps): JSX.Element => {
  const { t } = useTranslation();
  const { Creatives } = useStore();
  const creatives = Creatives.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  }) as ListResponse<DisplayCreative>;
  const filteredCreatives = creatives.models.filter((c) => !c.get('is_archived'));
  return (
    <FormSummaryItem
      errors={validationErrors?.redirects}
      label={t('Creatives')}
      value={
        creatives.isPending || filteredCreatives.length > 0 ? (
          <ol>
            {creatives.isPending && (
              <li>
                <Skeleton width={100} />
              </li>
            )}
            {filteredCreatives.map((crv) => {
              const spec = crv.getSpec();
              return (
                <li key={crv.id}>
                  {crv.get('name')} | {crv.get('width')}px x {crv.get('height')}px |{' '}
                  {spec ? spec.name : t('unknown')}
                </li>
              );
            })}
          </ol>
        ) : (
          t('(None)')
        )
      }
    />
  );
});

CreativesSummary.displayName = 'CreativesSummary';

export default CreativesSummary;
