import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormSummaryItem, Time } from '@feathr/components';
import { useUser } from '@feathr/extender/state';
import { moment, TimeFormat } from '@feathr/hooks';

import type { ISummaryProps } from './CampaignSummary.types';

const SendSummary = observer(({ campaign, validationErrors }: ISummaryProps): JSX.Element => {
  const user = useUser();

  const startTimestamp = campaign.get('date_send_start');
  const endTimestamp = campaign.get('date_send_end');
  const userTimezone = user.get('timezone');

  const { t } = useTranslation();
  return (
    <>
      <FormSummaryItem
        errors={validationErrors?.date_send_start}
        label={t('Send date/time')}
        value={
          <>
            {campaign.get('send_schedule') === 'now' ? (
              <span>{t('As soon as possible')}</span>
            ) : (
              <Time
                format={TimeFormat.shortWeekdayDateTime}
                timestamp={moment.utc(startTimestamp).format(TimeFormat.isoDateTime)}
                timezone={userTimezone}
              />
            )}
          </>
        }
      />
      {endTimestamp && (
        <FormSummaryItem
          errors={validationErrors?.date_send_end}
          label={t('End date/time')}
          value={
            <Time
              format={TimeFormat.shortWeekdayDateTime}
              timestamp={moment.utc(endTimestamp).format(TimeFormat.isoDateTime)}
              timezone={userTimezone}
            />
          }
        />
      )}
    </>
  );
});

SendSummary.displayName = 'SendSummary';

export default SendSummary;
