import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { TTDCampaign } from '@feathr/blackbox';
import { AlertV2 as Alert, EAlertV2Type as EAlertType, Toggle } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { useAccount, useRole } from '@feathr/report_components';

import * as styles from './CrossDeviceTargetingToggle.css';

interface IProps {
  campaign: TTDCampaign;
}

function CrossDeviceTargetingToggle({ campaign }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const { isAdmin, isSudoer } = useRole();
  const hasPermissions = isAdmin || isSudoer;

  const campaignSpecificCrossDevice = campaign.get('bidding_strategy').cross_device_vendor_id;
  const hasNotSetCrossDevice = typeof campaignSpecificCrossDevice === 'undefined';
  const account = useAccount();

  const initialCrossDeviceTargeting = Boolean(
    hasNotSetCrossDevice ? account.hasEarlyAccess : campaignSpecificCrossDevice,
  );
  const [crossDeviceTargeting, setCrossDeviceTargeting] = useState<boolean>(
    initialCrossDeviceTargeting,
  );

  function handleChange(newValue?: boolean): void {
    if (newValue === undefined) {
      return;
    }

    const currentBiddingStrategy = toJS(campaign.get('bidding_strategy'));

    setCrossDeviceTargeting(newValue);
    campaign.set({
      bidding_strategy: {
        ...currentBiddingStrategy,
        // If enabling cross device, set the vendor ID to undefined so backend falls back to
        // the right vendor id on save
        cross_device_vendor_id: newValue ? undefined : 0,
      },
    });
  }

  return (
    <>
      <Toggle
        disabled={!account.hasEarlyAccess}
        helpText={t(
          'Find and target this audience across all of their devices, not just the device that they were initially tracked on.',
        )}
        label={t('Enable omni targeting')}
        layout={'well'}
        onChange={handleChange}
        value={crossDeviceTargeting}
      />
      {!account.hasEarlyAccess && (
        <Alert
          className={styles.marginBuster}
          description={
            hasPermissions
              ? t('Adjust your account settings to enable beta features.')
              : t('An admin can adjust account settings to enable beta features.')
          }
          title={t('Early access to features in beta is disabled')}
          type={EAlertType.warning}
        >
          {hasPermissions && (
            <a href={localUrl('settings/account/general')}>{t('Update account settings')}</a>
          )}
        </Alert>
      )}
    </>
  );
}

export default observer(CrossDeviceTargetingToggle);
