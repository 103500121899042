import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import type { TImporterState } from '@feathr/blackbox';
import type { Importer, Partners } from '@feathr/blackbox';
import { Alert, AlertType, Button } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import * as styles from './ImportersAlert.css';

interface IProps {
  eventId: string;
  partnersCollection: Partners;
}

function ImportersAlert({ eventId, partnersCollection }: IProps) {
  const { Importers } = useStore();
  const [importers, setImporters] = useState<Importer[]>([]);

  useEffect(() => {
    async function loadImporters() {
      const loadedImporters = Importers.list(
        {
          filters: {
            event: eventId,
            state: 'pending',
          },
        },
        { reset: true },
      );
      await when(() => !loadedImporters.isPending);
      // Converting observables back to vanilla JavaScript.
      setImporters(loadedImporters.models.slice());
    }

    loadImporters();
  }, []);

  useEffect(() => {
    // Keep refreshing until all importers have completed.
    let interval: NodeJS.Timeout;
    if (importers.length) {
      interval = setInterval(async () => {
        const loadedImporters = Importers.list({
          filters: {
            id__in: importers.map((importer) => importer.id),
          },
        });
        await when(() => !loadedImporters.isPending);
        // Converting observables back to vanilla JavaScript.
        setImporters(loadedImporters.models.slice());
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [importers.length]);

  if (!importers.length) {
    return null;
  }

  function handleRefresh() {
    partnersCollection.refreshApiCache();
  }

  return (
    <Alert className={styles.alert} type={AlertType.info}>
      {importers.map((importer) => {
        const state: TImporterState = importer.get('state');
        return (
          <p key={importer.id}>
            {state === 'pending' ? (
              <>
                Importing <em>{importer.get('name', 'Unknown import')}</em> is in progress...
              </>
            ) : (
              <>
                Importing <em>{importer.get('name', 'Unknown import')}</em> has completed.{' '}
                <Button onClick={handleRefresh} type={'link'}>
                  Refresh partners
                </Button>
                .
              </>
            )}
          </p>
        );
      })}
    </Alert>
  );
}

export default observer(ImportersAlert);
