import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Campaign, Flight, ICampaignAttributes, ILeg } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { Card, StatsCardV1 } from '@feathr/components';
import type { ListResponse } from '@feathr/rachis';
import { useStore } from '@feathr/report_components/state';
import type { IReportProps } from '@feathr/report_components/types';

import CampaignStats from './CampaignStats';

import * as styles from './FlightCard.css';

interface IProps {
  flight: Flight;
  end: IReportProps['end'];
  localUrl?: IReportProps['localUrl'];
  start: IReportProps['start'];
}

function FlightCard({ end, flight, localUrl, start }: IProps): JSX.Element {
  const { Campaigns } = useStore();

  const legs: IObservableArray<ILeg> = flight.get('legs');

  const campaigns: ListResponse<Campaign<ICampaignAttributes>> = Campaigns.list({
    filters: {
      flight: flight.id,
    },
  });

  return (
    <StatsCardV1 title={reportModuleLabels.includeFlightStats}>
      <div className={styles.root}>
        {legs.map((leg: ILeg, legId: number) => {
          const campaignsInLeg: Campaign[] = leg.campaigns
            .map((campaignId: string) =>
              campaigns.models.find(
                (campaign: Campaign<ICampaignAttributes>): boolean => campaign.id === campaignId,
              ),
            )
            .filter(
              (campaign: Campaign<ICampaignAttributes> | undefined) => !!campaign,
            ) as Campaign[];

          return (
            <Card
              className={styles.leg}
              contentClassName={styles.legContent}
              key={legId}
              title={leg.name}
            >
              {campaignsInLeg.map((campaign: Campaign<ICampaignAttributes>) => (
                <CampaignStats
                  campaign={campaign}
                  end={end}
                  key={campaign.id}
                  localUrl={localUrl}
                  start={start}
                />
              ))}
            </Card>
          );
        })}
      </div>
    </StatsCardV1>
  );
}

export default observer(FlightCard);
