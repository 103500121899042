import { computed, makeObservable } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel } from '@feathr/rachis';

import type { FieldDataType } from './custom_fields';

interface IFormStats {
  num_crumbs: number;
  num_views: number;
  num_submissions: number;
  submission_rate: number;
}

export enum EFormState {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
}

export interface IRowItem {
  /** The u_key of the related custom field */
  id: string;
  help_text?: string;
  /** Read only name of the field */
  name: string;
  /** User configurable name of the field */
  label: string;
  placeholder?: string;
  required?: boolean;
  type: FieldDataType;
}

export interface IFormConfig {
  settings: {
    submit: { label: string; color: string };
  };
  rows: Array<{ fields: IRowItem[] }>;
}

export type JSONString<T> = string & { __type__: T };

export interface IForm extends IBaseAttributes {
  account: string;
  name: string;
  state: EFormState;
  /** Form JSON */
  content_json: JSONString<IFormConfig>;
  /** Project ID */
  event: string;
  /** Thank you message JSON */
  submission_html: string;
  total_stats: IFormStats;
  version: number;
  date_last_modified: string;
}

export class Form extends DisplayModel<IForm> {
  public readonly className = 'Form';

  public get constraints(): TConstraints<IForm> {
    return {
      name: {
        presence: {
          allowEmpty: false,
        },
      },
    };
  }

  public override getDefaults(): Partial<IForm> {
    return {
      content_json: '{ "rows": [] }' as JSONString<IFormConfig>,
      submission_html: '<p>Thank you for your submission!</p>',
    };
  }

  constructor(attributes: Partial<IForm> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getItemUrl(pathSuffix?: string): string {
    const path = window.location.pathname.split('/');
    const eventId = path[3];

    return concatPath(
      `/projects/${this.get('event') ?? eventId}/content/forms/${this.id}`,
      pathSuffix,
    );
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Form';
  }

  /**
   * Returns the content_json as an IFormConfig object for the Form Editor.
   */
  @computed
  public get formConfig(): IFormConfig {
    const content = this.get('content_json');
    return JSON.parse(content);
  }

  @computed
  public get usedFields(): IRowItem[] {
    return this.formConfig.rows.flatMap((row) => row.fields);
  }

  public setConfig(config: IFormConfig): void {
    this.set({ content_json: JSON.stringify(config) as JSONString<IFormConfig> });
  }
}

export class Forms extends Collection<Form> {
  public getClassName(): string {
    return 'forms';
  }

  public getModel(attributes: Partial<IForm>): Form {
    return new Form(attributes);
  }
}
