import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { Alert, AlertType, Step, Steps, Wizard } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import AddCampaignButton from '../../CampaignsPage/AddCampaignButton';
import CampaignSummary from '../../CampaignSummary';
import createCampaignStore, { CampaignContext } from '../CampaignEdit/campaignEditContext';
import SaveCampaignButton from '../SaveCampaignButton';

interface IProps {
  campaign: Campaign;
}

/**
 * Creates an Alert that has a description based on the provided campaign's state and a link to create a new campaign.
 */
function LookalikeAlert({ campaign }: IProps): JSX.Element {
  const campaignState = campaign.get('state');
  const now = new Date();
  const dateEndStr = campaign.get('date_end');
  const dateEnd = dateEndStr ? new Date(dateEndStr) : new Date();
  const isLiveCampaign = now < dateEnd;

  type alertMessageKeys = 'draftErroringOrEnded' | 'publishedOrPublishing' | 'stopped' | 'default';
  const alertMessageMap: Record<alertMessageKeys, JSX.Element> = {
    draftErroringOrEnded: (
      <Trans t={t}>
        This campaign cannot be edited or published. This is because it is using both lookalike and
        affinity audiences. Lookalike and affinity targeting should now be done in{' '}
        <AddCampaignButton type={'link'}>separate campaigns</AddCampaignButton>.
      </Trans>
    ),
    publishedOrPublishing: (
      <Trans t={t}>
        This campaign cannot be edited. It can only be stopped. This is because it is using both
        lookalike and affinity audiences. Lookalike and affinity targeting should now be done in{' '}
        <AddCampaignButton type={'link'}>separate campaigns</AddCampaignButton>.
      </Trans>
    ),
    stopped: (
      <Trans t={t}>
        This campaign cannot be edited. It can only be published. This is because it is using both
        lookalike and affinity audiences. Lookalike and affinity targeting should now be done in{' '}
        <AddCampaignButton type={'link'}>separate campaigns</AddCampaignButton>.
      </Trans>
    ),
    default: (
      <Trans t={t}>
        This campaign type is outdated and can no longer be edited. Please create a{' '}
        <AddCampaignButton type={'link'}>new campaign</AddCampaignButton> instead.
      </Trans>
    ),
  };

  let alertMessageKey: alertMessageKeys = 'default';

  if ([CampaignState.Draft, CampaignState.Erroring].includes(campaignState) || !isLiveCampaign) {
    alertMessageKey = 'draftErroringOrEnded';
  } else if ([CampaignState.Published, CampaignState.Publishing].includes(campaignState)) {
    alertMessageKey = 'publishedOrPublishing';
  } else if (campaignState === CampaignState.Stopped) {
    alertMessageKey = 'stopped';
  }

  return <Alert type={AlertType.warning}>{alertMessageMap[alertMessageKey]}</Alert>;
}

/**
 * The edit page for legacy Lookalike campaigns. Previously campaigns contained both group and tradedesk targetables, these have now been split into separate campaigns (Lookalike Targeting for groups and Behavioral Targeting for tradedesk).
 *
 * Now all old Lookalike Campaigns can no longer be edited. Other actions depend on the current state of the campaign:
 * - Draft campaigns cannot be published.
 * - Published or Publishing campaigns can be stopped.
 * - Stopped campaigns can be Republished provided they are within the campaign's `end_date`.
 * - Erroring campaigns should display a message to contact the account manager
 */
function LookalikeCampaignEdit({ campaign }: IProps): JSX.Element {
  const { Targetings } = useStore();
  const steps = (
    <Steps completed={0} current={0}>
      {[<Step key={0} stepIndex={0} title={'Review'} />]}
    </Steps>
  );
  const campaignState = campaign.get('state');
  // We only want to allow stopped campaigns to republish if their end_date has not passed
  const now = new Date();
  const dateEndStr = campaign.get('date_end');
  const dateEnd = dateEndStr ? new Date(dateEndStr) : new Date();
  const isLiveCampaign = now < dateEnd;
  const actions =
    campaignState === CampaignState.Erroring || !isLiveCampaign
      ? undefined
      : [
          <SaveCampaignButton
            campaign={campaign}
            childModels={[]}
            disabled={campaign.get('state') === CampaignState.Draft || undefined}
            key={'changeState'}
            shouldChangeState={true}
          />,
        ];

  const targetings = Targetings.list({ filters: { _parent: campaign.id, is_archived__ne: true } });
  const campaignStore = createCampaignStore({ targetings });

  return (
    <CampaignContext.Provider value={campaignStore}>
      <div data-appcues-campaign={campaign.get('_cls')}>
        <LookalikeAlert campaign={campaign} />
        <Wizard actions={actions} steps={steps}>
          <CampaignSummary campaign={campaign} />
        </Wizard>
      </div>
    </CampaignContext.Provider>
  );
}

export default observer(LookalikeCampaignEdit);
