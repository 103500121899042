import { when } from 'mobx';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, AlertType, ModalV1, Spinner, toast } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

interface IProps {
  selected: string[];
  setSelected: (selection: string[]) => void;
  isArchiveModalOpen: boolean;
  toggleArchiveModalOpen: () => void;
}

function SegmentBulkArchiveModal({
  selected,
  setSelected,
  isArchiveModalOpen,
  toggleArchiveModalOpen,
}: IProps): JSX.Element {
  const { Goals, Segments, Targetings } = useStore();
  const [activeTargetSegments, setActiveTargetSegments] = useState<string[]>([]);
  const [activeGoalSegments, setActiveGoalSegments] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    // Active groups are groups that are actively being used as a goal or a target
    const getActiveSegments = async (segmentIds: string[]): Promise<void> => {
      const targetings = Targetings.list({
        filters: {
          _target_data__in: segmentIds,
          is_archived__ne: true,
        },
      });
      const goals = Goals.list({
        filters: {
          segment__in: segmentIds,
          is_archived__ne: true,
        },
      });
      await when(() => !targetings.isPending && !goals.isPending);
      setIsLoading(false);
      if (targetings.models.length) {
        setActiveTargetSegments([
          ...new Set(targetings.models.map((targeting) => targeting.attributes.target_data!)),
        ]);
        // Converted array to a set to ensure values are unique
      } else {
        setActiveTargetSegments([]);
      }
      if (goals.models.length) {
        setActiveGoalSegments([...new Set(goals.models.map((goal) => goal.attributes.segment!))]);
      } else {
        setActiveGoalSegments([]);
      }
    };
    getActiveSegments(selected);
  }, [Goals, Targetings, isArchiveModalOpen, selected]);

  function onArchiveModalClose(): void {
    toggleArchiveModalOpen();
    setActiveGoalSegments([]);
    setActiveTargetSegments([]);
  }

  async function confirmArchive(): Promise<void> {
    try {
      await Segments.bulk(selected, { is_archived: true });
      toast('Selected groups archived', { type: 'success' });
      Segments.refreshApiCache();
      setSelected([]);
      onArchiveModalClose();
    } catch (e) {
      toast('There was a problem archiving the selected groups.', { type: 'error' });
    }
  }

  return (
    <ModalV1
      confirmButtonText={t('Archive groups')}
      confirmDisabled={activeGoalSegments.length || activeTargetSegments.length ? true : false}
      controlled={true}
      onClose={onArchiveModalClose}
      onConfirm={confirmArchive}
      t={t}
      title={t('Archive Selected Groups')}
    >
      {isLoading ? (
        <Spinner />
      ) : !activeGoalSegments.length && !activeTargetSegments.length ? (
        <>
          <Alert type={AlertType.warning}>
            {t('Archive the following group?', { count: selected.length })}
          </Alert>
          <ol>
            {selected.slice(0, 20).map((segmentId) => {
              const segmentToDelete = Segments.get(segmentId);
              return <li key={segmentId}>{segmentToDelete.name}</li>;
            })}
          </ol>
          {selected.length > 20 && (
            <span>{t('...and {{count}} other group.', { count: selected.length - 20 })}</span>
          )}
        </>
      ) : (
        <>
          {!!activeGoalSegments.length && (
            <>
              <Alert type={AlertType.danger}>
                {t(
                  'The following group is currently being used as a goal and cannot be archived.',
                  { count: activeGoalSegments.length },
                )}
              </Alert>
              <ol>
                {activeGoalSegments.slice(0, 24).map((segmentId) => {
                  const segmentToDelete = Segments.get(segmentId);
                  return <li key={segmentId}>{segmentToDelete.name}</li>;
                })}
              </ol>
            </>
          )}
          {!!activeTargetSegments.length && (
            <>
              <Alert type={AlertType.danger}>
                {t(
                  'The following group is currently being used as a target and cannot be archived.',
                  { count: activeTargetSegments.length },
                )}
              </Alert>
              <ol>
                {activeTargetSegments.slice(0, 24).map((segmentId) => {
                  const segmentToDelete = Segments.get(segmentId);
                  return <li key={segmentId}>{segmentToDelete.name}</li>;
                })}
              </ol>
            </>
          )}
        </>
      )}
    </ModalV1>
  );
}

export default SegmentBulkArchiveModal;
