import { observer } from 'mobx-react-lite';
import React, { Fragment, type JSX } from 'react';
import { useTranslation } from 'react-i18next';

import type { IParticipation } from '@feathr/blackbox';
import { FormSummaryItem, Skeleton } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import type { ISummaryProps } from './CampaignSummary.types';

const ParticipationSummary = observer(
  ({ campaign, validationErrors }: ISummaryProps): JSX.Element => {
    const { t } = useTranslation();
    const { Partners } = useStore();

    const participation: IParticipation['participation'] = campaign.get('participation', {
      mode: 'campaign',
      partner_ids: [],
    });

    const partners = Partners.list({
      filters:
        participation.mode === 'event'
          ? { participation: campaign.id }
          : { id__in: participation.partner_ids.slice(0, 10) },
      pagination: {
        page_size: 10,
      },
    });

    const count =
      participation.mode === 'manual'
        ? participation.partner_ids.length
        : partners.pagination.count;

    return (
      <FormSummaryItem
        errors={validationErrors?.participation}
        label={t('Partners')}
        value={
          <>
            {participation.mode === 'event' ? (
              <>{t('All partners in this project')}</>
            ) : (
              <>
                {partners.models
                  // Cannot sort observable in place, so make a copy using slice().
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((partner, i) => (
                    <Fragment key={partner.id}>
                      {partner.isPending ? (
                        // TODO: Build random width option into Skeleton component
                        <Skeleton width={100 + Math.random() * 60} />
                      ) : (
                        `${partner.name} <${partner.get('email')}>`
                      )}
                      {i < partners.models.length - 1 && <br />}
                    </Fragment>
                  ))}
                {count > 10 ? t(' and {{count}} more.', { count: count - 10 }) : ''}
              </>
            )}
          </>
        }
      />
    );
  },
);

ParticipationSummary.displayName = 'ParticipationSummary';

export default ParticipationSummary;
