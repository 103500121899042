import classNames from 'classnames';
import type { TFunction } from 'i18next';
import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  Campaign,
  Segments as SegmentsCollection,
  Targetable,
  Targetables as TargetablesCollection,
  Targeting,
} from '@feathr/blackbox';
import { CampaignClass, CampaignState, FacebookCampaign, Segment } from '@feathr/blackbox';
import { Alert, AlertType, Button, ButtonValid, Form } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import AffinityTargeting from './AffinityTargeting';
import EmailListTargeting from './EmailListTargeting';
import GeoFenceTargeting from './GeoFenceTargeting';
import LookalikeTargeting from './LookalikeTargeting';
import SearchKeywordTargeting from './SearchKeywordTargeting';
import SeedSegmentTargeting from './SeedSegmentTargeting';
import SegmentTargeting from './SegmentTargeting';

import * as styles from './CampaignEdit.css';

interface IProps {
  onNext: () => void;
  onPrev: () => void;
  campaign: Campaign;
  targetings: IObservableArray<Targeting>;
}

interface ITargetingsErrors extends TValidateGrouped {
  all?: string[];
  kind?: string[];
  target_data?: string[];
}
interface ITargetablesErrors extends TValidateGrouped {
  all?: string[];
  radius?: string[];
  units?: string[];
}

interface IErrors extends TValidateGrouped {
  targetings: ITargetingsErrors[];
  targetables: ITargetablesErrors[];
}

export function validateStepTwo(
  campaign: Campaign,
  targetings: IObservableArray<Targeting>,
  targetables?: Array<Segment | Targetable>,
): IErrors {
  const errorMessages = {
    targetings: [],
    targetables: [{ units: [], radius: [] }],
  } as IErrors;
  const validatedTargetings = targetings.filter(
    (t) => !t.get('is_archived') && t.get('kind') !== 'geo',
  );
  const includedTargetings = validatedTargetings.filter((t) => t.get('included'));
  const isSegmentCampaign = [CampaignClass.Segment, CampaignClass.Facebook].includes(
    campaign.get('_cls'),
  );
  if (validatedTargetings.length === 0) {
    errorMessages.targetings.push({ all: ['Please add at least one Target.'] });
  }
  if (includedTargetings.length === 0) {
    errorMessages.targetings.push({
      all: ['At least one Target must be "included" rather than "excluded".'],
    });
  }

  validatedTargetings.forEach((t) => {
    errorMessages.targetings.push(
      t.validate<ITargetingsErrors>(['target_data', 'kind'], false, 'grouped').errors,
    );
  });
  if (campaign instanceof FacebookCampaign) {
    if (
      targetables?.length &&
      targetables
        .flatMap((trgt) => (trgt instanceof Segment ? trgt.get('predicates') : []))
        .some((pred) => !!pred && pred.attr_against !== 'loc_url')
    ) {
      errorMessages.targetables.push({
        all: ['Meta campaign targets can only use the "URL" filter.'],
      });
    }
  }
  if (!isSegmentCampaign && targetables && validatedTargetings.length !== targetables.length) {
    errorMessages.targetables.push({
      all: ['One or more targets are incomplete.'],
    });
  }
  if (targetables) {
    targetables.forEach((t: Segment | Targetable) => {
      if (t.isErrored) {
        errorMessages.targetables.push({
          all: ['Target is invalid.'],
        });
      } else {
        errorMessages.targetables.push(t.validate<ITargetablesErrors>([], false, 'grouped').errors);
      }
    });
  }
  return errorMessages;
}

export function getTargetSegments(
  targetings: IObservableArray<Targeting>,
  Segments: SegmentsCollection,
): Segment[] {
  return targetings
    .filter((t) => t.get('kind') === 'segment' && !!t.get('target_data') && !t.get('is_archived'))
    .map((t) => Segments.get(t.get('target_data')!));
}

export function getTargetables(
  targetings: IObservableArray<Targeting>,
  Targetables: TargetablesCollection,
): Targetable[] {
  return targetings
    .filter(
      (t) =>
        ['email_list', 'geo_audience', 'geofence', 'lookalike', 'search'].includes(t.get('kind')) &&
        !!t.get('target_data') &&
        !t.get('is_archived'),
    )
    .map((t) => Targetables.get(t.get('target_data')!))
    .filter((t) => t.isDirty || t.isEphemeral || !t.isPending || t.isPending);
}

function targetingDescriptions(
  t: TFunction,
  campaignClass: string,
): Partial<Record<CampaignClass, string | React.ReactNode>> {
  const descriptions = {
    [CampaignClass.Segment]: (
      <p>
        {t(
          'Retargeting Campaign Targets are derived from a new or existing group. Each Target can be configured to include or exclude the people in the group. Feathr will bid to show ads to people in an included Target unless they are also in an excluded Target.',
        )}
      </p>
    ),
    [CampaignClass.Facebook]: (
      <p>
        {t(
          'Retargeting Campaign Targets are derived from a new or existing group. Each Target can be configured to include or exclude the people in the group. Meta will bid to show ads to people in an included Target unless they are also in an excluded Target.',
        )}
        <br />
        <strong>{t('Note: Meta campaigns currently only support targeting by URL.')}</strong>
      </p>
    ),
    [CampaignClass.Lookalike]: (
      <>
        <p>
          {t(
            'Lookalike Campaign Targets are either made from third-party data automatically derived from one of your groups, or categorized third-party data you select.',
          )}
        </p>
        <p>
          {t(
            'In the case of an automatic target, we use the online behavior of people in your selected seed group to find sets of third-party data that represent people that are statisically similar to the people in that group.',
          )}
        </p>
        <p>
          {t(
            'For custom lookalike targets, you select a third-party data object from our comprehensive library of third-party data categorized by industry, demographic, and market.',
          )}
        </p>
      </>
    ),
    [CampaignClass.Affinity]: (
      <p>
        {t(
          'Simply select an audience that you want to target from our comprehensive library of affinity audiences that are categorized by industry, demographic, and market.',
        )}
      </p>
    ),
    [CampaignClass.SeedSegment]: (
      <p>
        {t(
          'We will analyze the behavioral and characteristics of the group you select to find and choose the most appropriate audience to target with this campaign.',
        )}
      </p>
    ),
    [CampaignClass.EmailList]: (
      <p>
        {t(
          'Email List Campaign Targets are made from third-party data derived from an uploaded list of email addresses. We map the email addresses to cookies through a third-party data vendor. This process can take a few days, so it is best to publish campaigns of this type well in advance to ensure it is ready to run on the first day of its duration.',
        )}
      </p>
    ),
    // Recommending a minimum of 10,000 as 2,000 may be too small for specific targeted audiences
    [CampaignClass.EmailListFacebook]: (
      <p>
        {t(
          'Email list uploads are sent to Facebook and matched to users. Facebook will usually match between 60-70% of the contacts on your list. Feathr requires a minimum of 2,500 contacts and recommends 10,000 to have the most effective reach.',
        )}
      </p>
    ),
    [CampaignClass.Search]: (
      <p>
        {t(
          'Search Keyword Campaign Targets are made from third-party data derived from an uploaded list of search keywords. We map the keywords to sets of cookies through a third-party data vendor. This process can take a few days, so it is best to publish campaigns of this type well in advance to ensure it is ready to run on the first day of its duration.',
        )}
      </p>
    ),
    [CampaignClass.MobileGeoFencing]: (
      <>
        <span>
          {t(
            'Geofence Campaign Targets are made from mobile device location data. Each Target is made up of a collection of places defined by longitude and latitude coordinates.',
          )}
        </span>
        <br />
        <span>
          {t(
            'Feathr will bid to show ads to people who are near to the any of the chosen places during the duration of the campaign.',
          )}
        </span>
        <br />
        <span>
          {t(
            'For targeting specific events, Feathr recommends including not just the venue but also nearby hotels and transportation hubs such as airports and train stations that will service attendees of the event. This is to ensure that the campaign has appropriate reach to get good results.',
          )}
        </span>
        <br />
        <span>
          {t('You may enter the name of the place, or coordinates in the search input. e.g.')}{' '}
          <em>134, -126</em>
        </span>
        <Alert className={styles.alert} type={AlertType.warning}>
          {t('Geofence locations within the EU are no longer available due to GDPR.')}
          <br />
          <a
            href={
              'https://help.feathr.co/hc/en-us/articles/360039271953-Data-Protection-Compliance-with-Feathr'
            }
            target={'_blank'}
          >
            {t('Learn more about GDPR Compliance')}
          </a>
          .
        </Alert>
      </>
    ),
    [CampaignClass.MobileGeoFenceRetargeting]: (
      <>
        <span>
          {t(
            'Geofence Campaign Targets are made from mobile device location data. Each Target is made up of a collection of places defined by longitude and latitude coordinates.',
          )}
        </span>
        <br />
        <span>
          {t(
            'While the campaign is running, Feathr will bid to show ads to people who were near to any of the chosen places during the time span associated with each set of places.',
          )}
        </span>
        <br />
        <span>
          {t(
            'For targeting specific events, Feathr recommends including not just the venue but also nearby hotels and transportation hubs such as airports and train stations that would have serviced attendees of the event. This is to ensure that the campaign has appropriate reach to get good results.',
          )}
        </span>
        <br />
        <span>
          {t('You may enter the name of the place, or coordinates in the search input. e.g.')}{' '}
          <em>134, -126</em>
        </span>
        <Alert className={styles.alert} type={AlertType.warning}>
          {t('Geofence locations within the EU are no longer available due to GDPR.')}
          <br />
          <a
            href={
              'https://help.feathr.co/hc/en-us/articles/360039271953-Data-Protection-Compliance-with-Feathr'
            }
            target={'_blank'}
          >
            {t('Learn more about GDPR Compliance')}
          </a>
          .
        </Alert>
      </>
    ),
  };
  return descriptions[campaignClass];
}

const targetingKinds: Partial<Record<CampaignClass, string>> = {
  [CampaignClass.Segment]: 'segment',
  [CampaignClass.Lookalike]: 'lookalike',
  [CampaignClass.SeedSegment]: 'lookalike',
  [CampaignClass.Affinity]: 'lookalike',
  [CampaignClass.EmailList]: 'email_list',
  [CampaignClass.Search]: 'search',
  [CampaignClass.MobileGeoFencing]: 'geofence',
  [CampaignClass.MobileGeoFenceRetargeting]: 'geo_audience',
  [CampaignClass.Facebook]: 'segment',
  [CampaignClass.EmailListFacebook]: 'email_list',
};

const NextStepButton = observer(({ campaign, targetings, onNext }: Omit<IProps, 'onPrev'>) => {
  const { Segments, Targetables } = useStore();
  const { t } = useTranslation();
  const isSegmentCampaign = [CampaignClass.Segment, CampaignClass.Facebook].includes(
    campaign.get('_cls'),
  );
  let targetables: Segment[] | Targetable[] = [];
  if (isSegmentCampaign) {
    targetables = getTargetSegments(targetings, Segments);
  } else if (
    [
      CampaignClass.Search,
      CampaignClass.EmailList,
      CampaignClass.Lookalike,
      CampaignClass.SeedSegment,
      CampaignClass.Affinity,
      CampaignClass.MobileGeoFencing,
      CampaignClass.MobileGeoFenceRetargeting,
      CampaignClass.EmailListFacebook,
    ].includes(campaign.get('_cls'))
  ) {
    targetables = getTargetables(targetings, Targetables);
  }
  const validationErrors = validateStepTwo(campaign, targetings, targetables);
  return (
    <ButtonValid errors={flattenErrors(validationErrors)} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

function CampaignEditStepTwo({ campaign, targetings, onNext, onPrev }: IProps): JSX.Element {
  const campaignClass = campaign.get('_cls');
  const { Targetings } = useStore();
  const { t } = useTranslation();
  const isSegmentCampaign = [CampaignClass.Segment, CampaignClass.Facebook].includes(campaignClass);

  const removeTargeting = useCallback(
    (targeting: Targeting) => {
      if (targeting.isEphemeral) {
        targeting.collection!.remove(targeting.id);
        targetings.remove(targeting);
      } else {
        targeting.set({ is_archived: true });
      }
    },
    [targetings],
  );

  const helpDeskLink = (t: TFunction): JSX.Element | null => {
    let prompt = '';
    let url = '';
    if (campaign.get('_cls') === CampaignClass.Segment) {
      prompt = 'about group best practices';
      url = 'https://help.feathr.co/hc/en-us/articles/360037462293-Best-Practices-for-Segments';
    } else if (campaign.get('_cls') === CampaignClass.EmailList) {
      prompt = 'how to format an Email List Upload';
      url =
        'https://help.feathr.co/hc/en-us/articles/360039811873-How-to-Format-an-Email-List-Upload';
    } else if (campaign.get('_cls') === CampaignClass.Search) {
      prompt = 'how to generate a Search Keyword List';
      url =
        'https://help.feathr.co/hc/en-us/articles/360039088334-How-To-Generate-a-Search-Keyword-List';
    } else {
      return null;
    }

    return (
      <p>
        <a href={url} rel={'noreferrer'} target={'_blank'}>
          {t('Check out our help desk to learn {{prompt}}.', { prompt })}
        </a>
      </p>
    );
  };

  const onClick = useCallback(() => {
    const model = Targetings.create({
      parent: campaign.get('id'),
      kind: targetingKinds[campaignClass],
      included: true,
    });
    runInAction(() => {
      targetings.push(model);
    });
  }, [targetings]);

  const searchTargetings = targetings.filter((t) => {
    return t.get('kind') === 'search' && !t.get('is_archived');
  });

  // Disable "Add Target" button for these campaign states
  const isDisabledCampaignState =
    campaign.get('state') === CampaignState.Published ||
    campaign.get('state') === CampaignState.Stopped;

  // Disable "Add Target" button for these campaign classes
  const isDisabledCampaignClass =
    campaign.get('_cls') === CampaignClass.MobileGeoFencing ||
    campaign.get('_cls') === CampaignClass.MobileGeoFenceRetargeting ||
    campaign.get('_cls') === CampaignClass.EmailList ||
    campaign.get('_cls') === CampaignClass.EmailListFacebook;

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <Button
          disabled={
            (isDisabledCampaignState && isDisabledCampaignClass) ||
            (campaign.get('_cls') === CampaignClass.Search && searchTargetings.length >= 1)
          }
          key={'add'}
          name={'add_target'}
          onClick={onClick}
          type={targetings.length >= 1 ? 'secondary' : 'primary'}
        >
          {t('Add target')}
        </Button>,
        <NextStepButton campaign={campaign} key={'next'} onNext={onNext} targetings={targetings} />,
      ]}
      className={classNames({ [styles.formRoot]: isSegmentCampaign })}
      description={
        <>
          <p>
            {t(
              'Click the Add Target button below to add a Target. Each Target represents a group of people.',
            )}
          </p>
          {targetingDescriptions(t, campaignClass)}
          {helpDeskLink(t)}
        </>
      }
      label={'Edit Campaign: Targets'}
    >
      {targetings
        .filter((t) => !t.get('is_archived') && t.get('kind') !== 'geo')
        .map((targeting) => {
          if (isSegmentCampaign) {
            return (
              <SegmentTargeting
                campaign={campaign}
                key={targeting.listId}
                onRemove={removeTargeting}
                targeting={targeting}
                targetings={targetings}
              />
            );
          }
          if (campaignClass === CampaignClass.Lookalike) {
            return (
              <LookalikeTargeting
                campaign={campaign}
                key={targeting.listId}
                onRemove={removeTargeting}
                targeting={targeting}
              />
            );
          }
          if (campaignClass === CampaignClass.SeedSegment) {
            return (
              <SeedSegmentTargeting
                campaign={campaign}
                key={targeting.listId}
                onRemove={removeTargeting}
                targeting={targeting}
              />
            );
          }
          if (campaignClass === CampaignClass.Affinity) {
            return (
              <AffinityTargeting
                campaign={campaign}
                key={targeting.listId}
                onRemove={removeTargeting}
                targeting={targeting}
              />
            );
          }
          if ([CampaignClass.EmailList, CampaignClass.EmailListFacebook].includes(campaignClass)) {
            return (
              <EmailListTargeting
                campaign={campaign}
                key={targeting.listId}
                onRemove={removeTargeting}
                targeting={targeting}
              />
            );
          }
          if (campaignClass === CampaignClass.Search) {
            return (
              <SearchKeywordTargeting
                campaign={campaign}
                key={targeting.listId}
                onRemove={removeTargeting}
                targeting={targeting}
              />
            );
          }
          if (
            campaignClass === CampaignClass.MobileGeoFencing ||
            campaignClass === CampaignClass.MobileGeoFenceRetargeting
          ) {
            return (
              <GeoFenceTargeting
                campaign={campaign}
                key={targeting.listId}
                onRemove={removeTargeting}
                targeting={targeting}
              />
            );
          }
          throw new Error('Unexpected campaign class in CampaignEditStepTwo');
        })}
    </Form>
  );
}

export default observer(CampaignEditStepTwo);
