import type { JSX } from 'react';
import React from 'react';

import type { Event } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Table } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import type { IObject } from '@feathr/rachis';

interface IProps {
  columns: Array<IColumn<Event>>;
  filters?: IObject;
  isPaginated?: boolean;
}

function EventsTable({ columns, filters, isPaginated = true }: IProps): JSX.Element {
  const { Events } = useStore();
  return (
    <Table<Event>
      collection={Events}
      columns={columns}
      filters={filters}
      initialPagesize={10}
      initialSort={[{ id: 'date_created' }]}
      isPaginated={isPaginated}
      noDataText={'No Projects'}
    />
  );
}

export default EventsTable;
