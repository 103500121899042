import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { Billable } from '@feathr/blackbox';
import { Alert, AlertType, List, ListItem, ModalV1, Spinner, toast } from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';

interface IProps {
  billable: Billable;
  onClose: () => void;
}

function BillableDeleteModal({ billable, onClose }: Readonly<IProps>): JSX.Element {
  const { Events } = useStore();
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const events = Events.list({
    filters: {
      billing__billable_id: billable.id,
      is_archived__ne: true,
    },
  });

  async function handleConfirm(): Promise<void> {
    await billable.delete();
    if (billable.isErrored) {
      toast(billable.error?.message, { type: 'error' });
    } else {
      toast(t('Billing configuration deleted.'), { type: 'success' });
    }
  }

  return (
    <ModalV1
      confirmButtonText={t('Delete')}
      confirmButtonType={'danger'}
      confirmDisabled={events.isPending || events.models.length > 0}
      controlled={true}
      onClose={onClose}
      onConfirm={handleConfirm}
      size={'sm'}
      t={t}
      title={t('Delete Billing Configuration')}
    >
      {events.isPending ? (
        <Spinner />
      ) : events.models.length > 0 ? (
        <Alert type={AlertType.danger}>
          <p>
            {t(
              'You cannot delete this billing configuration because it is in use by the following projects:',
            )}
          </p>
          <List>
            {events.models.map((event) => (
              <ListItem key={event.id}>
                <Link to={`${localUrl(event.getItemUrl())}/settings/billing`}>
                  {event.get('name')}
                </Link>
              </ListItem>
            ))}
          </List>
        </Alert>
      ) : (
        <Alert type={AlertType.warning}>
          {t(
            'Are you sure you want to delete this billing configuration? This action cannot be undone.',
          )}
        </Alert>
      )}
    </ModalV1>
  );
}

export default observer(BillableDeleteModal);
