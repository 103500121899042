import { observable } from 'mobx';

import type { Attributes, TConstraints } from '@feathr/rachis';

import type { IParticipation } from '../model/participation';
import { type Template, TemplateClass } from '../templates';
import { Campaign } from './campaign';
import type { CampaignClass, ICampaignAttributes } from './types';

export interface ICampaignLink {
  link_id: string;
  url: string;
  title: string;
  icon: string;
}

export type TReferralCampaign = ICampaignAttributes &
  IParticipation & {
    readonly _cls: CampaignClass.Referral;
    banner_templates: string[];
    destination_url: string;
    email_templates: string[];
    generates_leads: boolean;
    links: ICampaignLink[];
    page_templates: string[];
    /** Templates is a convenience prop that contains all template Ids from banner_templates, email_templates, and page_templates. */
    readonly templates: string[];
    twitter_hashtags: string;
    twitter_text: string;
  };

export class ReferralCampaign extends Campaign<TReferralCampaign> {
  public override get constraints(): TConstraints<TReferralCampaign> {
    return {
      ...super.constraints,
      links: {
        array: {
          link_id: { presence: { allowEmpty: false } },
          title: { presence: { allowEmpty: false } },
          url: { presence: { allowEmpty: false }, url: true },
        },
      },
      destination_url: (...args: any[]) => {
        const attributes = args[1];
        const url = args[0];
        if (attributes && url?.startsWith('@')) {
          return {
            presence: { allowEmpty: false },
            format: {
              pattern: '^@.*@$',
              message: 'is not a valid merge pattern (must begin and end with "@").',
            },
          };
        }
        if (attributes?.banner_templates?.length) {
          return {
            url: true,
            presence: { allowEmpty: false },
          };
        } else {
          return undefined;
        }
      },
      banner_templates: {
        presence: {
          allowEmpty: true,
        },
      },
      page_templates: {
        presence: {
          allowEmpty: true,
        },
      },
    };
  }

  public async addTemplate(template: Template): Promise<this> {
    if (!template.id) {
      throw new Error('Template is missing an id.');
    }

    const type = template.get('_cls');
    if (type === TemplateClass.ReferralBanner) {
      const bTemplateIds = this.get('banner_templates', observable([]));
      this.set({ banner_templates: [...bTemplateIds, template.id] } as Partial<Attributes<this>>);
    } else if (type === TemplateClass.ReferralEmail) {
      const eTemplateIds = this.get('email_templates', observable([]));
      this.set({ email_templates: [...eTemplateIds, template.id] } as Partial<Attributes<this>>);
    } else if (type === TemplateClass.ReferralPage) {
      const pTemplateIds = this.get('page_templates', observable([]));
      this.set({ page_templates: [...pTemplateIds, template.id] } as Partial<Attributes<this>>);
    }
    return this.patchDirty();
  }

  public removeTemplate(templateId: string): void {
    const bTemplateIds: string[] = this.get('banner_templates', observable([]));
    if (bTemplateIds.includes(templateId)) {
      this.set({ banner_templates: bTemplateIds.filter((t) => t !== templateId) } as Partial<
        Attributes<this>
      >);
      this.save();
      return;
    }

    const eTemplateIds: string[] = this.get('email_templates', observable([]));
    if (eTemplateIds.includes(templateId)) {
      this.set({ email_templates: eTemplateIds.filter((t) => t !== templateId) } as Partial<
        Attributes<this>
      >);
      return;
    }

    const pTemplateIds: string[] = this.get('page_templates', observable([]));
    if (pTemplateIds.includes(templateId)) {
      this.set({ page_templates: pTemplateIds.filter((t) => t !== templateId) } as Partial<
        Attributes<this>
      >);
    }
  }
}
