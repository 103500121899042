import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { RowRenderProps } from 'react-table';

import type { Bill } from '@feathr/blackbox';
import { Table } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import { BillListColumns } from './BillListColumns';
import BillTable from './BillTable';

interface IProps {
  filters: Record<string, unknown>;
  isLoading?: boolean;
  filterElements?: JSX.Element[];
  noDataText?: string;
}

function BillTableSubComponent({ original }: RowRenderProps): JSX.Element {
  return <BillTable bill={original as Bill} />;
}

function BillListTable({
  filters,
  isLoading = false,
  filterElements = [],
  noDataText,
}: IProps): JSX.Element {
  const { Bills } = useStore();
  const { t } = useTranslation();

  return (
    <Table<Bill>
      collection={Bills}
      columns={BillListColumns}
      filterElements={filterElements}
      filters={filters}
      initialPagesize={12}
      initialSort={[{ id: '_id', desc: true }]}
      isLoading={isLoading}
      isPaginated={false}
      noDataText={noDataText ?? t('No Data')}
      SubComponent={BillTableSubComponent}
    />
  );
}

export default observer(BillListTable);
