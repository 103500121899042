import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Spinner } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useStore } from '@feathr/extender/state';

import DomainEdit from './DomainEdit';

function DomainEditPage() {
  const { t } = useTranslation();
  const { domainId } = useParams<{ domainId: string }>();
  const { Domains } = useStore();
  const domain = Domains.get(domainId);

  return (
    <Page pageTitle={t('Configure Domain')}>
      {domain.isPending ? <Spinner /> : <DomainEdit domain={domain} />}
    </Page>
  );
}

export default observer(DomainEditPage);
