import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { CardV2 as Card, Checkbox, SaveButtonValid, Spinner } from '@feathr/components';
import { useActionBar, useStore } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import * as styles from './Subscriptions.css';

interface IProps {
  readonly person: Person;
}

function validatePersonSubscriptions(person: Person): TValidateGrouped {
  return person.validate(['email'], false, 'grouped').errors;
}

function Subscriptions({ person }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { Events: Projects } = useStore();
  const { setRightActions } = useActionBar();
  const subscriptions = person.get('pp_opt_outs', { all: false, events: [] });

  const validationErrors = validatePersonSubscriptions(person);

  const projects = Projects.list({
    filters: {
      is_archived__ne: true,
    },
    only: ['id', 'name'],
    ordering: ['name'],
    pagination: {
      page_size: 1000,
    },
  });

  useEffect(() => {
    setRightActions(
      <SaveButtonValid
        disabled={!person.isDirty}
        errors={flattenErrors(validationErrors)}
        method={'patch'}
        model={person}
      >
        {t('Save')}
      </SaveButtonValid>,
    );
  }, [person.isDirty]);

  function handleChangeAll(isOptedOut?: boolean): void {
    // True means we are opted out, false we are opted in.
    person.set({
      pp_opt_outs: { ...subscriptions, all: Boolean(isOptedOut) },
    });
  }

  function handleChangeEvent(eventId: string): (newValue?: boolean) => void {
    return function (newValue?: boolean) {
      let newEvents: string[] = toJS(subscriptions.events) ?? [];
      if (!newValue) {
        // Adding event to array makes it opt-out.
        if (!newEvents.includes(eventId)) {
          newEvents.push(eventId);
        }
      } else {
        // Removing event from array makes it opt-in.
        newEvents = newEvents.filter((id) => id !== eventId);
      }

      person.set({ pp_opt_outs: { ...subscriptions, events: newEvents } });
    };
  }

  return (
    <div className={styles.root}>
      <Card>
        <Card.Header title={t('Subscriptions')} />
        <Card.Content addVerticalGap={true}>
          <Checkbox
            className={styles.checkbox}
            label={t('Unsubscribe from all')}
            layout={'well'}
            onChange={handleChangeAll}
            value={subscriptions.all}
          />

          <ul className={styles.list}>
            {projects.isPending ? (
              <Spinner />
            ) : (
              projects.models.map(({ id, name }) => (
                <li key={id}>
                  <Checkbox
                    className={styles.checkbox}
                    // When subscriptions.all is true, disable interface for subscription events.
                    disabled={subscriptions.all}
                    label={name}
                    onChange={handleChangeEvent(id)}
                    value={subscriptions.events?.includes(id) ? false : true}
                  />
                </li>
              ))
            )}
          </ul>
        </Card.Content>
      </Card>
    </div>
  );
}

export default observer(Subscriptions);
