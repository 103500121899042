import debounce from 'debounce-promise';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  CustomField,
  ICustomData,
  ICustomField,
  TPersonCustomDataValue,
} from '@feathr/blackbox';
import type { IRadioOption, ISelectOption } from '@feathr/components';
import {
  AsyncCreatableSelectElement,
  DatePicker,
  Input,
  NumberInput as NumInput,
  Radios,
} from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';

import * as styles from './FieldInput.css';

type THandler = (key: ICustomField['u_key'], newValue?: TPersonCustomDataValue) => void;
interface IProps {
  readonly customData: ICustomData;
  readonly field: CustomField;
  readonly onChange: THandler;
  readonly value?: TPersonCustomDataValue;
}

interface ISharedProps<T extends TPersonCustomDataValue> extends IProps {
  readonly value?: T;
}

function FieldInput(props: ISharedProps<string | number | boolean | string[]>): JSX.Element {
  const { customData, field, onChange, value } = props;
  const { CustomFields } = useStore();
  const key = field.get('u_key');
  const type = field.get('data_type');
  const readOnly = field.get('is_read_only');
  const { t } = useTranslation();

  // Handlers are defined specifically for each type of input
  function handleTextChange(newValue?: string): void {
    onChange(key, newValue);
  }

  function handleNumberChange(newValue?: number): void {
    onChange(key, newValue === undefined ? 0 : newValue);
  }

  const booleanOptions: IRadioOption[] = [
    { id: 'true', name: t('Yes') },
    { id: 'false', name: t('No') },
  ];

  function handleBooleanChange(newValue?: string): void {
    // This should never happen.
    if (!newValue) {
      return;
    }

    onChange(key, newValue === 'true' ? true : false);
  }

  function handleDateChange(newValue?: string): void {
    onChange(key, newValue);
  }

  function handleListChange(options: ISelectOption[]): void {
    onChange(
      key,
      options.map(({ id }) => id),
    );
  }

  function getDefaultListOptions(): ISelectOption[] {
    const listValues = Object.values(customData)
      .filter((value) => value instanceof Array && value.length > 0)
      .map((value) => value) as string[][];
    return listValues.length > 0
      ? listValues
          .reduce((acc, curr) => {
            const values = [...curr].filter((v) => !acc.includes(v));
            return acc.concat(values);
          }, [])
          .map((str) => ({ name: str, id: str }))
      : [];
  }

  const getListOptions = useRef<(inputValue: string) => Promise<ISelectOption[]>>(
    debounce(async (query?: string) => {
      const options = await CustomFields.getListOptions(key, query);
      return options
        .filter(({ value }) => !!value)
        .map(({ value }) => ({ name: value, id: value }));
    }, DEFAULT_DEBOUNCE_WAIT),
  ).current;

  function formatCreateLabel(inputValue: string): string {
    return t('Create new field: {{fieldname}}', { fieldname: inputValue });
  }

  function createOption(inputValue: string): ISelectOption {
    return { name: inputValue, id: inputValue };
  }

  function getNewOptionData(inputValue: string): ISelectOption {
    return { name: inputValue, id: inputValue };
  }

  // Rendering components based on the data type
  switch (type) {
    case 'str':
      return (
        <Input
          isClearable={true}
          key={'str'}
          label={key}
          onChange={handleTextChange}
          readOnly={readOnly}
          type={'text'}
          value={value as string}
        />
      );

    case 'int':

    case 'float':
      return (
        <NumInput
          className={styles.number}
          isClearable={true}
          key={'number'}
          label={key}
          onChange={handleNumberChange}
          readOnly={readOnly}
          value={value as number}
        />
      );

    case 'bool':
      return (
        <Radios
          disabled={readOnly}
          label={key}
          layout={'block'}
          onChange={handleBooleanChange}
          options={booleanOptions}
          readOnly={readOnly}
          value={value === undefined ? undefined : String(value)}
        />
      );

    /*
     * TODO: Date picker should be clearable but there is a bug in the backend
     * that prevents this from working. https://github.com/Feathr/anhinga/issues/3204
     */
    case 'date':
      return (
        <DatePicker
          key={'date'}
          label={key}
          onDateStrChange={handleDateChange}
          readOnly={readOnly}
          selected={value === undefined ? undefined : new Date(value as string)}
          showCalendarIcon={true}
        />
      );

    case 'list':
      return (
        <AsyncCreatableSelectElement
          createOption={createOption}
          defaultOptions={getDefaultListOptions()}
          defaultValue={getDefaultListOptions().filter(({ id }) =>
            (value instanceof Array ? value : []).includes(id),
          )}
          formatCreateLabel={formatCreateLabel}
          getNewOptionData={getNewOptionData}
          isMulti={true}
          key={'list'}
          label={field?.get('u_key')}
          loadOptions={getListOptions}
          name={'field-input-list'}
          onChange={handleListChange}
          readOnly={readOnly}
          value={(value instanceof Array ? value : []).map((val) => ({ name: val, id: val }))}
        />
      );

    default:
      return <div>{t('Unsupported data type. Please contact support.')}</div>;
  }
}

export default observer(FieldInput);
