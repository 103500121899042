import { Grid } from '@mantine/core';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { CampaignClass } from '@feathr/blackbox';

import CampaignClassCard from '../CampaignClassCard';
import type { IType, TCampaignSection } from '../CampaignTypeSelect';
import { campaignSectionDescription, campaignSectionTitle } from '../CampaignTypeSelect';

import * as styles from './CampaignTypeSelectSection.css';

export interface IProps {
  disabled?: boolean;
  onSelect: (type: CampaignClass) => void;
  section?: TCampaignSection;
  types: IType[];
}

function CampaignTypeSelectSection({
  disabled,
  onSelect,
  section,
  types,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const monetizationSection = section ?? 'monetization';

  return (
    <section className={styles.root}>
      {section && (
        <>
          <h3 className={styles.title}>{campaignSectionTitle(section, t)}</h3>
          <p className={styles.description}>{campaignSectionDescription(section, t)}</p>
        </>
      )}
      <Grid data-name={`${monetizationSection}-group`}>
        {types.map((type) => (
          <Grid.Col
            data-name={`${monetizationSection}-campaign`}
            key={type.id}
            span={{ xs: 6, sm: 6, md: 4 }}
          >
            <CampaignClassCard cls={type.id} disabled={disabled} onClick={onSelect} />
          </Grid.Col>
        ))}
      </Grid>
    </section>
  );
}

export default CampaignTypeSelectSection;
