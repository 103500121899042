import type { ITTDCampaignAttributes } from './ttd_base';
import { TTDCampaign } from './ttd_base';
import type { CampaignClass } from './types';

// Mobile Geofencing
export type TMobileGeoFenceRetargetingCampaign = ITTDCampaignAttributes & {
  readonly _cls: CampaignClass.MobileGeoFenceRetargeting;
  address: string;
  address_name: string;
};

export class MobileGeoFenceRetargetingCampaign extends TTDCampaign<TMobileGeoFenceRetargetingCampaign> {}
