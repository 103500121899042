import { faBracketsCurly, faEdit, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { WithT } from 'i18next';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { PinpointPartnerMessage } from '@feathr/blackbox';
import { ArchiveModalV1, Button, TableColumnHeader, Tooltip } from '@feathr/components';
import type { IConfirmButtonProps } from '@feathr/extender/components/ConfirmButton';
import ConfirmButton from '@feathr/extender/components/ConfirmButton';
import type { ITemplateRow } from '@feathr/extender/components/TemplatesTable/TemplatesTable';
import { useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './templateColumnMessageOptions.css';

function getHashStep() {
  const matchedHash = /\#step(\d{1})$/.exec(location.hash);
  return matchedHash ? +matchedHash[1] : undefined;
}

interface IDisableOptions extends WithT {
  editMetadata?: boolean;
  editTemplate?: boolean;
  removeTemplate?: boolean;
}

function templateColumnMessageOptions({
  t,
  editMetadata = true,
  editTemplate = true,
  removeTemplate = true,
}: IDisableOptions) {
  return {
    id: 'options',
    Header: TableColumnHeader({
      title: t('Options'),
    }),
    width: 150,
    sortable: false,
    className: tableStyles.cellCenter,
    Cell({ original }: ITemplateRow) {
      return (
        <Observer>
          {function useAnonymousFunction() {
            const localUrl = useLocalUrl();
            const { message, template } = original;
            const { t } = useTranslation();
            const [showModal, toggleShowModal] = useToggle(false);

            async function handleConfirm() {
              message!.removeTemplate(template.id);
              toggleShowModal();
            }

            const confirmButtonProps = {
              buttonClassName: styles.optionsButton,
              disabled: message!.isPending || template.isUpdating,
              buttonType: 'naked' as IConfirmButtonProps['buttonType'],
              element: 'Button' as IConfirmButtonProps['element'],
              model: message as PinpointPartnerMessage,
              step: getHashStep(),
            };

            return (
              <>
                <Tooltip title={t('Edit metadata')}>
                  <ConfirmButton
                    {...confirmButtonProps}
                    data-name={'edit_metadata_option'}
                    description={t(
                      'Before you can edit this metadata, the changes to your campaign need to be saved.',
                    )}
                    disabled={editMetadata || template.isReadOnly}
                    prefix={<FontAwesomeIcon icon={faBracketsCurly} />}
                    redirect={localUrl(template.getItemUrl('edit/metadata'))}
                    title={t('Edit metadata')}
                  />
                </Tooltip>
                <Tooltip title={t('Edit template')}>
                  <ConfirmButton
                    {...confirmButtonProps}
                    data-name={'edit_template_option'}
                    description={t(
                      'Before you can edit this template, the changes to your campaign need to be saved.',
                    )}
                    disabled={editTemplate || template.isReadOnly}
                    prefix={<FontAwesomeIcon icon={faEdit} />}
                    redirect={localUrl(template.getItemUrl('edit/design'))}
                    title={t('Edit Template')}
                  />
                </Tooltip>
                <Tooltip title={t('Remove template')}>
                  <Button
                    className={styles.optionsButton}
                    data-name={'remove_template_option'}
                    disabled={removeTemplate || message!.isPending}
                    onClick={toggleShowModal}
                    prefix={<FontAwesomeIcon icon={faTrash} />}
                    type={'naked'}
                  />
                  {showModal && (
                    <ArchiveModalV1
                      message={t('Are you sure you want to archive this?')}
                      model={template}
                      onClose={toggleShowModal}
                      onConfirm={handleConfirm}
                      successMessage={t('Archived {{name}}', { name: template.name })}
                      t={t}
                    />
                  )}
                </Tooltip>
              </>
            );
          }}
        </Observer>
      );
    },
  };
}

export default templateColumnMessageOptions;
