import { faCheck, faShareNodes } from '@fortawesome/pro-regular-svg-icons';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router';

import { EFormState } from '@feathr/blackbox';
import { Button, Icon, Label, SaveButton, Tab, Time } from '@feathr/components';
import { useActionBar, useStore } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import Page from '../../Page';
import { FormEditor } from './FormEditor';
import Settings from './Settings';

import * as styles from './FormEditPage.css';

function FormEditPage(): JSX.Element {
  const { formId } = useParams<{ formId?: string }>();
  const { Forms } = useStore();

  if (!formId) {
    throw new Error('Form missing ID');
  }

  const { t } = useTranslation();
  const form = Forms.get(formId);
  const lastSaved = form.get('date_last_modified');
  const { setLeftActions, setRightActions } = useActionBar();
  const [activeTab, setActiveTab] = useState<'edit' | 'settings'>('edit');

  useEffect(() => {
    // Reset changed model if they weren't saved so
    // the user doesn't see the changes if they navigate away
    // and back to this page.
    return () => {
      if (form.isDirty) {
        form.discardDirty();
      }
    };
  }, [form]);

  useEffect(() => {
    setLeftActions(
      <span className={styles.leftActions}>
        <Label weight={'semibold'}>{t('Last saved:')} </Label>
        <Time
          className={styles.lastSaved}
          format={TimeFormat.longDateTime}
          formatLocal={true}
          showTimezone={true}
          timestamp={lastSaved}
        />
      </span>,
    );

    const rightActions = [
      <Button key={'publish'} prefix={<Icon icon={faCheck} />} type={'success'}>
        {t('Publish')}
      </Button>,
    ];

    if (form.get('state') === EFormState.Draft) {
      rightActions.unshift(<SaveButton key={'save'} method={'patch'} model={form} />);
    }

    setRightActions(rightActions);
  }, [setLeftActions, setRightActions, t, form.isPending]);

  function handleClickEditTab(): void {
    setActiveTab('edit');
  }

  function handleClickSettingsTab(): void {
    setActiveTab('settings');
  }

  const tabs = [
    <Tab
      active={activeTab === 'edit'}
      key={'edit'}
      onClick={handleClickEditTab}
      title={t('Edit')}
    />,
    <Tab
      active={activeTab === 'settings'}
      key={'settings'}
      onClick={handleClickSettingsTab}
      title={t('Settings')}
    />,
  ];

  const actions = [
    <Button key={'share'} prefix={<Icon icon={faShareNodes} />} type={'secondary'}>
      {t('Share')}
    </Button>,
  ];

  return (
    <Page
      actions={actions}
      legacyScrollClassName={styles.legacyScroll}
      tabs={tabs}
      title={t('Edit {{- name}}', { name: form.name })}
    >
      <Prompt
        message={t('You have unsaved changes. Are you sure you want to leave this page?')}
        when={form.isDirty}
      />
      {activeTab === 'edit' && <FormEditor form={form} />}
      {activeTab === 'settings' && <Settings form={form} />}
    </Page>
  );
}

export default observer(FormEditPage);
