import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Importer } from '@feathr/blackbox';
import { Table, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useStore } from '@feathr/extender/state';

import AddImporterButton from './AddImporterButton';
import dataImportsColumns from './dataImportsColumns';

function DataImportsPage(): JSX.Element {
  const { Importers } = useStore();
  const { t } = useTranslation();

  const importers = Importers.list({
    filters: {
      kind: 'person',
    },
  });

  const actions = (
    <Toolbar>
      <AddImporterButton />
    </Toolbar>
  );

  return (
    <Page
      actions={actions}
      description={t(
        'Start new contact data imports or manage existing ones. This is also where you can import lists of unsubscribed contacts.',
      )}
      title={t('Imports')}
    >
      <Table<Importer>
        collection={Importers}
        columns={dataImportsColumns([], t)}
        initialSort={[{ id: 'id', desc: true }]}
        isLoading={importers.isPending}
        noDataText={'No imports found.'}
      />
    </Page>
  );
}

export default observer(DataImportsPage);
