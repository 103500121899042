import { faPlus, faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Event, IStats } from '@feathr/blackbox';
import { EUsageMetrics } from '@feathr/blackbox';
import type { IGridProps, ISorted } from '@feathr/components';
import {
  Alert,
  AlertType,
  Button,
  DebouncedInput,
  Grid,
  Input,
  Superpixel,
  Toolbar,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import FilterTags from '@feathr/extender/components/FilterTags';
import {
  useAccount,
  useLocalUrl,
  useRole,
  useStore,
  useUsageAndQuota,
  useUser,
} from '@feathr/extender/state';
import { useId } from '@feathr/hooks';
import { useInfiniteList } from '@feathr/rachis';

import EventCard from './EventCard';
import EventsPageSort from './EventsPageSort';

import * as styles from './EventsPage.css';

interface IFilters {
  name__icontains?: string;
  tag_ids__all: string[];
}

function EventsPage(): JSX.Element {
  const { Events } = useStore();
  const listId = useId();
  const [, clearInfiniteList] = useInfiniteList(Events, listId);
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const user = useUser();
  const { hasProjects } = useRole();
  const account = useAccount();
  const [filters, setFilters] = useState<IFilters>({ name__icontains: '', tag_ids__all: [] });
  const [sort, setSort] = useState<ISorted>({ id: 'date_created', desc: true });

  const { getUsageText } = useUsageAndQuota(account.id, [EUsageMetrics.Projects]);
  const stats = account.get('stats', { num_crumbs: 0 } as IStats);
  const showSuperpixel = !account.isPending && stats.num_crumbs! < 1;
  const addNewProject = localUrl('/projects/add');

  function updateFilters(newFilters: IFilters): void {
    setFilters(newFilters);
    clearInfiniteList();
  }

  function updateSort(newSort: ISorted): void {
    setSort(newSort);
    clearInfiniteList();
  }

  function handleChangeTags(values: string[]): void {
    updateFilters({ ...filters, tag_ids__all: values });
  }

  function handleChangeName(newValue?: string): void {
    updateFilters({
      ...filters,
      name__icontains: newValue || undefined,
    });
  }

  const toolbar: IGridProps<Event>['toolbar'] = [
    <>
      <FilterTags
        context={'event'}
        onChange={handleChangeTags}
        value={filters.tag_ids__all}
        wrapperClassName={styles.filter}
      />
    </>,
    <>
      <DebouncedInput<string> defaultValue={filters.name__icontains} onChange={handleChangeName}>
        {(liveValue, onChangeLiveValue): JSX.Element => (
          <Input
            // Because there is no visible we add a label for screen readers.
            aria-label={'Search by name'}
            className={styles.filterName}
            isClearable={true}
            name={'project_search'}
            onChange={onChangeLiveValue}
            placeholder={t('Search by name...')}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
      <EventsPageSort onChange={updateSort} value={sort} />
    </>,
  ];

  // Users that have sudo and Enterprise Unlimited Accounts can add projects. Enterprise accounts cannot add projects.
  const canAddProject = account.canAddProject(user);

  const newProjectButton: JSX.Element = (
    <>
      <Button link={addNewProject} prefix={<FontAwesomeIcon icon={faPlus} />} type={'primary'}>
        {t('New project')}
      </Button>
    </>
  );

  const clearFiltersButton: JSX.Element = (
    <>
      <Button onClick={handleClearTags} prefix={<FontAwesomeIcon icon={faTimes} />}>
        {t('Clear filters')}
      </Button>
    </>
  );

  const projectSearch = filters.name__icontains ? filters.name__icontains.length > 0 : false;
  const projectTags = filters.tag_ids__all?.length > 0;

  function handleClearTags(): void {
    if (projectTags) {
      updateFilters({ ...filters, tag_ids__all: [] });
    }
  }

  function projectSearchNoResults(): JSX.Element {
    switch (true) {
      case projectSearch:
        return (
          <>
            <p>
              {t('No projects match the name "{{- name}}".', {
                name: filters.name__icontains,
              })}
            </p>
            {newProjectButton}
          </>
        );

      case projectTags:
        return (
          <>
            <p>{t('No projects use these tags.')}</p>
            {clearFiltersButton}
          </>
        );

      default:
        return (
          <>
            <p>{t('Add a new project to get started.')}</p>
            {newProjectButton}
          </>
        );
    }
  }

  const noDataElement: JSX.Element = (
    <div className={styles.noProjects}>
      <h4>{t('No projects found')}</h4>
      {projectSearchNoResults()}
    </div>
  );

  return (
    <Page
      actions={
        !account.isPending && canAddProject && hasProjects && <Toolbar>{newProjectButton}</Toolbar>
      }
      className={styles.content}
      secondaryText={getUsageText()}
      title={showSuperpixel ? t('Welcome to Feathr!') : t('Projects')}
    >
      {showSuperpixel && (
        <>
          <Alert type={AlertType.info}>
            {t('Set up your Super Pixel! We cannot track your site traffic without it.')}
          </Alert>
          <Superpixel
            className={styles.superpixel}
            crumbs={stats.num_crumbs ?? 0}
            hasServices={account.hasServices}
            id={account.id}
            loading={account.isPending}
          />
        </>
      )}
      <Grid
        className={styles.grid}
        collection={Events}
        filters={filters}
        filtersClassName={styles.filters}
        itemType={EventCard}
        listId={listId}
        noDataText={noDataElement}
        only={['id', 'logo', 'name', 'tag_ids', 'date_start', 'date_end']}
        sort={[sort]}
        toolbar={toolbar}
      />
    </Page>
  );
}

export default observer(EventsPage);
