import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { TAttributionModel } from '@feathr/blackbox';
import type { Flight } from '@feathr/blackbox';
import { Stat } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { currencyFormatter } from '@feathr/extender/utils';

import * as styles from './FlightStats.css';

interface IProps {
  attributionModel?: TAttributionModel;
  className?: string;
  displayMode: 'condensed' | 'default';
  flight: Flight;
}

function FlightStats({ attributionModel, className, displayMode, flight }: IProps): JSX.Element {
  const { AggregatedStats, Campaigns } = useStore();

  const campaigns = Campaigns.list({
    filters: {
      flight: flight.id,
    },
  });

  const isLoading = campaigns.isPending || !attributionModel;

  const { conversions: conversionStats } = flight.get('total_stats');

  let roi = 0;
  let numConversions = 0;
  if (attributionModel && conversionStats && conversionStats[attributionModel]) {
    roi = conversionStats[attributionModel]?.roi || 0;
    numConversions = conversionStats[attributionModel]?.num || 0;
  }

  const aggregatedStats = AggregatedStats.aggregate({
    filters: {
      metadata__date__gte: flight.reportRangeStart,
      metadata__date__lte: flight.reportRangeEnd,
      metadata__obj_id: flight.id,
    },
    model: 'flight',
  });

  const budget = campaigns.models.reduce((sum, cpn) => {
    const { target_value = 0 } = cpn.get('exposure_settings');
    if (cpn.get('parent_kind') === 'partner') {
      return sum;
    }
    return target_value + sum;
  }, 0);

  const spend = aggregatedStats.get('spend', 0);

  const size = displayMode === 'default' ? 'large' : 'medium';

  return (
    <dl className={classNames(styles.root, [styles[size as keyof typeof styles]], className)}>
      {!!budget && (
        <Stat
          isLoading={isLoading}
          label={'Budget'}
          size={size}
          value={currencyFormatter.format(budget)}
        />
      )}
      <Stat
        isLoading={isLoading}
        label={'Spend'}
        size={size}
        value={currencyFormatter.format(spend)}
      />
      <Stat isLoading={isLoading} label={'Conversions'} size={size} value={numConversions} />
      <Stat
        isLoading={isLoading}
        label={'Conversion value'}
        size={size}
        value={currencyFormatter.format(roi)}
      />
    </dl>
  );
}

export default observer(FlightStats);
