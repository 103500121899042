import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useParams } from 'react-router';

import Page from '@feathr/extender/App/Page';
import { useStore } from '@feathr/extender/state';

import BillableForm, { getLabel } from '../BillableForm';

function BillableEditPage(): JSX.Element {
  const { Billables } = useStore();
  const { billingId } = useParams<{ billingId: string }>();
  const billable = Billables.get(billingId);

  // TODO: When hooking up default billable in #3363, set this to an attribute from billable
  const isDefault = false;

  return (
    <Page title={getLabel(false, isDefault)}>
      <BillableForm billable={billable} isDefault={isDefault} />
    </Page>
  );
}

export default observer(BillableEditPage);
