import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { Chip } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import * as styles from './Tags.css';

interface IProps {
  className?: string;
  value: string[];
}

function Tags({ className, value }: IProps): JSX.Element {
  const { Tags: TagsCollection } = useStore();
  return (
    <div className={classNames(styles.root, className)}>
      {value.map((tagId: string) => {
        const tag = TagsCollection.get(tagId);
        return (
          <Chip key={tagId} theme={'tag'}>
            {tag.name}
          </Chip>
        );
      })}
    </div>
  );
}

export default observer(Tags);
