import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStore } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function PartnerLabel({ className, id }: IProps) {
  const { Partners } = useStore();
  const partner = Partners.get(id);
  return (
    <div className={classNames({ [skeleton]: partner.isPending }, className)}>
      {partner.get('name', 'placeholder') as string}
    </div>
  );
}

export default observer(PartnerLabel);
