import type { TFunction } from 'i18next';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Person } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { AvatarV2 as Avatar, TableColumnHeader, Time } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';
import type { EReportFlavors } from '@feathr/report_components/state';

import PersonName from '../ConversionTableCard/PersonName';
import { flavorToInteractionMap } from './CampaignEngagementCard';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './campaignEngagementColumns.css';

interface IRow {
  original: Person;
}

interface IPersonCellProps {
  original: Person;
  localUrl: ((url: string) => string) | undefined;
}

function PersonCell({ localUrl, original }: Readonly<IPersonCellProps>): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        if (original) {
          return (
            <div className={styles.container}>
              <Avatar name={original.get('name')} placeholder={original.get('placeholder')} />
              <div className={styles.content}>
                <PersonName localUrl={localUrl} person={original.toJS()} />
                {original.get('external_id') && <span>{original.get('external_id')}</span>}
              </div>
            </div>
          );
        }

        return <div className={styles.container}>Anonymous</div>;
      }}
    </Observer>
  );
}

function campaignEngagementColumns(
  t: TFunction,
  localUrl: ((url: string) => string) | undefined,
  activeFlavor: EReportFlavors,
): Array<IColumn<Person>> {
  return [
    {
      id: 'name',
      className: tableStyles.cell,
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Name'),
      }),
      headerClassName: tableStyles.sort,
      sortable: true,
      Cell({ original }: IRow): JSX.Element {
        return <PersonCell localUrl={localUrl} original={original} />;
      },
    },
    {
      id: 'interaction',
      className: tableStyles.cellCenter,
      Header: TableColumnHeader({
        title: t('Interaction'),
      }),
      sortable: false,
      Cell(): JSX.Element {
        return <>{flavorToInteractionMap(t)[activeFlavor]}</>;
      },
    },
    {
      // TODO: This doesn't work for the Unsubscribed from all flavor yet
      id: 'first_interacted',
      className: tableStyles.cellCenter,
      Header: TableColumnHeader({
        title: t('First interacted'),
      }),
      sortable: false,
      Cell({ original }: IRow): JSX.Element {
        const firstCrumbDate = original.get('inner_hits')?.first_flavor[0].d_c;
        return <Time format={TimeFormat.shortDateTime} timestamp={firstCrumbDate} />;
      },
    },
  ];
}

export default campaignEngagementColumns;
