import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { IGoal, Segment } from '@feathr/blackbox';
import type { Segments as SegmentsCollection } from '@feathr/blackbox';
import { StatBox } from '@feathr/components';
import { useStore } from '@feathr/report_components/state';

import type { IStatProps } from './Stats';

interface IProps extends IStatProps {
  goals: IGoal[];
}

function getGoalSegments(goals: IGoal[], Segments: SegmentsCollection): Segment[] {
  return goals.filter((g) => !!g.segment && !g.is_archived).map((g) => Segments.get(g.segment!));
}

function ConversionStat({ value, goals }: Readonly<IProps>): JSX.Element {
  const { Segments } = useStore();

  const conversionsFormatted = numeral(value).format('0,0');
  const multiGoal = goals.length > 1;
  const segment = goals.length > 0 && getGoalSegments([goals[0]], Segments)[0];
  const conversionsSecondary =
    goals.length === 0
      ? undefined
      : multiGoal
        ? ''
        : segment
          ? `${numeral(value / segment.count).format('0.0%')} of ${numeral(segment.count).format(
              '0,0',
            )} people in goal group`
          : '';

  return (
    <StatBox
      label={'Conversions'}
      primary={conversionsFormatted}
      secondary={conversionsSecondary}
    />
  );
}

export default observer(ConversionStat);
