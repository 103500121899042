import type { ITemplate } from '../templates';
import { Campaign } from './campaign';
import type { ICampaignAttributes } from './types';
import { CampaignClass } from './types';

export type TLandingPageCampaign = ICampaignAttributes & {
  readonly _cls: CampaignClass.LandingPage;
  template: ITemplate;
  url: string;
};

export class LandingPageCampaign extends Campaign<TLandingPageCampaign> {}

// Function that allows type narrowing for Landing Page Campaigns.
export function isLandingPageCampaign(campaign: Campaign): campaign is LandingPageCampaign {
  return campaign.get('_cls') === CampaignClass.LandingPage;
}
