import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { EmailVerification } from '@feathr/blackbox';
import { Button, DebouncedInput, Input, Table } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useLocalUrl, useStore } from '@feathr/extender/state';

import columns from './EmailsColumns';

import * as styles from './EmailsPage.css';

function EmailsPage() {
  const { EmailVerifications } = useStore();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const [search, setSearch] = useState<string | undefined>();
  const { t } = useTranslation();

  function handleAddEmail() {
    const newEmail = EmailVerifications.create();
    history.push(localUrl(localUrl(newEmail.getItemUrl('edit'))));
  }

  function handleDebouncedSearchChange(newValue?: string) {
    setSearch(newValue);
  }

  const filterElements = [
    ,
    <>
      <DebouncedInput<string> defaultValue={search} onChange={handleDebouncedSearchChange}>
        {(liveValue, onChangeLiveValue) => (
          <Input
            className={styles.filter}
            isClearable={true}
            onChange={onChangeLiveValue}
            placeholder={t('Search by name or email...')}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
    </>,
  ];

  const filters = search
    ? {
        __raw__: {
          $or: [
            { email: { $regex: search, $options: 'i' } },
            { from_name: { $regex: search, $options: 'i' } },
          ],
        },
      }
    : undefined;

  return (
    <Page
      actions={[
        <Button key={'add'} onClick={handleAddEmail} type={'primary'}>
          {t('Add email')}
        </Button>,
      ]}
      description={t(
        'Setup and manage emails that you can use as the from address on email campaigns.',
      )}
      title={t('Emails')}
    >
      <Table<EmailVerification>
        collection={EmailVerifications}
        columns={columns}
        filterElements={filterElements}
        filters={filters}
        initialPagesize={10}
        initialSort={[{ id: 'email' }]}
        isPaginated={true}
        noDataText={t('No email addresses have been verified.')}
      />
    </Page>
  );
}

export default EmailsPage;
