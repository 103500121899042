import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ITemplate } from '@feathr/blackbox';
import type { Template } from '@feathr/blackbox';
import { ArchiveModalV1, ConfirmModalV1, ContextMenu } from '@feathr/components';
import TemplateSummary from '@feathr/extender/components/TemplateSummary';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { getIconForAction, useToggle } from '@feathr/hooks';

import { toastMessage } from '../templateUtils';

interface IProps {
  template: Template;
}

function EventTemplatesCampaignOptions({ template }: Readonly<IProps>): JSX.Element {
  const { Templates } = useStore();
  const localUrl = useLocalUrl();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isProjectModalOpen, toggleProjectModalOpen] = useToggle(false);
  const { t } = useTranslation();

  async function handleMakeProject(): Promise<void> {
    const patch = {
      event_id: template.get('event'),
    } as Partial<ITemplate>;

    const updatedTemplate = await template.clone(patch);
    Templates.refreshApiCache();

    toastMessage({
      updatedTemplate,
      successMessage: t('Your template has been duplicated and turned into a project template.'),
      errorMessage: t('An error occurred while trying to duplicate your template:\n{{error}}', {
        error: updatedTemplate.error?.message || 'Unexpected error',
      }),
    });
    toggleProjectModalOpen();
  }

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <ContextMenu.Item
          disabled={template.isUpdating}
          link={localUrl(template.getItemUrl())}
          prefix={getIconForAction('view')}
        >
          {t('View')}
        </ContextMenu.Item>
        <ContextMenu.Item
          disabled={template.isUpdating}
          link={localUrl(template.getItemUrl('edit'))}
          prefix={getIconForAction('edit')}
        >
          {t('Edit')}
        </ContextMenu.Item>
        <ContextMenu.Divider />
        <ContextMenu.Item
          disabled={template.isUpdating}
          onClick={toggleProjectModalOpen}
          prefix={getIconForAction('duplicate')}
        >
          {t('Duplicate as saved template')}
        </ContextMenu.Item>
        <ContextMenu.Divider />
        <ContextMenu.Item
          onClick={toggleArchiveModalOpen}
          prefix={getIconForAction('archive')}
          theme={'danger'}
        >
          {t('Archive')}
        </ContextMenu.Item>
      </ContextMenu>
      {isProjectModalOpen && (
        <ConfirmModalV1
          message={t('Are you sure you want to duplicate this template into a project template?')}
          onClose={toggleProjectModalOpen}
          onConfirm={handleMakeProject}
          t={t}
          title={t('Duplicate as saved template')}
        >
          <TemplateSummary template={template} />
        </ConfirmModalV1>
      )}
      {isArchiveModalOpen && (
        <ArchiveModalV1
          errorMessage={t('There was an issue archiving the template. Please try again later.')}
          model={template}
          onClose={toggleArchiveModalOpen}
          successMessage={t('Template {{name}} has been archived.', { name: template.name })}
          t={t}
        >
          <TemplateSummary template={template} />
        </ArchiveModalV1>
      )}
    </>
  );
}

export default observer(EventTemplatesCampaignOptions);
