import { faCaretDown, faClipboard } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TFunction } from 'i18next';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { RowRenderProps } from 'react-table';

import type { ITrackedLink } from '@feathr/blackbox';
import type {
  Redirect,
  RedirectDomains as RedirectDomainCollection,
  TrackedLinkCampaign,
} from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import {
  Button,
  CopyToClipboardButton,
  Dropdown,
  Menu,
  Skeleton,
  Table,
  TableColumnHeader,
  Tooltip,
} from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import * as styles from './TrackedLinkCampaignPage.css';

interface IProps {
  campaign: TrackedLinkCampaign;
}

function LinkCell(param: keyof ITrackedLink, campaign: TrackedLinkCampaign) {
  return ({ original }: RowRenderProps): JSX.Element => {
    const link = campaign
      .get('tracked_links')
      .find((l: ITrackedLink) => l.redirect_id === original.id);
    if (link) {
      return <>{link[param]}</>;
    }
    return <>-</>;
  };
}

// TODO: Verify that sorting solution per https://github.com/Feathr/shrike/issues/1595
function LinkTableColumns(
  RedirectDomains: RedirectDomainCollection,
  campaign: TrackedLinkCampaign,
  t: TFunction,
): Array<IColumn<Redirect>> {
  return [
    {
      id: 'originalUrl',
      Header: TableColumnHeader({
        title: t('Original URL'),
      }),
      width: 356,
      Cell: LinkCell('original_url', campaign),
    },
    {
      id: 'utmSource',
      Header: TableColumnHeader({
        title: t('Source'),
      }),
      Cell: LinkCell('utm_source', campaign),
    },
    {
      id: 'utmMedium',
      Header: TableColumnHeader({
        title: t('Medium'),
      }),
      Cell: LinkCell('utm_medium', campaign),
    },
    {
      id: 'utmContent',
      Header: TableColumnHeader({
        title: t('Content'),
      }),
      Cell: LinkCell('utm_content', campaign),
    },
    {
      id: 'utmTerm',
      Header: TableColumnHeader({
        title: t('Term'),
      }),
      Cell: LinkCell('utm_term', campaign),
    },
    {
      id: 'copy',
      Header: '',
      width: 200,
      Cell({ original }): JSX.Element {
        const domain = RedirectDomains.get(campaign.get('domain_id'));
        return (
          <Observer>
            {(): JSX.Element => {
              if (!domain.isPending) {
                const shortLink = `https://${domain.get('domain')}/${original.get('short_code')}`;
                const fullURL = original.get('url');
                const menu = (
                  <Menu>
                    <CopyToClipboardButton
                      prefix={<FontAwesomeIcon icon={faClipboard} />}
                      t={t}
                      text={fullURL}
                      type={'naked'}
                    >
                      Copy full URL
                    </CopyToClipboardButton>
                  </Menu>
                );
                return (
                  <>
                    <Tooltip title={shortLink}>
                      <CopyToClipboardButton
                        className={styles.btnGroupLeft}
                        prefix={<FontAwesomeIcon icon={faClipboard} />}
                        t={t}
                        text={shortLink}
                      >
                        Copy short link
                      </CopyToClipboardButton>
                    </Tooltip>
                    <Dropdown content={menu}>
                      <Button className={styles.btnGroupRight}>
                        <FontAwesomeIcon icon={faCaretDown} />
                      </Button>
                    </Dropdown>
                  </>
                );
              }
              return <Skeleton />;
            }}
          </Observer>
        );
      },
    },
  ];
}

export default function TrackedLinkCampaignLinks({ campaign }: IProps): JSX.Element {
  const { Redirects, RedirectDomains } = useStore();
  const { t } = useTranslation();

  const redirectIds = campaign
    .get('tracked_links', [])
    .map((link: ITrackedLink) => link.redirect_id);
  const initialPagesize = redirectIds.length > 0 ? Math.min(redirectIds.length, 5) : 1;
  return (
    <Table<Redirect>
      collection={Redirects}
      columns={LinkTableColumns(RedirectDomains, campaign, t)}
      filters={{ tracked_link_campaign: campaign.id }}
      initialPagesize={initialPagesize}
    />
  );
}
