import type { IObservableArray } from 'mobx';
import { observable, when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import type { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';

import type {
  Goal,
  ITrackedLink,
  Redirect,
  RedirectDomain,
  Segment,
  TrackedLinkCampaign,
} from '@feathr/blackbox';
import { Step, Steps, Wizard } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { Model } from '@feathr/rachis';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import CampaignEditStepOne, { validateStepOne } from '../CampaignEdit/CampaignEditStepOne';
import SaveCampaignButton from '../SaveCampaignButton';
import StepGoals, { getGoalSegments, validateStepGoals } from '../StepGoals';
import TrackedLinkCampaignEditStepFour from './TrackedLinkCampaignEditStepFour';
import TrackedLinkCampaignEditStepTwo, { validateStepTwo } from './TrackedLinkCampaignEditStepTwo';

interface IProps extends RouteComponentProps<Record<string, string | undefined>> {
  campaign: TrackedLinkCampaign;
}

interface ICampaignDetails {
  campaign: TrackedLinkCampaign;
  redirects: IObservableArray<Redirect>;
  goals: IObservableArray<Goal>;
  goalSegments?: Segment[];
  domain: RedirectDomain;
}
interface ICampaignDetails {
  campaign: TrackedLinkCampaign;
  domain: RedirectDomain;
  goals: IObservableArray<Goal>;
  goalSegments?: Segment[];
  redirects: IObservableArray<Redirect>;
}

function validate(details: ICampaignDetails): ICampaignValidationErrors {
  const stepOne = validateStepOne(details.campaign);
  const stepTwo = validateStepTwo(details.campaign, details.redirects, details.domain);
  const stepThree = validateStepGoals(details.goals, details.goalSegments);

  return {
    name: stepOne,
    tracked_links: stepTwo.tracked_links,
    goals: stepThree.goals,
  };
}

const getCompletedStep = ({
  campaign,
  goals,
  goalSegments,
  redirects,
  domain,
}: ICampaignDetails): number => {
  if (validateStepOne(campaign).length) {
    return 0;
  }
  if (flattenErrors(validateStepTwo(campaign, redirects, domain)).length) {
    return 1;
  }
  if (validateStepGoals(goals, goalSegments).length) {
    return 2;
  }
  return 3;
};

function CampaignEdit({ campaign }: IProps): JSX.Element {
  const { Goals, Segments, Redirects, RedirectDomains } = useStore();
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [completedStep, setCompletedStep] = useState<number>(-1);

  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const redirects: IObservableArray<Redirect> = observable(
    campaign.get('tracked_links').map((tl: ITrackedLink) => Redirects.get(tl.redirect_id)),
  );
  const redirectsIsPending = redirects.some((rdr) => rdr.isPending);
  const domain = RedirectDomains.get(campaign.get('domain_id'));
  const doCompleteStep = (): void => {
    if (!goals.isPending && !redirectsIsPending && !domain.isPending) {
      const goalSegments = getGoalSegments(goals.models, Segments);
      Promise.all([...goalSegments.map((s) => when(() => !s.isPending))]).then(() => {
        const step = getCompletedStep({
          campaign,
          goals: goals.models,
          goalSegments: getGoalSegments(goals.models, Segments),
          redirects,
          domain,
        });
        setCompletedStep(step);
        setCurrentStep(step);
      });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(doCompleteStep, [goals.isPending, redirectsIsPending, domain.isPending]);

  function onNext(): void {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    if (nextStep > (completedStep ?? 0)) {
      setCompletedStep(nextStep);
    }
  }

  function onPrev(): void {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
  }

  function buttonValidate(): ICampaignValidationErrors {
    return validate({
      campaign,
      goals: goals.models,
      goalSegments: getGoalSegments(goals.models, Segments),
      redirects,
      domain,
    });
  }

  const steps = [
    <Step key={0} stepIndex={0} title={'Name'} />,
    <Step key={1} stepIndex={1} title={'Links'} />,
    <Step key={2} stepIndex={2} title={'Conversion Tracking'} />,
    <Step key={3} stepIndex={3} title={'Review'} />,
  ];

  const goalSegments = getGoalSegments(goals.models, Segments);
  const validationErrors = validate({
    campaign,
    goals: goals.models,
    goalSegments,
    redirects,
    domain,
  });

  const childModels: Model[] = [...goals.models, ...redirects];
  const grandchildModels: Model[] = [...goalSegments];

  const saveButton = (
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      grandchildModels={grandchildModels}
      key={'changeState'}
      name={'publish_or_stop'}
      shouldChangeState={true}
      validate={buttonValidate}
    />
  );

  const actions: JSX.Element[] = [
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      grandchildModels={grandchildModels}
      key={'save'}
      shouldChangeState={false}
    />,
    saveButton,
  ];

  return (
    <div data-appcues-campaign={campaign.get('_cls')}>
      <Wizard
        actions={actions}
        isLoading={
          goals.isPending || redirectsIsPending || domain.isPending || completedStep === undefined
        }
        steps={
          <Steps current={currentStep} initialCompleted={completedStep} onChange={setCurrentStep}>
            {steps}
          </Steps>
        }
      >
        {currentStep === 0 && <CampaignEditStepOne campaign={campaign} onNext={onNext} />}
        {currentStep === 1 && (
          <TrackedLinkCampaignEditStepTwo
            campaign={campaign}
            domain={domain}
            onNext={onNext}
            onPrev={onPrev}
            redirects={redirects}
          />
        )}
        {currentStep === 2 && (
          <StepGoals campaign={campaign} goals={goals.models} onNext={onNext} onPrev={onPrev} />
        )}
        {currentStep === 3 && (
          <TrackedLinkCampaignEditStepFour
            campaign={campaign}
            domain={domain}
            goals={goals.models}
            onPrev={onPrev}
            redirects={redirects}
            saveButton={saveButton}
            validationErrors={validationErrors}
          />
        )}
      </Wizard>
    </div>
  );
}

export default withRouter(observer(CampaignEdit));
