import type { PickerOptions } from 'filestack-js';

import type { TSubscriptionPickerKey } from './SubscriptionsPage';

const sharedProperties: PickerOptions = {
  // 2.0 MB
  maxSize: 2 * 1024 * 1024,
  accept: ['image/jpeg', 'image/png', 'image/svg+xml'],
};

const logoProperties: PickerOptions = {
  ...sharedProperties,
  imageMin: [50, 50],
};

const faviconProperties: PickerOptions = {
  ...sharedProperties,
  imageMax: [32, 32],
};

export const pickerConfig: Record<TSubscriptionPickerKey, PickerOptions> = {
  logo_url: logoProperties,
  favicon_url: faviconProperties,
};
