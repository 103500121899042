import {
  AuthenticatedStore,
  AuthenticatedStoreContext,
  createAuthenticatedStore,
  useAuthenticatedStore,
} from '@feathr/report_components';

/**
 * @deprecated use AuthenticatedStore instead
 */
export { AuthenticatedStore as RootStore };

/**
 * @deprecated use useStore() or useContext(AuthenticatedStoreContext) instead
 */
export { AuthenticatedStoreContext as StoresContext };

/**
 * @deprecated use createAuthenticatedStore instead
 */
export { createAuthenticatedStore as createStore };

/**
 * Alias useStore for useAuthenticatedStore
 */
export default useAuthenticatedStore;
