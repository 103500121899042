import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons';
import {
  faCommentExclamation,
  faExternalLink,
  faFileDownload,
  faInfoCircle,
  faLink,
  faPlayCircle,
  faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ObjectId } from 'bson';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { OptionProps, SingleValueProps } from 'react-select';
import { components } from 'react-select';

import type { ICampaignLink } from '@feathr/blackbox';
import { Button, Input, Select, Tooltip } from '@feathr/components';
import type { ILinkErrors } from '@feathr/extender/App/EventsPage/CampaignSummary';
import { flattenError } from '@feathr/hooks';

import * as styles from './CampaignLinksInput.css';

interface IProps {
  errors?: ILinkErrors[];
  onChange: (value: ICampaignLink[]) => void;
  value: ICampaignLink[];
}

interface IIconOption {
  id: string;
  name: string;
}

const iconMap = {
  link: faLink,
  'external-link': faExternalLink,
  'comment-exclamation': faCommentExclamation,
  'question-circle': faQuestionCircle,
  'file-download': faFileDownload,
  'play-circle': faPlayCircle,
  'info-circle': faInfoCircle,
};

function IconOption(props: OptionProps<IIconOption>): JSX.Element {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <FontAwesomeIcon icon={iconMap[data.id]} size={'2x'} />
      </div>
    </components.Option>
  );
}

function IconSingleValue(props: SingleValueProps<IIconOption>): JSX.Element {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <FontAwesomeIcon icon={iconMap[data.id]} size={'2x'} />
    </components.SingleValue>
  );
}

function CampaignLinksInput({ errors, onChange, value }: IProps): JSX.Element {
  const { t } = useTranslation();

  function handleAddLink(): void {
    onChange([
      ...value,
      { link_id: new ObjectId().toHexString(), title: '', url: '', icon: 'link' },
    ]);
  }

  function handleRemoveLink(linkId: string) {
    return () => {
      onChange(value.filter((link) => link.link_id !== linkId));
    };
  }

  function handleChangeURL(linkId: string) {
    return (newValue?: string) => {
      const newValueObject = value.map((link) => {
        if (link.link_id !== linkId) {
          return link;
        }
        return { ...link, url: newValue || '' };
      });
      onChange(newValueObject);
    };
  }

  function handleChangeTitle(linkId: string) {
    return (newValue?: string) => {
      const newValueObject = value.map((link) => {
        if (link.link_id !== linkId) {
          return link;
        }
        return { ...link, title: newValue || '' };
      });
      onChange(newValueObject);
    };
  }

  function handleChangeIcon(linkId: string) {
    return (option: { id: string; name: string }) => {
      const newValue = value.map((link) => {
        if (link.link_id !== linkId) {
          return link;
        }
        return { ...link, icon: option.id };
      });
      onChange(newValue);
    };
  }

  const iconOptions = [
    { id: 'link', name: t('Link') },
    { id: 'external-link', name: t('External Link') },
    { id: 'comment-exclamation', name: t('Feedback') },
    { id: 'question-circle', name: t('Help') },
    { id: 'file-download', name: t('File Download') },
    { id: 'play-circle', name: t('Play Video') },
    { id: 'info-circle', name: t('Info') },
  ];

  return (
    <>
      {value.length > 0 && (
        <table className={styles.table}>
          <tbody>
            {value.map((link, i) => {
              const error = errors ? errors[i] : undefined;

              return (
                <tr key={link.link_id}>
                  <td>
                    <Input
                      helpPlacement={'bottom'}
                      helpText={t('URL must start with http:// or https://')}
                      label={t('URL')}
                      onChange={handleChangeURL(link.link_id)}
                      type={'url'}
                      validationError={error && flattenError(error.url)}
                      value={link.url}
                    />
                  </td>
                  <td>
                    <Input
                      label={t('Title')}
                      onChange={handleChangeTitle(link.link_id)}
                      type={'text'}
                      validationError={error && flattenError(error.title)}
                      value={link.title}
                    />
                  </td>
                  <td>
                    <Select
                      components={{ Option: IconOption, SingleValue: IconSingleValue }}
                      label={t('Icon')}
                      name={'campaign-link-icon'}
                      onSelectSingle={handleChangeIcon(link.link_id)}
                      options={iconOptions}
                      value={iconOptions.find((o) => o.id === link.icon)}
                    />
                  </td>
                  <td>
                    <Tooltip title={t('Remove this link')}>
                      <Button onClick={handleRemoveLink(link.link_id)} type={'naked'}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <Button onClick={handleAddLink} prefix={<FontAwesomeIcon icon={faPlus} />}>
        {t('Add link')}
      </Button>
    </>
  );
}

export default CampaignLinksInput;
