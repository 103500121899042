import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import type { IBreadcrumbListParams, IPredicate } from '@feathr/blackbox';
import type { Breadcrumb, Flavors } from '@feathr/blackbox';
import { Table } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import FlavorSelect from '@feathr/extender/components/FlavorSelect';
import MessageSelect from '@feathr/extender/components/MessageSelect/MessageSelect';
import PartnerSelect from '@feathr/extender/components/PartnerSelect';
import { useStore } from '@feathr/extender/state';

import columns from './columns';

function EventPartnersActivityPage() {
  const { eventId } = useParams<{ eventId: string }>();
  const [flavors, setFlavors] = useState<Array<keyof Flavors> | undefined>();
  const [partnerIds, setPartnerIds] = useState<string[]>([]);
  const [messageIds, setMessageIds] = useState<string[]>([]);

  const { Breadcrumbs } = useStore();

  function handlePartnersChange(newValues: string | string[]) {
    setPartnerIds(newValues as string[]);
  }

  function handleMessagesChange(newValues: string | string[]) {
    setMessageIds(newValues as string[]);
  }

  function handlePartnersClear() {
    setPartnerIds([]);
  }

  function handleFlavorsClear() {
    setFlavors(undefined);
  }

  function handleMessagesClear() {
    setMessageIds([]);
  }

  const limitOptionsTo = [
    'dashboard_view',
    'dashboard_create',
    'dashboard_submit',
    'dashboard_send_complete',
    'dashboard_send_request',
    'dashboard_import',
    'partner_message_view',
    'partner_message_link_click',
    'partner_message_send',
    'report_view',
    'import',
  ] as Array<keyof Flavors>;
  const filterElements: React.ReactNode | [React.ReactNode, React.ReactNode] = (
    <>
      <PartnerSelect
        isMulti={true}
        onChange={handlePartnersChange}
        onClear={handlePartnersClear}
        placeholder={'Select partners...'}
        projectIds={[eventId]}
        value={partnerIds}
      />
      <FlavorSelect
        isMulti={true}
        limitOptionsTo={limitOptionsTo}
        onChange={setFlavors}
        onClear={handleFlavorsClear}
        value={flavors}
      />
      <MessageSelect
        eventId={eventId}
        isMulti={true}
        onChange={handleMessagesChange}
        onClear={handleMessagesClear}
        placeholder={'Select partner messages...'}
        value={messageIds}
      />
    </>
  );

  const predicates: IPredicate[] = [
    {
      kind: 'activity',
      attr_against: 'e_id',
      attr_type: 'string',
      comparison: 'eq',
      value: eventId,
    },
    {
      kind: 'activity',
      attr_against: 'p_id',
      attr_type: partnerIds.length ? 'list' : 'string',
      comparison: partnerIds.length ? 'eq' : 'exists',
      value: partnerIds.length ? partnerIds : undefined,
    },
    {
      kind: 'activity',
      attr_against: 'flvr',
      attr_type: 'list',
      comparison: 'eq',
      value: flavors && flavors.length ? flavors : limitOptionsTo,
    },
  ];

  if (messageIds && messageIds.length) {
    predicates.push({
      kind: 'activity',
      attr_against: 'sreq_id',
      attr_type: 'list',
      comparison: 'eq',
      value: messageIds,
    });
  }

  const params: Partial<IBreadcrumbListParams> = {
    mode: 'match_all',
    lookback_mode: 'unbounded',
    lookback_value: 0,
    predicates,
  };

  return (
    <Page title={'Activity'}>
      <Table<Breadcrumb>
        collection={Breadcrumbs}
        columns={columns}
        filterElements={filterElements}
        initialSort={[{ id: 'd_c', desc: true }]}
        params={params}
      />
    </Page>
  );
}

export default observer(EventPartnersActivityPage);
