import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, TAttributionModel } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  EAlertV2Type as AlertType,
  Spinner,
  StatBox,
  StatsCard,
} from '@feathr/components';
import { useStore } from '@feathr/report_components/state';

import * as styles from '../LeadsSummaryCard/LeadsSummaryCard.css';

interface IProps {
  attributionModel: TAttributionModel;
  campaign: Campaign;
  end: string;
  start: string;
}

function LeadsSummaryCard({
  attributionModel,
  campaign,
  end,
  start,
}: Readonly<IProps>): JSX.Element {
  const { Campaigns } = useStore();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [uniquePartners, setUniquePartners] = useState(0);
  const [uniqueLeads, setUniqueLeads] = useState(0);
  const [avgLeadsPerPartner, setAvgLeadsPerPartner] = useState(0);
  const [isPartial, setIsPartial] = useState(false);

  function formatNumber(value: number): string {
    // Check to see if value is a whole number.
    if (value % 1 === 0) {
      return numeral(value).format('0,0');
    }
    return numeral(value).format('0,0.00');
  }

  const inclusiveEndDate = new Date(
    new Date(end).setDate(new Date(end).getDate() + 1),
  ).toISOString();

  useEffect(() => {
    const getItems = async (): Promise<void> => {
      const response = await Campaigns.getPartnerStats(
        campaign.id,
        start,
        inclusiveEndDate,
        attributionModel,
        [{ id: 'conversions', desc: true }],
        { page: 0, page_size: 500 },
      );
      setUniquePartners(response.data.filter((res) => res.leads > 0).length);
      setUniqueLeads(response.data.reduce((prev, curr) => prev + curr.leads, 0));

      if (response.meta?.is_partial) {
        setIsPartial(true);
      }

      setLoading(false);
    };
    getItems();
  }, [attributionModel, campaign.id, Campaigns, inclusiveEndDate, start]);

  useEffect(() => {
    setAvgLeadsPerPartner(uniqueLeads / uniquePartners);
  }, [uniqueLeads, uniquePartners, avgLeadsPerPartner]);

  if (!loading) {
    return (
      <StatsCard title={t('Leads summary')}>
        {isPartial && (
          <StatsCard.Content>
            <Alert
              className={styles.alert}
              description={t('Some partners may be missing from this statistic.')}
              title={t('Your campaign has more partners than can be tracked.')}
              type={AlertType.warning}
            />
          </StatsCard.Content>
        )}
        <StatsCard.Content>
          <StatBox
            label={t('Partners with leads')}
            primary={formatNumber(uniquePartners)}
            tooltip={t('The number of partners that have generated leads.')}
          />
          <StatBox
            label={t('Total leads generated')}
            primary={formatNumber(uniqueLeads)}
            tooltip={t('The number of leads generated by partners in this campaign.')}
          />
          <StatBox
            label={t('Average leads per partner')}
            primary={formatNumber(avgLeadsPerPartner ? avgLeadsPerPartner : 0)}
            tooltip={t('The average number of leads per lead-generating partner.')}
          />
        </StatsCard.Content>
      </StatsCard>
    );
  } else {
    return <Spinner />;
  }
}

export default observer(LeadsSummaryCard);
