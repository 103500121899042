import type { Client, PickerResponse } from 'filestack-js';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { DisplayCreative, IDimensionDefinition } from '@feathr/blackbox';
import {
  CreativeClass,
  ValidDimensions,
  ValidFacebookDimensions,
  ValidFacebookVideoDimensions,
} from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import {
  Button,
  CodeMirror,
  CopyToClipboardButton,
  Fieldset,
  Form,
  Input,
  Popover,
  Radios,
  Select,
  toast,
  Toolbar,
} from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import * as styles from './CreativeMetadata.css';

interface IProps {
  creative: DisplayCreative;
  destinationUrlPlaceholder?: string;
  showInlineEditor?: boolean;
  showReplaceButton?: boolean;
  showName?: boolean;
  showStatus?: boolean;
  validDimensions?: Record<string, IDimensionDefinition>;
}

function getVideoDimensions(url: string): Promise<{
  width: number;
  height: number;
  duration: number;
}> {
  return new Promise<{ width: number; height: number; duration: number }>((resolve) => {
    const video = document.createElement('video');
    video.addEventListener('loadedmetadata', () => {
      resolve({
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
      });
    });
    video.src = url;
  });
}

function CreativeMetadata({
  creative,
  destinationUrlPlaceholder,
  showInlineEditor,
  showReplaceButton,
  showStatus = false,
  showName = true,
  validDimensions,
}: IProps): JSX.Element {
  const { Campaigns } = useStore();
  const { t } = useTranslation();
  const type = creative.get('_cls');
  const isVideo = type === CreativeClass.DisplayVideo || type === CreativeClass.FacebookVideo;
  const campaignId = creative.get('parent');
  const campaign = campaignId ? Campaigns.get(campaignId) : undefined;

  const dimensions: Record<string, IDimensionDefinition> = validDimensions ?? {
    ...ValidDimensions,
    ...ValidFacebookDimensions,
    ...ValidFacebookVideoDimensions,
  };
  const dimensionOptions = Object.keys(dimensions).map<ISelectOption>((key: string) => {
    const value = dimensions[key];
    return {
      id: value.spec,
      name: value.name,
    };
  });
  const dimension = dimensionOptions.find((dim) => dim.id === creative.get('spec'));
  const isFacebook = type === CreativeClass.FacebookImage || type === CreativeClass.FacebookVideo;

  function handleDestinationChange(newValue?: string): void {
    creative.set({
      use_original_destination: newValue === 'use_original_destination',
    });
  }

  function handleEnabledChange(newValue?: string): void {
    creative.set({ is_enabled: newValue === 'enabled' });
  }

  function setImage(client: Client): (files: PickerResponse) => void {
    const original = {
      url: creative.get('url'),
      mimetype: creative.get('mimetype'),
      width: creative.get('width'),
      height: creative.get('height'),
      spec: creative.get('spec'),
    };
    return (files: PickerResponse) => {
      const { filesUploaded } = files;
      filesUploaded.forEach(async (file) => {
        const { key, container, handle } = file;
        const url = `https://s3.amazonaws.com/${container}/${encodeURIComponent(key!)}`;
        const metadata = await client.metadata(handle, {
          width: true,
          height: true,
          mimetype: true,
          size: true,
          filename: true,
        });

        let { width, height } = metadata;
        if (isVideo) {
          const videoDimensions = await getVideoDimensions(url);
          width = videoDimensions.width;
          height = videoDimensions.height;
        }

        const { mimetype, filename } = metadata;
        creative.set({ height, width, spec: undefined });
        const dimensionDefinition = creative.getSpec();
        creative.set({ mimetype, spec: dimensionDefinition?.spec, url });

        if (campaign && !campaign?.isPending) {
          const validDimensions = creative.getValidDimensions(campaign);
          const specs = Object.values(validDimensions).map((v) => v.spec);
          if (!specs.includes(creative.get('spec'))) {
            // TODO: Should this include ValidFacebookDimensions and ValidFacebookVideoDimensions?
            const closest = creative.closestValidDimension(ValidDimensions);
            toast(
              t(
                '"{{filename}}" is the wrong size ({{width}}x{{height}}). The closest valid size would be {{closest}}',
                { filename, width, height, closest },
              ),
              { type: 'error' },
            );
            creative.set(original);
          }
        }
      });
    };
  }

  async function handleReplace(): Promise<void> {
    const filestackJs = await import(/* webpackChunkName: "filestack-js" */ 'filestack-js');
    const client = filestackJs.init(FILESTACK_API_KEY);
    client
      .picker({
        maxFiles: 1,
        maxSize: 200 * 1024 * 1024,
        accept: isVideo ? ['video/mp4', 'video/webm'] : ['image/jpeg', 'image/png', 'image/gif'],
        onUploadDone: setImage(client),
        transformations: {
          crop: false,
          circle: false,
          rotate: false,
        },
        dropPane: {},
      })
      .open();
  }

  function getDimensionsOptionLabel({ id }: ISelectOption): string {
    const key = Object.keys(dimensions).find((k) => dimensions[k].spec === id);
    const d = key ? dimensions[key] : { height: 0, name: t('Unknown Dimensions'), width: 0 };
    return `${d.width}px x ${d.height}px (${d.name})`;
  }

  function getDimensionsOptionValue({ id }: ISelectOption): string {
    const key = Object.keys(dimensions).find((k) => dimensions[k].spec === id);
    const d = key ? dimensions[key] : { height: 0, name: t('Unknown Dimensions'), width: 0 };
    return `${d.width}px x ${d.height}px (${d.name})`;
  }

  function handleSelectDimensions({ id }: ISelectOption): void {
    const key = Object.keys(dimensions).find((k) => dimensions[k].spec === id);
    if (key) {
      creative.set({
        spec: dimensions[key].spec,
        width: dimensions[key].width,
        height: dimensions[key].height,
      });
    }
  }

  return (
    <Form className={styles.root} label={t('Edit metadata')}>
      {showReplaceButton &&
        type !== CreativeClass.DisplayAdTag &&
        type !== CreativeClass.DisplayBannersnack && (
          <Button className={styles.upload} onClick={handleReplace} type={'primary'}>
            {isVideo ? t('Replace video') : t('Replace image')}
          </Button>
        )}
      {showName && type !== CreativeClass.DisplayBannersnack && isVideo && !isFacebook && (
        <Fieldset>
          <Input attribute={'name'} label={t('Name')} model={creative} type={'text'} />
        </Fieldset>
      )}
      {showInlineEditor && type === CreativeClass.DisplayAdTag && (
        <>
          <CodeMirror
            attribute={'adtag'}
            helpText={t(
              'Copy and paste your ad tag markup here. Add Feathr macros to enable impression and click tracking.',
            )}
            label={t('Ad tag HTML')}
            model={creative}
            name={'adtag'}
            options={{ height: 136 }}
          />
          <Toolbar>
            <Popover toggle={'onMouseOver'}>
              <span>
                <CopyToClipboardButton t={t} text={'@FEATHR_TRACK@'}>
                  <code>@FEATHR_TRACK@</code>
                </CopyToClipboardButton>
              </span>
              <div>
                <Trans t={t}>
                  This macro will be replaced with an HTML <code>img</code> tag for tracking
                  impressions.
                </Trans>
              </div>
            </Popover>
            <Popover toggle={'onMouseOver'}>
              <span>
                <CopyToClipboardButton t={t} text={'@FEATHR_CLICK@'}>
                  <code>@FEATHR_CLICK@</code>
                </CopyToClipboardButton>
              </span>
              <div>
                {t('This macro will be replaced with a click-through URL for tracking clicks.')}
              </div>
            </Popover>
            <Popover toggle={'onMouseOver'}>
              <span>
                <CopyToClipboardButton t={t} text={'@FEATHR_CLICK_ESC@'}>
                  <code>@FEATHR_CLICK_ESC@</code>
                </CopyToClipboardButton>
              </span>
              <div>
                {t(
                  'This macro will be replaced with a URI-encoded version of the click-through URL for tracking clicks.',
                )}
              </div>
            </Popover>
          </Toolbar>
          <Fieldset>
            <Select
              getOptionLabel={getDimensionsOptionLabel}
              getOptionValue={getDimensionsOptionValue}
              label={t('Dimensions')}
              name={'dimensions'}
              onSelectSingle={handleSelectDimensions}
              options={dimensionOptions}
              value={dimension}
            />
          </Fieldset>
        </>
      )}
      <Fieldset>
        <Input attribute={'name'} label={t('Name')} model={creative} type={'text'} />
        <Input
          attribute={'alt_text'}
          helpText={t(
            'Please provide advertisement text with a clear call-to-action to display in the event the image cannot be loaded and for visually-impaired individuals who may use a screen reader. (Text provided here will override alternate text provided at the campaign level.)',
          )}
          label={t('Alternate text')}
          model={creative}
          optional={true}
          placeholder={t(
            "Advertisement: Don't bee too late to register for Beecon 2023! Click here to register now!",
          )}
          type={'text'}
        />
        {type === CreativeClass.DisplayAdTag && (
          <>
            <Radios
              className={styles.radios}
              helpText={t(
                'Sometimes ad tags come with built-in destination URLs and click areas. You can keep these or override them with the destination URL configured here.',
              )}
              label={t('Override built-in destination URLs')}
              onChange={handleDestinationChange}
              options={[
                {
                  id: 'override_original_destination',
                  name: t('Override built-in destination URLs'),
                },
                { id: 'use_original_destination', name: t('Use built-in destination URLs') },
              ]}
              value={
                creative.get('use_original_destination')
                  ? 'use_original_destination'
                  : 'override_original_destination'
              }
            />
          </>
        )}
        <Input
          attribute={'destination_url'}
          disabled={creative.get('use_original_destination')}
          helpText={t('Specify a destination that is distinct for this creative.')}
          label={t('Destination URL')}
          model={creative}
          optional={true}
          placeholder={destinationUrlPlaceholder}
          type={'url'}
        />
        {showStatus && (
          <Radios
            className={styles.radios}
            label={t('Creative status')}
            onChange={handleEnabledChange}
            options={[
              { id: 'enabled', name: t('Enabled') },
              { id: 'disabled', name: t('Disabled') },
            ]}
            value={creative.get('is_enabled') ? 'enabled' : 'disabled'}
          />
        )}
      </Fieldset>
    </Form>
  );
}

export default observer(CreativeMetadata);
