import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DripCampaign, EmailBaseCampaign } from '@feathr/blackbox';
import { FilterCarousel, Label } from '@feathr/components';
import EmailDetails from '@feathr/extender/components/EmailDetails';
import ReportActions from '@feathr/extender/components/PageHeaderActions';
import { useReportExport } from '@feathr/extender/hooks';
import { useStore } from '@feathr/extender/state';

import CampaignPage from '../../CampaignPage';
import CampaignReportSection, { getCampaignReportConfig } from '../CampaignReportSection';

import * as styles from './DripCampaignReportPage.css';

interface IProps {
  campaign: DripCampaign;
}

function DripCampaignReportPage({ campaign: parentCampaign }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { Campaigns } = useStore();
  const [activeCampaign, setActiveCampaign] = useState<'all' | EmailBaseCampaign['id']>('all');
  const showAllCampaigns = activeCampaign === 'all';
  const shouldShowDetails = !showAllCampaigns;
  const config = getCampaignReportConfig(parentCampaign);
  const reportExportProps = useReportExport({ model: parentCampaign, config });
  const { name, linkedCampaignIds } = parentCampaign;

  const { models: childCampaigns, isPending: isChildCampaignsPending } = Campaigns.list({
    filters: { id__in: linkedCampaignIds },
    ordering: ['step_number'],
  });

  const carouselItems = childCampaigns.map(({ id, name }) => ({
    content: name,
    onClick: () => setActiveCampaign(id),
  }));

  const carousel = isChildCampaignsPending ? undefined : (
    <div className={styles.carousel}>
      <FilterCarousel
        initialSlide={'prefix'}
        items={carouselItems}
        ordered={true}
        prefix={{
          content: t('All {{count}} Emails', { count: childCampaigns.length }),
          onClick: () => setActiveCampaign('all'),
        }}
      />
    </div>
  );

  const childCampaign = shouldShowDetails
    ? childCampaigns.find((c) => c.id === activeCampaign)
    : undefined;
  const index = childCampaigns.findIndex((c) => c.id === activeCampaign);

  const actions =
    activeCampaign === 'all' ? (
      <ReportActions campaign={parentCampaign} {...reportExportProps} />
    ) : undefined;

  return (
    <CampaignPage
      actions={actions}
      campaign={parentCampaign}
      headerChildren={carousel}
      title={name}
    >
      {activeCampaign !== 'all' && (
        <>
          <Label className={styles.label}>{`${index + 1}. ${childCampaign!.name}`}</Label>
          <EmailDetails campaign={childCampaign! as EmailBaseCampaign} />
        </>
      )}
      <h3 className={shouldShowDetails ? styles.titleWithDetails : styles.title}>{t('Report')}</h3>
      <CampaignReportSection
        campaign={activeCampaign === 'all' ? parentCampaign : childCampaign!}
        {...reportExportProps}
      />
    </CampaignPage>
  );
}

export default observer(DripCampaignReportPage);
