import { useDisclosure } from '@mantine/hooks';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { Account, Campaign, Partner } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import {
  ArchiveModalV1,
  ContextMenu,
  CopyToClipboardButton,
  TableColumnHeader,
  toast,
} from '@feathr/components';
import { useAccount, useLocalUrl, useStore } from '@feathr/extender/state';
import { getIconForAction, useToggle } from '@feathr/hooks';

import { AddCampaignModal } from '../../../CampaignsPage/AddCampaignButton';

import styles from './OptionsColumn.css';

interface IProps {
  original: Partner;
}

function OptionsCell({ original: partner }: IProps): JSX.Element {
  const account: Account | undefined = useAccount();
  const { t } = useTranslation();
  const { Campaigns } = useStore();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const [showArchiveModal, toggleShowArchiveModal] = useToggle(false);
  const [isAddCampaignModalOpen, { open: openAddCampaignModal, close: closeAddCampaignModal }] =
    useDisclosure(false);

  const parent: string = partner.get('parent');

  async function createMessage(): Promise<void> {
    const message: Campaign = Campaigns.create({
      _cls: CampaignClass.PinpointPartnerMessage,
      event: parent,
      participation: {
        partner_ids: [partner.id],
        mode: 'manual',
      },
    });
    const response = await Campaigns.add(message, { validate: false });
    history.push(localUrl(response.getItemUrl()));
  }

  async function archive(): Promise<void> {
    try {
      partner.set({ is_archived: true });
      await partner.save();
      toast(t('Successfully archived.'), { type: 'success' });

      // If err is instance of Error, it should be of type any.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      partner.set({ is_archived: false });
      toast(
        t('Encountered an error while archiving:\n{{errorMessage}}', {
          errorMessage: error.message,
        }),
        {
          type: 'error',
        },
      );
    } finally {
      toggleShowArchiveModal();
    }
  }

  const hasMonetization = !!account?.hasMonetization;
  const monetizationDisabled: boolean =
    !partner.get('logo') ||
    !partner.get('logo').includes('http') ||
    !partner.get('website') ||
    !partner.get('website').includes('http') ||
    !hasMonetization;

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <ContextMenu.Label>{t('Dashboard')}</ContextMenu.Label>

        <ContextMenu.Item
          href={partner.getDashboardUrl()}
          prefix={getIconForAction('view')}
          target={'_blank'}
        >
          {t('View')}
        </ContextMenu.Item>
        <CopyToClipboardButton
          component={'ContextMenuItem'}
          successMessage={t('Copied dashboard link to clipboard')}
          t={t}
          text={partner.getDashboardUrl()}
        >
          {t('Copy link')}
        </CopyToClipboardButton>

        <ContextMenu.Divider />
        <ContextMenu.Label>{t('Partner')}</ContextMenu.Label>
        <ContextMenu.Item link={localUrl(partner.getItemUrl())} prefix={getIconForAction('edit')}>
          {t('Edit')}
        </ContextMenu.Item>
        <ContextMenu.Item onClick={createMessage} prefix={getIconForAction('email')}>
          {t('Message')}
        </ContextMenu.Item>
        <ContextMenu.Item
          disabled={monetizationDisabled}
          onClick={openAddCampaignModal}
          prefix={getIconForAction('add')}
          tooltip={
            monetizationDisabled &&
            t(
              'You can only add monetization campaigns for partners with a valid logo and website URL.',
            )
          }
        >
          {t('Monetize')}
        </ContextMenu.Item>
        <ContextMenu.Item
          onClick={toggleShowArchiveModal}
          prefix={getIconForAction('archive')}
          theme={'danger'}
        >
          {t('Archive')}
        </ContextMenu.Item>
      </ContextMenu>

      <AddCampaignModal
        campaignClasses={[
          CampaignClass.Affinity,
          CampaignClass.EmailList,
          CampaignClass.Facebook,
          CampaignClass.Lookalike,
          CampaignClass.MobileGeoFenceRetargeting,
          CampaignClass.MobileGeoFencing,
          CampaignClass.PinpointEmail,
          CampaignClass.Search,
          CampaignClass.SeedSegment,
          CampaignClass.Segment,
        ]}
        onClose={closeAddCampaignModal}
        opened={isAddCampaignModalOpen}
        partnerId={partner.id}
      />

      {showArchiveModal && (
        <ArchiveModalV1
          model={partner}
          onClose={toggleShowArchiveModal}
          onConfirm={archive}
          t={t}
        />
      )}
    </>
  );
}

export default () => ({
  id: 'options',
  checkboxLabel: 'Options',
  Header: TableColumnHeader({
    title: 'Options',
  }),
  className: styles.root,
  width: 80,
  sortable: false,
  Cell(row: IProps): JSX.Element {
    return <OptionsCell {...row} />;
  },
});
