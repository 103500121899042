import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { DripCampaign, DripStepCampaign, IDripCampaignStepSpec } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import { useStore } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';

import {
  getUnverifiedFromAddresses,
  validateAutomation,
  validateInitialEnrollment,
} from './DripCampaignEdit.utils';

type TValidityMap = Array<[number, boolean]>;

export interface IUseDripStepValidationResponse {
  errors: string[];
  firstInvalidStepIndex: number | null;
  invalidSteps: number[];
  unverifiedFromAddresses: string[];
}

export interface IUseDripStepValidationProps {
  campaign: DripCampaign;
  steps: IDripCampaignStepSpec[];
}

/** Returns the validation errors associated with all steps in the drip campaign builder */
function useDripStepValidation({
  campaign,
  steps,
}: Readonly<IUseDripStepValidationProps>): IUseDripStepValidationResponse {
  const { Campaigns, EmailVerifications } = useStore();
  const { t } = useTranslation();
  const [unverifiedFromAddresses, setUnverifiedFromAddresses] = useState<string[]>([]);

  useEffect(() => {
    (async (): Promise<void> => {
      const results = await getUnverifiedFromAddresses(steps, EmailVerifications);
      setUnverifiedFromAddresses(results);
    })();
  }, [steps]);

  const errors = useMemo(() => {
    const ephemeralCampaigns = steps.map((step) => {
      const dripCampaignStep = {
        ...step,
        parent_id: campaign.id,
        _cls: CampaignClass.DripStep,
      };
      return Campaigns.create(dripCampaignStep) as DripStepCampaign;
    });

    const validityMap: TValidityMap = [];

    const validationErrors = ephemeralCampaigns
      .map((ephemeralCampaign, index) => {
        const currentStep = index + 1;
        const message = t('Step {{currentStep}} must be completed', {
          currentStep,
        });

        if (index === 0) {
          const initialEnrollmentErrors = flattenErrors(
            validateInitialEnrollment({
              campaign: ephemeralCampaign,
              isVerifiedSender: !unverifiedFromAddresses?.includes(
                ephemeralCampaign.get('from_address'),
              ),
            }),
          );
          validityMap.push([index, !!initialEnrollmentErrors.length]);
          return initialEnrollmentErrors.length ? message : undefined;
        } else {
          const automationErrors = flattenErrors(
            validateAutomation({
              campaign: ephemeralCampaign,
              isVerifiedSender: !unverifiedFromAddresses?.includes(
                ephemeralCampaign.get('from_address'),
              ),
            }),
          );
          validityMap.push([index, !!automationErrors.length]);
          return automationErrors.length ? message : undefined;
        }
      })
      // We're filtering out the undefineds here so it will always be an array of strings
      .filter((error) => error !== undefined) as string[];

    const invalidSteps = validityMap.filter(([, value]) => value).map(([key]) => key);
    const firstInvalidStepIndex = invalidSteps?.[0] ?? null;

    const combinedValidationErrors = [
      ...validationErrors,
      ...(unverifiedFromAddresses?.map((email) => t('{{email}} is not verified', { email })) ?? [
        t('Checking email verifications...'),
      ]),
    ];

    return {
      validationErrors: combinedValidationErrors,
      firstInvalidStepIndex,
      invalidSteps,
    };
  }, [Campaigns, campaign.id, steps, t, unverifiedFromAddresses]);

  return {
    errors: errors.validationErrors,
    firstInvalidStepIndex: errors.firstInvalidStepIndex,
    invalidSteps: errors.invalidSteps,
    unverifiedFromAddresses,
  };
}

export default useDripStepValidation;
