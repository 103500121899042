import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { enumKeys } from '@feathr/hooks';

export enum ELanguages {
  'en-US' = 'English',
  'nl-NL' = 'Nederlands',
}

export const Languages = enumKeys(ELanguages);
export type Languages = (typeof Languages)[number];

export const DefaultLanguage = Languages[0];
document.documentElement.lang = DefaultLanguage;

const hash = process.env.NODE_ENV !== 'development' ? GIT_VERSION || 'dev' : 'dev';
const backendPath = hash !== 'dev' ? `${CDN_URL}locales/${hash}/` : `/locales/`;

i18next
  // Enables the i18next backend
  .use(Backend)
  // Enable automatic language detection
  .use(LanguageDetector)
  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    backend: { loadPath: `${backendPath}{{lng}}.json` },
    contextSeparator: '____',
    debug: process.env.NODE_ENV === 'development',
    detection: {
      lookupQuerystring: 'lng',
      order: ['querystring', 'localStorage', 'navigator'],
    },
    fallbackLng: DefaultLanguage,
    keySeparator: false,
    load: 'currentOnly',
    nsSeparator: false,
    pluralSeparator: '____',
    react: {
      // TODO: i18next-parser doen't keep basic html nodes
      transSupportBasicHtmlNodes: false,
      // transKeepBasicHtmlNodesFor: ['br', 'em', 'p', 'strong'],
      transKeepBasicHtmlNodesFor: [],
    },
  });

export default i18next;
