import { Observer, observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import type { ITemplate, Template } from '@feathr/blackbox';
import { CampaignClass, RecipientType, TemplateClass } from '@feathr/blackbox';
import { Button, Label, Time, toast } from '@feathr/components';
import type { Bee, IEntityContentJson } from '@feathr/extender/components/BeeEditor';
import { EBeeEditorAction } from '@feathr/extender/components/BeeEditor';
import TemplateSelectModal from '@feathr/extender/components/TemplateSelectModal';
import { useStore } from '@feathr/extender/state';
import { TimeFormat, useReactionEffect, useRedirect } from '@feathr/hooks';

import BeeTemplateEditor, { BeeEditorProvider, useBeeEditor } from './BeeTemplateEditor';

import * as styles from './TemplateEditor.css';

export interface ITemplateEditorProps {
  actions?: ReactNode;
  className?: string;
  disableSave?: boolean;
  disableSaveMessage?: ReactNode;
  onTemplateSelect?: (template: Template) => void;
  isChangeable?: boolean;
  template: Template;
  recipientType?: RecipientType;
  isPartnerMessageCampaign?: boolean;
  isReadOnly?: boolean;
}

enum TemplateEditorState {
  ready = 'ready',
  saving = 'saving',
  sending = 'sending',
  previewing = 'previewing',
  addingDynamicContent = 'addingDynamicContent',
  managingForm = 'managingForm',
  resolvingSave = 'resolvingSave',
  resolvingSend = 'resolvingSend',
  resolvingPreview = 'resolvingPreview',
  showingPreview = 'showingPreview',
  showingSend = 'showingSend',
  error = 'error',
}

function TemplateEditor({
  actions,
  className,
  disableSave,
  disableSaveMessage,
  onTemplateSelect,
  template,
  isChangeable = false,
  recipientType,
  isPartnerMessageCampaign = false,
  isReadOnly = false,
}: Readonly<ITemplateEditorProps>): JSX.Element {
  return (
    <BeeEditorProvider
      options={{
        shouldParseMergeTags: isPartnerMessageCampaign,
      }}
      template={template}
    >
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          const { t } = useTranslation();
          const { Campaigns } = useStore();
          const { campaignId } = useParams<{ campaignId: string }>();
          const location = useLocation();
          const [redirect] = useRedirect();
          const params = new URLSearchParams(location.search);
          const redirectParam = params.get('redirect');
          const rootRef = useRef<HTMLDivElement>(null);
          const [beePlugin, setBeePlugin] = useState<Bee | null>(null);

          const { editorState, dispatchEditorAction } = useBeeEditor();

          const templateContent = template.get('content');

          const unsavedContent = localStorage.getItem(template.id);

          useReactionEffect(
            () => unsavedContent !== null,
            () => {
              async function patchUnsavedChanges(): Promise<void> {
                const parsedContent = JSON.parse(unsavedContent!);
                template.set({ content: parsedContent });
                const response = await template.patchDirty();
                if (response.isErrored) {
                  toast(t('There was an error updating your unsaved changes.'), {
                    type: ToastType.ERROR,
                  });
                  return;
                }
                toast(t('Your unsaved changes were updated.'), {
                  type: ToastType.INFO,
                });
                localStorage.removeItem(template.id);
              }

              patchUnsavedChanges();
            },
            { once: true },
          );

          if (!recipientType) {
            const { PinpointEmail, SmartPinpointEmail, AutoPinpointEmail, PinpointPartnerMessage } =
              CampaignClass;
            const campaignClass = campaignId ? Campaigns.get(campaignId).get('_cls') : undefined;
            if (
              campaignClass &&
              [PinpointEmail, SmartPinpointEmail, AutoPinpointEmail].includes(campaignClass)
            ) {
              recipientType = RecipientType.Person;
            } else if (campaignClass === PinpointPartnerMessage) {
              recipientType = RecipientType.Partner;
            }
          }

          const toastError = useCallback(
            (err?: string) => {
              localStorage.setItem(template.id, JSON.stringify(templateContent));
              toast(
                t('Something went wrong, please try again. {{- error}}', { error: err ?? '' }),
                {
                  type: 'error',
                },
              );
            },
            [t, template, templateContent],
          );

          const handleChangeTemplate = useCallback(
            (newTemplate: ITemplate) => {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              const { content, dynamic_content, name, metatags, subject, thumbnail_url } =
                newTemplate;
              template.set({
                content,
                dynamic_content,
                name,
                metatags,
                subject,
                thumbnail_url,
                _cls: TemplateClass.PinpointEmail,
              });

              if (beePlugin) {
                const templateJSON: IEntityContentJson = {
                  page: JSON.parse(template.get('content').json).page as IEntityContentJson['page'],
                };
                beePlugin.load(templateJSON);
              }

              if (onTemplateSelect) {
                onTemplateSelect(template);
              }
            },
            [onTemplateSelect, template, beePlugin],
          );

          const handleToggleFullscreen = useCallback(() => {
            if (rootRef.current) {
              const el = rootRef.current;
              if (el.requestFullscreen && !document.fullscreenElement) {
                el.requestFullscreen();
              } else if (document.exitFullscreen && document.fullscreenElement) {
                document.exitFullscreen();
              } else {
                toastError(
                  t("Your browser doesn't support this feature. Try it out in Chrome or Firefox!"),
                );
              }
            }
          }, [t, toastError]);

          function handleToggleSendMode(): void {
            dispatchEditorAction({ type: EBeeEditorAction.send });
            /*
             * TODO: refactor toolbar so we don't need to define this
             * function here, and remove the local reference to the
             * BeePlugin
             */
            beePlugin!.send();
          }

          function handleTogglePreviewMode(): void {
            if (editorState.state === TemplateEditorState.ready) {
              dispatchEditorAction({ type: EBeeEditorAction.preview });
              beePlugin!.save();
            } else if (editorState.state === TemplateEditorState.showingPreview) {
              dispatchEditorAction({ type: EBeeEditorAction.resolve });
            }
          }

          function isBannerTemplate(template: Template): boolean {
            return template.isBanner && !!template.get('bannersnack_enabled');
          }

          async function handleSave(): Promise<void> {
            const response = await template.patchDirty();

            if (response.isErrored) {
              toast(t('Something went wrong. Please try again.'), { type: ToastType.ERROR });
              return;
            }

            if (!isBannerTemplate(template)) {
              toast(
                t(
                  'Changes saved. Domains linked in this email were added to your domain allow list.',
                ),
                {
                  type: ToastType.SUCCESS,
                },
              );
            } else {
              toast(t('Changes saved.'), { type: ToastType.SUCCESS });
            }
          }

          // need to test this
          async function handleRedirect(): Promise<void> {
            await handleSave();
            redirect();
          }

          /*
           * TODO: refactor this toolbar so we don't need to have
           * a stateful reference to the BeePlugin at this level
           */
          const toolbar: ReactNode = (
            <>
              {actions}
              {isChangeable && (
                <TemplateSelectModal
                  label={t('Change template')}
                  onSelect={handleChangeTemplate}
                  templateClass={TemplateClass.PinpointEmail}
                />
              )}
              <Button id={'toggleFullscreen'} onClick={handleToggleFullscreen}>
                {t('Full screen')}
              </Button>
              {[TemplateClass.ReferralEmail, TemplateClass.PinpointEmail].includes(
                template.get('_cls'),
              ) && (
                <Button
                  disabled={!beePlugin || editorState.state !== TemplateEditorState.ready}
                  isLoading={editorState.state === TemplateEditorState.sending}
                  name={'send_preview'}
                  onClick={handleToggleSendMode}
                >
                  {t('Send preview')}
                </Button>
              )}
              <Button
                disabled={
                  !beePlugin ||
                  ![TemplateEditorState.ready, TemplateEditorState.showingPreview].includes(
                    editorState.state,
                  )
                }
                isLoading={editorState.state === TemplateEditorState.previewing}
                name={'show_preview'}
                onClick={handleTogglePreviewMode}
              >
                {editorState.state === TemplateEditorState.showingPreview
                  ? t('Back to editor')
                  : t('Show preview')}
              </Button>
              <Button
                disabled={
                  !beePlugin ||
                  disableSave ||
                  !template.isDirty ||
                  ![TemplateEditorState.ready, TemplateEditorState.showingPreview].includes(
                    editorState.state,
                  )
                }
                isLoading={editorState.state === TemplateEditorState.saving}
                name={'save_design'}
                onClick={handleSave}
                tooltip={disableSave ? disableSaveMessage : undefined}
                type={'primary'}
              >
                {t('Save design')}
              </Button>
              <div className={styles.lastSaved}>
                <Label>{t('Last saved:')}</Label>
                <Time
                  format={TimeFormat.timeFromNow}
                  timestamp={template.get('date_last_modified')}
                />
              </div>
              {redirectParam && (
                <Fragment key={'redirect'}>
                  <div className={styles.divider} />
                  <Button
                    disabled={editorState.state !== TemplateEditorState.ready}
                    isLoading={editorState.state === TemplateEditorState.saving}
                    name={'return'}
                    onClick={handleRedirect}
                    type={'primary'}
                  >
                    {recipientType === RecipientType.Partner
                      ? t('Return to message')
                      : t('Return to campaign')}
                  </Button>
                </Fragment>
              )}
            </>
          );

          return (
            <BeeTemplateEditor
              className={className}
              isReadOnly={isReadOnly}
              setBeePlugin={setBeePlugin}
              template={template}
              toolbar={toolbar}
              wrapperRef={rootRef}
            />
          );
        }}
      </Observer>
    </BeeEditorProvider>
  );
}

export default observer(TemplateEditor);
