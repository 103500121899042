import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ICampaignSegment } from '@feathr/blackbox';
import { CampaignClass, PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { FormSummaryItem } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import type { ISummaryProps } from './CampaignSummary.types';

const AudienceSummary = observer(({ campaign }: ISummaryProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { Segments } = useStore();
  const isAutoSend = campaign.get('_cls') === CampaignClass.AutoPinpointEmail;

  if (!(campaign instanceof PinpointEmailBaseCampaign)) {
    return null;
  }

  const campaignSegments: ICampaignSegment[] = isAutoSend
    ? campaign.get('segments', []).filter((s) => !s.included)
    : campaign.get('segments', [] as ICampaignSegment[]);
  const segments = campaignSegments
    .filter((campaignSegment) => campaignSegment.id !== undefined)
    .map((campaignSegment) => Segments.get(campaignSegment.id));

  function getLabel(index: number): JSX.Element {
    return (
      <>
        {segments[index].get('name')}&nbsp; | {numeral(segments[index].emails || 0).format('0,0')}{' '}
        {t('Email Addresses')} {!campaignSegments[index].included && '(' + t('excluded') + ')'}
      </>
    );
  }

  if (campaign.get('s3_key')) {
    return <FormSummaryItem label={t('Audience')} value={campaign.get('filename')} />;
  } else {
    return (
      <FormSummaryItem
        label={t('Audience')}
        value={
          segments.length === 1 ? (
            <span>{getLabel(0)}</span>
          ) : (
            <ol>
              {segments.map((segment, i) => (
                <li key={segment.get('name')}>{getLabel(i)}</li>
              ))}
            </ol>
          )
        }
      />
    );
  }
});

AudienceSummary.displayName = 'AudienceSummary';

export default AudienceSummary;
