import type { IObservableArray } from 'mobx';
import type { JSX, ReactElement } from 'react';
import React from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import type { DailyStat, IFlavors, IHosts } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { TableStatsCard } from '@feathr/components';
import { cssVar } from '@feathr/hooks';

import { FunnelChart } from '../FunnelChart';
import type { IData } from '../FunnelChart/FunnelChart';

import * as styles from './ActivityBreakdownCard.css';

interface ICardProps {
  conversions: number;
  dailyStats: IObservableArray<DailyStat>;
}

interface IHostDatum {
  Clicks: number;
  Impressions: number;
}

interface IHostData {
  [key: string]: IHostDatum;
}

type TFlavor = keyof IFlavors;

const domainOptions = [
  ['google', 'Google'],
  ['doubleclick', 'Google'],
  ['yahoo', 'Yahoo'],
  ['adnxs', 'AdNexus Media'],
  ['atwola', 'AOL Network'],
  ['aol', 'AOL Network'],
  ['adtechus', 'AOL Network'],
  ['zynga', 'Zynga Game Network'],
  ['intermarket', 'Intermarket Network'],
];

function maskDomain(domain: string): string {
  const match = domainOptions.find((option) => domain.includes(option[0]));
  if (match) {
    return match[1];
  }
  return domain;
}

interface ITickProps {
  payload: { value: string };
  x: number;
  y: number;
}

function DomainTick(props: Readonly<ITickProps>): ReactElement<SVGElement> {
  const { x, y, payload } = props;
  let label = payload.value;
  if (label.length >= 20) {
    label = `${label.slice(0, 17)}...`;
  }
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        dy={2.5}
        fill={cssVar('--color-text-body')}
        fontSize={10}
        textAnchor={'end'}
        width={100}
        x={0}
        y={0}
      >
        {label}
      </text>
    </g>
  );
}

function getFlavorValue(flavors: IFlavors, ...keys: TFlavor[]): number {
  return keys.reduce((acc, key) => {
    if (flavors && flavors[key]) {
      return acc + (flavors[key] || 0);
    }
    return acc;
  }, 0);
}

function sumHostActivity(statHosts: IHosts, flavor: keyof IHosts, hosts: IHostData): void {
  const flavorHosts = statHosts[flavor];
  if (flavorHosts) {
    Object.entries(flavorHosts).forEach(([key, value]) => {
      if (key === 'daily') {
        return;
      }
      const domain = maskDomain(key);
      if (flavor.includes('view')) {
        if (hosts[domain]) {
          hosts[domain].Impressions += value;
        } else {
          hosts[domain] = {
            Impressions: value,
            Clicks: 0,
          };
        }
      } else if (flavor.includes('click')) {
        if (hosts[domain]) {
          hosts[domain].Clicks += value;
        } else {
          hosts[domain] = {
            Impressions: 0,
            Clicks: value,
          };
        }
      }
    });
  }
}

export function ActivityBreakdownCard({ dailyStats, conversions }: ICardProps): JSX.Element {
  const hosts: IHostData = {};
  let views = 0;
  let clicks = 0;
  dailyStats.forEach((stat) => {
    const flavors = stat.get('flavors');
    if (flavors) {
      views += getFlavorValue(flavors, 'ad_view', 'page_view', 'email_view');
      clicks += getFlavorValue(flavors, 'ad_click', 'page_link_click', 'email_link_click');
    }
    const statHosts = stat.get('hosts');
    if (statHosts) {
      (
        [
          'ad_view',
          'page_view',
          'email_view',
          'ad_click',
          'page_link_click',
          'email_link_click',
        ] as Array<keyof IHosts>
      ).forEach((flavor) => {
        sumHostActivity(statHosts, flavor, hosts);
      });
    }
  });

  const chartData = Object.entries(hosts)
    .sort((a, b) => b[1].Impressions - a[1].Impressions)
    .slice(0, 10)
    .map(([domain, { Impressions, Clicks }]) => {
      return {
        domain: domain.split('_').join('.'),
        Impressions,
        Clicks,
      };
    });

  const funnelChartData = ((): IData[] => {
    const funnelChartData: Array<[number, string]> = [
      [views, 'Views'],
      [clicks, 'Clicks'],
      [conversions, 'Conversions'],
    ];
    return funnelChartData
      .filter(([value]) => value > 0)
      .map(([value, label]) => ({ value, label }));
  })();

  return (
    <TableStatsCard contentType={'bordered'} title={reportModuleLabels.includeActivityBreakdown}>
      <div className={styles.wrapper}>
        <ResponsiveContainer aspect={1.25} width={'50%'}>
          <BarChart data={chartData} height={400} layout={'vertical'}>
            <CartesianGrid strokeDasharray={'3 3'} />
            <YAxis dataKey={'domain'} tick={DomainTick} type={'category'} width={100} />
            <XAxis type={'number'} />
            <Tooltip />
            <Bar dataKey={'Impressions'} fill={cssVar('--color-primary-3')} stackId={'a'} />
            <Bar dataKey={'Clicks'} fill={cssVar('--color-brand-orange')} stackId={'a'} />
          </BarChart>
        </ResponsiveContainer>
        <ResponsiveContainer aspect={1.25} width={'50%'}>
          <FunnelChart data={funnelChartData} height={400} width={500} />
        </ResponsiveContainer>
      </div>
    </TableStatsCard>
  );
}
