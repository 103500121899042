import classNames from 'classnames';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ValueType } from 'react-select';

import type { Event } from '@feathr/blackbox';
import type { IFormElementProps, ISelectOption } from '@feathr/components';
import { AsyncSelect } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import type { Attributes, IListParams } from '@feathr/rachis';

import type { IEventFilters } from '../EventSwitcher/EventSwitcher';

import * as styles from './EventSelect.css';

interface IProps extends IFormElementProps {
  className?: string;
  id?: string;
  onChange: (newValue: ISelectOption) => void;
  onClear: () => void;
  placeholder?: string;
  value: ValueType<ISelectOption>;
}

function EventSelect({
  className,
  id,
  helpText,
  label,
  onChange,
  onClear,
  placeholder,
  value,
}: IProps): JSX.Element {
  const { Events } = useStore();
  const { t } = useTranslation();

  async function loadOptions(inputValue: string): Promise<ISelectOption[]> {
    const listParams: IListParams<Attributes<Event>> = {
      filters: {
        is_archived__ne: true,
      } as IEventFilters,
      only: ['id', 'logo', 'name'],
      ordering: ['name'],
      pagination: {
        page: 0,
        page_size: 10,
      },
    };
    if (inputValue.length) {
      listParams.filters!.name__icontains = inputValue;
    }
    const data = Events.list(listParams);
    await when(() => !data.isPending);
    return data.models.map((event: Event) => ({
      id: event.id,
      name: event.name,
    }));
  }

  function handleChange(eventSelected: ISelectOption): void {
    onChange(eventSelected);
  }

  function handleOptionsMessage({ inputValue }): string {
    return inputValue.length ? t('No results...') : t('Start typing the name of a project...');
  }

  return (
    <AsyncSelect<ISelectOption>
      defaultOptions={true}
      helpText={helpText}
      id={id}
      isClearable={true}
      label={label}
      loadOptions={loadOptions}
      name={'event_select'}
      noOptionsMessage={handleOptionsMessage}
      onClear={onClear}
      onSelectSingle={handleChange}
      placeholder={placeholder}
      value={value}
      wrapperClassName={classNames(styles.root, className)}
    />
  );
}

export default observer(EventSelect);
