import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import type { RowRenderProps } from 'react-table';

import type { Breadcrumb } from '@feathr/blackbox';
import { Chip } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

interface IProps extends RowRenderProps {
  original: Breadcrumb;
}

function EventChip({ original }: IProps): JSX.Element | null {
  const eventId = original.get('e_id');
  const { Events } = useStore();
  const event = eventId ? Events.get(eventId) : undefined;

  if (!event || event.isPending) {
    return null;
  }

  return <Chip>{event.name}</Chip>;
}

export default observer(EventChip);
