import { observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router';

import type { LandingPageCampaign } from '@feathr/blackbox';
import { useStore } from '@feathr/extender/state';

import LandingPageCampaignPage from './LandingPageCampaignPage';
import LandingPageTemplateEdit from './LandingPageTemplateEdit';

function TemplateLandingPageCampaignPage() {
  const { campaignId } = useParams<{ eventId: string; campaignId: string }>();
  const { Campaigns } = useStore();
  const campaign = Campaigns.get(campaignId) as LandingPageCampaign;

  return (
    <LandingPageCampaignPage title={'Page'}>
      <LandingPageTemplateEdit campaign={campaign} />
    </LandingPageCampaignPage>
  );
}

export default observer(TemplateLandingPageCampaignPage);
