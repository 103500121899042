import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Conversion } from '@feathr/blackbox';
import { Card, Chip, Form, Skeleton, Time, Tooltip, Value } from '@feathr/components';
import CampaignChip from '@feathr/extender/components/CampaignChip';
import FlightChip from '@feathr/extender/components/FlightChip';
import PartnerChip from '@feathr/extender/components/PartnerChip';
import SegmentChip from '@feathr/extender/components/SegmentChip';
import { useAccount, useStore } from '@feathr/extender/state';
import { TimeFormat } from '@feathr/hooks';

import CreativeCard from './CreativeCard';

import * as styles from './ConversionDetails.css';

interface IProps {
  conversion: Conversion;
}

function ConversionDetails({ conversion }: IProps): JSX.Element {
  const isLoading = conversion.isPending;
  const account = useAccount();
  const { Persons } = useStore();
  const { t } = useTranslation();

  const attributionModel = account.get('attribution_model');

  const person = !isLoading ? Persons.get(conversion.get('person')) : undefined;
  const campaigns = conversion.get('campaigns');
  const flights = conversion.get('flights');
  const attributedCampaigns = !isLoading
    ? conversion.get('attribution')[attributionModel]?.map((item) => item.campaign)
    : [];
  const goalSegments = conversion.get('goal_segments');
  const sourceTargetSegments = conversion.get('source_target_segments');
  const { p_id: partnerIds = [], crv_id: creativeIds = [] } = conversion.get('objects_touched', {});

  return (
    <Card>
      <Form label={t('Actvity details')}>
        <Value
          label={t('Date')}
          value={
            isLoading ? (
              <Skeleton width={60} />
            ) : (
              <Time format={TimeFormat.shortDate} timestamp={conversion.get('d_c')} />
            )
          }
        />
        <Value
          label={t('Person')}
          value={isLoading || !person || person.isPending ? <Skeleton width={100} /> : person.name}
        />
        {!!flights && flights.length > 0 && (
          <Value
            className={styles.chips}
            label={
              <>
                {t('Goal Flights')}&nbsp;
                <Tooltip title={t('Flights with a goal that matches this conversion.')}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </>
            }
            value={
              isLoading ? (
                <Chip isLoading={true} />
              ) : (
                flights.map((id) => <FlightChip id={id} key={id} />)
              )
            }
          />
        )}
        <Value
          className={styles.chips}
          label={
            <>
              {t('Goal Campaigns')}&nbsp;
              <Tooltip title={t('Campaigns with a goal that matches this conversion.')}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </>
          }
          value={
            isLoading ? (
              <Chip isLoading={true} />
            ) : (
              campaigns.map((id) => <CampaignChip id={id} key={id} />)
            )
          }
        />
        <Value
          className={styles.chips}
          label={
            <>
              {t('Attributed Campaigns')}&nbsp;
              <Tooltip
                title={t(
                  'Campaigns that are considered to have contributed to this conversion according to your default attribution model.',
                )}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </>
          }
          value={
            isLoading ? (
              <Chip isLoading={true} />
            ) : (
              attributedCampaigns?.map((id) => <CampaignChip id={id} key={id} />)
            )
          }
        />
        {(isLoading || goalSegments.length > 0) && (
          <Value
            className={styles.chips}
            label={t('Goals')}
            value={
              isLoading ? (
                <Chip isLoading={true} />
              ) : (
                goalSegments.map((id) => <SegmentChip id={id} key={id} />)
              )
            }
          />
        )}
        {(isLoading || sourceTargetSegments.length > 0) && (
          <Value
            className={styles.chips}
            label={t('Source Target Groups')}
            value={
              isLoading ? (
                <Chip isLoading={true} />
              ) : (
                sourceTargetSegments.map((id) => <SegmentChip id={id} key={id} />)
              )
            }
          />
        )}
        {creativeIds.length > 0 && (
          <Value
            className={styles.cards}
            label={t('Contributing Creatives')}
            value={
              isLoading ? (
                <CreativeCard isLoading={true} />
              ) : (
                creativeIds.map((id) => <CreativeCard id={id} key={id} />)
              )
            }
          />
        )}
        {partnerIds.length > 0 && (
          <Value
            className={styles.chips}
            label={t('Contributing Partners')}
            value={
              isLoading ? (
                <Chip isLoading={true} />
              ) : (
                partnerIds.map((id) => <PartnerChip id={id} key={id} />)
              )
            }
          />
        )}
      </Form>
    </Card>
  );
}

export default observer(ConversionDetails);
