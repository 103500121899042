import type { TFunction } from 'i18next';
import capitalize from 'lodash.capitalize';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import type { Form } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Chip, TableColumnHeader, Time } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { formStateColorMap } from '@feathr/extender/styles/form';
import { TimeFormat } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function formColumns(t: TFunction): Array<IColumn<Form>> {
  return [
    {
      id: 'name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Name'),
      }),
      minWidth: 300,
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const localUrl = useLocalUrl();
              return <Link to={localUrl(original.getItemUrl('/edit'))}>{original.name}</Link>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'status',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Status'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              const state = original.get('state');
              return <Chip theme={formStateColorMap[state]}>{capitalize(state)}</Chip>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'form_views',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Form Views'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return <>{original.get('total_stats')?.num_views ?? 0}</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'submissions',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Submissions'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return <>{original.get('total_stats')?.num_submissions ?? 0}</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'submission_rate',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Submission Rate'),
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return <>{original.get('total_stats')?.submission_rate ?? 0}</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'last_updated',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Last Updated'),
      }),
      minWidth: 120,
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              return (
                <Time
                  format={TimeFormat.shortDateTime}
                  formatLocal={true}
                  timestamp={original.get('date_last_modified')}
                />
              );
            }}
          </Observer>
        );
      },
    },
  ];
}

export default formColumns;
