import { makeObservable, observable, reaction } from 'mobx';
import { useEffect, useState } from 'react';

import type { TFlags, TFlagsRecord } from '@feathr/blackbox';
import { EFlags } from '@feathr/blackbox';

import { useAccount, useUser } from '.';

export class Flags {
  @observable protected flags = {} as Partial<TFlagsRecord>;

  constructor(flags: Partial<TFlagsRecord>) {
    makeObservable(this);
    this.set(flags);

    Object.keys(EFlags)
      // Filter out numeric keys, or we end with duplicates.
      .filter((key) => typeof key !== 'number')
      .forEach((key) => {
        Object.defineProperty(this, key, {
          get: (): boolean => {
            return this.flags[key as TFlags] || false;
          },
        });
      });
  }

  public set(flags: Partial<TFlagsRecord>): void {
    this.flags = flags;
  }
}

function useFlags(): Flags & TFlagsRecord {
  const account = useAccount();
  const user = useUser();

  const [flags] = useState<Flags & TFlagsRecord>(
    new Flags({
      ...(account ? account.get('flags') : {}),
      ...(user ? user.flags : {}),
    }) as Flags & TFlagsRecord,
  );

  useEffect(
    () =>
      reaction(
        () => [account?.isPending, user?.isPending],
        () => {
          if (account && !account.isPending && user && !user.isPending) {
            flags.set({ ...account.get('flags', {} as TFlagsRecord), ...user.flags });
          }
        },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return flags;
}

export default useFlags;
