import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStore } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function CampaignLabel({ className, id }: IProps) {
  const { Campaigns } = useStore();
  const campaign = Campaigns.get(id);
  const name = campaign.isPending ? 'placeholder' : campaign.name;
  return <div className={classNames({ [skeleton]: campaign.isPending }, className)}>{name}</div>;
}

export default observer(CampaignLabel);
