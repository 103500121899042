import { useCallback, useEffect, useState } from 'react';

interface IUsePreloadImageResult {
  imgSrc: string;
  isLoading: boolean;
  reload: () => void;
}

// Since hooks cannot be conditional, we need to handle undefined srcs
function usePreloadImage(src: string | undefined): IUsePreloadImageResult {
  const [isLoading, setIsLoading] = useState(true);
  const [cacheBustedSrc, setCacheBustedSrc] = useState<string>('');
  // Key to trigger reloads (incremented on each reload)
  const [reloadKey, setReloadKey] = useState(0);

  const reload = useCallback(() => {
    // Increment the reload key to trigger a new cachebuster
    setReloadKey((prevKey) => prevKey + 1);
  }, []);

  useEffect(
    () => {
      // Prevent running effect if no src is provided
      if (!src) {
        return;
      }

      // Function to generate the cachebuster URL based on current date and time
      const getCacheBustedUrl = (url: string): string => {
        // Use the current timestamp as the cachebuster
        const timestamp = Date.now();
        return `${url}?cb=${timestamp}`;
      };

      const img = new Image();
      const bustedSrc = getCacheBustedUrl(src);
      // Update the image source with the cachebuster
      setCacheBustedSrc(bustedSrc);

      setIsLoading(true);

      // Set image source and stop loading when the image is fully loaded
      img.src = bustedSrc;
      img.onload = (): void => {
        setIsLoading(false);
      };

      img.onerror = (): void => {
        // eslint-disable-next-line no-console
        console.error(`Failed to load image: ${bustedSrc}`);
        // Stop loading in case of error
        setIsLoading(false);
      };
    },
    // Re-run effect whenever the source or reloadKey changes
    [src, reloadKey],
  );

  return { isLoading, imgSrc: cacheBustedSrc, reload };
}

export default usePreloadImage;
