import { createContext, useContext } from 'react';

import {
  AggregatedStats,
  Breadcrumbs,
  Campaigns,
  Conversions,
  Creatives,
  DailyStats,
  Events,
  FacebookCampaignInsights,
  Flights,
  Goals,
  Partners,
  Persons,
  Redirects,
  Segments,
  Targetings,
  Templates,
} from '@feathr/blackbox';

export class ReportStore {
  public AggregatedStats = new AggregatedStats([]);

  public Breadcrumbs = new Breadcrumbs([]);

  public Campaigns = new Campaigns([]);

  public Conversions = new Conversions([]);

  public Creatives = new Creatives([]);

  public Events = new Events([]);

  public FacebookCampaignInsights = new FacebookCampaignInsights([]);

  public Flights = new Flights([]);

  public Goals = new Goals([]);

  public Partners = new Partners([]);

  public Persons = new Persons([]);

  public Redirects = new Redirects([]);

  public Segments = new Segments([]);

  public Stats = new DailyStats([]);

  public Targetings = new Targetings([]);

  public Templates = new Templates([]);
}

export const ReportStoreContext = createContext<ReportStore | null>(null);

export function createReportStore(): ReportStore {
  return new ReportStore();
}

function useReportStore(): ReportStore {
  const context = useContext(ReportStoreContext);
  if (!context) {
    throw new Error('useReportStore must be used within ReportStoreProvider');
  }
  return context;
}

export default useReportStore;
