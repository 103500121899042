import { observable } from 'mobx';
import type { JSX, ReactNode } from 'react';
import React, { createContext, useContext, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { ITemplate, Template } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import { toast } from '@feathr/components';
import type {
  Bee,
  BeePluginError,
  IBeeEditorAction,
  IBeeEditorConfig,
  IPluginForm,
  TFormResolveHandler,
  TResolveHandler,
} from '@feathr/extender/components/BeeEditor';
import {
  EBeeEditorAction,
  formCustomFieldsFilters,
  getMergefieldCustomFieldsFilters,
} from '@feathr/extender/components/BeeEditor';
import { useAccount, useStore } from '@feathr/extender/state';

export enum TemplateEditorState {
  ready = 'ready',
  saving = 'saving',
  sending = 'sending',
  previewing = 'previewing',
  addingDynamicContent = 'addingDynamicContent',
  managingForm = 'managingForm',
  resolvingSave = 'resolvingSave',
  resolvingSend = 'resolvingSend',
  resolvingPreview = 'resolvingPreview',
  showingPreview = 'showingPreview',
  showingSend = 'showingSend',
  error = 'error',
}

interface ITemplateEditorState {
  state: TemplateEditorState;
  json?: string;
  form?: IPluginForm['structure'];
  error?: BeePluginError;
  resolve?: TResolveHandler;
  reject?: (error?: Error) => void;
}

const initialState: ITemplateEditorState = {
  state: TemplateEditorState.ready,
  json: '',
  error: undefined,
};

function reducer(state: ITemplateEditorState, action: IBeeEditorAction): ITemplateEditorState {
  const resolveMap: Partial<Record<TemplateEditorState, TemplateEditorState>> = {
    [TemplateEditorState.error]: TemplateEditorState.ready,
    [TemplateEditorState.saving]: TemplateEditorState.resolvingSave,
    [TemplateEditorState.sending]: TemplateEditorState.resolvingSend,
    [TemplateEditorState.previewing]: TemplateEditorState.resolvingPreview,
    [TemplateEditorState.resolvingSave]: TemplateEditorState.ready,
    [TemplateEditorState.resolvingSend]: TemplateEditorState.showingSend,
    [TemplateEditorState.resolvingPreview]: TemplateEditorState.showingPreview,
    [TemplateEditorState.showingSend]: TemplateEditorState.ready,
    [TemplateEditorState.showingPreview]: TemplateEditorState.ready,
    [TemplateEditorState.addingDynamicContent]: TemplateEditorState.ready,
    [TemplateEditorState.managingForm]: TemplateEditorState.ready,
  };

  switch (action.type) {
    case EBeeEditorAction.save:
      return { ...state, state: TemplateEditorState.saving };

    case EBeeEditorAction.send:
      return { ...state, state: TemplateEditorState.sending };

    case EBeeEditorAction.preview:
      return { ...state, state: TemplateEditorState.previewing };

    case EBeeEditorAction.error:
      return { ...state, error: action.error, state: TemplateEditorState.error };

    case EBeeEditorAction.addDynamicContent:
      return {
        ...state,
        state: TemplateEditorState.addingDynamicContent,
        resolve: action.resolve,
        reject: action.reject,
      };

    case EBeeEditorAction.manageForm:
      return {
        ...state,
        form: observable(action.form!),
        state: TemplateEditorState.managingForm,
        resolve: action.resolve,
        reject: action.reject,
      };

    case EBeeEditorAction.resolve:
      return {
        ...state,
        state: resolveMap[state.state] as TemplateEditorState,
      };

    default:
      return state;
  }
}

interface IOptions {
  setBeePlugin?: (plugin: Bee) => void;
  // Derived from isPartnerMessageCampaign
  shouldParseMergeTags?: boolean;
}

interface IBeeEditorProviderProps {
  readonly children: ReactNode;
  options?: IOptions;
  template: Template;
}

interface IBeeEditorContext {
  config: Omit<IBeeEditorConfig, 'mergefields'>;
  editorState: ITemplateEditorState;
  dispatchEditorAction: (action: IBeeEditorAction) => void;
}

const BeeEditorContext = createContext<IBeeEditorContext | undefined>(undefined);

/*
 * See Kent C. Dodds' article on effective context usage:
 * https://kentcdodds.com/blog/how-to-use-react-context-effectively
 */
function BeeEditorProvider({
  children,
  options = {},
  template,
}: Readonly<IBeeEditorProviderProps>): JSX.Element {
  const { CustomFields, Fonts } = useStore();
  const account = useAccount();
  const { t } = useTranslation();

  const [editorState, dispatchEditorAction] = useReducer(reducer, initialState);

  const memoizedValue = useMemo(() => {
    const { setBeePlugin, shouldParseMergeTags = false } = options;

    async function handleManageForm(
      resolve: TFormResolveHandler,
      reject: (e?: Error) => void,
      form: IPluginForm['structure'],
    ): Promise<void> {
      await dispatchEditorAction({
        type: EBeeEditorAction.manageForm,
        resolve,
        reject,
        form,
      });
    }

    function onError(error: BeePluginError): void {
      dispatchEditorAction({ type: EBeeEditorAction.error, error });
    }

    function onSend(): void {
      dispatchEditorAction({ type: EBeeEditorAction.resolve, json: undefined });
    }

    function isBannerTemplate(template: Template): boolean {
      return template.isBanner && !!template.get('bannersnack_enabled');
    }

    async function handleSave(): Promise<void> {
      const response = await template.patchDirty();

      if (response.isErrored) {
        toast(t('Something went wrong. Please try again.'), { type: ToastType.ERROR });
        return;
      }

      if (!isBannerTemplate(template)) {
        toast(
          t('Changes saved. Domains linked in this email were added to your domain allow list.'),
          {
            type: ToastType.SUCCESS,
          },
        );
      } else {
        toast(t('Changes saved.'), { type: ToastType.SUCCESS });
      }
    }

    function onAutoSave(json: string): void {
      // Only auto-save if the template is not already being saved
      if (editorState.state === TemplateEditorState.saving) {
        return;
      }

      // Only auto-save if the template is dirty
      if (!template.isDirty) {
        return;
      }

      async function saveAndDispatch(): Promise<void> {
        dispatchEditorAction({ type: EBeeEditorAction.save, json });
        await handleSave();
        dispatchEditorAction({ type: EBeeEditorAction.resolve });
      }

      saveAndDispatch();
    }

    function onSave(json: string): void {
      dispatchEditorAction({ type: EBeeEditorAction.resolve, json });
    }

    function onChange(json: string): void {
      const templateContent: ITemplate['content'] = template.get('content');
      template.set({ content: { ...templateContent, json } });
    }

    const fonts = Fonts.list({ only: ['id', 'name'], ordering: ['id'] });

    const cls = template.get('_cls');
    const mergefieldCustomFields = CustomFields.list({
      filters: getMergefieldCustomFieldsFilters(template.get('_cls')),
      pagination: { page: 0, page_size: 1000 },
    });
    const formCustomFields = [
      TemplateClass.Page,
      TemplateClass.LandingPage,
      TemplateClass.ReferralPage,
    ].includes(cls)
      ? CustomFields.list({
          filters: formCustomFieldsFilters,
          pagination: { page: 0, page_size: 1000 },
        })
      : CustomFields.newListResponse();

    const config: Omit<IBeeEditorConfig, 'mergefields'> = {
      actions: {
        handleManageForm,
        onChange,
        onError,
        onAutoSave,
        onSave,
        onSend,
        dispatchEditorAction,
        setBeePlugin,
      },
      models: { account, fonts, formCustomFields, mergefieldCustomFields },
      shouldParseMergeTags,
      t,
    };
    return {
      config,
      editorState,
      dispatchEditorAction,
    };
  }, [account, CustomFields, dispatchEditorAction, editorState, Fonts, options, t, template]);

  return <BeeEditorContext.Provider value={memoizedValue}>{children}</BeeEditorContext.Provider>;
}

function useBeeEditor(): IBeeEditorContext {
  const context = useContext(BeeEditorContext);

  if (context === undefined) {
    throw new Error('useBeeEditor hook must be wrapped in an BeeEditorContext context provider');
  }
  return context;
}

export { BeeEditorProvider, useBeeEditor };
