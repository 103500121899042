import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormSummaryItem, Skeleton } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import type { ISummaryProps } from './CampaignSummary.types';

const GoalsSummary = observer(({ campaign, validationErrors }: ISummaryProps): JSX.Element => {
  const { t } = useTranslation();
  const { Goals, Segments } = useStore();
  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const filteredGoals = goals.models.filter((g) => !g.get('is_archived') && !!g.get('segment'));

  const valueGoals = (
    <>
      {t('Goals')}
      <ol>
        {goals.isPending && (
          <li>
            <Skeleton width={100} />
          </li>
        )}
        {filteredGoals.map((g) => (
          <li key={g.id}>
            {Segments.get(g.get('segment')!).get('name')} |&nbsp;
            {numeral(g.get('conv_value')).format('$0,0.00')}
          </li>
        ))}
      </ol>
    </>
  );

  const valueAdvanced = (
    <p>
      {t('Advanced |')}&nbsp;
      {filteredGoals.map((g) => (
        <span key={g.id}>{Segments.get(g.get('segment')!).get('name')}</span>
      ))}
    </p>
  );

  return (
    <>
      {campaign.isMonetization && (
        <FormSummaryItem
          errors={validationErrors?.monetization_value}
          label={t('Value')}
          value={'$ ' + numeral(campaign.get('monetization_value')).format('0,0')}
        />
      )}
      {goals.isPending ||
        (filteredGoals.length > 0 && (
          <FormSummaryItem
            errors={validationErrors?.goals}
            label={t('Conversion Tracking')}
            value={
              goals.isPending || filteredGoals.length > 0
                ? campaign.get('conversion_tracking_mode') === 'auto'
                  ? valueGoals
                  : valueAdvanced
                : t('(None)')
            }
          />
        ))}
    </>
  );
});

GoalsSummary.displayName = 'GoalsSummary';

export default GoalsSummary;
