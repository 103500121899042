import debounce from 'debounce-promise';
import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  GoogleAdsSmartCampaign,
  IGoogleAdsLocationSuggestion,
  Targetable,
  Targetables as TargetablesCollection,
  Targeting,
} from '@feathr/blackbox';
import { CampaignState, EIntegrationTypes, TargetableClass } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import {
  AsyncSelect,
  CardContent,
  CardHeader,
  CardV2 as Card,
  Form,
  toast,
} from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT, getIconForAction } from '@feathr/hooks';

import LocationTag from './LocationTag';

import * as styles from './LocationStep.css';

interface ILocationStepProps {
  readonly campaign: GoogleAdsSmartCampaign;
  readonly targetings: IObservableArray<Targeting>;
}

export function validateLocationStep(targetings: IObservableArray<Targeting>): string[] {
  const selectedLocations = targetings.filter(
    (t) => t.get('kind') === 'geo' && t.get('is_archived') !== true,
  );
  if (selectedLocations.length === 0) {
    return ['At least one location must be selected.'];
  }
  return [];
}

export function getSelectedTargetables(
  targetings: IObservableArray<Targeting>,
  Targetables: TargetablesCollection,
): Targetable[] {
  return targetings
    .filter((t) => t.get('kind') === 'geo' && !!t.get('target_data') && !t.get('is_archived'))
    .map((t) => Targetables.get(t.get('target_data')!))
    .filter((t) => t.isEphemeral);
}

function LocationStep({ campaign, targetings }: ILocationStepProps): JSX.Element {
  const { Targetings, Targetables } = useStore();
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState<boolean>(false);
  const [suggestedLocations, setSuggestedLocations] = useState<IGoogleAdsLocationSuggestion[]>([]);
  const { t } = useTranslation();
  const state = campaign.get('state', CampaignState.Draft);

  const debouncedLocationOptions = debounce(loadLocationOptions, DEFAULT_DEBOUNCE_WAIT);

  async function loadLocationOptions(inputValue: string): Promise<ISelectOption[]> {
    setIsLoadingSuggestions(true);
    try {
      const suggestedLocations = await campaign.getLocations(inputValue);
      setSuggestedLocations(suggestedLocations);
      setIsLoadingSuggestions(false);
      return suggestedLocations.map((location) => ({
        id: location.id,
        name: `${location.canonical_name} (${location.target_type})`,
      }));
    } catch (error) {
      toast(t('Something went wrong: {{- error}}', { error }), {
        type: ToastType.ERROR,
      });
      setIsLoadingSuggestions(false);
      return [];
    }
  }

  /*
   * Since locations must be saved immediately because they are required for the keywords step,
   * we must manually republish the campaign. Generally this happens on the save changes button
   * but we bypass this button and save immediately to prevent empty on the next step since
   * locations are required to suggest keywords.
   */
  async function republishLocation(): Promise<void> {
    // Must republish right away if the campaign is published
    if (state === CampaignState.Published) {
      await campaign.publish();
      toast(t('Location updated'), {
        type: ToastType.INFO,
      });
    }
  }

  async function handleSelectLocation(option: ISelectOption): Promise<void> {
    const selectedLocation = suggestedLocations.find((location) => location.id === option.id);
    if (!selectedLocation) {
      return;
    }

    try {
      const newTargetable: Targetable = Targetables.create({
        _cls: TargetableClass.google_ads_geo,
        name: selectedLocation.name,
        integrations: [EIntegrationTypes.GoogleAds],
        location_info: {
          geo_target_constant: selectedLocation.geo_target_constant,
        },
      });
      // Saving immediately for now as it needs to be saved for suggested keywords on the next step to work
      const targetableResponse = await Targetables.add(newTargetable);

      if (targetableResponse) {
        const newTargeting: Targeting = Targetings.create({
          name: selectedLocation.name,
          integrations: [EIntegrationTypes.GoogleAds],
          parent: campaign.get('id'),
          kind: 'geo',
          target_data: targetableResponse.id,
        });
        // Saving immediately for now as it needs to be saved for suggested keywords on the next step to work
        const targetingResponse = await Targetings.add(newTargeting);

        if (targetingResponse) {
          runInAction(() => {
            targetings.push(targetingResponse);
          });
        }
        // Must also republish since the location is immediately saved above
        republishLocation();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast(t('Location could not be saved: {{- error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
  }

  async function handleRemoveTargeting(targeting: Targeting): Promise<void> {
    if (!targeting.isEphemeral) {
      targeting.set({ is_archived: true });
      // Deleting immediately for now as suggested keywords on the next rely on the locations targetings/targetables
      Targetings.delete(targeting.id);
      // Must also republish since the location is immediately deleted
      republishLocation();
    } else {
      runInAction(() => {
        targetings.remove(targeting);
        Targetings.remove(targeting.id);
      });
    }
  }

  function handleNoOptionsMessage(): string {
    return t('No locations found.');
  }

  return (
    <div className={styles.root}>
      <Form label={t('Location')} width={'wide'}>
        <Card name={'advertise-in'}>
          <CardHeader description={t('Show your ad in the right places.')} title={t('Location')} />
          <CardContent addVerticalGap={true}>
            {targetings.length ? (
              <div data-name={'selectedLocations'}>
                {targetings
                  .filter((t) => !t.get('is_archived') && t.get('kind') === 'geo')
                  .map((targeting, index) => {
                    return (
                      <LocationTag
                        key={targeting.id || index}
                        onClick={handleRemoveTargeting}
                        suffix={getIconForAction('remove')}
                        targeting={targeting}
                      />
                    );
                  })}
              </div>
            ) : null}

            <AsyncSelect
              helpPlacement={'bottom'}
              helpText={t(
                'Your ad will be displayed to individuals in the specified locations, as well as to those who have shown interest in these areas.',
              )}
              isLoading={isLoadingSuggestions}
              isMulti={false}
              label={t('Add a location by zip code, city, state, or country')}
              loadOptions={debouncedLocationOptions}
              name={'location-suggest'}
              noOptionsMessage={handleNoOptionsMessage}
              onSelectSingle={handleSelectLocation}
              placeholder={t('Start typing a location...')}
              prefix={getIconForAction('search')}
              showDropdownIndicator={false}
              value={null}
            />
            {/* TODO: Add a link to the locations page */}
            <a href={'/'}>{t('Learn more about locations')}</a>
          </CardContent>
        </Card>
      </Form>
    </div>
  );
}

export default observer(LocationStep);
