import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import type { IEventBilling } from '@feathr/blackbox';
import { AlertV2 as Alert, EAlertV2Type as AlertType } from '@feathr/components';
import { useAccount, useLocalUrl, useStore } from '@feathr/extender/state';

interface IProps {
  className?: string;
}

function RouteAlert({ className }: Readonly<IProps>): JSX.Element | null {
  const { t } = useTranslation();
  const account = useAccount();
  const localUrl = useLocalUrl();

  const { Events } = useStore();
  const { eventId } = useParams<{ eventId: string }>();
  const match = useRouteMatch<{
    accountId: string;
    primary: string;
    secondary?: string;
  }>({
    path: '/:accountId/:primary/:secondary?',
  });

  if (match && match.params.primary === 'projects' && eventId) {
    const event = Events.get(eventId);
    const billing = event.get('billing', {} as IEventBilling);
    const showConfigureMediaBilling = !(
      !account ||
      location.pathname.includes('settings/billing/edit') ||
      event.isPending ||
      billing.billable_id
    );
    const showMediaBillingBadStanding = false;

    if (showConfigureMediaBilling) {
      return (
        <Alert
          className={className}
          description={
            <Trans t={t}>
              <a
                href={localUrl(event.getItemUrl('settings/billing/edit'))}
                rel={'noreferrer'}
                target={'_blank'}
              >
                Configure media billing
              </a>{' '}
              to publish campaigns that incur media spend.
            </Trans>
          }
          title={t('This project has no media billing configuration applied.')}
          type={AlertType.danger}
        />
      );
    } else if (showMediaBillingBadStanding) {
      return (
        <Alert
          className={className}
          description={t(
            'If this project remains delinquent, its in-progress campaigns that incur media spend will stop spending and draft campaigns will not be allowed to publish.',
          )}
          title={t('This project has an unpaid media bill.')}
          type={AlertType.danger}
        >
          <a
            href={localUrl(event.getItemUrl('settings/billing'))}
            rel={'noreferrer'}
            target={'_blank'}
          >
            {t('Pay any outstanding media bills')}
          </a>
        </Alert>
      );
    }
  }
  return null;
}

export default observer(RouteAlert);
