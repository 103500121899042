import { faFileImport, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { action, type IObservableArray } from 'mobx';
import { Observer, observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { EmailBaseCampaign, ICampaignSegment, Importer } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  CardV2,
  Checkbox,
  EmptyState,
  Fieldset,
  toast,
} from '@feathr/components';
import ConfirmButton from '@feathr/extender/components/ConfirmButton';
import type { ISegmentSelectChangeProps } from '@feathr/extender/components/SegmentSelect';
import SegmentSelect from '@feathr/extender/components/SegmentSelect';
import { useFlags, useLocalUrl, useStore, useUser } from '@feathr/extender/state';
import { moment, TimeFormat } from '@feathr/hooks';

import * as styles from './EmailCampaignSegments.css';

interface IProps {
  campaign: EmailBaseCampaign;
  disabled: boolean;
  hideIncluded?: boolean;
  showImportContacts?: boolean;
  showSendAll?: boolean;
  step?: number;
  title?: string;
  disabledTooltip?: string;
}

function EmailCampaignSegments({
  campaign,
  disabled,
  hideIncluded = false,
  showImportContacts = true,
  showSendAll = true,
  step,
  title,
  disabledTooltip,
}: Readonly<IProps>): JSX.Element {
  const { Goals, Importers, Targetings } = useStore();
  const importerRef = useRef<Importer | undefined>();
  const [segments, setSegments] = useState<IObservableArray<ICampaignSegment>>(
    campaign.get('segments', []),
  );
  const localUrl = useLocalUrl();
  const flags = useFlags();
  const user = useUser();
  const { t } = useTranslation();

  const isExcluded = [CampaignClass.Drip, CampaignClass.AutoPinpointEmail].includes(
    campaign.get('_cls'),
  );
  const filteredSegments = !isExcluded ? segments : segments.filter((s) => !s.included);
  const shouldShowAdditional = isExcluded && segments.filter((s) => !s.included).length >= 1;

  async function addImporter(): Promise<void> {
    importerRef.current = undefined;
    try {
      const importerAttrs: Record<string, string> = {
        created_by: user.id,
        kind: 'person',
        state: 'draft',
        // TODO: Translate Importer name.
        name: `Import ${moment().format(TimeFormat.isoDate)}`,
      };

      const newImporter = Importers.create(importerAttrs);
      const response = await Importers.add(newImporter, { validate: false });

      // Add group to campaign.
      segments.push({ id: response.get('segment'), included: !isExcluded });
      campaign.set({ segments: segments });
      campaign.setAttributeDirty('segments');
      importerRef.current = response;
      // Campaign is saved by <ConfirmButton />.
    } catch (e) {
      toast(t('There was an error creating the import.'), { type: 'error' });
    }
  }

  function getRedirect(): string | false {
    if (!importerRef.current) {
      return false;
    }
    return localUrl(importerRef.current.getItemUrl());
  }

  function handleChangeSegment({ included, oldId, id }: ISegmentSelectChangeProps): void {
    const i = segments.findIndex((s) => s.id === oldId);
    if (i === -1) {
      // This should not happen.
      return;
    }

    action(() => {
      segments[i].id = id;
      segments[i].included = included;

      campaign.set({ segments: segments });
      campaign.setAttributeDirty('segments');

      setSegments(campaign.get('segments', []));
    })();
  }

  function handleRemoveSegment(id?: string): void {
    if (!id) {
      const segment = segments.find((s) => !s.id);
      if (segment) {
        segments.remove(segment);
      }
    } else {
      campaign.set({
        segments: campaign.get('segments', []).filter((s) => s.id !== id),
      });
      setSegments(campaign.get('segments', []));
    }
    if (!campaign.get('segments', []).length) {
      campaign.set({ consent: { has_consent: false } });
    }
  }

  function handleChangeEndpointType(checked?: boolean): void {
    campaign.set({ endpoint_type: checked ? 'all_emails' : 'primary_email' });
  }

  useEffect(() => {
    if (segments.length === 0) {
      handleChangeEndpointType(false);
    }
  }, [segments.length]);

  function addSegment(): void {
    action(() => {
      segments.push({ id: '', included: !isExcluded });
    })();
  }

  function getAddSegmentButtonText(): string {
    if (isExcluded && shouldShowAdditional) {
      return t('Add additional group');
    }
    if (!isExcluded && segments.length >= 1) {
      return t('Add additional group');
    }
    return t('Add group');
  }

  return (
    <CardV2 width={'full'}>
      <CardHeader title={title ?? t('Groups')}>
        {showImportContacts && (
          <ConfirmButton
            description={t(
              'Before you can import contacts, the changes to your campaign need to be saved.',
            )}
            disabled={disabled}
            model={campaign}
            name={'import_contacts'}
            onConfirm={addImporter}
            prefix={<FontAwesomeIcon icon={faFileImport} />}
            redirect={getRedirect}
            step={step}
          >
            {t('Import contacts')}
          </ConfirmButton>
        )}
      </CardHeader>
      <CardContent>
        <>
          {segments.length === 0 && <EmptyState label={t('No groups added')} theme={'slate'} />}
          {!!segments.length && (
            <Fieldset className={styles.segment}>
              {filteredSegments.map((segment) => (
                <Observer key={segment.id || 'add'}>
                  {(): JSX.Element => {
                    const affectedTargetings = Targetings.list({
                      filters: {
                        _parent__ne: campaign.id,
                        _target_data: segment.id || undefined,
                      },
                      pagination: {
                        distinct: 'parent',
                      },
                    });
                    const affectedGoals = Goals.list({
                      filters: { segment: segment.id || undefined },
                    });
                    return (
                      <SegmentSelect
                        affectedCampaigns={affectedTargetings.pagination.distinct}
                        affectedGoals={affectedGoals.pagination.count}
                        disabled={disabled}
                        disableInclusion={isExcluded}
                        excludeIds={segments.filter((s) => s.id !== undefined).map((s) => s.id!)}
                        hideIncluded={hideIncluded}
                        included={segment.included}
                        name={'segment_select'}
                        onChange={handleChangeSegment}
                        onRemove={handleRemoveSegment}
                        stat={'num_emails_total'}
                        value={segment.id}
                      />
                    );
                  }}
                </Observer>
              ))}
            </Fieldset>
          )}
        </>
      </CardContent>
      <CardActions>
        {flags.pinpointSendAllEmails && showSendAll && (
          <Checkbox
            className={styles.endpointType}
            // Checkbox is only active when at least one target group is added
            disabled={segments.length === 0}
            label={t(
              'Some targeted people may have multiple email addresses. Send this email to every email address stored for every targeted person.',
            )}
            onChange={handleChangeEndpointType}
            value={campaign.get('endpoint_type') === 'all_emails'}
          />
        )}
        <Button
          disabled={disabled || segments.some((s) => !s.id)}
          name={'add_segment'}
          onClick={addSegment}
          prefix={<FontAwesomeIcon icon={faPlus} />}
          tooltip={disabledTooltip}
        >
          {getAddSegmentButtonText()}
        </Button>
      </CardActions>
    </CardV2>
  );
}

export default observer(EmailCampaignSegments);
