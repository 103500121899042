import { when } from 'mobx';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Flight } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { ArchiveModal, toast } from '@feathr/components';
import CampaignSummary from '@feathr/extender/App/EventsPage/CampaignSummary';
import { useLocalUrl, useStore } from '@feathr/extender/state';

interface ICampaignArchiveModalProps {
  campaign: Campaign;
  isArchiveModalOpen: boolean;
  closeArchiveModal: () => void;
}

function CampaignArchiveModal({
  campaign,
  isArchiveModalOpen,
  closeArchiveModal,
}: Readonly<ICampaignArchiveModalProps>): JSX.Element {
  const rootStore = useStore();
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  const state = campaign.get('state');
  const isNotDraft = state !== CampaignState.Draft;

  const flightOrProject = campaign.get('flight') ? t('flight') : t('project');
  const flightOrProjectURL = campaign.get('flight')
    ? localUrl(campaign.getItemUrl())
    : localUrl(`/projects/${campaign.get('parent')}/report`);

  const alertPageLink = (
    <a href={flightOrProjectURL} target={'_blank'}>
      {t('View {{flightOrProject}} report', { flightOrProject })}
    </a>
  );

  async function getFlight(): Promise<Flight> {
    const { Flights } = rootStore;
    const flight = Flights.get(campaign.get('flight')!);
    await when(() => !flight.isPending);
    return Flights.get(flight.id);
  }

  async function archive(): Promise<void> {
    try {
      campaign.set({
        is_archived: true,
        state: CampaignState.Archived,
      });
      if (campaign.get('flight')) {
        const flight = await getFlight();
        flight.removeCampaign(campaign.id);
        campaign.set({ flight: undefined });
        await Promise.all([campaign.save(), flight.save()]);
      } else {
        await campaign.save();
      }
      campaign.collection!.remove(campaign.id);
      toast(t('Campaign {{name}} has been archived.', { name: campaign.name }));
      closeArchiveModal();
    } catch (error: any) {
      toast(
        t('Encountered an error while archiving:\n{{- errorMessage}}', {
          errorMessage: error.message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  return (
    <ArchiveModal
      alertDescription={
        isNotDraft
          ? t(
              'Archiving this campaign will make this {{flightOrProject}}\'s reported conversions inaccurate. To recalculate conversions to exclude the archived campaign, select "Recalculate conversions" from the conversions table on this {{flightOrProject}}\'s report.',
              { flightOrProject },
            )
          : undefined
      }
      alertLinks={isNotDraft && alertPageLink}
      alertTitle={isNotDraft && t('Are you sure you want to archive this campaign?')}
      confirmationPhrase={t('ARCHIVE')}
      model={campaign}
      onClose={closeArchiveModal}
      onConfirm={archive}
      opened={isArchiveModalOpen}
      requireConfirmation={true}
      t={t}
      title={t('Archive Campaign')}
    >
      <CampaignSummary campaign={campaign} showMinimal={true} />
    </ArchiveModal>
  );
}

export default CampaignArchiveModal;
