import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { UserRole } from '@feathr/blackbox';
import type { IColumn, ITableWrapperProps } from '@feathr/components';
import { Table } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import type { IObject } from '@feathr/rachis';

interface IRolesTableProps {
  columns: Array<IColumn<UserRole>>;
  filters?: IObject;
  filterElements?: ITableWrapperProps['filters'];
  filterLabel?: ITableWrapperProps['filterLabel'];
  isPaginated?: boolean;
}

function RolesTable(props: Readonly<IRolesTableProps>): JSX.Element {
  const { UserRoles } = useStore();
  const { t } = useTranslation();

  return (
    <Table<UserRole>
      {...props}
      collection={UserRoles}
      initialPagesize={10}
      // Sort by ID by default, allowing Admin and User roles to be at the top of the table.
      initialSort={[{ id: 'id' }]}
      noDataText={t('No roles were found.')}
    />
  );
}

export default RolesTable;
