import type { IObservableArray } from 'mobx';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import type { Goal, ReferralCampaign, Segment } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { Step, Steps, Wizard } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { flattenError, flattenErrors } from '@feathr/hooks';
import type { Model } from '@feathr/rachis';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import SaveCampaignButton from '../SaveCampaignButton';
import StepGoals, { getGoalSegments, validateStepGoals } from '../StepGoals';
import Review from './Review';
import StepFive, { validateStepFive } from './StepFive';
import StepFour, { validateStepFour } from './StepFour';
import StepOne, { validateStepOne } from './StepOne';
import StepThree from './StepThree';
import StepTwo from './StepTwo';

interface IProps {
  campaign: ReferralCampaign;
}

export interface IValidateReferralCampaignProps {
  campaign: ReferralCampaign;
  goals: IObservableArray<Goal>;
  goalSegments: Segment[];
}

function validate({
  campaign,
  goals,
  goalSegments,
}: IValidateReferralCampaignProps): ICampaignValidationErrors {
  const one = validateStepOne(campaign);
  const four = validateStepFour(campaign);
  const five = validateStepFive(campaign);
  return {
    name: (one.name as string[]) || [],
    duration: ['start_date', 'end_date'].reduce((errors, key) => {
      return [...errors, ...flattenError(one[key] || [])];
    }, [] as string[]),
    // No validation required for participation.
    participation: [],
    metadata: flattenErrors(four),
    links: five.links,
    goals: flattenErrors(validateStepGoals(goals, goalSegments)),
  };
}

const getCompletedStep = ({
  campaign,
  goals,
  goalSegments,
}: IValidateReferralCampaignProps): number => {
  if (flattenErrors(validateStepOne(campaign)).length) {
    return 0;
  }
  if (flattenErrors(validateStepFour(campaign)).length) {
    return 3;
  }
  if (flattenErrors(validateStepFive(campaign)).length) {
    return 4;
  }
  if (flattenErrors(validateStepGoals(goals, goalSegments)).length) {
    return 5;
  }
  return 6;
};

function ReferralCampaignEdit({ campaign }: IProps) {
  const location = useLocation();
  const { Goals, Segments } = useStore();

  const foundStep = /\#step(\d{1})$/.exec(location.hash);
  const defaultStep = foundStep ? +foundStep[1] - 1 : undefined;

  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const goalSegments = getGoalSegments(goals.models, Segments);

  const [currentStep, setCurrentStep] = useState<number>(defaultStep || 0);
  const [completeStep, setCompleteStep] = useState<number>(defaultStep || 0);

  useEffect(() => {
    if (!campaign.isPending) {
      if (
        [CampaignState.Published, CampaignState.Publishing, CampaignState.Stopped].includes(
          campaign.get('state', CampaignState.Draft),
        )
      ) {
        setCurrentStep(defaultStep !== undefined ? defaultStep : 6);
        setCompleteStep(6);
      } else {
        const step = getCompletedStep({ campaign, goals: goals.models, goalSegments });
        setCurrentStep(defaultStep !== undefined ? defaultStep : step);
        setCompleteStep(step);
      }
    }
  }, [campaign.isPending]);

  const hasBannerTemplates = campaign.get('banner_templates', observable([])).length;
  const hasPageTemplates = campaign.get('page_templates', observable([])).length;

  function onNext() {
    // Account for optionally hidden step.
    const nextStep =
      !(hasBannerTemplates || hasPageTemplates) && currentStep === 2
        ? currentStep + 2
        : currentStep + 1;
    setCurrentStep(nextStep);
    if (completeStep < currentStep) {
      setCompleteStep(currentStep);
    }
  }

  function onPrev() {
    // Account for optionally hidden step.
    const prevStep =
      !(hasBannerTemplates || hasPageTemplates) && currentStep === 4
        ? currentStep - 2
        : currentStep - 1;
    setCurrentStep(prevStep);
  }

  // Converting observables back to vanilla JavaScript.
  const childModels: Model[] = [...goals.models.slice()];
  const grandchildModels: Model[] = [...goalSegments];

  const actions = [
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      grandchildModels={grandchildModels}
      key={'save'}
      name={'save_changes'}
    />,
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      // Disabled publish if not all steps are completed.
      disabled={completeStep < 5 || undefined}
      grandchildModels={grandchildModels}
      key={'changeState'}
      name={'publish_or_stop'}
      shouldChangeState={true}
      validate={() => validate({ campaign, goals: goals.models, goalSegments })}
    />,
  ];

  const steps = [
    <Step key={1} stepIndex={0} title={'Info'} />,
    <Step key={2} stepIndex={1} title={'Partners'} />,
    <Step key={3} stepIndex={2} title={'Templates'} />,
    <Step key={4} stepIndex={4} stepNumber={4} title={'Links (optional)'} />,
    <Step key={5} stepIndex={5} stepNumber={5} title={'Conversion Tracking'} />,
    <Step key={6} stepIndex={6} stepNumber={6} title={'Review'} />,
  ];
  if (hasBannerTemplates || hasPageTemplates) {
    steps.splice(3, 0, <Step key={'3a'} stepIndex={3} stepNumber={'3a'} title={'Metadata'} />);
  }

  return (
    <div data-appcues-campaign={campaign.get('_cls')}>
      <Wizard
        actions={actions}
        steps={
          <Steps completed={completeStep} current={currentStep} onChange={setCurrentStep}>
            {steps}
          </Steps>
        }
      >
        {currentStep === 0 && <StepOne campaign={campaign} onNext={onNext} />}
        {currentStep === 1 && <StepTwo campaign={campaign} onNext={onNext} onPrev={onPrev} />}
        {currentStep === 2 && <StepThree campaign={campaign} onNext={onNext} onPrev={onPrev} />}
        {currentStep === 3 && <StepFour campaign={campaign} onNext={onNext} onPrev={onPrev} />}
        {currentStep === 4 && <StepFive campaign={campaign} onNext={onNext} onPrev={onPrev} />}
        {currentStep === 5 && (
          <StepGoals campaign={campaign} goals={goals.models} onNext={onNext} onPrev={onPrev} />
        )}
        {currentStep === 6 && (
          <Review campaign={campaign} goals={goals.models} onPrev={onPrev} validate={validate} />
        )}
      </Wizard>
    </div>
  );
}

export default observer(ReferralCampaignEdit);
