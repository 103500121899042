import type { WithT } from 'i18next';
import debounce from 'lodash.debounce';
import type { IObservableArray } from 'mobx';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type {
  Account,
  AutoPinpointEmailCampaign,
  Campaign,
  EmailVerification,
  EmailVerifications as EmailVerificationsCollection,
  Segment,
  Template,
} from '@feathr/blackbox';
import { CampaignState, Goal, PinpointEmailBaseCampaign } from '@feathr/blackbox';
import type { IWizardStepsCompleted } from '@feathr/components';
import { Step, Steps, useWizardState, Wizard } from '@feathr/components';
import { useAccount, useStore } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT, flattenErrors } from '@feathr/hooks';
import type { Model } from '@feathr/rachis';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import PinpointEmailCampaignStepConfigureDetails, {
  validateStepConfigureDetails,
} from '../PinpointEmailCampaignEdit/PinpointEmailCampaignStepConfigureDetails';
import { validateStepFour as validateStepExclusions } from '../PinpointEmailCampaignEdit/PinpointEmailCampaignStepFour';
import PinpointEmailCampaignStepTest from '../PinpointEmailCampaignEdit/PinpointEmailCampaignStepTest';
import SaveCampaignButton from '../SaveCampaignButton';
import StepGoals, { getGoalSegments, validateStepGoals } from '../StepGoals';
import { getGoalsStatus } from './AutoPinpointEmailCampaignEdit.utils';
import AutoPinpointEmailCampaignStepAutomation, {
  validateStepAutomation,
} from './AutoPinpointEmailCampaignStepAutomation';
import AutoPinpointEmailCampaignStepExclusions from './AutoPinpointEmailCampaignStepExclusions';
import AutoPinpointEmailCampaignStepScheduleSend, {
  validateStepScheduleSend,
} from './AutoPinpointEmailCampaignStepScheduleSend';

import * as styles from './AutoPinpointEmailCampaignEdit.css';

interface IProps {
  campaign: AutoPinpointEmailCampaign;
}

interface IValidationDetails extends WithT {
  account: Account;
  campaign: AutoPinpointEmailCampaign;
  emailVerification?: EmailVerification;
  goals: IObservableArray<Goal>;
  goalSegments?: Segment[];
  template?: Template;
}

const getEmailVerifications = debounce(
  (campaign: AutoPinpointEmailCampaign, EmailVerifications: EmailVerificationsCollection) => {
    return campaign.getEmailVerifications(EmailVerifications);
  },
  DEFAULT_DEBOUNCE_WAIT,
  { leading: true },
);

function validate({
  account,
  campaign,
  emailVerification,
  goals,
  goalSegments,
  template,
  t,
}: IValidationDetails): ICampaignValidationErrors {
  const one = validateStepConfigureDetails(campaign, emailVerification, template);
  const two = validateStepAutomation(campaign);
  const four = validateStepGoals(goals, goalSegments);
  // Skip step 6 (test) because it is not required to publish a campaign.
  const six = validateStepScheduleSend(campaign);

  const validationErrors: ICampaignValidationErrors = {
    name: one.name ?? [],
    subject: one.subject ?? [],
    from_address: one.from_address ?? [],
    from_name: one.from_name ?? [],
    'address.company_name': one['address.company_name'] ?? [],
    'address.premise1': one['address.premise1'] ?? [],
    'address.locality': one['address.locality'] ?? [],
    'address.administrative_area_name': one['address.administrative_area_name'] ?? [],
    'address.postal_code': one['address.postal_code'] ?? [],
    'address.country_code': one['address.country_code'] ?? [],
    status: one.status ?? [],
    actions: two.actions ?? [],
    segments: (two.segments as string[]) ?? [],
    repeat: two.repeat ?? [],
    cooldown_value: two.cooldown_value ?? [],
    cooldown_unit: (two.cooldown_unit as string[]) ?? [],
    delay_value: two.delay_value ?? [],
    delay_unit: two.delay_unit ?? [],
    goals: four.goals,
    date_start: (six.date_start as string[]) ?? [],
    date_end: (six.date_end as string[]) ?? [],
    date_send_start: six.date_send_start ?? [],
    date_send_end: six.date_send_end ?? [],
    'consent.has_consent': six['consent.has_consent'] ?? [],
  };
  if (account.get('email_health') === 'suspended') {
    validationErrors.account = [
      t('Your Feathr account is suspended and cannot publish any new email campaigns.'),
    ];
  }

  return validationErrors;
}

function AutoPinpointEmailCampaignEdit({ campaign }: Readonly<IProps>): JSX.Element {
  const account = useAccount();
  const { EmailVerifications, Goals, Segments, Templates } = useStore();
  const [testEmail, setTestEmail] = useState<string | undefined>();
  const [testPersonId, setTestPersonId] = useState<string | undefined>();
  const { t } = useTranslation();

  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const segments = campaign
    .get('segments', [])
    .filter(({ id }) => !!id)
    .map(({ id }) => Segments.get(id));
  const templateId = campaign.get('template_id');
  const templates = campaign.get('template_id') ? [Templates.get(campaign.get('template_id'))] : [];
  const template = templateId ? Templates.get(templateId) : undefined;
  // Used for validation only.
  const emailVerifications = getEmailVerifications(campaign, EmailVerifications);

  // Converting observables back to vanilla JavaScript.
  const childModels: Model[] = [...templates, ...segments, ...goals.models.slice()];
  const grandchildModels: Model[] = [...getGoalSegments(goals.models, Segments)];

  const goalSegments = getGoalSegments(goals.models, Segments);
  const emailVerification = campaign.getEmailVerification(emailVerifications);

  const getStepErrors = useCallback(
    (step: number): string[] => {
      // If we're loading the wizard, return no errors
      if (step === -1) {
        return [];
      }
      const map = {
        0: flattenErrors(validateStepConfigureDetails(campaign, emailVerification, template)),
        1: flattenErrors(validateStepAutomation(campaign)),
        2: flattenErrors(validateStepExclusions(campaign)),
        3: flattenErrors(validateStepGoals(goals.models, goalSegments)),
        // We don't validate the test step because a test send is not necessary to be able to publish a campaign.
        4: [],
        5: flattenErrors(validateStepScheduleSend(campaign)),
      };
      return map[step];
    },
    [campaign, emailVerification, goalSegments, goals.models, template],
  );

  const getCompletedStepMap = (): IWizardStepsCompleted => {
    return {
      0: !getStepErrors(0).length,
      1: !getStepErrors(1).length,
      2: !getStepErrors(2).length,
      3: !getStepErrors(3).length,
      4: !getStepErrors(4).length,
      5: !getStepErrors(5).length,
    };
  };

  function buttonValidate(): ICampaignValidationErrors {
    if (
      !account ||
      !emailVerifications ||
      campaign.isPending ||
      emailVerifications.isPending ||
      goals.isPending
    ) {
      return {};
    }

    const emailVerification = campaign.getEmailVerification(emailVerifications);
    const goalSegments = getGoalSegments(goals.models, Segments);
    return validate({
      account,
      campaign,
      emailVerification,
      goals: goals.models,
      goalSegments,
      template,
      t,
    });
  }

  const waitFor: () => boolean = () => {
    return !(
      campaign.isPending ||
      !emailVerifications ||
      emailVerifications.isPending ||
      goals.isPending ||
      // Only check pending status if templateId is set. Template should also not be ephemeral because it's saved as soon as it is selected.
      (campaign.get('template_id') && template && (template.isEphemeral || template.isPending))
    );
  };

  async function preSave(newCampaign: Campaign): Promise<void> {
    if (newCampaign instanceof PinpointEmailBaseCampaign) {
      const campaignSegments = newCampaign.get('segments');
      await Promise.all(
        campaignSegments.map(async (s) => {
          if (!s.id) {
            return;
          }
          const campaignSegment = Segments.get(s.id);
          await when(() => !campaignSegment.isPending);

          if (campaignSegment.isEphemeral) {
            await Segments.add(campaignSegment);
          }
        }),
      );
    }
  }

  async function postStateChange(campaign: Campaign, stateChanged?: boolean): Promise<void> {
    if (campaign instanceof PinpointEmailBaseCampaign && stateChanged) {
      // The template's read_only field was toggled on the backend, so we must reload it
      await Promise.all(
        templates.map(async (t) => {
          t.reload();
        }),
      );
    }
  }

  const { currentStep, completeStep, onNext, onPrev, onChangeStep } = useWizardState({
    getCompletedStepMap,
    waitFor,
    skipToMaxStep: [
      CampaignState.Published,
      CampaignState.Publishing,
      CampaignState.Stopped,
    ].includes(campaign.get('state', CampaignState.Draft)),
    deps: [template],
  });

  const wizardProps = {
    campaign,
    onNext,
    onPrev,
    goals: goals.models,
    validate,
    disabled: campaign.readOnly,
  };

  // Set loading while data is fetched and initialized.
  const isLoading = completeStep < 0;

  const saveButton = (
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      disabled={!campaign.editable || undefined}
      grandchildModels={grandchildModels}
      key={'changeState'}
      name={'publish_or_stop'}
      postSave={postStateChange}
      preSave={preSave}
      shouldChangeState={true}
      validate={buttonValidate}
    />
  );

  // Goals for this campaign type are always editable regardless of the campaign state.
  const { goalsAreValid, goalsAreDirty } = getGoalsStatus(
    childModels.filter((model) => model instanceof Goal) as Goal[],
  );

  const isDisabled =
    !goalsAreValid ||
    isLoading ||
    campaign.get('state') === CampaignState.Publishing ||
    // Goals are editable after publish
    (!goalsAreDirty && !campaign.editable) ||
    undefined;

  const actions = [
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      disabled={isDisabled}
      grandchildModels={grandchildModels}
      key={'save'}
      name={'save_changes'}
      preSave={preSave}
    />,
    saveButton,
  ];

  const steps = (
    <Steps completed={completeStep} current={currentStep} onChange={onChangeStep}>
      <Step key={1} stepIndex={0} title={t('Configure Details')} />
      <Step key={2} stepIndex={1} title={t('Automation')} />
      <Step key={3} stepIndex={2} title={t('Exclusions')} />
      <Step key={4} stepIndex={3} title={t('Goal')} />
      <Step key={5} stepIndex={4} title={t('Test')} />
      <Step key={6} stepIndex={5} title={t('Schedule & Publish')} />
    </Steps>
  );

  return (
    <div data-appcues-campaign={campaign.get('_cls')}>
      <Wizard
        actions={actions}
        isFullWidth={true}
        isLoading={completeStep < 0}
        layout={'horizontal'}
        steps={steps}
      >
        {currentStep === 0 && (
          <div className={styles.container}>
            <PinpointEmailCampaignStepConfigureDetails template={template} {...wizardProps} />
          </div>
        )}
        {currentStep === 1 && (
          <div className={styles.containerWide}>
            <AutoPinpointEmailCampaignStepAutomation {...wizardProps} />
          </div>
        )}
        {currentStep === 2 && (
          <div className={styles.containerWide}>
            <AutoPinpointEmailCampaignStepExclusions {...wizardProps} />
          </div>
        )}
        {/* Goals step should always be enabled - so override disabled set by campaign.readOnly */}
        {currentStep === 3 && (
          <div className={styles.containerWide}>
            <StepGoals {...wizardProps} disabled={false} />
          </div>
        )}
        {/* Test step should always be enabled - so override disabled set by campaign.readOnly */}
        {currentStep === 4 && (
          <div className={styles.container}>
            <PinpointEmailCampaignStepTest
              {...wizardProps}
              disabled={false}
              setTestEmail={setTestEmail}
              setTestPersonId={setTestPersonId}
              testEmail={testEmail}
              testPersonId={testPersonId}
            />
          </div>
        )}
        {currentStep === 5 && (
          <div className={styles.container}>
            <AutoPinpointEmailCampaignStepScheduleSend
              setCurrentStep={onChangeStep}
              {...wizardProps}
              submitButton={saveButton}
            />
          </div>
        )}
      </Wizard>
    </div>
  );
}

export default observer(AutoPinpointEmailCampaignEdit);
