import { when } from 'mobx';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IPerson } from '@feathr/blackbox';
import { AsyncSelect } from '@feathr/components';
import { PersonOption } from '@feathr/extender/components/SelectOptions';
import { useStore } from '@feathr/extender/state';

import * as styles from './PersonSelect.css';

interface IProps {
  disabled?: boolean;
  helpText?: string;
  isClearable?: boolean;
  label?: ReactNode;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onChange: (value?: string) => void;
  placeholder?: string;
  required?: boolean;
  validationError?: string[] | string;
  name: string;
  value?: string;
}

function PersonSelect({
  disabled = false,
  helpText,
  isClearable = false,
  label,
  onBlur,
  onChange,
  placeholder,
  required = false,
  validationError,
  name,
  value,
}: IProps): JSX.Element {
  const { Persons } = useStore();
  const { t } = useTranslation();

  async function loadPersonOptions(inputValue: string): Promise<IPerson[]> {
    const persons = Persons.list(
      {
        predicates: [
          {
            kind: 'attribute',
            attr_against: 'name',
            attr_type: 'string',
            comparison: 'wildcard',
            value: `*${inputValue}*`,
          },
          {
            kind: 'attribute',
            group: [
              {
                kind: 'attribute',
                attr_against: 'email',
                attr_type: 'string',
                comparison: 'wildcard',
                value: `*${inputValue}*`,
              },
              {
                kind: 'attribute',
                attr_against: 'email',
                attr_type: 'string',
                comparison: 'exists',
                value: '',
              },
            ],
          },
        ],
        mode: 'match_any',
        lookback_mode: 'unbounded',
        pagination: {
          page: 0,
          page_size: 20,
        },
      },
      {
        url: Persons.url('page'),
      },
    );
    await when(() => !persons.isPending);
    return persons.models.map((person) => person.toJS());
  }

  function handleSelectPerson(option: IPerson): void {
    onChange(option.id);
  }

  function handleClearPerson(): void {
    onChange(undefined);
  }

  function getOptionLabel({ name, email, placeholder }: IPerson): string {
    return name || email || placeholder?.name || t('Selected user');
  }

  return (
    <AsyncSelect
      components={{ Option: PersonOption }}
      defaultOptions={true}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      helpText={helpText}
      isClearable={isClearable}
      label={label}
      loadOptions={loadPersonOptions}
      name={name}
      onBlur={onBlur}
      onClear={handleClearPerson}
      onSelectSingle={handleSelectPerson}
      placeholder={placeholder}
      required={required}
      validationError={validationError}
      value={value ? Persons.get(value).toJS() : undefined}
      wrapperClassName={styles.root}
    />
  );
}

export default PersonSelect;
