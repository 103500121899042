import type { IObservableArray } from 'mobx';
import { autorun, runInAction, set } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Account, Targeting, TTDCampaign } from '@feathr/blackbox';
import { CampaignState, EBiddingStrategyClass } from '@feathr/blackbox';
import { Button, ButtonValid, CardV2 as Card, Form } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';

import {
  getAudienceSize,
  getRecommendedBid,
} from '../AdCampaignEditBudgetStep/AdCampaignEditBudgetStep.utils';
import { getTargetables, getTargetSegments } from '../CampaignEditStepTwo';
import { validateStepOptimize } from './AdCampaignEditOptimizationStep.utils';
import BaseBidSelect from './BaseBidSelect';
import CrossDeviceTargetingToggle from './CrossDeviceTargetingToggle';
import FreqCapSelect from './FreqCapSelect';
import FreqPeriodSelect from './FreqPeriodSelect';
import OptimizationRadios from './OptimizationRadios';

import * as styles from './AdCampaignEditOptimizationStep.css';

export interface IAdCampaignEditOptimizationStepProps {
  onNext: () => void;
  onPrev: () => void;
  campaign: TTDCampaign;
  account: Account;
  targetings: IObservableArray<Targeting>;
}

function AdCampaignEditOptimizationStep({
  campaign,
  onNext,
  onPrev,
  targetings,
}: Readonly<IAdCampaignEditOptimizationStepProps>): JSX.Element {
  const { Segments, Targetables } = useStore();
  const { t } = useTranslation();
  const biddingStrategyType = campaign.get('bidding_strategy')._cls;
  const validationErrors = validateStepOptimize(campaign);

  const isDraft = campaign.get('state') === CampaignState.Draft;
  const segments = getTargetSegments(targetings, Segments);
  const targetables = getTargetables(targetings, Targetables);

  useEffect(() => {
    return autorun(() => {
      if (!campaign.isMonetization) {
        const biddingStrategy = campaign.get('bidding_strategy');
        // If the user has not interacted with the base bid input recalculate
        // the max_bid based on the audience size.
        if (biddingStrategy && !biddingStrategy.custom_bid && isDraft) {
          const audienceSize = getAudienceSize(campaign, segments, targetables);
          const recommendedBid = getRecommendedBid(audienceSize);

          runInAction((): void => {
            set(biddingStrategy, {
              base_bid: recommendedBid,
              max_bid: recommendedBid * 2,
            });
          });
          campaign.setAttributeDirty('bidding_strategy');
        }
      }
    });
  }, [campaign, segments, targetables, isDraft]);

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <ButtonValid
          errors={flattenErrors(validationErrors)}
          key={'next'}
          name={'next_step'}
          onClick={onNext}
        >
          {t('Next')}
        </ButtonValid>,
      ]}
      className={styles.root}
      label={'Edit Campaign: Optimization'}
    >
      <Card width={'narrow'}>
        <Card.Header
          description={t(
            'Auto-optimize optimizes your campaign using various inputs and settings. There are some cases where you may want to adjust them, but typically the automated option will perform best.',
          )}
          title={'Campaign Optimization'}
        />
        <Card.Content addVerticalGap={true}>
          <OptimizationRadios campaign={campaign} />
          {biddingStrategyType === EBiddingStrategyClass.Manual && (
            <>
              <FreqCapSelect campaign={campaign} />
              <BaseBidSelect campaign={campaign} />
              <FreqPeriodSelect campaign={campaign} />
              {!campaign.isMonetization && <CrossDeviceTargetingToggle campaign={campaign} />}
            </>
          )}
        </Card.Content>
      </Card>
    </Form>
  );
}

export default observer(AdCampaignEditOptimizationStep);
