import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IRowItem } from '@feathr/blackbox';
import { Icon, Input } from '@feathr/components';

import Field from './Field';

import * as styles from './FormFields.css';

interface IProps {
  fields: IRowItem[];
}

function FormFields({ fields }: IProps): JSX.Element {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');

  function handleSearch(value?: string): void {
    setSearch(value || '');
  }

  const filteredFields = (function () {
    return fields.filter((field) => field.label.toLowerCase().includes(search.toLowerCase()));
  })();

  return (
    <>
      <Input
        isClearable={true}
        onChange={handleSearch}
        placeholder={t('Search by name...')}
        prefix={<Icon icon={faSearch} />}
        type={'text'}
        value={search}
        wrapperClassName={styles.input}
      />

      <div className={styles.container}>
        {filteredFields.map((field) => (
          <Field field={field} key={field.id} />
        ))}
      </div>
    </>
  );
}

export default observer(FormFields);
