import type { ITTDCampaignAttributes } from './ttd_base';
import { TTDCampaign } from './ttd_base';
import type { CampaignClass } from './types';

// Lookalike
export type TSeedSegmentCampaign = ITTDCampaignAttributes & {
  readonly _cls: CampaignClass.SeedSegment;
};

export class SeedSegmentCampaign extends TTDCampaign<TSeedSegmentCampaign> {}
