import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import { TemplateClass, templateTypeLabel } from '@feathr/blackbox';
import { Button, ConfirmModalV1, Form, Input, Radios, Tab, Toolbar } from '@feathr/components';
import type { IPageProps } from '@feathr/extender/App/Page';
import Page from '@feathr/extender/App/Page';
import { templatesBlankToClassMap } from '@feathr/extender/components/TemplateSelect';
import { useAccount, useLocalUrl, useStore } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

export interface IProps extends IPageProps {
  sectionTitle: string;
}

import type { JSX } from 'react';

import blankTemplate from '@feathr/extender/components/TemplateSelect/blankTemplate.json';

function EventTemplatesPage({ children, sectionTitle, title, ...props }: IProps): JSX.Element {
  const account = useAccount();
  const { Templates } = useStore();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const [name, setName] = useState<string | undefined>();
  const [templateType, setTemplateType] = useState<TemplateClass>(TemplateClass.PinpointEmail);
  const [isModalVisible, toggleModalVisible] = useToggle(false);
  const { t } = useTranslation();

  function handleNameChange(newValue?: string): void {
    setName(newValue);
  }

  function handleTemplateTypeChange(newValue?: string): void {
    setTemplateType((newValue as TemplateClass) || TemplateClass.PinpointEmail);
  }

  async function handleAdd(): Promise<void> {
    toggleModalVisible();
    const blankIndex = templatesBlankToClassMap[templateType];
    const template = Templates.create({
      ...blankTemplate[blankIndex],
      /*
       * If we do not reset id we will overwrite an existing template and cause
       * errors on template_revisions collection in mogodb!
       */
      id: undefined,
      _cls: templateType,
      account: account.id,
      default: false,
      event: eventId,
      name,
      parent_kind: 'event',
      parent: eventId,
    });
    const response = await Templates.add(template, { validate: false });
    history.push(localUrl(response.getItemUrl('edit/design')));
  }

  const actions = (
    <Toolbar>
      <Button
        onClick={toggleModalVisible}
        prefix={<FontAwesomeIcon icon={faPlus} />}
        type={'primary'}
      >
        {t('Add template')}
      </Button>
    </Toolbar>
  );

  const tabs = [
    <Tab key={'global'} link={'global'} title={t('Saved Templates')} />,
    <Tab key={'campaign'} link={'campaign'} title={t('From Campaigns')} />,
  ];

  return (
    <Page {...props} actions={actions} tabs={tabs} title={sectionTitle}>
      <h3>{title}</h3>
      {children}
      {isModalVisible && (
        <ConfirmModalV1
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Create template')}
          confirmDisabled={!name || !templateType}
          onClose={toggleModalVisible}
          onConfirm={handleAdd}
          t={t}
          title={'Add New Template'}
        >
          <Form label={t('Add New Template')}>
            <Input
              helpText={t(
                'This is the name of the template used in Feathr and in your reports. Make it descriptive and memorable so you can find this template later.',
              )}
              label={t('Name')}
              onChange={handleNameChange}
              placeholder={t('Unnamed Template')}
              type={'text'}
              value={name}
            />
            <Radios
              label={t('Type')}
              onChange={handleTemplateTypeChange}
              options={[
                {
                  id: TemplateClass.ReferralBanner,
                  name: templateTypeLabel(TemplateClass.ReferralBanner),
                },
                {
                  id: TemplateClass.PinpointEmail,
                  name: templateTypeLabel(TemplateClass.PinpointEmail),
                },
                {
                  id: TemplateClass.ReferralEmail,
                  name: templateTypeLabel(TemplateClass.ReferralEmail),
                },
                {
                  id: TemplateClass.ReferralPage,
                  name: templateTypeLabel(TemplateClass.ReferralPage),
                },
                {
                  id: TemplateClass.LandingPage,
                  name: templateTypeLabel(TemplateClass.LandingPage),
                },
              ]}
              value={templateType}
            />
          </Form>
        </ConfirmModalV1>
      )}
    </Page>
  );
}

export default EventTemplatesPage;
