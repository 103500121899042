import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, CardV2 as Card } from '@feathr/components';
import { useAccount, useLocalUrl, useStore } from '@feathr/extender/state';

import Page from '../../Page';

import * as styles from './ProResourcesPage.css';

const GENERAL_SUPPORT_CALENDLY_URL =
  'https://calendly.com/feathr-phonesupport/30-minute-support-call';

function ProResourcesPage(): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const account = useAccount();
  const { Users } = useStore();

  const strategistID = account.get('strategist');
  const strategist = strategistID ? Users.get(strategistID) : undefined;
  const strategistCalendlyUrl = strategist?.get('calendly_link') ?? undefined;

  return (
    <Page
      description={t(
        "Feathr's Pro package helps you leverage the full value of the Feathr platform with strategic guidance, dedicated support, and in-depth account reviews so you can get personalized help when you need it. It also provides ongoing access to the Data Integration Services team for conversion tracking and custom data requests.",
      )}
      title={t('Pro Resources')}
    >
      <div className={styles.root}>
        <Card>
          <Card.Header
            description={t(
              'If you need assistance with placing the Super Pixel, a Conversion Pixel, or utilizing custom data collection please make a new Implementation request.',
            )}
            title={t('Implementations')}
          >
            <Button link={localUrl('/data/pixel/implementations')} name={'implementations'}>
              {t('View implementations')}
            </Button>
          </Card.Header>
        </Card>

        {strategistCalendlyUrl && (
          <Card>
            <Card.Header
              description={t(
                'Book a consultation call with your dedicated Strategic Advisor to discuss campaign ideas, optimizations, campaign health reviews, and advanced training topics.',
              )}
              title={t('Book a Strategy Consultation')}
            >
              <Button href={strategistCalendlyUrl} name={'strategy-call'}>
                {t('Schedule a call')}
              </Button>
            </Card.Header>
          </Card>
        )}
        <Card name={'troubleshooting'}>
          <Card.Header
            description={t(
              'Is something not going quite right with your account? Book a technical troubleshooting call with the Support team.',
            )}
            title={t('Troubleshooting?')}
          >
            <Button href={GENERAL_SUPPORT_CALENDLY_URL} name={'troubleshooting-call'}>
              {t('Schedule a call')}
            </Button>
          </Card.Header>

          <Card.Content
            action={
              <div className={styles.hours}>
                <p>{t('Monday - Thursday')}</p>
                <p>{t('9am-5pm EST')}</p>
              </div>
            }
            description={'(352) 399-0499'}
            title={t('Call us')}
          ></Card.Content>
        </Card>
      </div>
    </Page>
  );
}

export default observer(ProResourcesPage);
