import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStore } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function UserLabel({ className, id }: IProps) {
  const { Users } = useStore();
  const user = Users.get(id);
  return (
    <div className={classNames({ [skeleton]: user.isPending }, className)}>
      {user.get('name', 'placeholder') as string}
    </div>
  );
}

export default observer(UserLabel);
