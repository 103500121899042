import classNames from 'classnames';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import type { DailyStat } from '@feathr/blackbox';
import { Label, TableStatsCard } from '@feathr/components';
import { cssVar, moment, TimeFormat } from '@feathr/hooks';
import { EReportFlavors } from '@feathr/report_components/state';

import * as styles from './ActivityCard.css';

type TFlavor =
  | 'ad_click'
  | 'ad_view'
  | 'email_view'
  | 'email_link_click'
  | 'page_view'
  | 'page_link_click'
  | 'pinpoint_tracked_email_click'
  | 'pinpoint_tracked_email_delivered'
  | 'pinpoint_tracked_email_hardbounce'
  | 'pinpoint_tracked_email_open'
  | 'pinpoint_tracked_email_send'
  | 'pinpoint_tracked_email_softbounce'
  | 'pinpoint_tracked_email_suppression'
  | 'created_by_import'
  | 'updated_by_import'
  | 'imis_create'
  | 'imis_update'
  | 'raisers_edge_constituent_created'
  | 'raisers_edge_constituent_updated'
  | 'raisers_edge_constituent_summary'
  | 'raisers_edge_gift_added';

type TActivityFlavor = TFlavor | 'ctr';
type TLineFlavors = Array<{ key: TActivityFlavor; op?: '+' | '-' }>;

interface ILineConfig {
  name: string;
  flavors?: TLineFlavors;
  yAxisId?: 'left' | 'right';
  showStatSummary?: boolean;
  statsKey?: string;
}

interface ICardProps {
  dailyStats: IObservableArray<DailyStat>;
  lines: ILineConfig[];
  label: string;
}

type TChartKeys = TFlavor | 'date';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TChartData<K extends keyof any = TChartKeys> = {
  [P in K]: K extends 'date' ? string : number | undefined;
};

const colors = [
  cssVar('--color-primary-3'),
  cssVar('--color-brand-orange'),
  cssVar('--color-brand-purple'),
  cssVar('--color-brand-green'),
  cssVar('--color-sky-700'),
];

function ActivityCard({ dailyStats, lines, label }: Readonly<ICardProps>): JSX.Element {
  const statsToShow =
    lines.length -
    lines.filter(({ showStatSummary }: ILineConfig) => showStatSummary === false).length;

  function tooltipFormatter(value: string | number | Array<string | number>): ReactNode {
    const locale = 'en-US';
    const isDecimal = typeof value === 'number' && value > Math.floor(value);
    const percentStyle = { style: 'percent', minimumFractionDigits: 2 };
    const options = isDecimal ? percentStyle : undefined;

    // Formats a decimal as a percent, e.g. 0.5 -> 50% or default formatting, e.g. 1000 -> 1,000
    return value.toLocaleString(locale, options);
  }

  function hasCtrKey(flavors: TLineFlavors | undefined, keys: TActivityFlavor[]): boolean {
    if (!flavors) {
      return false;
    }
    return flavors.some(({ key }) => {
      return keys.includes(key);
    });
  }

  // Check if the chart includes flavors could be used to calculate CTR
  const isAdCTR = lines.some(({ flavors }) =>
    hasCtrKey(flavors, [EReportFlavors.ad_click, EReportFlavors.ad_view]),
  );
  const isPageCTR = lines.some(({ flavors }) =>
    hasCtrKey(flavors, [EReportFlavors.page_link_click, EReportFlavors.page_view]),
  );
  const isEmailCTR = lines.some(({ flavors }) =>
    hasCtrKey(flavors, [EReportFlavors.email_link_click, EReportFlavors.email_view]),
  );
  const isCTR = isAdCTR || isEmailCTR || isPageCTR;

  const chartData: TChartData[] = dailyStats.map((stat) => {
    return lines.reduce(
      (data, { name, flavors, statsKey }) => {
        data[name] = 0;
        for (const i in flavors) {
          const flavorsStat = stat.get('flavors', {});

          if (isCTR && flavors[i].key === 'ctr') {
            let clicks = 0;
            let views = 0;

            if (isAdCTR) {
              // Ads
              clicks = flavorsStat[EReportFlavors.ad_click] || 0;
              views = flavorsStat[EReportFlavors.ad_view] || 0;
            } else if (isEmailCTR) {
              // Emails
              clicks = flavorsStat[EReportFlavors.email_link_click] || 0;
              views = flavorsStat[EReportFlavors.email_view] || 0;
            } else if (isPageCTR) {
              // Pages
              clicks = flavorsStat[EReportFlavors.page_link_click] || 0;
              views = flavorsStat[EReportFlavors.page_view] || 0;
            }

            // check views to prevent dividing by 0
            data[name] = views ? clicks / views : 0;
          }
          if (flavors[i].op === '-') {
            data[name] -= stat.get('flavors', {})[flavors[i].key] || 0;
          } else {
            data[name] += stat.get('flavors', {})[flavors[i].key] || 0;
          }
        }
        if (statsKey) {
          data[name] += stat.get(statsKey, { daily: 0 }).daily;
        }
        return data;
      },
      {
        date: moment.utc(stat.get('metadata').date, moment.ISO_8601).format(TimeFormat.isoDate),
      } as TChartData,
    );
  });
  return (
    <TableStatsCard contentType={'bordered'} title={label}>
      <div
        className={classNames(styles.wrapper, {
          [styles.manyStats]: statsToShow > 2,
        })}
      >
        <div className={styles.chartContainer}>
          {/* width set at 99% allows the responsive container to resize appropriately with the statsContainer */}
          <ResponsiveContainer height={200} width={'99%'}>
            <LineChart
              data={chartData}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              syncId={'anyId'}
            >
              <XAxis dataKey={'date'} interval={'preserveStartEnd'} minTickGap={100} />
              <YAxis yAxisId={'left'} />
              <YAxis orientation={'right'} yAxisId={'right'} />
              <CartesianGrid
                stroke={cssVar('--color-decorative-secondary')}
                strokeDasharray={'5 5'}
              />
              {/* eslint-disable-next-line react/jsx-no-bind */}
              <Tooltip formatter={tooltipFormatter} />
              {lines.map(({ name, yAxisId }, i) => {
                return (
                  <Line
                    connectNulls={true}
                    dataKey={name}
                    dot={false}
                    fill={colors[i]}
                    key={name}
                    label={<>{name}</>}
                    name={name}
                    stroke={colors[i]}
                    type={'monotone'}
                    yAxisId={yAxisId ?? 'left'}
                  />
                );
              })}
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className={styles.statsContainer}>
          {lines.map(({ name, showStatSummary }, i) => {
            if (showStatSummary === false) {
              return null;
            }
            const value = chartData.reduce((acc, stat) => acc + stat[name], 0);
            const formatted = numeral(value).format('0,0');
            return (
              <div className={styles.stat} key={name}>
                <div className={styles.statNumber} style={{ color: colors[i] }}>
                  {formatted}
                </div>
                <Label>{name}</Label>
              </div>
            );
          })}
        </div>
      </div>
    </TableStatsCard>
  );
}

export default observer(ActivityCard);
