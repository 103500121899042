import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FacebookCampaign } from '@feathr/blackbox';
import { FormSummaryItem } from '@feathr/components';

import type { ISummaryProps } from './CampaignSummary.types';

const ObjectiveSummary = observer(
  ({ campaign, validationErrors }: ISummaryProps): JSX.Element | null => {
    const { t } = useTranslation();

    if (!(campaign instanceof FacebookCampaign)) {
      return null;
    }

    return (
      <FormSummaryItem
        errors={validationErrors?.subject}
        label={t('Objective')}
        value={campaign.objectiveType}
      />
    );
  },
);

ObjectiveSummary.displayName = 'ObjectiveSummary';

export default ObjectiveSummary;
