import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { Well } from '@feathr/components';
import EmailTemplateSelect from '@feathr/extender/components/EmailTemplateSelect';
import { useStore } from '@feathr/extender/state';
import type { IValidateGrouped } from '@feathr/rachis';

import EmailInformationInputs from '../../../EmailDetails/EmailInformationInputs';
import SenderInformationInputs from '../../../EmailDetails/SenderInformationInputs';
interface IEmailInputsProps {
  campaign: PinpointEmailBaseCampaign;
  validationErrors: IValidateGrouped;
}

function EmailInputs({ campaign, validationErrors }: Readonly<IEmailInputsProps>): JSX.Element {
  const { Templates } = useStore();

  const templateId = campaign.get('template_id');
  const template = templateId ? Templates.get(templateId) : undefined;

  return (
    <Well layout={'vertical'} theme={'white'}>
      <EmailTemplateSelect campaign={campaign} context={'drip'} template={template} />
      <SenderInformationInputs
        campaign={campaign}
        disabled={false}
        validationErrors={validationErrors}
      />
      <EmailInformationInputs
        campaign={campaign}
        disabled={false}
        template={template}
        validationErrors={validationErrors}
      />
    </Well>
  );
}

export default observer(EmailInputs);
