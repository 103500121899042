import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { Fragment } from 'react';
import { useHistory, useParams } from 'react-router';

import type { Segment } from '@feathr/blackbox';
import { Form, Input, SaveButton, Tooltip } from '@feathr/components';
import TagsField from '@feathr/extender/components/TagsField';
import { useLocalUrl, useStore } from '@feathr/extender/state';

import Page from '../../Page';

function DataSegmentEditPage(): JSX.Element {
  const { Segments } = useStore();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { segmentId } = useParams<{ segmentId: string }>();

  const segment = Segments.get(segmentId);

  const errors = segment.validate(['name', 'tag_ids'], false).errors;
  const isValid = errors.length === 0;

  const saveButton = (
    <SaveButton<Segment>
      disabled={!isValid}
      errorMessage={'There was an issue saving. Please try again later.'}
      key={'finish'}
      method={'patch'}
      model={segment}
      onSave={(updatedSegment) => {
        history.push(localUrl(updatedSegment.getItemUrl()));
      }}
      successMessage={'Changes have been saved.'}
    />
  );

  const actions = [
    isValid ? (
      <Fragment key={'finish'}>{saveButton}</Fragment>
    ) : (
      <Tooltip
        key={'finish'}
        title={errors.map((error, i) => (
          <Fragment key={i}>
            {error}
            {i < errors.length - 1 && <br />}
          </Fragment>
        ))}
      >
        {saveButton}
      </Tooltip>
    ),
  ];

  return (
    <Page pageTitle={`Edit ${segment.name}`} title={'Edit Group'}>
      <Form actions={actions} label={'Edit Group'}>
        <Input attribute={'name'} label={'Name'} model={segment} type={'text'} />
        <TagsField
          context={'person_segment'}
          label={'Tags'}
          onChange={(values) => segment.set({ tag_ids: values })}
          value={segment.get('tag_ids', [])}
        />
      </Form>
    </Page>
  );
}

export default observer(DataSegmentEditPage);
