import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CampaignClass, FacebookCampaign } from '@feathr/blackbox';
import { FormSummary, FormSummaryItem } from '@feathr/components';
import CampaignTypeChip from '@feathr/extender/components/CampaignTypeChip';

import AudienceStatsSummary from './AudienceStatsSummary';
import AudienceSummary from './AudienceSummary';
import BudgetSummary from './BudgetSummary';
import type { ISummaryProps } from './CampaignSummary.types';
import CreativesSummary from './CreativesSummary';
import DripEnrollmentSummary from './DripEnrollmentSummary';
import DurationSummary from './DurationSummary';
import EmailSummary from './EmailSummary';
import GeoFiltersSummary from './GeoFiltersSummary';
import GoalsSummary from './GoalsSummary';
import LinksSummary from './LinksSummary';
import MetadataSummary from './MetadataSummary';
import ObjectiveSummary from './ObjectiveSummary';
import ParticipationSummary from './ParticipationSummary';
import ReferralLinksSummary from './ReferralLinksSummary';
import SendSummary from './SendSummary';
import SubjectSummary from './SubjectSummary';
import TargetedCountriesSummary from './TargetedCountriesSummary';
import TargetsSummary from './TargetsSummary';
import TemplatesSummary from './TemplatesSummary';
import TriggerSummary from './TriggerSummary';

import * as styles from './CampaignSummary.css';

function getSummaryComponents(cls: CampaignClass): Array<React.FC<ISummaryProps>> {
  switch (cls) {
    case CampaignClass.Segment:

    case CampaignClass.Search:

    case CampaignClass.Lookalike:

    case CampaignClass.SeedSegment:

    case CampaignClass.Affinity:

    case CampaignClass.EmailList:
      return [
        TargetsSummary,
        GeoFiltersSummary,
        DurationSummary,
        BudgetSummary,
        CreativesSummary,
        GoalsSummary,
      ];

    case CampaignClass.MobileGeoFencing:

    case CampaignClass.MobileGeoFenceRetargeting:
      return [TargetsSummary, DurationSummary, BudgetSummary, CreativesSummary, GoalsSummary];

    case CampaignClass.TrackedLink:
      return [LinksSummary, GoalsSummary];

    case CampaignClass.Facebook:

    case CampaignClass.EmailListFacebook:
      return [
        ObjectiveSummary,
        TargetedCountriesSummary,
        TargetsSummary,
        DurationSummary,
        BudgetSummary,
        CreativesSummary,
        GoalsSummary,
      ];

    case CampaignClass.Referral:
      return [
        DurationSummary,
        ParticipationSummary,
        TemplatesSummary,
        MetadataSummary,
        ReferralLinksSummary,
        GoalsSummary,
      ];

    case CampaignClass.PinpointEmail:
      return [
        SendSummary,
        EmailSummary,
        SubjectSummary,
        AudienceSummary,
        TemplatesSummary,
        GoalsSummary,
        AudienceStatsSummary,
      ];

    case CampaignClass.Drip:
      return [SendSummary, DripEnrollmentSummary];

    case CampaignClass.SmartPinpointEmail:
      return [
        SendSummary,
        EmailSummary,
        SubjectSummary,
        AudienceSummary,
        TriggerSummary,
        TemplatesSummary,
        GoalsSummary,
        AudienceStatsSummary,
      ];

    case CampaignClass.AutoPinpointEmail:
      return [
        SendSummary,
        EmailSummary,
        SubjectSummary,
        AudienceSummary,
        TriggerSummary,
        TemplatesSummary,
        GoalsSummary,
      ];

    default:
      return [GoalsSummary];
  }
}

function CampaignSummary({
  campaign,
  validationErrors = {},
  showMinimal = false,
  layout = 'horizontal',
}: Readonly<ISummaryProps>): JSX.Element {
  const { t } = useTranslation();

  const minimalProps = (
    <>
      <FormSummaryItem
        errors={validationErrors.name}
        key={'campaign-name'}
        label={t('Campaign name')}
        value={campaign.name}
      />
      <FormSummaryItem
        key={'campaign-type'}
        label={t('Type')}
        value={<CampaignTypeChip cls={campaign.get('_cls')} />}
      />
    </>
  );

  if (showMinimal) {
    return <FormSummary layout={'vertical'}>{minimalProps}</FormSummary>;
  }

  return (
    <FormSummary layout={layout}>
      {campaign instanceof FacebookCampaign && validationErrors.facebook?.length ? (
        <FormSummaryItem
          errors={validationErrors.facebook}
          key={'facebook-integration-status'}
          label={t('Meta Integration Status')}
          value={t('Error')}
        />
      ) : (
        <></>
      )}
      {minimalProps}

      <>
        {getSummaryComponents(campaign.get('_cls')).map((SummaryComponent) => {
          return (
            <SummaryComponent
              campaign={campaign}
              key={SummaryComponent.displayName}
              validationErrors={validationErrors}
            />
          );
        })}
      </>
      <>
        {validationErrors.account?.map((msg, index) => (
          <div className={styles.error} key={index}>
            {msg}
          </div>
        ))}
      </>
    </FormSummary>
  );
}

export default observer(CampaignSummary);
