import type { IObservableArray } from 'mobx';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ICampaignLink } from '@feathr/blackbox';
import { FormSummaryItem, Value } from '@feathr/components';
import { flattenErrors } from '@feathr/hooks';

import type { ISummaryProps } from '../CampaignSummary.types';

import * as styles from './ReferralLinksSummary.css';

const ReferralLinksSummary = observer(
  ({ campaign, validationErrors }: ISummaryProps): JSX.Element => {
    const { t } = useTranslation();
    const links: IObservableArray<ICampaignLink> = campaign.get('links', observable([]));
    return (
      <FormSummaryItem
        className={styles.links}
        label={t('Links')}
        value={
          links.length > 0 ? (
            <ol>
              {links.map((l, i) => {
                const error = validationErrors?.links || ([] as Array<Record<string, string[]>>);
                return (
                  <li key={l.link_id}>
                    <Value
                      validationError={error[i] ? flattenErrors(error[i]) : undefined}
                      value={`${l.title} [${l.url || t('missing link')}]`}
                    />
                  </li>
                );
              })}
            </ol>
          ) : (
            t('(None)')
          )
        }
      />
    );
  },
);

ReferralLinksSummary.displayName = 'ReferralLinksSummary';

export default ReferralLinksSummary;
