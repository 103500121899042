import { createContext, useContext } from 'react';

import {
  Accounts,
  APIKeys,
  Billables,
  Bills,
  BlackbaudRaisersEdgeIntegrations,
  ContactLists,
  CustomFields,
  DataRequests,
  Domains,
  EmailSendRequests,
  EmailVerifications,
  Events,
  FacebookCampaignIntegrations,
  FacebookPages,
  Fonts,
  Forms,
  GeoFilters,
  GoogleAdsCustomers,
  ImisIntegrations,
  Importers,
  Notifications,
  PartnerExports,
  Paths,
  RedirectDomains,
  SendRequests,
  Tags,
  Targetables,
  UsageMetrics,
  UsageQuotas,
  UsageReports,
  UserNotifications,
  UserRoles,
  Users,
} from '@feathr/blackbox';

import { ReportStore } from './useReportStore';

export class AuthenticatedStore extends ReportStore {
  public Accounts = new Accounts([]);

  public APIKeys = new APIKeys([]);

  public Billables = new Billables([]);

  public Bills = new Bills([]);

  public BlackbaudRaisersEdgeIntegrations = new BlackbaudRaisersEdgeIntegrations([]);

  public ContactLists = new ContactLists([]);

  public CustomFields = new CustomFields([]);

  public DataRequests = new DataRequests([]);

  public Domains = new Domains([]);

  // Partner dashboard email sends
  public EmailSendRequests = new EmailSendRequests([]);

  public EmailVerifications = new EmailVerifications([]);

  public override Events = new Events([]);

  public ImisIntegrations = new ImisIntegrations([]);

  public FacebookCampaignIntegrations = new FacebookCampaignIntegrations([]);

  public FacebookPages = new FacebookPages([]);

  public Fonts = new Fonts([]);

  public Forms = new Forms([]);

  public GeoFilters = new GeoFilters([]);

  public GoogleAdsCustomers = new GoogleAdsCustomers([]);

  public Importers = new Importers([]);

  public Notifications = new Notifications([]);

  public PartnerExports = new PartnerExports([]);

  public Paths = new Paths([]);

  public RedirectDomains = new RedirectDomains([]);

  public SendRequests = new SendRequests([]);

  public Tags = new Tags([]);

  public Targetables = new Targetables([]);

  public UsageReports = new UsageReports([]);

  public UsageMetrics = new UsageMetrics([]);

  public UsageQuotas = new UsageQuotas([]);

  public UserNotifications: UserNotifications;

  public UserRoles = new UserRoles([]);

  public Users = new Users([]);

  public constructor() {
    super();

    this.UserNotifications = new UserNotifications([], {
      Notifications: this.Notifications,
    });
  }
}
export const AuthenticatedStoreContext = createContext<AuthenticatedStore | null>(null);

export function createAuthenticatedStore(): AuthenticatedStore {
  return new AuthenticatedStore();
}

function useAuthenticatedStore(): AuthenticatedStore {
  const context = useContext(AuthenticatedStoreContext);
  if (!context) {
    throw new Error('useAuthenticatedStore must be used within AuthenticatedStoreProvider');
  }
  return context;
}

export default useAuthenticatedStore;
