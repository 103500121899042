import { faCog, faSearch, faTasks } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observable, reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { UserNotification } from '@feathr/blackbox';
import { ENotificationLevel } from '@feathr/blackbox';
import { Button, Checkbox, DebouncedInput, Grid, Input, Select, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useLocalUrl, useStore, useUser } from '@feathr/extender/state';
import { useId } from '@feathr/hooks';
import type { IObject, ListResponse } from '@feathr/rachis';
import { useInfiniteList } from '@feathr/rachis';

import NotificationCard from './NotificationCard';

import * as styles from './NotificationsPage.css';

interface IFilters {
  showRead?: boolean;
  level?: ENotificationLevel;
  title?: string;
}

interface ILevelFilterOption {
  name: string;
  id: ENotificationLevel;
}

function NotificationsPage(): JSX.Element {
  const { UserNotifications } = useStore();
  const listId = useId();
  const [, clearInfiniteList] = useInfiniteList(UserNotifications, listId);
  const localUrl = useLocalUrl();
  const unreadNotifications = useRef<ListResponse<UserNotification>['models']>(observable([]));
  const [filters, setFilters] = useState<IFilters>({ showRead: false });
  const user = useUser();
  const { t } = useTranslation();

  useEffect(() => {
    return reaction(
      () => user.get('notifications'),
      () => clearInfiniteList(),
    );
  });

  function updateFilters(newFilters: IFilters) {
    setFilters(newFilters);
    clearInfiniteList();
  }

  function handleUpdate(items: ListResponse<UserNotification>['models']) {
    unreadNotifications.current = items;
  }

  const levelFilterOptions: ILevelFilterOption[] = [
    { name: t('Info'), id: ENotificationLevel.info },
    { name: t('Warning'), id: ENotificationLevel.warning },
    { name: t('Critical'), id: ENotificationLevel.critical },
  ];

  function handleDebouncedSearchChange(newValue?: string) {
    updateFilters({
      ...filters,
      title: newValue ? newValue : undefined,
    });
  }

  function handleChangeShowRead(newValue?: boolean) {
    updateFilters({ ...filters, showRead: newValue });
  }

  function handleClearLevelFilter() {
    updateFilters({ ...filters, level: undefined });
  }

  function handleSelectLevelFilter(newValue: ILevelFilterOption) {
    updateFilters({ ...filters, level: newValue.id });
  }

  function handleMarkAllAsRead() {
    UserNotifications.readAll();
  }

  const toolbar = [
    <>
      <Select<ILevelFilterOption>
        isClearable={true}
        name={'notification-severity'}
        onClear={handleClearLevelFilter}
        onSelectSingle={handleSelectLevelFilter}
        options={levelFilterOptions}
        placeholder={t('Severity')}
        value={levelFilterOptions.find((o) => o.id === filters.level)}
        wrapperClassName={styles.filter}
      />
      <Checkbox
        label={t('Show read notfications')}
        onChange={handleChangeShowRead}
        value={filters.showRead}
        wrapperClassName={styles.filterCheckbox}
      />
    </>,
    <>
      <DebouncedInput<string> defaultValue={filters.title} onChange={handleDebouncedSearchChange}>
        {(liveValue, onChangeLiveValue) => (
          <Input
            className={styles.filterSearch}
            isClearable={true}
            onChange={onChangeLiveValue}
            placeholder={t('Search by title or description...')}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
    </>,
  ];

  const query: IObject = {};
  if (filters.title) {
    query.__raw__ = {
      $or: [
        { message: { $regex: filters.title, $options: 'i' } },
        { title: { $regex: filters.title, $options: 'i' } },
      ],
    };
  }
  if (filters.level !== undefined) {
    query.level = filters.level;
  }
  if (!filters.showRead) {
    query.is_read__ne = true;
  }

  return (
    <Page
      actions={
        <Toolbar>
          <Button icon={<FontAwesomeIcon icon={faTasks} />} onClick={handleMarkAllAsRead}>
            Mark all as read
          </Button>
          <Button
            link={localUrl('/settings/user/notifications')}
            prefix={<FontAwesomeIcon icon={faCog} />}
          >
            Notification settings
          </Button>
        </Toolbar>
      }
      title={t('My Notifications')}
    >
      <Grid
        className={styles.grid}
        collection={UserNotifications}
        filters={query}
        itemClassName={styles.gridItem}
        itemType={NotificationCard}
        listId={listId}
        noDataText={t('No notification were found for the given filters.')}
        onUpdate={handleUpdate}
        sort={[{ id: '_id', desc: true }]}
        toolbar={toolbar}
      />
    </Page>
  );
}

export default observer(NotificationsPage);
