import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ValueType } from 'react-select';

import type { CustomField, ICustomField, Partner } from '@feathr/blackbox';
import { FieldCollection, FieldDataType } from '@feathr/blackbox';
import { CreatableSelect, Fieldset, Form } from '@feathr/components';
import DataTypeSelect from '@feathr/extender/components/DataTypeSelect';
import { FieldOption } from '@feathr/extender/components/SelectOptions';
import { useStore } from '@feathr/extender/state';
import { moment, TimeFormat, useToggle } from '@feathr/hooks';

interface IProps {
  toggleAddData: () => void;
  partner: Partner;
}

function typeToValue(type: FieldDataType) {
  switch (type) {
    case FieldDataType.date:
      return moment.utc().format(TimeFormat.isoDate);

    case FieldDataType.str:
      return '';

    case FieldDataType.bool:
      return false;

    case FieldDataType.float:
      return 0;

    case FieldDataType.list:
      return [];

    default:
      return undefined;
  }
}

function AddCustomData({ partner, toggleAddData }: IProps): JSX.Element {
  const { CustomFields } = useStore();
  const { t } = useTranslation();

  const customFields = CustomFields.list({
    filters: {
      collection: FieldCollection.Partner,
      is_archived__ne: true,
    },
    pagination: { page_size: 1000 },
  });
  const createNewField = (dataType: FieldDataType): void => {
    if (newField) {
      newField.set({ data_type: dataType });
      CustomFields.add(newField);
      runInAction(() => {
        customFields.models.push(newField);
      });
      const value = typeToValue(dataType);
      partner.set({
        custom_data: { ...partner.get('custom_data'), [newField.get('u_key')]: value },
      });
    }
  };
  const [newField, setNewField] = useState<CustomField>();
  const [toggleDataType, setToggleDataType] = useToggle(false);
  const customDataKeys = Object.keys(partner.get('custom_data'));

  function compareOption(inputValue: string, option: ICustomField) {
    return option.u_key === inputValue.trim();
  }

  function isValidNewOption(
    inputValue: string,
    _: ValueType<ICustomField>,
    options: readonly ICustomField[],
  ) {
    return !(!inputValue || options.some((option) => compareOption(inputValue, option)));
  }

  function formatCreateLabel(fieldName: string) {
    return t('Create field: {{fieldName}}', { fieldName });
  }

  function handleChangeDataType(newValue?: FieldDataType) {
    if (!newValue) {
      return;
    }
    createNewField(newValue);
    setToggleDataType();
    setNewField(undefined);
    toggleAddData();
  }

  return (
    <Form label={t('Add custom data')}>
      <Fieldset>
        {!toggleDataType ? (
          <CreatableSelect
            components={{ Option: FieldOption }}
            createOption={(inputValue) => {
              const customField = CustomFields.create({
                u_key: inputValue,
                collection: FieldCollection.Partner,
              });
              setNewField(customField);
              setToggleDataType();
              return customField.toJS();
            }}
            defaultOptions={customFields.models
              .filter((field) => !customDataKeys.includes(field.get('u_key')))
              .map((field) => field.toJS())}
            formatCreateLabel={formatCreateLabel}
            getOptionLabel={(option) => option.u_key}
            id={'selectField'}
            isLoading={customFields.isPending}
            isValidNewOption={isValidNewOption}
            name={'select-field'}
            onSelectSingle={(option) => {
              const selectedField = option.id && CustomFields.get(option.id);
              if (!!selectedField && !selectedField.isEphemeral) {
                const value = typeToValue(option.data_type);
                partner.set({
                  custom_data: {
                    ...partner.get('custom_data'),
                    [option.u_key]: value,
                  },
                });
                toggleAddData();
              }
            }}
            placeholder={t('Select field...')}
          />
        ) : (
          <DataTypeSelect
            id={'selectDataType'}
            onChange={handleChangeDataType}
            placeholder={t('Select data type...')}
          />
        )}
      </Fieldset>
    </Form>
  );
}

export default observer(AddCustomData);
