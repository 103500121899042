import type { IObservableArray } from 'mobx';
import { isObservableArray, toJS, when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import type { ActionMeta, ValueType } from 'react-select';
import { components } from 'react-select';

import type { ICampaignAttributes, IEvent, IPartner, ISegment, ITag } from '@feathr/blackbox';
import type { Campaign, Event, Partner, Segment, Tag } from '@feathr/blackbox';
import { AsyncSelect } from '@feathr/components';
import {
  CampaignIconOption,
  EventOption,
  PartnerOption,
} from '@feathr/extender/components/SelectOptions';
import { useStore } from '@feathr/extender/state';
import type { DisplayModel, IBaseAttributes } from '@feathr/rachis';

import * as styles from './PredicateIdValueSelect.css';

export interface ICollectionOption {
  id?: string;
  name?: string;
}

export type TCollection = 'Partners' | 'Campaigns' | 'Events' | 'Tags' | 'Segments';

interface IProps {
  collection: TCollection;
  disabled?: boolean;
  filters?: Record<string, string | string[] | boolean>;
  multi?: boolean;
  naked: boolean;
  onSelect: (option: ICollectionOption | ICollectionOption[]) => void;
  placeholder: string;
  name: string;
  value?: string | IObservableArray<string>;
}

type TSelection = Array<Event | Campaign | Partner | Tag | Segment>;
type TValue =
  | Array<IEvent | ICampaignAttributes | IPartner | ITag | ISegment>
  | IEvent
  | ICampaignAttributes
  | IPartner
  | ITag
  | ISegment
  | undefined;

function GenericOption(props: any): JSX.Element {
  return <components.Option {...props} />;
}

const collectionToOptionMap = new Map<TCollection, (props: any) => JSX.Element>([
  ['Events', EventOption],
  ['Campaigns', CampaignIconOption],
  ['Partners', PartnerOption],
  ['Tags', GenericOption],
  ['Segments', GenericOption],
]);

function PredicateIdValueSelect({
  collection,
  disabled = false,
  filters,
  multi = false,
  onSelect,
  placeholder,
  name,
  value,
}: IProps): JSX.Element {
  const context = useStore();

  function onChange(
    newValue: ValueType<ICollectionOption>,
    action: ActionMeta<ICollectionOption>,
  ): void {
    if (['select-option', 'remove-value', 'clear'].includes(action.action)) {
      if (Array.isArray(newValue)) {
        onSelect(newValue as ICollectionOption[]);
      } else {
        onSelect(newValue as ICollectionOption);
      }
    }
  }

  async function loadOptions(newValue: string): Promise<ICollectionOption[]> {
    let realFilters: { [key: string]: string | string[] | boolean } = {
      name__icontains: newValue,
    };
    if (realFilters) {
      realFilters = { ...realFilters, ...filters };
    }
    const data = context[collection].list({ filters: realFilters });
    await when(() => !data.isPending);
    return (data.models as IObservableArray<DisplayModel<IBaseAttributes>>).map((model) => {
      const attributes = model.toJS();
      /*
       * We are using model.name instead of model.get('name') because it adds a placeholder if empty.
       * These are custom per model.
       */
      attributes.name = model.name;
      return attributes;
    });
  }

  function getOptionLabel(option: ICollectionOption): string {
    return option.name || '';
  }

  function getOptionValue(option: ICollectionOption): string {
    return option.id || '';
  }

  let selectedObjects: TSelection = [];
  let isLoading = false;
  let isDisabled = disabled;
  let realValue: TValue;
  if (value) {
    if (isObservableArray(value)) {
      selectedObjects = toJS(value).map((val) => context[collection].get(val));
    } else {
      selectedObjects.push(context[collection].get(value));
    }
  }
  if (selectedObjects && selectedObjects.some((model) => model.isPending)) {
    isLoading = true;
    isDisabled = true;
  }
  if (selectedObjects && selectedObjects.length) {
    if (multi) {
      realValue = selectedObjects.map((model) => model.toJS());
    } else {
      realValue = selectedObjects[0].toJS();
    }
  }
  return (
    <AsyncSelect
      cacheOptions={true}
      className={styles.select}
      components={{
        Option: collectionToOptionMap.get(collection),
      }}
      defaultOptions={true}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={multi}
      loadOptions={loadOptions}
      name={name}
      onChange={onChange}
      placeholder={placeholder}
      value={realValue}
    />
  );
}

export default observer(PredicateIdValueSelect);
