import { useContext } from 'react';

import type { AuthenticatedStore } from './useAuthenticatedStore';
import { AuthenticatedStoreContext } from './useAuthenticatedStore';
import type { ReportStore } from './useReportStore';
import { ReportStoreContext } from './useReportStore';

export function isAuthenticatedStore(
  store: ReportStore | AuthenticatedStore,
): store is AuthenticatedStore {
  return 'Accounts' in store && 'Users' in store;
}

export class NoAuthenticatedStoreError extends Error {
  constructor(message = 'User and Account stores are unavailable!') {
    const trueProto = new.target.prototype;
    super(message);
    Object.setPrototypeOf(this, trueProto);
  }

  public override toString(): string {
    return `NoAuthenticatedStoreError: ${this.message}`;
  }
}

export function assertAuthenticatedStore(
  store: ReportStore | AuthenticatedStore,
): asserts store is AuthenticatedStore {
  if (!isAuthenticatedStore(store)) {
    throw new NoAuthenticatedStoreError();
  }
}

function useStore(): AuthenticatedStore | ReportStore {
  const authenticatedStore = useContext(AuthenticatedStoreContext);
  const reportStore = useContext(ReportStoreContext);

  if (authenticatedStore === null && reportStore === null) {
    throw new Error(
      'useStore must be used within AuthenticatedStoreProvider or ReportStoreProvider',
    );
  }

  if (authenticatedStore !== null) {
    return authenticatedStore;
  }
  return reportStore!;
}

export default useStore;
