import classNames from 'classnames';
import camelCase from 'lodash.camelcase';
import startCase from 'lodash.startcase';
import { Observer, observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import type { RowRenderProps } from 'react-table';

import type { DataRequest } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import {
  ArchiveModalV1,
  Button,
  Chip,
  ConfirmModalV1,
  ContextMenu,
  ModalV1,
  Table,
  TableColumnHeader,
  Time,
  toast,
  Toolbar,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useAccount, useLocalUrl, useStore } from '@feathr/extender/state';
import { dataRequestStateColorMap } from '@feathr/extender/styles/data_request';
import { getIconForAction, TimeFormat, useToggle } from '@feathr/hooks';

import ConversionPixelCode from '../DataConversionPixelsPage/ConversionPixelCode';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IRow extends RowRenderProps {
  original: DataRequest;
}

function OptionsCell({ original }: IRow): JSX.Element {
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isCancelModalOpen, toggleCancelModalOpen] = useToggle(false);
  const { t } = useTranslation();

  async function handleCancel(): Promise<void> {
    try {
      await original.canceled();
      toast(t('Your request has been canceled.'), { type: 'success' });

      // If err is instance of Error, it should be of type any.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast(
        t('Something went wrong while canceling your request:\n{{error}}', {
          error: error.message,
        }),
        {
          type: 'error',
        },
      );
    } finally {
      toggleArchiveModalOpen();
    }
  }

  async function handleArchive(): Promise<void> {
    try {
      await original.archived();
      toast(t('Your request has been archived.'), { type: 'success' });

      // If err is instance of Error, it should be of type any.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast(
        t('Something went wrong while archiving your request:\n{{error}}', {
          error: error.message,
        }),
        {
          type: 'error',
        },
      );
    } finally {
      toggleArchiveModalOpen();
    }
  }

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <ContextMenu.Item onClick={toggleCancelModalOpen} prefix={getIconForAction('cancel')}>
          {t('Cancel')}
        </ContextMenu.Item>
        <ContextMenu.Item
          onClick={toggleArchiveModalOpen}
          prefix={getIconForAction('archive')}
          theme={'danger'}
        >
          {t('Archive')}
        </ContextMenu.Item>
      </ContextMenu>
      {isCancelModalOpen && (
        <ConfirmModalV1
          cancelButtonText={t('Nevermind')}
          confirmButtonText={t('Yes, stop work')}
          onClose={toggleCancelModalOpen}
          onConfirm={handleCancel}
          t={t}
          title={t('Cancel')}
        >
          {t('Are you sure you want to cancel this?')}
        </ConfirmModalV1>
      )}
      {isArchiveModalOpen && (
        <ArchiveModalV1
          model={original}
          onClose={toggleArchiveModalOpen}
          onConfirm={handleArchive}
          t={t}
        />
      )}
    </>
  );
}

function StateCell({ original }: IRow): JSX.Element {
  const state = original.get('state');
  return (
    <>
      {!!state && (
        <Chip theme={dataRequestStateColorMap[state]}>{startCase(camelCase(state))}</Chip>
      )}
    </>
  );
}

function DataRequestsPage(): JSX.Element {
  const { DataRequests } = useStore();
  const history = useHistory();
  const account = useAccount();
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const ComposedOptionsCell = observer(OptionsCell);
  const ComposedStateCell = observer(StateCell);

  const FieldColumns: Array<IColumn<DataRequest>> = [
    {
      id: 'name',
      Header: TableColumnHeader({ sortType: 'alpha', title: t('Name') }),
      headerClassName: tableStyles.sort,
      className: classNames(tableStyles.cell),
      Cell({ original }): JSX.Element {
        return <Link to={localUrl(original.getItemUrl())}>{original.name}</Link>;
      },
    },
    {
      id: 'zendesk_ticket_id',
      Header: TableColumnHeader({ sortType: 'numeric', title: t('Ref. ID') }),
      headerClassName: tableStyles.sort,
      className: classNames(tableStyles.cellRight),
      width: 100,
      Cell({ original }): JSX.Element {
        return <>{original.get('zendesk_ticket').id}</>;
      },
    },
    {
      id: 'code',
      Header: TableColumnHeader({ title: t('Code') }),
      sortable: false,
      className: tableStyles.cellCenter,
      width: 85,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const { Segments } = useStore();
              const [showCodeModal, toggleCodeModal] = useToggle(false);

              const conversionPixelId = original.get('conversion_pixel');
              const segment = conversionPixelId ? Segments.get(conversionPixelId) : undefined;

              if (!segment) {
                return <>-</>;
              }

              return (
                <>
                  <Button onClick={toggleCodeModal}>{t('Show')}</Button>
                  {showCodeModal && (
                    <ModalV1
                      confirmButtonText={t('Ok')}
                      controlled={true}
                      onClose={toggleCodeModal}
                      t={t}
                      title={t('Conversion Pixel')}
                    >
                      <ConversionPixelCode
                        category={original.get('conversion_category')}
                        segment={segment}
                      />
                    </ModalV1>
                  )}
                </>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'date_created',
      Header: TableColumnHeader({ sortType: 'numeric', title: t('Created') }),
      headerClassName: tableStyles.sort,
      sortable: true,
      className: tableStyles.cell,
      width: 110,
      Cell({ original }): JSX.Element {
        return <Time format={TimeFormat.shortDate} timestamp={original.get('date_created')} />;
      },
    },
    {
      id: 'date_submitted',
      Header: TableColumnHeader({ sortType: 'numeric', title: t('Submitted') }),
      headerClassName: tableStyles.sort,
      sortable: true,
      className: tableStyles.cell,
      width: 120,
      Cell({ original }): JSX.Element {
        return (
          <>
            {original.get('date_submitted') ? (
              <Time format={TimeFormat.shortDate} timestamp={original.get('date_submitted')} />
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      id: 'gtm_container',
      Header: TableColumnHeader({ sortType: 'alpha', title: t('Container') }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      width: 100,
      Cell({ original }): JSX.Element {
        return <>{original.get('gtm_container')}</>;
      },
    },
    {
      id: 'state',
      Header: TableColumnHeader({ sortType: 'alpha', title: t('Status') }),
      headerClassName: tableStyles.sort,
      width: 140,
      Cell(row): JSX.Element {
        return <ComposedStateCell {...row} />;
      },
    },
    {
      id: 'options',
      Header: TableColumnHeader({ title: t('Options') }),
      className: tableStyles.cellCenter,
      width: 80,
      sortable: false,
      Cell(row): JSX.Element {
        return <ComposedOptionsCell {...row} />;
      },
    },
  ];

  async function createDataRequest(): Promise<void> {
    const dataRequest = DataRequests.create({
      id: undefined,
    });
    const response = await DataRequests.add(dataRequest, { validate: false });
    history.push(localUrl(response.getItemUrl()));
  }

  const actions = (
    <Toolbar>
      <Button
        disabled={!account.hasPixelImplementation}
        onClick={createDataRequest}
        prefix={getIconForAction('add')}
        tooltip={
          account.hasPixelImplementation
            ? null
            : t("You cannot create an implementation with your account's current products.")
        }
        type={'primary'}
      >
        {t('Add Data Request')}
      </Button>
    </Toolbar>
  );

  return (
    <Page actions={actions} title={t('Implementations')}>
      <Table<DataRequest>
        collection={DataRequests}
        columns={FieldColumns}
        noDataText={t('No Implementations were found.')}
      />
    </Page>
  );
}

export default DataRequestsPage;
