import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Account, BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

import noImg from 'images/no-img.png';

interface IPermissionColumn {
  shouldShow?: (account: Account) => boolean;
}

const PartnerColumn: IPermissionColumn & IColumn<BaseCampaign> = {
  id: 'parent',
  checkboxLabel: 'Partner',
  Header: TableColumnHeader({
    title: 'Partner',
  }),
  sortable: false,
  className: tableStyles.cell,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          const localUrl = useLocalUrl();
          const { Partners } = useStore();

          if (original.get('parent_kind') !== 'partner') {
            return <>-</>;
          }

          const parent = original.get('parent');
          const partner = Partners.get(parent);
          return (
            <>
              <div
                style={{
                  marginRight: '10px',
                  backgroundImage: `url("${partner.get('logo') || noImg}")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '40px',
                  height: '40px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                }}
              />
              <a href={localUrl(partner.getItemUrl())} style={{ verticalAlign: 'middle' }}>
                {partner.get('name')}
              </a>
            </>
          );
        }}
      </Observer>
    );
  },
  shouldShow: (account: Account): boolean => account.isFalcon,
};

export default PartnerColumn;
