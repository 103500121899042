import debounce from 'debounce-promise';
import isEqual from 'lodash.isequal';
import { observable, when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  EmailVerification,
  IAddress,
  IPinpointEmailCampaign,
  PinpointEmailBaseCampaign,
} from '@feathr/blackbox';
import { CampaignClass, EPinpointRequestStatus } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import {
  AddressInput,
  Alert,
  AlertType,
  Button,
  ButtonValid,
  Checkbox,
  EmailSelect,
  Fieldset,
  Form,
  Input,
  isAddressEmpty,
  SenderInformationWell,
  toast,
} from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT, flattenError, flattenErrors, useDebounce } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import * as styles from './PinpointEmailCampaignStepTwo.css';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
  disabled: boolean;
  onNext: () => void;
  onPrev: () => void;
  // The following are not used by Autosend Campaigns
  isVerifiedDomainSender?: boolean;
}

interface IButtonProps {
  campaign: PinpointEmailBaseCampaign;
  emailVerification?: EmailVerification;
  onNext: () => void;
}

interface IErrors extends TValidateGrouped {
  from_address?: string[];
  from_name?: string[];
  'address.company_name'?: string[];
  'address.premise1'?: string[];
  'address.locality'?: string[];
  'address.administrative_area_name'?: string[];
  'address.postal_code'?: string[];
  'address.country_code'?: string[];
  status?: string[];
}

function isEmailVerificationEmpty(emailVerification?: EmailVerification): boolean {
  const fromName = emailVerification?.get('from_name');
  const address = emailVerification?.get('address') ?? ({} as IAddress);
  return !fromName || isAddressEmpty(address);
}

export function validateStepTwo(
  campaign: PinpointEmailBaseCampaign,
  emailVerification?: EmailVerification,
): IErrors {
  const campaignErrors = campaign.validate(
    [
      'from_address',
      'from_name',
      'address.company_name',
      'address.premise1',
      'address.locality',
      'address.administrative_area_name',
      'address.postal_code',
      'address.country_code',
    ],
    false,
    'grouped',
  ).errors;
  if (Object.keys(campaignErrors).length) {
    return campaignErrors;
  }

  const emailVerificationErrors =
    emailVerification?.validate<IErrors>(['status'], false, 'grouped').errors ??
    (observable({}) as IErrors);

  const status = emailVerification?.get('status') ?? 'Unverified';

  if (!emailVerificationErrors.status) {
    emailVerificationErrors.status = [] as string[];
  }

  if (status === 'Success') {
    // Do nothing.
  } else if (status === 'Pending') {
    emailVerificationErrors.status.push('Your email is still pending verification.');
  } else {
    emailVerificationErrors.status.push('Your email is unverified.');
  }

  return emailVerificationErrors;
}

const NextStepButton = observer(
  ({ campaign, emailVerification, onNext }: IButtonProps): JSX.Element => {
    const { t } = useTranslation();
    const errors = validateStepTwo(campaign, emailVerification);
    return (
      <ButtonValid errors={flattenErrors(errors)} name={'next_step'} onClick={onNext}>
        {t('Next')}
      </ButtonValid>
    );
  },
);

function PinpointEmailCampaignStepTwo({
  campaign,
  disabled,
  isVerifiedDomainSender = false,
  onNext,
  onPrev,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { EmailVerifications } = useStore();
  const [fromAddress, setFromAddress] = useDebounce(campaign.get('from_address'));
  const [emailVerification, setEmailVerification] = useState<EmailVerification | undefined>();
  const [hadFocusFromAddress, setHadFocusFromAddress] = useState(false);
  const [copyFromVerification, setCopyFromVerification] = useState(false);
  const [isPending, setIsPending] = useState(true);
  const localUrl = useLocalUrl();

  const getEmailStatus: (fromAddress: string) => Promise<EmailVerification | undefined> =
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(
      debounce(async (fromAddress: string) => {
        await when(() => !campaign.isPending);

        setIsPending(true);
        const emailVerifications = EmailVerifications.list(
          { filters: { email: fromAddress } },
          { reset: true },
        );
        await when(() => !emailVerifications.isPending);
        setIsPending(false);
        const newEmailVerification =
          !emailVerifications.isErrored && emailVerifications.models.length === 1
            ? emailVerifications.models[0]
            : undefined;
        setEmailVerification(newEmailVerification);

        return newEmailVerification;
      }, DEFAULT_DEBOUNCE_WAIT),
      [EmailVerifications],
    );

  const stepValidationErrors = validateStepTwo(campaign, emailVerification);
  const emailStatus = emailVerification?.get('status') ?? 'Unverified';

  useEffect(() => {
    getEmailStatus(fromAddress).then((newEmailVerification) => {
      const patch: Partial<IPinpointEmailCampaign> = {
        from_name: campaign.get('from_name'),
        address: campaign.get('address'),
      };
      if (newEmailVerification && newEmailVerification.get('status') === 'Success') {
        if (
          campaign.get('from_address') === campaign.initialAttributes.from_address &&
          (campaign.initialAttributes.from_name ||
            // Check if address has any values filled in.
            Object.values(campaign.initialAttributes.address ?? {}).some((v) => !!v))
        ) {
          // Resetting to original email, so restore changed from_name, address
          const address = !isEqual(campaign.get('address'), campaign.initialAttributes.address)
            ? campaign.get('address')
            : campaign.initialAttributes.address;
          patch.from_name =
            campaign.initialAttributes.from_name || newEmailVerification.get('from_name');
          patch.address = address ?? newEmailVerification.get('address');
        } else {
          // We can safely overwrite from_name, address
          patch.from_name = newEmailVerification.get('from_name');
          patch.address = newEmailVerification.get('address');
        }
        campaign.set(patch);

        // Use patch to compare because campaign will still be awaiting update.
        if (
          patch.from_name === newEmailVerification.get('from_name') &&
          isEqual(patch.address, newEmailVerification.get('address'))
        ) {
          setCopyFromVerification(true);
        } else {
          setCopyFromVerification(false);
        }
      }
    });
  }, [getEmailStatus, fromAddress, campaign]);

  function handleBlurFromAddress(): void {
    setHadFocusFromAddress(true);
  }

  function handleChangeFromAddress(email?: string): void {
    setFromAddress(email ?? '');
    campaign.set({ from_address: email });
  }

  async function handleVerify(): Promise<void> {
    const updatedEmailVerification = await getEmailStatus(fromAddress);
    const updatedEmailStatus = updatedEmailVerification?.get('status') ?? 'Unverified';
    if (updatedEmailStatus === 'Success') {
      toast(t('This email address has been verified.'), { type: ToastType.SUCCESS });
      return;
    }

    if (updatedEmailStatus === 'Pending') {
      // Status was updated by getEmailStatus().
      toast(t('Check your email for a verification link.'), { type: ToastType.INFO });
      return;
    }

    const model = EmailVerifications.create({ email: campaign.get('from_address') });
    const response = await EmailVerifications.add(model);
    if (response.isErrored) {
      toast(t('There was a problem trying to send a verification email.'), {
        type: ToastType.ERROR,
      });
    } else {
      setEmailVerification(response);
      const isVerified = response.get('status') === EPinpointRequestStatus.Success;
      toast(
        isVerified
          ? t('Your email address {{fromAddress}} is verified', {
              fromAddress: campaign.get('from_address'),
            })
          : t(
              'A verification link has been sent to {{fromAddress}}. This link will expire in 24 hours.',
              { fromAddress: campaign.get('from_address') },
            ),
        { type: isVerified ? ToastType.SUCCESS : ToastType.INFO },
      );
    }
  }

  async function handleResend(): Promise<void> {
    if (emailVerification) {
      const response = await emailVerification.resend();
      if (emailVerification.isErrored) {
        toast(t('There was a problem trying to resend a verification email.'), {
          type: ToastType.ERROR,
        });
        // eslint-disable-next-line no-console
        console.error(response);
      } else {
        toast(
          t(
            'A verification link has been resent to {{fromAddress}}. This link will expire in 24 hours.',
            { fromAddress: campaign.get('from_address') },
          ),
          { type: ToastType.INFO },
        );
      }
    }
  }

  async function handleChangeCustom(newValue?: boolean): Promise<void> {
    setCopyFromVerification(!!newValue);
    if (newValue) {
      await when(() => !isPending);
      if (
        emailVerification &&
        emailVerification.get('status') === 'Success' &&
        !isEmailVerificationEmpty(emailVerification)
      ) {
        campaign.set({
          from_name: emailVerification.get('from_name'),
          address: emailVerification.get('address'),
        });
      }
    }
  }

  function createOption(inputValue: string): ISelectOption {
    return { name: inputValue, id: inputValue };
  }

  async function loadOptions(inputValue: string): Promise<ISelectOption[]> {
    const data = EmailVerifications.list({
      filters: inputValue ? { email: { $regex: inputValue, $options: 'i' } } : {},
      ordering: ['email'],
    });
    await when(() => !data.isPending);
    return data.models.map((model) => {
      const email = model.get('email');
      return { id: email, name: email };
    });
  }

  const hasDefaults = !isEmailVerificationEmpty(emailVerification);

  const isAutosend = campaign.get('_cls') === CampaignClass.AutoPinpointEmail;
  const shouldEnforceSendLimit = !isAutosend && !isVerifiedDomainSender;
  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        <NextStepButton
          campaign={campaign}
          emailVerification={emailVerification}
          key={'next'}
          onNext={onNext}
        />,
      ]}
      description={
        <Trans t={t}>
          Please provide an email address you'd like to use for this campaign. All emails sent to
          your partners or recipient list will originate from this email address.
        </Trans>
      }
      label={t('Edit Campaign: Sender Info')}
    >
      <Fieldset>
        {emailVerification && shouldEnforceSendLimit && (
          <Alert className={styles.alert} type={AlertType.warning}>
            <Trans t={t}>
              The domain for this email address has not been verified. Email campaigns from
              unverified domains are limited to 1,000 contacts. To remove this limitation,{' '}
              <a href={localUrl('settings/account/domains')}>authorize your domain</a> before
              publishing this campaign. Authorizing your domain improves deliverability and tightens
              email security.{' '}
              <a
                href={
                  'https://help.feathr.co/hc/en-us/articles/360040220793-Connecting-a-Domain-to-your-Feathr-Account'
                }
                target={'_blank'}
              >
                Learn more about authorizing your domain.
              </a>
            </Trans>
          </Alert>
        )}
        <EmailSelect
          aria-label={t('From email address')}
          createOption={createOption}
          disabled={disabled}
          helpText={t("Enter the address you'd like to send emails from.")}
          isLoading={campaign.isPending || isPending}
          label={t('From email address')}
          loadOptions={loadOptions}
          name={'from_email_address'}
          onBlur={handleBlurFromAddress}
          onChange={handleChangeFromAddress}
          onVerify={handleVerify}
          required={true}
          status={
            // Success maps to Verified in this component
            emailStatus === 'Success' ? 'Verified' : emailStatus
          }
          t={t}
          validationError={
            hadFocusFromAddress ? flattenError(stepValidationErrors.from_address) : undefined
          }
          value={campaign.get('from_address')}
        />
        {!!emailVerification && emailStatus !== 'Success' && (
          <Button onClick={handleResend} type={'link'}>
            {t('Resend verification email')}
          </Button>
        )}
      </Fieldset>
      {emailStatus === 'Success' && (
        <>
          {hasDefaults && (
            <Checkbox
              disabled={disabled}
              label={t('Use contact info from verified email address')}
              name={'use_verified_contact_info'}
              onChange={handleChangeCustom}
              value={copyFromVerification}
              wrapperClassName={styles.checkbox}
            />
          )}

          {!hasDefaults || !copyFromVerification ? (
            <Fieldset className={styles.addressGroup}>
              <Input
                attribute={'from_name'}
                disabled={disabled}
                label={t('From name')}
                model={campaign}
                name={'from_name'}
                required={true}
                type={'text'}
              />
              <AddressInput<PinpointEmailBaseCampaign>
                attribute={'address'}
                className={styles.address}
                disabled={disabled}
                isLoading={campaign.isPending}
                model={campaign}
                required={true}
                t={t}
              />
            </Fieldset>
          ) : (
            <SenderInformationWell
              address={campaign.get('address')}
              fromName={campaign.get('from_name')}
              isLoading={campaign.isPending}
              t={t}
              theme={'white'}
              width={'narrow'}
            />
          )}
        </>
      )}
    </Form>
  );
}

export default observer(PinpointEmailCampaignStepTwo);
