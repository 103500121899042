import { type IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import type {
  Account,
  Billable,
  DisplayCreative,
  Event,
  Goal,
  Segment,
  Targetable,
  Targeting,
  TTDCampaign,
  User,
} from '@feathr/blackbox';
import { Button, Form } from '@feathr/components';
import { useFlags, useStore, useUser } from '@feathr/extender/state';
import type { Model } from '@feathr/rachis';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import CampaignSummary from '../../CampaignSummary';
import SaveCampaignButton from '../SaveCampaignButton/index';
import { getGoalSegments } from '../StepGoals';
import type { ICampaignDetails } from './CampaignEdit';
import { getGeoFilters } from './CampaignEditStepThree';
import { getTargetables, getTargetSegments } from './CampaignEditStepTwo';
import TTDRecommendedOptimizationModal from './TTDRecommendedOptimizationModal';

import * as styles from './CampaignEdit.css';

interface IProps {
  onPrev: () => void;
  campaign: TTDCampaign;
  targets: IObservableArray<Targeting>;
  goals: IObservableArray<Goal>;
  creatives: IObservableArray<DisplayCreative>;
  billable?: Billable;
  account: Account;
  event: Event;
  validate: (
    details: ICampaignDetails,
    user?: User,
    noMaxBudget?: boolean,
  ) => ICampaignValidationErrors;
}

function CampaignEditStepSeven({
  campaign,
  targets,
  goals,
  creatives,
  billable,
  account,
  event,
  onPrev,
  validate,
}: IProps): JSX.Element {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const additionalBudgetParam = Number(queryParams.get('additionalBudget'));
  const { Segments, Targetables } = useStore();
  const flags = useFlags();
  const user = useUser();
  const { isSegmentCampaign, isPastEndDate, isMonetization, isPublished } = campaign;

  // Never prompt the user to increase budget if the campaign has reached its end date
  const showRecommendationsModal =
    !!additionalBudgetParam && !isPastEndDate && !isMonetization && isPublished;

  const geoFilters = getGeoFilters(targets, Targetables);
  const goalSegments = getGoalSegments(goals, Segments);

  const targetables = isSegmentCampaign
    ? getTargetSegments(targets, Segments)
    : getTargetables(targets, Targetables);

  const errors = validate(
    {
      account,
      billable,
      campaign,
      creatives,
      event,
      goals,
      goalSegments,
      targetables: !isSegmentCampaign ? (targetables as Targetable[]) : undefined,
      targetings: targets,
      targetingSegments: isSegmentCampaign ? (targetables as Segment[]) : undefined,
    },
    user,
    flags.noMaxBudget,
  );

  // Converting observables back to vanilla JavaScript.
  const grandchildModels: Model[] = [...goalSegments, ...geoFilters];
  const childModels: Model[] = [...creatives.slice(), ...goals.slice(), ...targets.slice()];
  if (targetables.length) {
    if (campaign.isSegmentCampaign) {
      grandchildModels.push(...targetables);
    } else {
      childModels.push(...targetables);
    }
  }

  function handleValidate(): ICampaignValidationErrors {
    return validate(
      {
        account,
        billable,
        campaign,
        creatives,
        event,
        goals,
        goalSegments,
        targetables: !isSegmentCampaign ? (targetables as Targetable[]) : undefined,
        targetings: targets,
        targetingSegments: isSegmentCampaign ? (targetables as Segment[]) : undefined,
      },
      user,
      flags.noMaxBudget,
    );
  }

  return (
    <Form
      actions={[
        <Button key={'prev'} onClick={onPrev}>
          Previous
        </Button>,
        <SaveCampaignButton
          campaign={campaign}
          childModels={childModels}
          grandchildModels={grandchildModels}
          key={'save'}
          shouldChangeState={true}
          validate={handleValidate}
        />,
      ]}
      className={styles.formRoot}
      description={
        <div className={styles.fieldset}>
          <p>
            Check the summary below to make sure everything is configured correctly. When you're
            ready, click Publish to launch your campaign!
          </p>
        </div>
      }
      label={'Edit Campaign: Review'}
    >
      <CampaignSummary campaign={campaign} validationErrors={errors} />
      {showRecommendationsModal && (
        <TTDRecommendedOptimizationModal campaign={campaign} validate={handleValidate} />
      )}
    </Form>
  );
}

export default observer(CampaignEditStepSeven);
