import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useRef } from 'react';

import type { Billable } from '@feathr/blackbox';
import Page from '@feathr/extender/App/Page';
import { useStore } from '@feathr/extender/state';

import BillableForm, { getLabel } from '../BillableForm';

function BillableAddPage(): JSX.Element {
  const { Billables } = useStore();

  // Get default param from page url
  const defaultParam = new URLSearchParams(window.location.search).get('default');
  const isDefault: true | undefined = defaultParam === 'true' || defaultParam === '1' || undefined;

  const billableRef = useRef<Billable | null>(null);
  // Expensive to create, so create it once
  if (billableRef.current === null) {
    billableRef.current = Billables.create();
  }

  return (
    <Page title={getLabel(true, !!isDefault)}>
      <BillableForm billable={billableRef.current} isDefault={isDefault} />
    </Page>
  );
}

export default observer(BillableAddPage);
