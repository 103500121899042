import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { Segment } from '@feathr/blackbox';
import { CardV2 as Card, Form, Input, SaveButtonValid } from '@feathr/components';
import TagsField from '@feathr/extender/components/TagsField';
import { useActionBar, useStore } from '@feathr/extender/state';

import DataPage from '../DataPage';

import * as styles from './DataSegmentSettingsPage.css';

function DataSegmentSettingsPage(): JSX.Element {
  const { t } = useTranslation();
  const { Segments } = useStore();
  const { segmentId } = useParams<{ segmentId: string }>();
  const { setRightActions } = useActionBar();

  const segment = Segments.get(segmentId);

  const errors = segment.validate(['name', 'tag_ids'], false).errors;
  const isValid = errors.length === 0;

  function handleChangeTags(tagIds: string[]): void {
    segment.set({ tag_ids: tagIds });
  }

  const { isReadOnly } = segment.permissions;

  useEffect(() => {
    setRightActions(
      <SaveButtonValid<Segment>
        disabled={isReadOnly || !isValid || !segment.isDirty}
        errorMessage={t('There was an issue saving. Please try again later.')}
        errors={errors}
        key={'finish'}
        method={'patch'}
        model={segment}
        successMessage={t('Changes have been saved.')}
      >
        {t('Save')}
      </SaveButtonValid>,
    );
  }, [segment.isDirty]);

  return (
    <DataPage
      contentClassName={styles.root}
      hideUsageSection={true}
      isGroupsSection={true}
      permissions={segment.permissions}
      sectionTitle={segment.name}
    >
      <Card>
        <Card.Header title={t('Edit Group')} />
        <Card.Content>
          <Form label={t('Edit Group')}>
            <Input
              attribute={'name'}
              disabled={isReadOnly}
              label={t('Name')}
              model={segment}
              type={'text'}
            />
            <TagsField
              context={'person_segment'}
              disabled={isReadOnly}
              label={t('Tags')}
              onChange={handleChangeTags}
              value={segment.get('tag_ids', [])}
            />
          </Form>
        </Card.Content>
      </Card>
    </DataPage>
  );
}

export default observer(DataSegmentSettingsPage);
