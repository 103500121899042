import { createTheme, MantineProvider } from '@mantine/core';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { ActionBarProvider } from '@feathr/extender/state';
import { i18n } from '@feathr/locales';
import { AuthenticatedStoreContext, createAuthenticatedStore } from '@feathr/report_components';

interface IProps {
  children: ReactNode;
}

function AppWrapper({ children }: Readonly<IProps>): JSX.Element {
  const store = createAuthenticatedStore();
  const theme = createTheme({});

  return (
    <I18nextProvider i18n={i18n}>
      <AuthenticatedStoreContext.Provider value={store}>
        <BrowserRouter>
          <MantineProvider theme={theme}>
            <ActionBarProvider>{children}</ActionBarProvider>
          </MantineProvider>
        </BrowserRouter>
      </AuthenticatedStoreContext.Provider>
    </I18nextProvider>
  );
}

export default AppWrapper;
