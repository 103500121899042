import { computed } from 'mobx';

import type { TConstraints } from '@feathr/rachis';

import { DisplayCampaign } from './display';
import type { IMonetizationAttributes } from './monetization';
import { monetizationConstraints } from './monetization';
import type { CampaignClass, ICampaignAttributes } from './types';

export enum FacebookObjectiveType {
  Reach_Legacy = 'REACH',
  Traffic_Legacy = 'LINK_CLICKS',
  Conversions_legacy = 'CONVERSIONS',
  Reach = 'OUTCOME_AWARENESS',
  Traffic = 'OUTCOME_TRAFFIC',
  Conversions = 'OUTCOME_ENGAGEMENT',
}

export const FacebookCampaignObjectiveMap = new Map<FacebookObjectiveType, string>([
  [FacebookObjectiveType.Reach_Legacy, 'Reach'],
  [FacebookObjectiveType.Traffic_Legacy, 'Traffic'],
  [FacebookObjectiveType.Reach, 'Reach'],
  [FacebookObjectiveType.Traffic, 'Traffic'],
]);

type DevicePlatforms = 'mobile' | 'desktop';
export type TPublisherPlatforms = 'facebook' | 'instagram' | 'messenger' | 'audience_network';
type FacebookPositions = 'feed' | 'right_hand_column';

export interface IFacebookExposureSettings {
  device_platforms: DevicePlatforms[];
  publisher_platforms: TPublisherPlatforms[];
  facebook_positions: FacebookPositions[];
  placement_type: string;
  age_min: number | null;
  age_max: number | null;
  target_value?: number;
}

export type TFacebookCampaign = ICampaignAttributes &
  IMonetizationAttributes & {
    readonly _cls: CampaignClass.Facebook | CampaignClass.EmailListFacebook;
    destination_url: string;
    objective_type: FacebookObjectiveType;
    targeted_countries: string[];
    exposure_settings: IFacebookExposureSettings;
    facebook_page_id: string;
    is_v2: boolean;
    publisher_platforms: TPublisherPlatforms[];
    instagram_actor_id: string;
    special_ad_category: 'NONE' | 'HOUSING' | 'EMPLOYMENT' | 'CREDIT' | 'ISSUES_ELECTIONS_POLITICS';
  };

export class FacebookCampaign<
  T extends TFacebookCampaign = TFacebookCampaign,
> extends DisplayCampaign<T> {
  public override get constraints(): TConstraints<TFacebookCampaign> {
    return {
      ...super.constraints,
      facebook_page_id: {
        presence: {
          allowEmpty: false,
        },
      },
      publisher_platforms: {
        presence: {
          allowEmpty: false,
        },
      },
      objective_type: {
        presence: {
          allowEmpty: false,
        },
      },
      targeted_countries: {
        presence: {
          allowEmpty: false,
        },
      },
      monetization_value: () => {
        if (this.isMonetization) {
          return monetizationConstraints.monetization_value;
        }
        return null;
      },
    };
  }

  @computed
  public get objectiveType(): string | undefined {
    return FacebookCampaignObjectiveMap.get(this.get('objective_type'));
  }
}
