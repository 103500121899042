import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { useStore } from '@feathr/extender/state';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';

interface IProps {
  className?: string;
  id: string;
}

function SegmentLabel({ className, id }: IProps) {
  const { Segments } = useStore();
  const segment = Segments.get(id);
  return (
    <div className={classNames({ [skeleton]: segment.isPending }, className)}>
      {segment.get('name', 'placeholder') as string}
    </div>
  );
}

export default observer(SegmentLabel);
