import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { Campaign, IDripCampaignStepSpec, IEmailVerification } from '@feathr/blackbox';
import { CampaignState, DripCampaign } from '@feathr/blackbox';
import type { IWizardStepsCompleted } from '@feathr/components';
import {
  ActionBar,
  AlertV2 as Alert,
  Button,
  ButtonValid,
  ConfirmModalV1,
  EAlertV2Type,
  Icon,
  Step,
  Steps,
  Tooltip,
  useWizardState,
  Wizard,
} from '@feathr/components';
import { useCampaign } from '@feathr/extender/hooks';
import { useAccount, useLocalUrl, useStore } from '@feathr/extender/state';
import { flattenErrors, useToggle } from '@feathr/hooks';
import type { Model } from '@feathr/rachis';

import SaveCampaignButton, { save } from '../SaveCampaignButton';
import { getGoalSegments, validateStepGoals } from '../StepGoals';
import BuilderStep from './BuilderStep';
import DetailsStep from './DetailsStep';
import { validate, validateDetails, validateGroupExclusions } from './DripCampaignEdit.utils';
import ExclusionsStep from './ExclusionsStep';
import GoalsStep from './GoalsStep';
import useDripStepValidation from './useDripStepValidation';

import * as styles from './DripCampaignEdit.css';

interface IDripCampaignEditProps {
  campaign: DripCampaign;
}

export interface IFromAddresses {
  // email: status
  [key: IEmailVerification['email']]: string;
}

function DripCampaignEdit({ campaign }: Readonly<IDripCampaignEditProps>): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const { disabled, text } = useCampaign({ campaign });

  const [isPublishModalOpen, togglePublishModalOpen] = useToggle(false);
  const history = useHistory();
  const localUrl = useLocalUrl();

  const { EmailVerifications, Goals, Segments, Templates } = useStore();
  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const goalSegments = getGoalSegments(goals.models, Segments);
  const groupExclusions = campaign.get('segments', []);

  const [stepSpecs, setStepSpecs] = useState<IDripCampaignStepSpec[]>(
    campaign.get('step_specs', []),
  );

  // Grab all the templates for the save/publish buttons
  const templates: Model[] = [];
  for (const step of stepSpecs) {
    if (step.template_id) {
      const template = Templates.get(step.template_id);
      if (template) {
        templates.push(template);
      }
    }
  }

  /*
   *  TODO: This logic is a bit duplicative. Either feed this as an arg to the drip validation
   * hook below or return it from the hook itself
   */
  const fromAddresses = stepSpecs.reduce((acc, campaign) => {
    if (campaign.from_address !== undefined) {
      acc.push(campaign.from_address);
    }
    return acc;
  }, []);
  const emailVerifications = EmailVerifications.list({
    filters: {
      email__in: fromAddresses,
    },
  });

  const {
    errors: stepOneErrors,
    firstInvalidStepIndex,
    invalidSteps,
    unverifiedFromAddresses,
  } = useDripStepValidation({
    steps: stepSpecs,
    campaign,
  });

  // Do not open any step in edit mode, regardless of validity
  const [editingStep, setEditingStep] = useState<number | null>(null);

  const stepTwoErrors = flattenErrors(validateGroupExclusions(groupExclusions));
  const stepThreeErrors = flattenErrors(validateStepGoals(goals.models, goalSegments));
  const stepFourErrors = flattenErrors(validateDetails(campaign));

  const getStepErrors = useCallback(
    (step: number): string[] => {
      // If we're loading the wizard, return no errors
      if (step === -1) {
        return [];
      }

      /*
       * Add an extra error to step 1 if the user is editing a step.
       * Sometimes a step can be valid but the user is still editing it.
       */
      if (editingStep !== null && !stepOneErrors.includes(t('Apply changes to continue'))) {
        stepOneErrors.unshift(t('Apply changes to continue'));
      }

      const map = {
        0: stepOneErrors,
        1: stepTwoErrors,
        2: stepThreeErrors,
        3: stepFourErrors,
      };

      return map[step];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      editingStep,
      stepOneErrors.length,
      stepTwoErrors.length,
      stepThreeErrors.length,
      stepFourErrors.length,
    ],
  );

  const getCompletedStepMap = useCallback((): IWizardStepsCompleted => {
    return {
      /*
       * Prevent the user from moving to the next step if there are no steps yet to validate.
       * This gives the builder the chance to create placeholder steps to work with.
       */
      0: !getStepErrors(0).length && stepSpecs.length > 0 && !emailVerifications.isPending,
      1: !getStepErrors(1).length,
      2: !getStepErrors(2).length,
      3: !getStepErrors(3).length,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailVerifications.isPending]);

  const segments = campaign
    .get('segments', [])
    .filter(({ id }) => !!id)
    .map(({ id }) => Segments.get(id));

  const childModels: Model[] = [...segments, ...goals.models.slice(), ...templates];
  const grandchildModels: Model[] = [...goalSegments];

  // Allows the wizard to know when it is ready to move to the next step
  const waitFor: () => boolean = () => {
    // Should wait for necessary models to be loaded - just a shell for now
    return !(campaign.isPending || goals.isPending);
  };

  async function preSave(newCampaign: Campaign): Promise<void> {
    if (newCampaign instanceof DripCampaign) {
      const campaignSegments = newCampaign.get('segments');
      await Promise.all(
        campaignSegments.map(async (s) => {
          if (!s.id) {
            return;
          }
          const campaignSegment = Segments.get(s.id);
          await when(() => !campaignSegment.isPending);

          if (campaignSegment.isEphemeral) {
            await Segments.add(campaignSegment);
          }
        }),
      );
    }
  }

  const { currentStep, completeStep, lastStep, onNext, onPrev, onChangeStep } = useWizardState({
    getCompletedStepMap,
    waitFor,
  });

  const steps = (
    <Steps
      completed={campaign.get('state') === CampaignState.Published ? lastStep : completeStep}
      current={currentStep}
      onChange={onChangeStep}
    >
      <Step key={1} stepIndex={0} title={t('Builder')} />
      <Step key={2} stepIndex={1} title={t('Exclusions')} />
      <Step key={3} stepIndex={2} title={t('Goals')} />
      <Step key={4} stepIndex={3} title={t('Details')} />
    </Steps>
  );

  async function handlePublish(): Promise<void> {
    await save({
      campaign,
      childModels,
      grandchildModels: [],
      shouldChangeState: true,
      t,
      accountId: account.id,
    });
    togglePublishModalOpen();
    history.push(localUrl(campaign.getItemUrl()));
  }

  const publishErrors = [
    ...flattenErrors(
      validate({
        account,
        stepOneErrors,
        campaign,
        goals: goals.models,
        goalSegments,
        groupExclusions,
        t,
      }),
    ),
  ];

  const readonly = disabled.edit && currentStep !== 2;
  const hasErrors = publishErrors.length > 0;

  const saveButton = (
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      disabled={readonly}
      grandchildModels={grandchildModels}
      key={'save'}
      name={'save_changes'}
      preSave={preSave}
      showIcon={true}
      // Make the save button the primary action on the goals step when not draft
      type={readonly ? 'primary' : 'secondary'}
    />
  );

  // Wrap the save button in a tooltip to avoid messing with the complicated innards of SaveCampaignButton
  const wrappedSaveButton = readonly ? (
    <Tooltip title={text.disableEdit}>{saveButton}</Tooltip>
  ) : (
    saveButton
  );

  const isLoading =
    campaign.isPending || goals.isPending || emailVerifications.isPending || currentStep === -1;

  return (
    <Wizard className={styles.wizard} isFullWidth={true} layout={'horizontal'} steps={steps}>
      <ActionBar
        left={
          <>
            <Button
              disabled={currentStep === 0 || isLoading}
              onClick={onPrev}
              prefix={<Icon icon={faArrowLeft} />}
            >
              {t('Previous')}
            </Button>
            <ButtonValid
              disabled={editingStep !== null || currentStep === lastStep || isLoading || undefined}
              errors={getStepErrors(currentStep)}
              name={'next_step'}
              onClick={onNext}
              suffix={<Icon icon={faArrowRight} />}
              /*
               * Only give the next button a primary type if the campaign is in draft because saving
               * and publishing become the primary action at different stages or if the campaign has
               * errors. If there are no errors, it's safe to say the campaign is publishable and
               * publish would become the pimary action.
               */
              type={!readonly && hasErrors ? 'primary' : 'secondary'}
            >
              {t('Next')}
            </ButtonValid>
          </>
        }
        right={
          <>
            {wrappedSaveButton}
            {!disabled.edit && (
              <ButtonValid
                disabled={isPublishModalOpen || editingStep !== null || isLoading || undefined}
                errors={publishErrors}
                name={'publish'}
                onClick={togglePublishModalOpen}
                prefix={<Icon icon={faCheck} />}
                tooltipPosition={'top-end'}
                type={'success'}
              >
                {t('Publish')}
              </ButtonValid>
            )}
          </>
        }
        usePortal={true}
      />

      <section className={styles.main}>
        {currentStep === 0 && (
          <BuilderStep
            campaign={campaign}
            editingStep={editingStep}
            errors={getStepErrors(currentStep)}
            firstInvalidStepIndex={firstInvalidStepIndex}
            invalidSteps={invalidSteps}
            setEditingStep={setEditingStep}
            setSteps={setStepSpecs}
            steps={stepSpecs}
            unverifiedFromAddresses={unverifiedFromAddresses}
          />
        )}
        {currentStep === 1 && <ExclusionsStep campaign={campaign} />}
        {currentStep === 2 && <GoalsStep campaign={campaign} goals={goals.models} />}
        {currentStep === 3 && <DetailsStep campaign={campaign} />}
      </section>
      {isPublishModalOpen && (
        <ConfirmModalV1
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Publish')}
          confirmButtonType={'success'}
          onClose={togglePublishModalOpen}
          onConfirm={handlePublish}
          t={t}
          title={t('Publish campaign')}
        >
          <Alert
            description={t(
              'After publishing this drip campaign, you won’t be able to edit anything other than its goals.',
            )}
            title={t('Heads up!')}
            type={EAlertV2Type.warning}
          />
        </ConfirmModalV1>
      )}
    </Wizard>
  );
}

export default observer(DripCampaignEdit);
