import capitalize from 'lodash.capitalize';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { IRerunScopeResponse, TRerunScopeVariant } from '@feathr/blackbox';
import { EConversionCategories } from '@feathr/blackbox';
import {
  AlertV2,
  EAlertV2Type,
  Input,
  ModalV1,
  SegmentedControl,
  Skeleton,
  toast,
} from '@feathr/components';
import { useStore } from '@feathr/report_components/state';

import * as styles from './RerunModal.css';

export type TContext = 'Campaign' | 'Project' | 'Flight' | 'Group';

interface IProps {
  onClose: () => void;
  onConfirm: () => Promise<void>;
  name: string;
  id: string;
  context: TContext;
}

function RerunModal({ onClose, onConfirm, name, context, id }: Readonly<IProps>): JSX.Element {
  const { Conversions } = useStore();

  const { t } = useTranslation();
  const [verification, setVerification] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [affectedConversions, setAffectedConversions] = useState<IRerunScopeResponse[] | null>(
    null,
  );
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(EConversionCategories.Campaigns);
  const [isLoadingAffectedConversions, setIsLoadingAffectedConversions] = useState(false);

  const contextName = context === 'Group' ? 'segment' : context.toLowerCase();

  const flights: IRerunScopeResponse[] = [],
    campaigns: IRerunScopeResponse[] = [],
    projects: IRerunScopeResponse[] = [];

  affectedConversions?.forEach((item) => {
    switch (item.type) {
      case EConversionCategories.Flights:
        flights.push(item);
        break;

      case EConversionCategories.Campaigns:
        campaigns.push(item);
        break;

      case EConversionCategories.Projects:
        projects.push(item);
        break;

      default:
      // Do nothing
    }
  });

  useEffect(() => {
    async function getAffectedConversions(): Promise<void> {
      try {
        setIsLoadingAffectedConversions(true);
        await Conversions.rerunScope(contextName as TRerunScopeVariant, id).then((result) => {
          setIsLoadingAffectedConversions(false);
          setAffectedConversions(result);
        });
      } catch (e) {
        setIsLoadingAffectedConversions(false);
        toast(t('There was an error retrieving affected conversions.'), {
          type: ToastType.ERROR,
        });
      }
    }

    getAffectedConversions();
  }, [Conversions, id, contextName, t]);

  function validate(): string | undefined {
    if (!isDirty) {
      return;
    }
    /*
     * Apparently if you have multiple interior spaces they render to the page as one space
     * so to keep things looking consistent, we replace multiple spaces with one space.
     */
    if (
      name
        .replace(/\s{2,}/g, ' ')
        .toLowerCase()
        .trim() !== verification.toLowerCase().trim()
    ) {
      return t('Does not match {{contextName}} name.', { contextName });
    }

    return undefined;
  }

  async function handleConfirm(): Promise<void> {
    try {
      // TODO: Should we display the message returned by onConfirm()?
      await onConfirm();
      toast(t('Conversion recalculation in progress. Check back in about 15 minutes.'), {
        type: ToastType.SUCCESS,
      });
    } catch (e) {
      const message = e instanceof Error ? e.message : String(e);
      toast(
        t(
          'Something went wrong while recalculating conversions, please try again later.\n{{- error}}',
          { error: message },
        ),
        {
          type: ToastType.ERROR,
        },
      );
    } finally {
      onClose();
    }
  }

  function handleChange(newValue?: string): void {
    setVerification(newValue ?? '');
    setIsDirty(true);
  }

  const getCategoryList = useCallback(
    (
      category: EConversionCategories,
      categoryList: IRerunScopeResponse[],
    ): JSX.Element | undefined => {
      if (activeCategoryIndex !== category) {
        return;
      }

      return categoryList.length ? (
        <ul className={styles.list}>
          {categoryList.map(({ id, name }) => {
            return <li key={id}>{name}</li>;
          })}
        </ul>
      ) : (
        <p className={styles.noResults}>{t('No results found.')}</p>
      );
    },
    [activeCategoryIndex, t],
  );

  const handleSetActiveCategoryIndex = (index: EConversionCategories) => () => {
    setActiveCategoryIndex(index);
  };

  return (
    <ModalV1
      className={styles.root}
      confirmButtonText={'Recalculate'}
      confirmDisabled={!isDirty || !!validate()}
      controlled={true}
      ephemeral={true}
      onClose={onClose}
      onConfirm={handleConfirm}
      t={t}
      title={'Recalculate Conversions'}
    >
      <>
        <AlertV2
          description={t(
            'We will make new conversions using the current Goals configuration. This process can’t be reversed, please proceed with caution.',
          )}
          title={t(
            'Recalculating conversions will remove all existing conversions for affected campaigns, flights, and projects.',
          )}
          type={EAlertV2Type.warning}
        />

        <p className={styles.body}>
          {t(
            'You can recalculate the conversions for this {{contextName}} to “reset” the conversion reporting. Old conversions will be erased and replaced with new conversions derived from {{contextName}} data.',
            { contextName },
          )}
        </p>

        <h4 className={styles.heading}>{t('Affected Items')}</h4>

        {isLoadingAffectedConversions ? (
          <>
            <Skeleton className={styles.body} title={true} />
            <Skeleton className={styles.body} paragraphs={3} />
          </>
        ) : (
          <>
            <SegmentedControl>
              <SegmentedControl.Item
                active={activeCategoryIndex === EConversionCategories.Campaigns}
                count={campaigns?.length ?? 0}
                onClick={handleSetActiveCategoryIndex(EConversionCategories.Campaigns)}
                title={t('Campaigns')}
              />
              <SegmentedControl.Item
                active={activeCategoryIndex === EConversionCategories.Flights}
                count={flights?.length ?? 0}
                onClick={handleSetActiveCategoryIndex(EConversionCategories.Flights)}
                title={t('Flights')}
              />
              <SegmentedControl.Item
                active={activeCategoryIndex === EConversionCategories.Projects}
                count={projects?.length ?? 0}
                onClick={handleSetActiveCategoryIndex(EConversionCategories.Projects)}
                title={t('Projects')}
              />
            </SegmentedControl>

            {getCategoryList(EConversionCategories.Campaigns, campaigns)}
            {getCategoryList(EConversionCategories.Flights, flights)}
            {getCategoryList(EConversionCategories.Projects, projects)}
          </>
        )}

        <p className={styles.body}>
          {t(
            'If you want to recalculate your conversion data, type the name of the {{contextName}} below and click “Recalculate”. Please allow up to 30 minutes for the recalculation process to complete.',
            { contextName },
          )}
        </p>
        <Input
          label={t('{{context}} name: {{- name}}', {
            context: capitalize(context),
            name,
          })}
          name={'name'}
          onChange={handleChange}
          placeholder={name}
          type={'text'}
          validationError={isDirty ? validate() : undefined}
          value={verification}
        />
      </>
    </ModalV1>
  );
}

export default observer(RerunModal);
