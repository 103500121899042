import { faCheckSquare, faSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { RowRenderProps } from 'react-table';

import { FieldDataType } from '@feathr/blackbox';
import { Time } from '@feathr/components';
import { moment, TimeFormat } from '@feathr/hooks';
import type { Model } from '@feathr/rachis';

import { tag as tagCN } from './DataTableCells.css';

interface IRow extends RowRenderProps {
  original: Model<any>;
}

type TCell = (fieldKey: string, path?: string, format?: TimeFormat) => (row: IRow) => JSX.Element;

export function BooleanCell(fieldKey: string, path?: string) {
  return (row: IRow): JSX.Element => {
    return (
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          const value = path ? row.original.get(path)[fieldKey] : row.original.get(fieldKey);
          const icon = value ? faCheckSquare : faSquare;
          return (
            <>
              <FontAwesomeIcon icon={icon} size={'2x'} />
            </>
          );
        }}
      </Observer>
    );
  };
}

export function DateCell(
  fieldKey: string,
  path?: string,
  format: TimeFormat = TimeFormat.shortDate,
  noDataMessage?: string,
) {
  return ({ original }: IRow): JSX.Element => {
    return (
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          const { t } = useTranslation();
          const data = path ? original.get(path)[fieldKey] : original.get(fieldKey);
          const tNever = <>{noDataMessage ?? t('Never')}</>;

          if (!data) {
            return tNever;
          }

          const dataMoment = moment.utc(data, moment.ISO_8601);
          const isNever = dataMoment.isBefore(moment.utc('2012-01-01', moment.ISO_8601));

          return !isNever ? <Time format={format} timestamp={dataMoment.toISOString()} /> : tNever;
        }}
      </Observer>
    );
  };
}

export function ListCell(fieldKey: string, path?: string) {
  return (row: IRow): JSX.Element => {
    return (
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          return (
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%', flexWrap: 'wrap' }}
            >
              {(path ? row.original.get(path)[fieldKey] : row.original.get(fieldKey)) ||
                [].map((value: string, index: number) => {
                  return (
                    // Is this a tag? Where is this in the app?
                    <span className={tagCN} key={index}>
                      {value}
                    </span>
                  );
                })}
            </div>
          );
        }}
      </Observer>
    );
  };
}

export function NumberCell(fieldKey: string, path?: string) {
  return (row: IRow): JSX.Element => {
    return (
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          return <>{path ? row.original.get(path)[fieldKey] : row.original.get(fieldKey) || '-'}</>;
        }}
      </Observer>
    );
  };
}

export function TextCell(fieldKey: string, path?: string) {
  return (row: IRow): JSX.Element => {
    return (
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          return (
            <>
              {path
                ? row.original.get(path)
                  ? row.original.get(path)[fieldKey]
                  : ''
                : row.original.get(fieldKey) || '-'}
            </>
          );
        }}
      </Observer>
    );
  };
}

export const fieldTypeToCellMap = new Map<FieldDataType, TCell>([
  [FieldDataType.str, TextCell],
  [FieldDataType.bool, BooleanCell],
  [FieldDataType.date, DateCell],
  [FieldDataType.float, NumberCell],
  [FieldDataType.int, NumberCell],
  [FieldDataType.list, ListCell],
]);
