import type { TFunction, WithT } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import type { APIKey } from '@feathr/blackbox';
import { APIKeyServiceNames } from '@feathr/blackbox';
import { Button, Fieldset, Form, SaveButtonValid } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { flattenError, flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import APIKeyServiceSelect from './APIKeyServiceSelect/APIKeyServiceSelect';

interface ISaveButtonProps {
  apiKey: APIKey;
  onSave: () => Promise<void>;
  hasServices?: boolean;
}

interface IErrors extends TValidateGrouped {
  name?: string[];
  services?: string[];
}

function getValidationErrors(apiKey: APIKey, t: TFunction, hasServices = false): IErrors {
  const validationErrors = apiKey.validate<IErrors>(['name'], false, 'grouped').errors;
  if (!hasServices) {
    validationErrors.services = [t('All available keys have been issued.')];
  }
  return validationErrors;
}

const SaveButton = observer(({ apiKey, hasServices, onSave, t }: ISaveButtonProps & WithT) => {
  const validationErrors = flattenErrors(getValidationErrors(apiKey, t, hasServices));
  return (
    <SaveButtonValid<APIKey>
      disabled={validationErrors.length > 0}
      errors={validationErrors}
      method={'add'}
      model={apiKey}
      onSave={onSave}
    >
      {t('Create')}
    </SaveButtonValid>
  );
});

function APIKeyEditPage(): JSX.Element {
  const { APIKeys } = useStore();
  const { t } = useTranslation();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { apiKeyId } = useParams<{ apiKeyId: string }>();
  const apiKey = APIKeys.get(apiKeyId);
  const apiKeysList = APIKeys.list();
  const availableServices = apiKeysList.isPending
    ? []
    : Object.values(APIKeyServiceNames).filter((service) => {
        const usedServices = apiKeysList.models.map((model) => model.name);
        return !usedServices.includes(service);
      });
  const hasServices = availableServices.length > 0;
  const validationErrors = getValidationErrors(apiKey, t, hasServices);

  async function onSave(): Promise<void> {
    history.push(localUrl('/settings/integrations/api-keys'));
  }

  function handleChangeService(newValue?: APIKeyServiceNames): void {
    apiKey.set({ service_name: newValue });
  }

  const actions = [
    <Button key={'cancel'} onClick={onSave}>
      {t('Cancel')}
    </Button>,
    <SaveButton apiKey={apiKey} hasServices={hasServices} key={'save'} onSave={onSave} t={t} />,
  ];
  return (
    <Page title={t('Generate API Key')}>
      <Form actions={actions} label={t('Create API key')}>
        <Fieldset>
          <APIKeyServiceSelect
            availableServices={availableServices}
            disabled={apiKeysList.isPending || (validationErrors.services?.length || 0) > 0}
            helpPlacement={'bottom'}
            helpText={t('The name of the third party integration you wish to authorize.')}
            key={'select'}
            label={t('Service')}
            onChange={handleChangeService}
            onClear={handleChangeService}
            validationError={
              apiKeysList.isPending ? undefined : flattenError(validationErrors.services)
            }
            value={apiKey.get('name')}
          />
        </Fieldset>
      </Form>
    </Page>
  );
}

export default observer(APIKeyEditPage);
