import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import { CampaignClass, CampaignState } from '@feathr/blackbox';

import PinpointEmailCampaignAudienceSummary from '../CampaignEditPage/PinpointEmailCampaignAudienceSummary';
import type { ISummaryProps } from './CampaignSummary.types';

const AudienceStatsSummary = observer(({ campaign }: ISummaryProps): JSX.Element | null => {
  const isSmart = campaign.get('_cls') === CampaignClass.SmartPinpointEmail;
  const stats = campaign.get('total_stats');

  if (
    !stats.num_targeted ||
    ![CampaignState.Published, CampaignState.Stopped].includes(campaign.get('state')) ||
    !campaign.isAfterDateSendStart() ||
    (isSmart && !campaign.isAfterDateSendEnd)
  ) {
    return null;
  }

  return <PinpointEmailCampaignAudienceSummary stats={stats} />;
});

AudienceStatsSummary.displayName = 'AudienceStatsSummary';

export default AudienceStatsSummary;
