import type { ITTDCampaignAttributes } from './ttd_base';
import { TTDCampaign } from './ttd_base';
import type { CampaignClass } from './types';

// Search Keyword
export type TSearchCampaign = ITTDCampaignAttributes & {
  readonly _cls: CampaignClass.Search;
};

export class SearchCampaign extends TTDCampaign<TSearchCampaign> {}
