import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { CustomField } from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import { Step, Steps, Wizard } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useStore } from '@feathr/extender/state';
import type { TValidateGrouped } from '@feathr/rachis';

import DataFieldEditStepOne from './DataFieldEditStepOne';
import DataFieldEditSummary from './DataFieldEditSummary';

export interface IDataFieldErrors extends TValidateGrouped {
  collection?: string[];
  data_type?: string[];
  description?: string[];
  u_key?: string[];
}

function validate(field: CustomField): IDataFieldErrors {
  return field.validate<IDataFieldErrors>(
    ['collection', 'data_type', 'description', 'u_key'],
    false,
    'grouped',
  ).errors;
}

function DataFieldEditPage(): JSX.Element {
  const { CustomFields } = useStore();
  // CustomFieldId is undefined when creating custom field.
  const { customFieldId } = useParams<{ customFieldId?: string }>();
  const { t } = useTranslation();

  const [field] = useState<CustomField>(
    !customFieldId
      ? CustomFields.create({ collection: FieldCollection.Person })
      : CustomFields.get(customFieldId),
  );
  const [currentStep, setCurrentStep] = useState(0);

  function handleNext(): void {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
  }

  function handlePrev(): void {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
  }

  const steps = (
    <Steps current={currentStep} onChange={setCurrentStep}>
      <Step title={t('Info')} />
      <Step title={t('Review')} />
    </Steps>
  );

  return (
    <Page
      loading={field.isPending}
      title={customFieldId === 'add' ? t('Add Custom Field') : field.name}
      width={'wide'}
    >
      <Wizard steps={steps}>
        {currentStep === 0 && <DataFieldEditStepOne field={field} onNext={handleNext} />}
        {currentStep === 1 && (
          <DataFieldEditSummary field={field} onPrev={handlePrev} validate={validate} />
        )}
      </Wizard>
    </Page>
  );
}

export default observer(DataFieldEditPage);
