import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import capitalize from 'lodash.capitalize';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Breadcrumb, CustomField, Person } from '@feathr/blackbox';
import { FieldCollection } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Button, Checkbox, Fieldset, Popover, Tooltip } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

import BreadcrumbColumns from '../BreadcrumbColumns';
import PersonColumns from '../PersonColumns';

import * as styles from './DataCustomize.css';

import defaultBreadcrumbFields from '@feathr/blackbox/fixtures/defaultBreadcrumbFields.json';
import defaultPersonFields from '@feathr/blackbox/fixtures/defaultPersonFields.json';

interface IProps {
  mode: 'persons' | 'breadcrumbs';
  columnIds: string[];
  onChange: (newColumnIds: string[]) => void;
}

interface IField {
  id: string;
  name: string;
}

function columnToLabel(
  column: IColumn<Person> | IColumn<Breadcrumb>,
  fields: IObservableArray<CustomField>,
  mode: 'persons' | 'breadcrumbs',
): string {
  const defaultFields = mode === 'persons' ? defaultPersonFields : defaultBreadcrumbFields;
  const defaultField = (defaultFields as IField[]).find((field) => field.id === column.id);
  if (defaultField) {
    return defaultField.name;
  }
  const customField = fields.find((field) => field.get('f_key') === column.id!.split('.')[1]);
  if (customField) {
    return customField.get('u_key');
  }
  return capitalize(column.id);
}

type TMode = 'persons' | 'breadcrumbs';

const modeToCollectionMap = new Map<TMode, FieldCollection>([
  ['persons', FieldCollection.Person],
  ['breadcrumbs', FieldCollection.Breadcrumb],
]);

const alwaysShownColumns = ['name', 'options'];

function DataCustomize({ columnIds, mode, onChange }: Readonly<IProps>): JSX.Element {
  const { CustomFields } = useStore();
  const { t } = useTranslation();

  const fields = CustomFields.list({
    filters: { collection: modeToCollectionMap.get(mode) },
  });

  let columns: Array<IColumn<Person>> | Array<IColumn<Breadcrumb>> = [];
  if (!fields.isPending) {
    if (mode === 'persons') {
      columns = PersonColumns(fields.models, t);
    } else {
      columns = BreadcrumbColumns(fields.models, t);
    }
  }

  function handleChange(id: string): () => void {
    return (): void => {
      const index = columnIds.indexOf(id);
      const columnVisible = index >= 0;
      if (columnVisible) {
        onChange([...columnIds.slice(0, index), ...columnIds.slice(index + 1, columnIds.length)]);
      } else {
        onChange([...columnIds, id]);
      }
    };
  }

  return (
    <Tooltip title={t('Customize columns')}>
      <Popover toggleOnClick={false}>
        <Button type={'icon-outlined'}>
          <FontAwesomeIcon icon={faCog} />
        </Button>
        <div className={styles.container}>
          <Fieldset label={t('Columns')}>
            {columns
              .filter(({ id }) => !alwaysShownColumns.includes(id!))
              .map((column: IColumn<Person> | IColumn<Breadcrumb>) => {
                const { id } = column;
                const label = columnToLabel(column, fields.models, mode);
                return (
                  <Checkbox
                    key={id}
                    label={label}
                    name={id}
                    onChange={handleChange(id!)}
                    value={columnIds.includes(id!)}
                  />
                );
              })}
          </Fieldset>
        </div>
      </Popover>
    </Tooltip>
  );
}

export default observer(DataCustomize);
