import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Location } from '@sentry/react/types/types';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useParams } from 'react-router';
import { Redirect } from 'react-router-dom';

import { templateTypeLabel } from '@feathr/blackbox';
import { Chip, Skeleton, Tab } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import TemplateEditor, { BannerTemplateEditor } from '@feathr/extender/components/TemplateEditor';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { templateTypeIcon, templateTypeTheme } from '@feathr/extender/styles/template';
import { useRedirect } from '@feathr/hooks';

import TemplateMetadataForm from './TemplateMetadataForm';

import * as styles from './TemplatePage.css';

function TemplateEditPage(): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const { templateId, tab } = useParams<{ templateId: string; tab: string }>();
  const [, setRedirect, hasRedirect] = useRedirect();
  const { Templates } = useStore();
  const template = Templates.get(templateId);
  const isBannersnackBannerTemplate = template.isBanner && !!template.get('bannersnack_enabled');
  const EditorComponent = isBannersnackBannerTemplate ? BannerTemplateEditor : TemplateEditor;

  if (template.get('default') === true || template.isReadOnly === true) {
    return <Redirect to={localUrl(template.getItemUrl())} />;
  }

  /*
   * To prompt the user, return a string. To allow the user to continue without
   * prompting, return true.
   */
  function promptUnsavedChanges(location: Location): string | boolean {
    const path = location.pathname;
    /*
     * Changes persist when tabbing between Design and Metadata, so
     * we don't want to prompt about unsaved changes unless navigating away
     * from the editor.
     */
    const shouldNotPrompt = path.endsWith('metadata') || path.endsWith('design');
    if (template.isDirty && !shouldNotPrompt) {
      return t(
        'You have unsaved changes to your template. Are you sure you want to leave without saving?',
      );
    }
    return true;
  }

  const tabs = [
    <Tab
      key={'design'}
      // Keep including redirect in url, but only if it's already set.
      link={hasRedirect ? setRedirect('design', undefined, true) : 'design'}
      title={'Design'}
    />,
    <Tab
      key={'metadata'}
      // Keep including redirect in url, but only if it's already set.
      link={hasRedirect ? setRedirect('metadata', undefined, true) : 'metadata'}
      title={'Metadata'}
    />,
  ];

  return (
    <Page
      description={
        <div className={styles.metadata}>
          <Chip>{`Version: ${template.get('version')}`}</Chip>
          <Chip
            prefix={
              template.isPending ? null : (
                <FontAwesomeIcon icon={templateTypeIcon(template.get('_cls'))} />
              )
            }
            theme={templateTypeTheme(template.get('_cls'))}
          >
            {templateTypeLabel(template.get('_cls'))}
          </Chip>
        </div>
      }
      tabs={!isBannersnackBannerTemplate ? tabs : undefined}
      title={template.name}
    >
      <Prompt message={promptUnsavedChanges} />
      {template.isPending ? (
        <Skeleton paragraphs={3} title={true} />
      ) : (
        <section className={styles.content}>
          {!tab || (tab === 'design' && <EditorComponent template={template} />)}
          {tab === 'metadata' && <TemplateMetadataForm template={template} />}
        </section>
      )}
    </Page>
  );
}

export default observer(TemplateEditPage);
