import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, NavItemHeader, Notification, NotificationList, Popover } from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';

import * as styles from './UnreadNotifications.css';

interface IProps {
  className?: string;
}

function UnreadNotifications({ className }: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const { UserNotifications } = useStore();

  const userNotifications = UserNotifications.list({
    filters: {
      is_read__ne: true,
    },
    pagination: {
      page_size: 3,
    },
    ordering: ['-_id'],
  });

  const showBadge = !userNotifications.isPending && userNotifications.models.length > 0;

  async function handleMarkAsRead(notificationId: string): Promise<void> {
    const userNotification = userNotifications.models.find(
      (un) => un.get('notification_id') === notificationId,
    );
    await userNotification?.read();
  }

  return (
    <Popover align={'end'} position={'right'} toggle={'onClick'}>
      <NavItemHeader
        prefix={
          showBadge ? (
            <Badge dotClassName={styles.unreadBadgeDot} size={'small'} status={'unread'}>
              <FontAwesomeIcon className={styles.icon} icon={faBell} />
            </Badge>
          ) : (
            <FontAwesomeIcon className={styles.icon} icon={faBell} />
          )
        }
      >
        {t('Notifications')}
      </NavItemHeader>
      <NotificationList
        className={classNames(styles.list, className)}
        isLoading={userNotifications.isPending}
        seeMoreLink={'/notifications'}
      >
        {userNotifications.models.length ? (
          userNotifications.models.map((userNotification) => {
            const notification = userNotification.notification;
            if (notification.isPending) {
              return (
                <li key={'loading'}>
                  <Notification isLoading={true} />
                </li>
              );
            }
            const level = notification.get('level');

            return (
              <li key={notification.id}>
                <Notification
                  className={className}
                  created={notification.get('timestamp')}
                  icon={notification.getIcon()}
                  id={notification.id}
                  isRead={userNotification.get('is_read')}
                  level={level}
                  link={localUrl(notification.getCta())}
                  markAsRead={level !== 2 ? handleMarkAsRead : undefined}
                  message={notification.getMessage()}
                  t={t}
                  title={notification.get('title')}
                />
              </li>
            );
          })
        ) : (
          <li className={styles.noResults} key={'no-data'}>
            <em>{t('You have no unread notifications.')}</em>
          </li>
        )}
      </NotificationList>
    </Popover>
  );
}

export default observer(UnreadNotifications);
