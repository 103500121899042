import { faChartPie } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';

import { Chip } from '@feathr/components';
import { useLocalUrl, useStore } from '@feathr/extender/state';

interface IProps {
  id: string;
  linkToItem?: boolean;
}

function SegmentChip({ id, linkToItem = true }: IProps) {
  const { Segments } = useStore();
  const localUrl = useLocalUrl();

  const segment = Segments.get(id);
  if (segment.isErrored) {
    return null;
  }

  if (segment.isPending) {
    return <Chip isLoading={true} />;
  }

  const content = (
    <Chip prefix={<FontAwesomeIcon icon={faChartPie} />}>
      {segment.name}
      {segment.get('is_archived') ? ' (Archived)' : ''}
    </Chip>
  );

  if (linkToItem) {
    return <Link to={localUrl(segment.getItemUrl())}>{content}</Link>;
  }
  return content;
}

export default observer(SegmentChip);
