import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Campaign, Event, TAttributionModel } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { Button, TableStatsCard, toast } from '@feathr/components';
import { useToggle } from '@feathr/hooks';
import type { IRachisMessage } from '@feathr/rachis';
import { isWretchError, wretch } from '@feathr/rachis';
import { useStore } from '@feathr/report_components/state';

import DailyStatsTable from '../DailyStatsTable';
import ExportModal from '../ExportModal';

interface IProps {
  attributionModel?: TAttributionModel;
  end: string;
  object: Event | Campaign;
  showConversions?: boolean;
  showSpend?: boolean;
  start: string;
}

function DailyStatsTableCard({
  attributionModel,
  end,
  object,
  showConversions,
  showSpend,
  start,
}: Readonly<IProps>): JSX.Element {
  const { Stats, FacebookCampaignInsights } = useStore();
  const [showExportModal, toggleExportModal] = useToggle(false);
  const { t } = useTranslation();
  const className = object.attributes._cls;
  const isFacebookCampaign = className.includes('Facebook');
  const getCampaignModel = isFacebookCampaign ? 'facebook_campaign' : 'campaign';
  const stats = Stats.list({
    filters: {
      metadata__date__lte: end,
      metadata__date__gte: start,
      metadata__obj_id: object.id,
    },
    model: className.includes('Event') ? 'event' : getCampaignModel,
  });

  async function handleConfirmExport(email): Promise<void> {
    const exportParams = new URLSearchParams({
      daily: '1',
      start,
      end,
      email,
    });
    await wretch<IRachisMessage>(
      `${object.collection!.url()}${object.id}/export?${exportParams.toString()}`,
      {
        method: 'GET',
        headers: object.collection!.getHeaders(),
      },
    );
  }

  async function handleSyncFacebookCampaignStats(): Promise<void> {
    const params = new URLSearchParams({
      campaign_id: object.id,
    });
    const response = await FacebookCampaignInsights.sync(params);
    if (isWretchError(response)) {
      toast(
        t('Something went wrong while syncing stats: {{- message}}', {
          message: response.error.message,
        }),
        { type: ToastType.ERROR },
      );
    } else {
      toast(t('Campaign stats syncing is in progress. Please check back in a few minutes.'), {
        type: 'success',
      });
    }
  }

  const actions = [
    isFacebookCampaign && (
      <Button key={'sync'} onClick={handleSyncFacebookCampaignStats}>
        {t('Sync stats')}
      </Button>
    ),
    <Button key={'export'} onClick={toggleExportModal}>
      {t('Export to CSV')}
    </Button>,
  ];

  return (
    <>
      <TableStatsCard actions={actions} title={reportModuleLabels.includeDailyStatsTable}>
        <DailyStatsTable
          attributionModel={attributionModel ?? 'full'}
          isPending={stats.isPending}
          showConversions={showConversions}
          showSpend={showSpend}
          stats={stats.models}
        />
      </TableStatsCard>
      {showExportModal && (
        <ExportModal
          onClose={toggleExportModal}
          onConfirm={handleConfirmExport}
          title={t('Export Daily Stats')}
        />
      )}
    </>
  );
}

export default observer(DailyStatsTableCard);
