import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { IConversionCategoryFilters as IFilters } from '@feathr/blackbox';
import { AsyncSelect } from '@feathr/components';
import { useStore } from '@feathr/report_components/state';

import * as styles from './ConversionTableCategorySelect.css';

interface IOption {
  value: string;
  label: string;
}

interface IProps {
  onSelectCategory: (categories: IOption[]) => void;
  values: string[] | undefined;
  filters: IFilters;
}

function ConversionTableCategorySelect({
  onSelectCategory,
  values,
  filters,
}: Readonly<IProps>): JSX.Element {
  const { Conversions } = useStore();
  const state = useLocalObservable(() => ({
    options: [] as IOption[],
    setOptions: (options: IOption[]): void => {
      state.options = options;
    },
  }));

  async function loadCategoryOptions(): Promise<IOption[]> {
    // eslint-disable-next-line
    const { category__in, ...rest } = filters;
    const categories = await Conversions.getCategories(rest);
    state.setOptions(categories.map((cat: string) => ({ label: cat, value: cat })));
    return state.options;
  }

  function getOptionLabel(option: IOption): string {
    return option.label;
  }

  function getOptionValue(option: IOption): string {
    return option.value;
  }

  function filterOption({ label }: IOption, inputValue: string): boolean {
    return label.toLowerCase().includes(inputValue.toLowerCase());
  }

  return (
    <AsyncSelect<IOption>
      defaultOptions={true}
      filterOption={filterOption}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isClearable={true}
      isMulti={true}
      label={'Filter by'}
      loadOptions={loadCategoryOptions}
      name={'category'}
      onSelectMulti={onSelectCategory}
      placeholder={'Category'}
      value={values ? state.options.filter((option) => values.includes(option.value)) : undefined}
      wrapperClassName={styles.select}
    />
  );
}

export default observer(ConversionTableCategorySelect);
