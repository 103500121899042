export { default as useAccount, NoAccountError } from './useAccount';
export { default as useFlags, Flags } from './useFlags';
export { default as useRole } from './useRole';
export { type TUseRole } from './useRole';
export { default as useUser, NoUserError } from './useUser';
export {
  AuthenticatedStore,
  AuthenticatedStoreContext,
  createAuthenticatedStore,
  default as useAuthenticatedStore,
} from './useAuthenticatedStore';
export {
  createReportStore,
  ReportStore,
  ReportStoreContext,
  default as useReportStore,
} from './useReportStore';
export {
  default as useStore,
  assertAuthenticatedStore,
  isAuthenticatedStore,
  NoAuthenticatedStoreError,
} from './useStore';

export enum EReportFlavors {
  ad_click = 'ad_click',
  ad_view = 'ad_view',
  email_link_click = 'email_link_click',
  email_view = 'email_view',
  page_link_click = 'page_link_click',
  page_view = 'page_view',
  pinpoint_tracked_email_click = 'pinpoint_tracked_email_click',
  pinpoint_tracked_email_complaint = 'pinpoint_tracked_email_complaint',
  pinpoint_tracked_email_delivered = 'pinpoint_tracked_email_delivered',
  pinpoint_tracked_email_hardbounce = 'pinpoint_tracked_email_hardbounce',
  pinpoint_tracked_email_open = 'pinpoint_tracked_email_open',
  pinpoint_tracked_email_send = 'pinpoint_tracked_email_send',
  pinpoint_tracked_email_softbounce = 'pinpoint_tracked_email_softbounce',
  pinpoint_tracked_email_suppression = 'pinpoint_tracked_email_suppression',
  email_preferences_update = 'email_preferences_update',
}
