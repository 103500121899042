import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useHistory } from 'react-router';
import type { ValueType } from 'react-select';

import type { IAddOn, ILicense, IPackage, IService } from '@feathr/blackbox';
import type { Billable } from '@feathr/blackbox';
import {
  Button,
  Checkbox,
  Fieldset,
  Form,
  Input,
  Popover,
  Spinner,
  Textarea,
  toast,
  Toolbar,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import BillableSelect from '@feathr/extender/components/BillableSelect';
import { useAccount, useLocalUrl, useStore, useUser } from '@feathr/extender/state';

import * as styles from '../LicensePage.css';

function LicenseEditPage(): JSX.Element {
  const { Billables } = useStore();
  const user = useUser();
  const account = useAccount();
  const history = useHistory();
  const localUrl = useLocalUrl();

  if (account.isPending || user.isPending) {
    return <Spinner />;
  }
  const license = account.get('license', {
    add_ons: [] as IAddOn[],
    autorenew: false,
    discounts: [] as IService[],
    packages: [] as IPackage[],
    period: {},
    services: [] as IService[],
  } as ILicense);
  const billable = license.billable ? Billables.get(license.billable) : undefined;

  function handleChange(value: ValueType<Billable>): void {
    if (!value || value instanceof Array) {
      return;
    }
    account.set({
      license: {
        ...license,
        billable: (value as Billable).id,
      },
    });
  }

  async function handleSave(): Promise<void> {
    try {
      await account.patchDirty();
      toast('License billing configuration successfully updated.', { type: 'success' });
      history.push(localUrl('/settings/billing/license'));
    } catch (e) {
      toast(
        'There was an issue saving changes to the license billing configuration. Please try again later.',
        { type: 'error' },
      );
      throw e;
    }
  }

  const referenceIDLabel = (
    <>
      Reference Number
      <Popover toggle={'onMouseOver'}>
        <span className={styles.info}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </span>
        <div className={styles.popover}>
          <p>
            Optional reference identification field (such as a PO number) that will be included on
            license invoices.
          </p>
        </div>
      </Popover>
    </>
  );

  function handleChangeReferenceId(newValue?: string): void {
    account.set({
      license: {
        ...license,
        reference_id: newValue,
      },
    });
  }

  function handleChangeNote(newValue?: string): void {
    account.set({
      license: {
        ...license,
        note: newValue,
      },
    });
  }

  function handleChangeAutorenew(): void {
    account.set({
      license: {
        ...license,
        autorenew: !license.autorenew,
      },
    });
  }

  function handleChangeAgency(): void {
    account.set({
      is_agency: !account.get('is_agency'),
    });
  }

  return (
    <Page title={'License Settings'}>
      <Form label={'License Settings Form'}>
        <BillableSelect label={'Billing configuration'} onChange={handleChange} value={billable} />
        <Input
          label={referenceIDLabel}
          onChange={handleChangeReferenceId}
          placeholder={'PO-12345678'}
          type={'text'}
          value={license.reference_id}
        />
        {user.isSudoer && (
          <Fieldset
            helpText={'These inputs are only visible to CSMs and other internal Feathr users.'}
            label={'Admin Controls'}
          >
            <Checkbox
              helpText={
                'If this box is checked your license package and any renewable services will automatically renew 60 days before your current license package expires. You will be billed with your chosen payment method at that time.'
              }
              label={'Autorenew'}
              onChange={handleChangeAutorenew}
              value={license.autorenew}
            />
            <Checkbox
              helpText={
                'If this box is checked, the users of this account will have access to the "Advertisers" UI, which will allow them to create "Advertisers" to associate with projects and campaigns. This is necessary because TTD needs all campaigns to be associated with an Advertiser object that actually corresponds to the organization the campaign is advertising on behalf of.'
              }
              label={'Is Agency'}
              onChange={handleChangeAgency}
              value={account.get('is_agency')}
            />
            <Textarea
              helpText={
                'Keep track of anything exceptional or worth noting about how this license should be handled.'
              }
              label={'Notes'}
              onChange={handleChangeNote}
              value={license.note}
            />
          </Fieldset>
        )}
        <Toolbar>
          <Button onClick={handleSave} type={'success'}>
            Save
          </Button>
        </Toolbar>
      </Form>
    </Page>
  );
}

export default observer(LicenseEditPage);
