import { ObjectId } from 'bson';
import type AggregateError from 'es-aggregate-error';
import isEqualWith from 'lodash.isequalwith';
import Md5 from 'md5.js';
import type { IObservableArray } from 'mobx';
import {
  action,
  computed,
  get,
  isObservable,
  makeObservable,
  observable,
  remove,
  runInAction,
  set,
  toJS,
} from 'mobx';
import { computedFn } from 'mobx-utils';
import { nanoid } from 'nanoid';
import type { AsyncValidateOption } from 'validate.js';

import { flattenErrors } from '@feathr/hooks';

import type { Attributes, Collection } from './collection';
import type { IValidateAsyncConstraint, IValidateGlobalOptions } from './validate';
import validatejs from './validate';
import type { IPhoneConstraint } from './validate/phone';
import type { IPresenceConstraint, IPresenceUnlessConstraint } from './validate/presenceUnless';

export type Keys<T> = Extract<keyof T, string> extends string ? string : never;

// TODO: Make this a real deep observable type; now it's a shallow observable type (might require typescript >= 3.7)
export type DeepObservable<T> = T extends any[] ? IObservableArray<T[number]> : T;

export interface IBaseAttributes extends Record<string, any> {
  id: string;
  is_archived?: boolean;
  type: string;
}

export type TOperationType = 'delete' | 'insert' | 'replace' | 'update';

interface IMongoId {
  _id: string;
}

export interface IDBChange<T extends IBaseAttributes> {
  change: {
    operationType: TOperationType;
    clusterTime: string;
    ns: {
      db: string;
      coll: string;
    };
    documentKey: IMongoId;
    // MongoDB returns _id instead of id.
    fullDocument?: T & IMongoId;
    updateDescription: {
      updatedFields: Partial<T>;
      /*
       * Cannot use Array<keyof T> because IBaseAttributes extends
       * Record<string, any> and it will always return string | number.
       */
      removedFields: string[];
    };
  };
}

export type TConstraint<T extends Record<string, any>> =
  | ((
      value: any,
      attributes: any,
      key: string,
      globalOptions: IValidateGlobalOptions,
      constraints: TConstraints<T>,
    ) => T | undefined)
  | T;

export interface IConstraint {
  array?: { [validator: string]: TConstraint<any> };
  async?: IValidateAsyncConstraint;
  phone?: IPhoneConstraint;
  presence?: TConstraint<IPresenceConstraint> | true;
  presenceUnless?: TConstraint<IPresenceUnlessConstraint>;
  [validator: string]: TConstraint<any>;
}

export type TConstraints<T extends Record<string, any>> = {
  [P in keyof T]?: IConstraint;
};

export interface IValidateGrouped {
  [key: string | number]: string | string[] | IValidateGrouped | IValidateGrouped[] | undefined;
}
export type TValidateGrouped = IValidateGrouped;

interface IFetchParams {
  pagination?: boolean;
  filters?: { [s: string]: any };
}

interface IDetailedValidation {
  attribute: string;
  value: string;
  validator: string;
  globalOptions: { format: 'detailed' };
  attributes: Record<string, string>;
  options: Record<string, any>;
  error: string;
}

export type TValidateDetailed = IDetailedValidation[];

export interface IValidation<T> {
  attributes: string[];
  errors: DeepObservable<T>;
  isPending: boolean;
}

function isEqualWithObservable(value: any, other: any): boolean | undefined {
  const isValueObservable = isObservable(value);
  const isOtherObservable = isObservable(other);

  if (isValueObservable || isOtherObservable) {
    return isEqualWith(
      isValueObservable ? toJS(value) : value,
      isOtherObservable ? toJS(other) : other,
      isEqualWithObservable,
    );
  }
  // Returning undefined causes isEqual to be used.
  return undefined;
}

export abstract class Model<IAttributes extends IBaseAttributes = IBaseAttributes> {
  public get listId(): string {
    if (this.id) {
      return this.id;
    }
    if (!this._listId) {
      this._listId = `lid-${nanoid(12)}`;
    }
    return this._listId;
  }

  @computed
  public get isDirty(): boolean {
    return this.dirtyAttributes.size > 0;
  }

  @computed
  public get isReadOnly(): boolean {
    return this.get('read_only') || false;
  }

  public abstract get constraints(): TConstraints<IAttributes>;

  public id: string;

  // tslint:disable-next-line variable-name
  public _id: string;

  public abstract readonly className: string;

  public collection: Collection<this> | null = null;

  @observable public isPending = false;

  @observable public isReplaced = false;

  @observable public isUpdating = false;

  @observable public isEphemeral = false;

  @observable public isFetched = false;

  @observable public isErrored = false;

  @observable public error: AggregateError | null = null;

  // Typescript workaround to store the attributes type.
  public attributesType: IAttributes = {} as IAttributes;

  @observable public attributes: Partial<IAttributes>;

  @observable public initialAttributes: Partial<IAttributes>;

  @observable public dirtyAttributes = new Set<string>();

  @observable public only = new Set<string>();

  public validateCache: Record<string, IValidation<any>> = {};

  // Keep a copy of original attributes.
  @observable public shadowAttributes: Partial<IAttributes> = {};

  // tslint:disable-next-line
  public _listId: string | undefined;

  private isValidComputed: (attributes: string[], onlyCheckDirty: boolean) => boolean = computedFn(
    (attributes: string[], onlyCheckDirty: boolean): boolean => {
      // @ts-ignore: mobx needs this to ensure isValid updates when values change.
      const _ = this.dirtyAttributes.size;
      const validation = this.validate(attributes, onlyCheckDirty);
      return Object.entries(validation.errors).length === 0;
    },
    // Only set keepAlive if we're in test mode
    { keepAlive: process.env.NODE_ENV === 'test' },
  );

  public constructor(initialAttributes: Partial<IAttributes> = {}) {
    makeObservable(this);
    this.id = initialAttributes.id ? initialAttributes.id! : new ObjectId().toHexString();
    this._id = this.id;
    const attributes = {
      ...this.getDefaults(),
      ...initialAttributes,
    };
    this.attributes = attributes;
    this.initialAttributes = attributes;
  }

  /**
   * Check if model passes validation.
   *
   * @param attributes The attributes to check. Pass in an empty array to check all.
   * @param onlyCheckDirty Whether or not to only check dirty attributes.
   */
  public isValid(attributes?: string[], onlyCheckDirty?: boolean): boolean {
    return this.isValidComputed(
      attributes && attributes.length > 0 ? attributes : Object.keys(this.constraints),
      !!onlyCheckDirty,
    );
  }

  public validate(
    attributes: string[],
    onlyCheckDirty?: boolean,
    format?: 'flat',
  ): IValidation<string[]>;

  public validate<T extends TValidateGrouped = TValidateGrouped>(
    attributes: string[],
    onlyCheckDirty?: boolean,
    format?: 'grouped',
  ): IValidation<T>;

  public validate(
    attributes: string[],
    onlyCheckDirty?: boolean,
    format?: 'detailed',
  ): IValidation<TValidateDetailed>;

  public validate(
    attributes: string[] = [],
    onlyCheckDirty = true,
    format: 'flat' | 'detailed' | 'grouped' = 'flat',
  ): IValidation<any> {
    this.checkReplaced();

    const constrainedAttributes =
      attributes.length > 0 ? attributes : Object.keys(this.constraints);

    const values = this.toJS();

    const constraints: TConstraints<IAttributes> = Object.keys(this.constraints)
      .filter((constraint) => {
        return constrainedAttributes.includes(constraint);
      })
      .filter((constraint) => {
        // Use root attribute for checking dirty with nested validation.
        return onlyCheckDirty ? this.isAttributeDirty(constraint.split('.')[0]) : true;
      })
      .reduce(
        (previousValue, currentValue) => ({
          [currentValue]: this.constraints[currentValue],
          ...previousValue,
        }),
        {},
      );

    function replacer(_: string, value: any): any {
      if (value === undefined) {
        return null;
      }
      return value;
    }

    const key: string = new Md5()
      .update(
        `${JSON.stringify(values, replacer)}${JSON.stringify(
          constrainedAttributes,
          replacer,
        )}${format}`,
      )
      .digest('hex');
    let validation = this.validateCache[key];
    const cacheExists = !!validation;
    const hasAsyncConstraints = !!Object.keys(constraints).find(
      (k) => !!constraints[k] && constraints[k]!.async,
    );

    async function asyncFetch(model: Model<IAttributes>, v8n: IValidation<any>): Promise<void> {
      let errors: string[] | TValidateGrouped | TValidateDetailed;
      if (!hasAsyncConstraints) {
        // Need to pass additional options to custom validators.
        errors = validatejs.validate(values, constraints, {
          format,
          // @ts-ignore: we're passing in an additional property here because we use it in our custom validators
          onlyCheckDirty,
          model,
        });
      } else {
        try {
          // The result is not used, because it only returns when validation passes
          await validatejs.async(values, constraints, {
            format,
            onlyCheckDirty,
            model,
          } as IValidateGlobalOptions & AsyncValidateOption);

          // If err is instance of Error, it should be of type any.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: string[] | TValidateGrouped | TValidateDetailed | any) {
          if (error instanceof Error) {
            // An exception was thrown by a validator.
            throw error;
          } else {
            // Validation failed.
            switch (format) {
              case 'flat':
                // Return of async is always in grouped format
                errors = flattenErrors(error);
                break;

              case 'grouped':
              // Same as detailed.

              case 'detailed':
                errors = error;
                break;
            }
          }
        }
      }
      runInAction(() => {
        switch (format) {
          case 'flat':
            (v8n.errors as unknown as IObservableArray<string>).replace((errors || []) as string[]);
            break;

          case 'grouped':
            set(v8n, 'errors', errors || {});
            break;

          case 'detailed':
            (v8n.errors as unknown as IObservableArray<IDetailedValidation>).replace(
              (errors || []) as TValidateDetailed,
            );
            break;
        }
        set(v8n, { isPending: false });
      });
    }

    if (!cacheExists) {
      validation = observable({
        attributes,
        errors:
          format === 'flat'
            ? (observable([] as string[]) as IValidation<string[]>['errors'])
            : format === 'grouped'
              ? (observable<TValidateGrouped>({}) as IValidation<TValidateGrouped>['errors'])
              : observable<TValidateDetailed>([]),
        isPending: true,
      });
      if (hasAsyncConstraints) {
        this.validateCache[key] = validation;
      }
      asyncFetch(this, validation);
    }

    return validation;
  }

  public getDefaults(): Partial<IAttributes> {
    return {};
  }

  public fetchParams(): IFetchParams {
    return {};
  }

  public has(attribute: string): boolean {
    this.checkReplaced();
    // If only is empty, all attributes are fetched.
    return this.only.size === 0 || this.only.has(attribute);
  }

  /**
   * Returns value of attribute
   * @param attribute
   */
  public get<K extends keyof IAttributes & string>(attribute: K): DeepObservable<IAttributes[K]>;
  public get<K extends keyof IAttributes & string>(
    attributes: K[],
  ): { [P in K]: DeepObservable<IAttributes[P]> };

  /**
   * Returns value of attribute; defaultValue when value is not set
   * Note: defaultValue is not returned when value is falsey, but when value is null or undefined
   *
   * @param attribute
   * @param defaultValue
   */
  public get<K extends keyof IAttributes & string>(
    attribute: K,
    defaultValue: Exclude<IAttributes[K], undefined>,
  ): DeepObservable<Exclude<IAttributes[K], undefined>>;

  public get<K extends keyof IAttributes & string>(
    attributeOrAttributes: K | K[],
    defaultValue?: any,
  ): DeepObservable<IAttributes[K]> | { [P in K]: DeepObservable<IAttributes[P]> } {
    this.checkReplaced();

    if (Array.isArray(attributeOrAttributes)) {
      // Handle the case where an array of attributes is passed
      return Object.fromEntries(
        attributeOrAttributes.map((attribute) => [
          attribute,
          this.get(attribute, defaultValue) as DeepObservable<IAttributes[K]>,
        ]),
      ) as { [P in K]: DeepObservable<IAttributes[P]> };
    }

    // Handle the case where a single attribute is passed
    const value = get(this.attributes, attributeOrAttributes);

    // Return defaultValue if value is undefined or null
    if (value === undefined || value === null) {
      return defaultValue;
    }

    return value;
  }

  @action.bound
  public set(patch: Partial<IAttributes>, dirty = true): void {
    this.checkReplaced();
    Object.keys(patch).forEach((attributeKey: keyof IAttributes) => {
      // Only set shadowAttribute if not already set; don't want to overwrite initial value.
      if (this.shadowAttributes[attributeKey] === undefined) {
        // Keep track of initial value for reference.
        this.shadowAttributes[attributeKey] = this.attributes[attributeKey];
      }
    });
    // Only update changed data.
    const diff = this.diff(patch);
    const diffPatch = Object.keys(patch)
      .filter((key) => diff.includes(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: patch[key],
        };
      }, {} as Partial<IAttributes>);
    set(this.attributes, diffPatch);
    if (dirty) {
      this.setAttributeDirty(...diff);
    }
  }

  @action.bound
  public unset(attribute: string, dirty = true): void {
    this.checkReplaced();
    remove(this.attributes, attribute);
    if (dirty) {
      this.setAttributeDirty(attribute);
    }
  }

  public toJS(): IAttributes {
    this.checkReplaced();
    return toJS(this.attributes) as IAttributes;
  }

  public archive(): Promise<void> {
    this.checkReplaced();
    if (this.collection && this.id) {
      return this.collection.archive(this.id);
    }

    throw new Error('Models need to have ids to archive');
  }

  public clone(data: Partial<IAttributes>): Promise<this> {
    this.checkReplaced();
    if (this.collection && this.id) {
      return this.collection.clone(this.id, data as Partial<Attributes<this>>);
    }

    throw new Error('Models need to have ids to clone');
  }

  public save(patch: Partial<IAttributes> = {}): Promise<this> {
    this.checkReplaced();
    if (this.only.size) {
      throw new Error('Projections are read-only');
    }

    if (this.collection && this.id) {
      return this.collection.save(this.id, patch as Partial<Attributes<this>>);
    }

    throw new Error('Models need to have ids to save');
  }

  public patch(
    patch: Partial<IAttributes>,
    fetchOptions: Partial<RequestInit> = {},
  ): Promise<this> {
    this.checkReplaced();
    if (this.only.size) {
      throw new Error('Projections are read-only');
    }
    if (this.collection && this.id) {
      return this.collection.patch(this.id, patch as Partial<Attributes<this>>, {
        method: 'PATCH',
        ...fetchOptions,
      });
    }

    throw new Error('Models need to have ids to save');
  }

  public reload(only?: Array<Keys<Attributes<this>>>): Promise<this> {
    this.checkReplaced();
    if (this.collection && this.id) {
      return this.collection.reload(this.id, only);
    }

    throw new Error('Models need to have ids to save');
  }

  public patchDirty(): Promise<this> {
    this.checkReplaced();
    const patch = [...this.dirtyAttributes].reduce(
      (previousValue, currentValue: keyof IAttributes) => {
        previousValue[currentValue] = this.get(currentValue as Keys<IAttributes>);
        return previousValue;
      },
      {} as Partial<IAttributes>,
    );
    return this.patch(patch);
  }

  public delete(): Promise<void> {
    this.checkReplaced();
    if (this.collection && this.id) {
      return this.collection.delete(this.id);
    }

    throw new Error('Models need to have ids to delete');
  }

  public diff(patch: Partial<IAttributes>): string[] {
    return Object.keys(patch).filter(
      (key) => !isEqualWith(this.attributes[key], patch[key], isEqualWithObservable),
    );
  }

  public discardDirty(): void {
    // Reset dirty attributes to initial values
    this.dirtyAttributes.forEach((attribute) => {
      this.attributes[attribute as keyof IAttributes] = this.shadowAttributes[attribute];
    });
    this.dirtyAttributes.clear();
  }

  public isAttributeDirty(attribute: string): boolean {
    return this.dirtyAttributes.has(attribute);
  }

  @action
  public setAttributeClean(...attributes: string[]): void {
    attributes.forEach((attribute) => {
      this.dirtyAttributes.delete(attribute);
      // If an attribute is considered clean, it should have no shadow attribute.
      delete this.shadowAttributes[attribute];
    });
  }

  @action
  public setAttributeDirty(...attributes: string[]): void {
    attributes.forEach((attribute) => {
      this.dirtyAttributes.add(attribute);
    });
  }

  @action
  public setAttributeFetched(...attributes: string[]): void {
    attributes.forEach((attribute) => {
      this.only.add(attribute);
    });
  }

  @action
  public clearAttributeFetched(): void {
    if (this.only.size) {
      this.only.clear();
    }
  }

  public getReplacement(): this {
    if (this.isReplaced && this.collection) {
      return this.collection.get(this.id);
    }
    return this;
  }

  protected checkReplaced(): void {
    if (process.env.NODE_ENV !== 'production' && this.isReplaced) {
      // eslint-disable-next-line no-console
      console.warn(
        'You are modifying or accessing properties of a replaced model. This may result in unexpected behavior!',
        'id:',
        this.id,
        'type:',
        this.className,
      );
    }
  }

  @action.bound
  public processErrorResponse(response: AggregateError): void {
    this.isErrored = true;
    this.isUpdating = false;
    this.isPending = false;
    this.error = response;
  }

  /**
   * Sanitizes the response (overriding unexpected values)
   * Use only when necessary - ideally the backend should be responsible for sanitization of this data
   * Should be overridden in specific models
   */
  public postFetch(response): any {
    return response;
  }

  protected assertCollection(
    collection: Collection<this> | null,
    /** Provide a custom `message` instead of the default one. */
    message?: string,
  ): asserts collection {
    const className = this.constructor.name;
    if (!this.collection) {
      throw new Error(message ?? `Collection is not set for this ${className} instance.`);
    }
  }

  protected assertId(id: string | null | undefined, className: string): asserts id {
    if (!this.id) {
      throw new Error(`ID is not set for this ${className} instance.`);
    }
  }
}

export abstract class DisplayModel<IAttributes extends IBaseAttributes> extends Model<IAttributes> {
  public abstract getItemUrl(pathSuffix?: string): string;

  public abstract get name(): string;
}
