import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IImportColumn, Importer } from '@feathr/blackbox';
import { defaultPartnerAttributes } from '@feathr/blackbox';
import { Button, Fieldset, Form, FormSummary, FormSummaryItem, Input } from '@feathr/components';
import Tags from '@feathr/extender/components/Tags';
import { useStore } from '@feathr/extender/state';
import type { IValidation } from '@feathr/rachis';

import type { IImportButtonProps } from './EventPartnersImportPage';

import * as styles from './EventPartnersImportPage.css';

interface IProps {
  importer: Importer;
  onPrev: () => void;
  ImportButton: React.FunctionComponent<IImportButtonProps>;
}

interface ISummaryProps {
  importer: Importer;
}

interface IColumnSummaryProps {
  importer: Importer;
}

export const validateStepThree = (importer: Importer): IValidation<string[]> => {
  return importer.validate(['name', 'file_name', 'event', 'tag_ids', 'column_mappers'], false);
};

const ImportDetailSummary = observer(({ importer }: ISummaryProps) => {
  const { Events } = useStore();
  const project = Events.get(importer.get('event'));
  return (
    <>
      <FormSummaryItem
        className={styles.summaryItem}
        label={'Import Name'}
        value={importer.get('name')}
      />
      <FormSummaryItem label={'File Name'} value={importer.get('file_name')} />
      <FormSummaryItem label={'Project'} value={project.get('name')} />
    </>
  );
});

const TagsSummary = observer(({ importer }: ISummaryProps) => {
  const tagIds = importer.get('tag_ids', []);
  const { t } = useTranslation();

  return <FormSummaryItem label={t('Tags')} value={<Tags value={tagIds} />} />;
});

const ColumnsSummary = observer(({ importer }: IColumnSummaryProps) => {
  const { CustomFields } = useStore();
  const fields = [
    ...defaultPartnerAttributes,
    ...importer.getUsedCustomFields(CustomFields).map((f) => f.toJS()),
  ];
  const columns: IObservableArray<IImportColumn> = importer.get('column_mappers');
  return (
    <FormSummaryItem
      label={'Columns'}
      value={columns.map((column) => {
        return (
          <Fieldset
            className={styles.fieldset}
            direction={'column'}
            key={`${column.column_name} Fieldset`}
          >
            <Input
              disabled={true}
              key={`${column.column_name}`}
              type={'text'}
              value={column.column_name}
            />
            <FontAwesomeIcon className={styles.arrow} icon={faArrowRight} />
            <Input
              disabled={true}
              isLoading={fields === undefined}
              key={`${column.column_name} Custom Field`}
              type={'text'}
              value={
                fields.find((f) => f.id === column.feathr_attr) !== undefined
                  ? fields.find((f) => f.id === column.feathr_attr)!.u_key
                  : ''
              }
            />
          </Fieldset>
        );
      })}
    />
  );
});

function PartnerImportStepThree({ importer, ImportButton, onPrev }: IProps): JSX.Element {
  const { t } = useTranslation();
  const actions = [
    <Button key={'prev'} onClick={onPrev}>
      Previous
    </Button>,
    <ImportButton importer={importer} key={'importButton'} />,
  ];

  return (
    <Form
      actions={actions}
      className={styles.formRoot}
      description={t(
        'Review your settings and make sure you are tagging and associating your imported partners how you want.',
      )}
      label={t('Review')}
    >
      <FormSummary>
        <ImportDetailSummary importer={importer} />
        <TagsSummary importer={importer} />
        <ColumnsSummary importer={importer} />
      </FormSummary>
    </Form>
  );
}

export default observer(PartnerImportStepThree);
