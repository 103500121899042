import { faCheck, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { Button, Toolbar } from '@feathr/components';
import Legs from '@feathr/extender/components/Legs';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import FlightPage from '../FlightPage';
import MakeSharedFlightModal from '../FlightPage/MakeSharedFlightModal';

function FlightDetailPage(): JSX.Element {
  const { Flights } = useStore();
  const { flightId } = useParams<{ flightId: string; eventId: string }>();
  const flight = Flights.get(flightId);
  const [isFlightModalOpen, toggleIsFlightModalOpen] = useToggle(false);
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  // TODO: move the report actions into someplace that can be shared with the detail page
  const actions = (
    <>
      <Toolbar>
        <Button
          link={localUrl(flight.getItemUrl('edit'))}
          prefix={<FontAwesomeIcon icon={faPencil} />}
        >
          {t('Edit')}
        </Button>
        <Button
          key={'flight-path'}
          onClick={toggleIsFlightModalOpen}
          prefix={flight.get('shared') ? <FontAwesomeIcon icon={faCheck} /> : undefined}
          type={'primary'}
        >
          {flight.get('shared') ? t('Flight Path') : t('Make Flight Path')}
        </Button>
      </Toolbar>
      {isFlightModalOpen && (
        <MakeSharedFlightModal flight={flight} toggleModal={toggleIsFlightModalOpen} />
      )}
    </>
  );

  return (
    <FlightPage actions={actions} flight={flight} title={'Overview'}>
      <Legs flight={flight} />
    </FlightPage>
  );
}

export default observer(FlightDetailPage);
