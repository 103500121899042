import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IDripCampaignStepSpec } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { CardV2 as Card, EmailTemplateSelect, Label, Well } from '@feathr/components';

import useDripText from '../../useDripText';
import useEphemeralCampaign from '../useEphemeralCampaign';

import * as styles from './PreviewAutomation.css';

interface IPreviewAutomationProps {
  dripCampaignId: string;
  step: IDripCampaignStepSpec;
}

function PreviewAutomation({
  dripCampaignId,
  step,
}: Readonly<IPreviewAutomationProps>): JSX.Element {
  const { t } = useTranslation();

  const { campaign, template } = useEphemeralCampaign({ dripCampaignId, step });
  const isDraft = campaign.get('state') === CampaignState.Draft;
  const {
    builder: { targetingMessage, targetingDelayLabel, targetingDelayTooltip },
  } = useDripText();

  const delayValue = `${campaign.get('delay_value')} ${campaign.get('delay_unit')}`;
  const setAction = campaign.get('set_action');

  return (
    <>
      <Card.Content>
        <Label weight={'semibold'}>{t('Automation')}</Label>
        <div className={styles.wells}>
          <Well layout={'vertical'} theme={'white'}>
            <Well.Row
              description={(setAction ? targetingMessage[setAction] : undefined) ?? t('Not set')}
              label={t('Step targeting')}
              tooltip={'Select who should be sent the email in this step'}
            />
            <Well.Row
              description={delayValue}
              label={setAction ? targetingDelayLabel[setAction] : t('Not set')}
              tooltip={setAction ? targetingDelayTooltip[setAction] : undefined}
            />
          </Well>
        </div>
      </Card.Content>
      <Card.Content>
        <Label weight={'semibold'}>{t('Email')}</Label>
        <EmailTemplateSelect
          description={template?.get('preview_text')}
          imageUrl={isDraft && template ? template.get('thumbnail_url') : undefined}
          isLoading={template?.isPending}
          previewUrl={template && !isDraft ? campaign.getTemplatePreviewUrl() : undefined}
          readOnly={true}
          subtitle={campaign.get('subject')}
          title={template ? campaign.get('from_address') : t('No template selected')}
        />
      </Card.Content>
    </>
  );
}

export default observer(PreviewAutomation);
