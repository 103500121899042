import { isWretchError, type TConstraints, wretch } from '@feathr/rachis';

import { Campaign } from './campaign';
import { monetizationConstraints } from './monetization';
import type { ICampaignAttributes, TCampaignAttributes } from './types';
import { CampaignClass } from './types';

export abstract class DisplayCampaign<
  T extends ICampaignAttributes = ICampaignAttributes,
> extends Campaign<T> {
  public override get constraints(): TConstraints<TCampaignAttributes> {
    return {
      ...super.constraints,
      destination_url: (...args: any[]) => {
        const attributes = args[1];
        if (
          [
            CampaignClass.LandingPage,
            CampaignClass.TrackedLink,
            CampaignClass.Drip,
            CampaignClass.DripStep,
            CampaignClass.PinpointEmail,
            CampaignClass.SmartPinpointEmail,
            CampaignClass.AutoPinpointEmail,
          ].includes(attributes._cls)
        ) {
          return undefined;
        }
        return {
          url: true,
          presence: {
            allowEmpty: false,
          },
        };
      },
      monetization_value: () => {
        if (this.isMonetization) {
          return monetizationConstraints.monetization_value;
        }
        return null;
      },
    };
  }

  // TODO: During integration of this endpoint, facebook should not return anything
  public async getRecommendedBudget(campaign: Partial<ICampaignAttributes>): Promise<T> {
    this.assertCollection(this.collection);

    const url = `${this.collection.url()}${this.id}/budget_recommendation`;

    const response = await wretch<T>(url, {
      method: 'POST',
      body: JSON.stringify(campaign),
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }
}
