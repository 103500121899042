import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormSummaryItem } from '@feathr/components';

import type { ISummaryProps } from './CampaignSummary.types';

const BudgetSummary = observer(({ campaign, validationErrors }: ISummaryProps): JSX.Element => {
  const { t } = useTranslation();
  const monetization = campaign.get('parent_kind') === 'partner';

  return (
    <FormSummaryItem
      errors={validationErrors?.budget}
      label={monetization ? t('Target Impressions') : t('Budget')}
      value={numeral(campaign.get('exposure_settings').target_value).format(
        monetization ? '0,0' : '$0,0.00',
      )}
    />
  );
});

BudgetSummary.displayName = 'BudgetSummary';

export default BudgetSummary;
