import type { ITTDCampaignAttributes } from './ttd_base';
import { TTDCampaign } from './ttd_base';
import type { CampaignClass } from './types';

// Historical Geofencing
export type TMobileGeoFencingCampaign = ITTDCampaignAttributes & {
  readonly _cls: CampaignClass.MobileGeoFencing;
  address: string;
  address_name: string;
};

export class MobileGeoFencingCampaign extends TTDCampaign<TMobileGeoFencingCampaign> {}
