import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { BannersnackCreative, Creative, DisplayCreative } from '@feathr/blackbox';
import { CreativeClass } from '@feathr/blackbox';
import { ArchiveModalV1, ContextMenu, toast } from '@feathr/components';
import CreativeDuplicateModal from '@feathr/extender/components/CreativeDuplicateModal';
import { useLocalUrl, useStore } from '@feathr/extender/state';
import { getIconForAction, useToggle } from '@feathr/hooks';

import DownloadBannersnackFileMenuItem from '../DownloadBannersnackFileMenuItem';

interface IProps {
  creative: Creative;
}

function EventCreativesOptions({ creative }: Readonly<IProps>): JSX.Element {
  const { Creatives } = useStore();
  const localUrl = useLocalUrl();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isDuplicateModalOpen, toggleIsDuplicateModalOpen] = useToggle(false);
  const { t } = useTranslation();

  async function handleArchive(): Promise<void> {
    try {
      await creative.archive();
      toast(t(`Creative {{name}} has been archived.`, { name: creative.get('name') }));
      toggleArchiveModalOpen();
    } catch (e) {
      toast(t('There was an issue archiving the creative. Please try again later.'), {
        type: 'error',
      });
    }
  }

  function handleDuplicate(): void {
    Creatives.refreshApiCache();
  }

  const type = creative.get('_cls');
  const animated = CreativeClass.DisplayBannersnack
    ? (creative as BannersnackCreative).get('animated')
    : false;

  return (
    <>
      <ContextMenu buttonType={'icon'} position={'left-start'}>
        <ContextMenu.Item
          disabled={creative.isUpdating}
          link={localUrl(creative.getItemUrl('edit'))}
          prefix={getIconForAction('edit')}
        >
          {t('Edit')}
        </ContextMenu.Item>
        <ContextMenu.Item
          disabled={creative.isUpdating}
          onClick={toggleIsDuplicateModalOpen}
          prefix={getIconForAction('duplicate')}
        >
          {t('Duplicate')}
        </ContextMenu.Item>
        <ContextMenu.Divider />
        {type === CreativeClass.DisplayBannersnack && (
          <>
            <DownloadBannersnackFileMenuItem
              creative={creative as BannersnackCreative}
              type={'jpg'}
            />
            <DownloadBannersnackFileMenuItem
              creative={creative as BannersnackCreative}
              type={'png'}
            />
            <DownloadBannersnackFileMenuItem
              creative={creative as BannersnackCreative}
              type={'pdf'}
            />
            {animated && (
              <>
                <DownloadBannersnackFileMenuItem
                  creative={creative as BannersnackCreative}
                  type={'animated-gif'}
                />
                <DownloadBannersnackFileMenuItem
                  creative={creative as BannersnackCreative}
                  type={'mp4'}
                />
              </>
            )}
            <ContextMenu.Divider />
          </>
        )}
        <ContextMenu.Item
          onClick={toggleArchiveModalOpen}
          prefix={getIconForAction('archive')}
          theme={'danger'}
        >
          {t('Archive ')}
        </ContextMenu.Item>
      </ContextMenu>
      {isDuplicateModalOpen && (
        <CreativeDuplicateModal
          creative={creative as DisplayCreative}
          eventId={creative.get('event')}
          onClose={toggleIsDuplicateModalOpen}
          onConfirm={handleDuplicate}
        />
      )}
      {isArchiveModalOpen && (
        <ArchiveModalV1
          model={creative}
          onClose={toggleArchiveModalOpen}
          onConfirm={handleArchive}
          t={t}
        />
      )}
    </>
  );
}

export default observer(EventCreativesOptions);
