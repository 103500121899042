import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormSummaryItem } from '@feathr/components';

import type { ISummaryProps } from './CampaignSummary.types';

const SubjectSummary = observer(({ campaign, validationErrors }: ISummaryProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FormSummaryItem
      errors={validationErrors?.subject}
      label={t('Email Subject')}
      value={campaign.get('subject')}
    />
  );
});

SubjectSummary.displayName = 'SubjectSummary';

export default SubjectSummary;
