import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { RowRenderProps } from 'react-table';

import type { Segment } from '@feathr/blackbox';
import { Button, Dropdown, Menu, MenuItem, ModalV1, Table, toast } from '@feathr/components';
import SegmentBulkArchiveModal from '@feathr/extender/App/DataSegmentsPage/SegmentBulkArchiveModal';
import Page from '@feathr/extender/App/Page';
import FilterTags from '@feathr/extender/components/FilterTags';
import TagsField from '@feathr/extender/components/TagsField';
import { useRole, useStore } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import dataSegmentsColumns from './dataSegmentsPageColumns';
import DataSegmentsPageFilters from './DataSegmentsPageFilters';
import SegmentInformaticsTable from './SegmentInformaticsTable';

import * as styles from './DataSegmentsPage.css';

interface IFilters {
  name__icontains?: string;
  tag_ids__all: string[];
}

const defaultColumns = [
  'name',
  'stats__num_cookies_total',
  'reachability',
  'tag_ids',
  'campaign_goals',
  'campaign_targets',
  'options',
];

function DataSegmentsPage(): JSX.Element {
  const { Goals, Segments, Tags, Targetings } = useStore();
  const [addTags, setAddTags] = useState<string[]>([]);
  const [columnIds, setColumnIds] = useState<string[]>(defaultColumns);
  const [filters, setFilters] = useState<IFilters>({
    name__icontains: '',
    tag_ids__all: [],
  });
  const [selected, setSelected] = useState<string[]>([]);
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isTagModalOpen, toggleTagModalOpen] = useToggle(false);
  const { t } = useTranslation();
  const { isAdmin, user } = useRole();
  // Some users may have checked off columns to hide them, so we need to make sure these are always shown.
  const alwaysShownColumns = ['name', 'options'];

  useEffect(() => {
    if (!user.isPending) {
      const segmentsColumnIds = user.getSetting<'segments_column_ids'>('segments_column_ids') || [
        ...defaultColumns,
      ];
      setColumnIds([...segmentsColumnIds, ...alwaysShownColumns]);
    }
  }, [user.isPending]);

  function updateColumnIds(newColumnIds: string[]): void {
    user.setSetting<'segments_column_ids'>('segments_column_ids', newColumnIds);
    setColumnIds(newColumnIds);
    user.patchDirty();
  }

  const batchActions = (
    <Dropdown
      className={styles.dropdown}
      content={
        <Menu className={styles.menu}>
          <MenuItem onClick={toggleTagModalOpen}>{t('Tag selected')}</MenuItem>
          <MenuItem onClick={toggleArchiveModalOpen}>{t('Archived selected')}</MenuItem>
        </Menu>
      }
    >
      <Button
        className={styles.bulkActions}
        disabled={!selected.length}
        suffix={<FontAwesomeIcon icon={faAngleDown} />}
      >
        {t('With selected...')}
      </Button>
    </Dropdown>
  );

  const filterElements = [
    <>
      <FilterTags
        context={'person_segment'}
        onChange={(values) => {
          setFilters({ ...filters, tag_ids__all: values });
        }}
        value={filters.tag_ids__all}
        wrapperClassName={styles.filter}
      />
    </>,
    <>
      <DataSegmentsPageFilters
        columnIds={columnIds}
        filters={filters}
        setFilters={setFilters}
        updateColumnIds={updateColumnIds}
      />
    </>,
  ];

  const SegmentsTableSubComponent = (props: RowRenderProps) => {
    return <SegmentInformaticsTable segment={props.original as Segment} />;
  };

  const shouldIncludeSubComponent =
    columnIds.includes('campaign_targets') ||
    columnIds.includes('campaign_goals') ||
    columnIds.includes('flight_goals');

  return (
    <Page title={t('Groups')}>
      <Table<Segment>
        batchActions={isAdmin ? batchActions : undefined}
        collection={Segments}
        columns={dataSegmentsColumns({ Goals, Tags, Targetings, columnIds })}
        filterElements={filterElements}
        filters={filters}
        initialPagesize={10}
        initialSort={[{ id: 'stats__num_cookies_total', desc: true }]}
        isSelectable={isAdmin}
        selected={selected}
        setSelected={setSelected}
        SubComponent={shouldIncludeSubComponent ? SegmentsTableSubComponent : undefined}
      />
      {isTagModalOpen && (
        <ModalV1
          className={styles.modal}
          confirmButtonText={t('Add tags')}
          controlled={true}
          onClose={() => {
            setAddTags([]);
            toggleTagModalOpen();
          }}
          onConfirm={async () => {
            try {
              await Segments.bulk(selected, { tag_ids: addTags });
              toast(t('The tags were added to your selection.'), { type: 'success' });
              toggleTagModalOpen();
            } catch (e) {
              toast(t('There was a problem adding the tags to your selection.'), { type: 'error' });
            }
          }}
          t={t}
          title={t('Add tags')}
        >
          <TagsField
            context={'person_segment'}
            onChange={(values) => setAddTags(values)}
            value={addTags}
            wrapperClassName={styles.tags}
          />
        </ModalV1>
      )}
      {isArchiveModalOpen && (
        <SegmentBulkArchiveModal
          isArchiveModalOpen={isArchiveModalOpen}
          selected={selected}
          setSelected={setSelected}
          toggleArchiveModalOpen={toggleArchiveModalOpen}
        />
      )}
    </Page>
  );
}

export default observer(DataSegmentsPage);
