import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DripCampaign } from '@feathr/blackbox';
import { FormSummaryItem } from '@feathr/components';

import type { ISummaryProps } from './CampaignSummary.types';
import TriggerSummary from './TriggerSummary';

const DripEnrollmentSummary = observer(
  ({ campaign, validationErrors }: ISummaryProps): JSX.Element | null => {
    const { t } = useTranslation();

    if (!(campaign instanceof DripCampaign)) {
      return null;
    }

    return (
      <FormSummaryItem
        key={campaign.id}
        label={t('Enrollment trigger', { count: campaign.get('actions').length })}
        value={
          <TriggerSummary
            campaign={campaign}
            showMinimal={true}
            validationErrors={validationErrors}
          />
        }
      />
    );
  },
);

DripEnrollmentSummary.displayName = 'DripEnrollmentSummary';

export default DripEnrollmentSummary;
