import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { ValueType } from 'react-select';

import { Billable } from '@feathr/blackbox';
import { Select } from '@feathr/components';
import BillingSource from '@feathr/extender/components/BillingSource';
import { useAccount, useStore } from '@feathr/extender/state';

import * as styles from './BillableSelect.css';

interface IProps {
  label?: React.ReactNode;
  isLoading?: boolean;
  onChange?: (value: ValueType<Billable>) => void;
  placeholder?: string;
  value?: Billable;
}

function BillableSelect({
  isLoading = false,
  label,
  onChange,
  placeholder,
  value,
}: IProps): JSX.Element {
  const { Billables } = useStore();
  const account = useAccount();
  const { t } = useTranslation();

  const primaryBillableId = account.get('license').billable;
  const primaryBillable = primaryBillableId ? Billables.get(primaryBillableId) : undefined;

  const filters = {};
  if (primaryBillableId) {
    filters['id__ne'] = primaryBillableId;
  }
  const otherBillables = Billables.list({ filters });

  const stripeSource = value?.get('stripe').source;

  function handleChange(changedValue: ValueType<Billable>): void {
    if (Array.isArray(changedValue)) {
      return;
    }
    if (onChange) {
      onChange(changedValue);
    }
  }

  function handleOptionLabel(option): string {
    const description = option.get('description');
    if (description) {
      return description;
    }

    const name = option.get('name');
    // Exception for no default option
    if (option.id === 'nodefault') {
      return name;
    }

    const email = option.get('email', 'no email address');
    return `${name} (${email})`;
  }

  function handleOptionDisabled(option): boolean {
    return option.id === 'nodefault';
  }

  const options = [
    {
      label: t('Default'),
      options: [],
    },
    {
      label: t('Other'),
      options: otherBillables.models.slice(),
    },
  ];

  if (primaryBillable) {
    options[0].options.push(primaryBillable);
  } else {
    options[0].options.push(
      // Add mock billable when no default billing is available
      new Billable({
        // This fake id is referenced in handleOptionDisabled to allow disabling the option
        id: 'nodefault',
        name: t('No default billing available'),
      }),
    );
  }

  return (
    <div className={styles.root}>
      <Select<Billable>
        getOptionLabel={handleOptionLabel}
        isLoading={otherBillables.isPending || isLoading}
        isOptionDisabled={handleOptionDisabled}
        label={label}
        name={'billable-select'}
        onChange={handleChange}
        // Shallow converting observables back to vanilla JavaScript.
        options={options}
        placeholder={placeholder ?? t('Select a billing configuration')}
        value={value}
      />
      {!!value && !!stripeSource && <BillingSource billable={value} source={stripeSource} />}
    </div>
  );
}

export default observer(BillableSelect);
