import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

import type { IPath } from '@feathr/blackbox';
import { Button, List, Spinner, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useAccount, useLocalUrl, useStore } from '@feathr/extender/state';
import { logUserEvents } from '@feathr/hooks';

import Path from './Path';

function FlightAddPage(): JSX.Element {
  const account = useAccount();
  const { Flights, Paths } = useStore();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const { t } = useTranslation();

  const flights = Flights.list({
    filters: {
      shared: true,
    },
  });

  const paths = Paths.list();

  const chooseFlightPath = useCallback(
    async (selectedPath: IPath) => {
      const flight = await Paths.createFlightFromPath(
        selectedPath.id,
        {
          event_id: eventId,
        },
        Flights,
      );

      logUserEvents({
        'Created flight': { account_id: account.id, flight_id: flight.id },
        [`Created ${selectedPath.shared_name} flight path`]: {
          account_id: account.id,
          flight_id: flight.id,
        },
      });
      history.push(localUrl(flight.getItemUrl()));
    },
    [Flights, Paths, account.id, eventId, history, localUrl],
  );

  async function handleOnClick(): Promise<void> {
    await chooseFlightPath(Paths.get('custom').toJS());
  }

  const actions = (
    <Toolbar>
      <Button onClick={handleOnClick} type={'primary'}>
        {t('New empty flight')}
      </Button>
    </Toolbar>
  );

  const description = t(
    'Flight Paths are a list of campaigns configured to accomplish a specific goal. Please choose one of the following Flight Paths to get started, or create an Empty Flight if you want to start from scratch.',
  );

  return (
    <Page actions={actions} description={description} title={'Flight Paths'}>
      <List>
        {!paths.isPending
          ? paths.models.map((path) => (
              <Path choosePath={chooseFlightPath} key={path.id} path={path.toJS()} />
            ))
          : ''}
        {!flights.isPending ? (
          flights.models.map((flight) => (
            <Path
              choosePath={chooseFlightPath}
              key={flight.id}
              path={flight.toJS() as unknown as IPath}
            />
          ))
        ) : (
          <Spinner />
        )}
      </List>
    </Page>
  );
}

export default observer(FlightAddPage);
