import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { IGeoIP, Person } from '@feathr/blackbox';
import { CardV2 as Card } from '@feathr/components';

interface IProps {
  readonly person: Person;
}

function TrackedLocationData({ person }: IProps): JSX.Element {
  const { t } = useTranslation();
  const unknown = t('Unknown');

  const {
    administrative_area_code: region,
    country_code: country,
    lat: latitude,
    lng: longitude,
    postal_code: zip,
    timezone,
  } = person.get('geoip', unknown as unknown as IGeoIP);

  return (
    <Card>
      <Card.Header title={t('Tracked location data')} />
      <Card.Content
        action={
          latitude && longitude
            ? t('{{latitude}}, {{longitude}}', {
                latitude,
                longitude,
              })
            : unknown
        }
        title={t('Approximate coordinates')}
      />
      <Card.Content action={zip || unknown} title={t('ZIP/Postal Code')} />
      <Card.Content action={region || unknown} title={t('State/Province')} />
      <Card.Content action={country || unknown} title={t('Country')} />
      <Card.Content action={timezone || unknown} title={t('Time zone')} />
    </Card>
  );
}

export default observer(TrackedLocationData);
