import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { DatePicker, Spinner } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { moment, TimeFormat } from '@feathr/hooks';
import { SegmentReport } from '@feathr/report_components';

import DataPage from '../../DataPage/DataPage';

import * as styles from './DataSegmentAnalyticsPage.css';

function DataSegmentAnalyticsPage(): JSX.Element {
  const { segmentId } = useParams<{ segmentId: string }>();
  const store = useStore();
  const { Segments, Stats } = store;
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  const segment = Segments.get(segmentId);

  const endMoment = end ? moment.utc(end, moment.ISO_8601) : moment.utc();
  const startMoment = start
    ? moment.utc(start, moment.ISO_8601)
    : endMoment.clone().subtract(30, 'days');
  const startDate = startMoment.toDate();
  const endDate = endMoment.toDate();
  const max = new Date();

  const endFormatted = endMoment.format(TimeFormat.isoDate);
  const startFormatted = startMoment.format(TimeFormat.isoDate);

  const complete = segment.fetchStats(
    Stats,
    startMoment.format(TimeFormat.isoDate),
    endMoment.format(TimeFormat.isoDate),
  );

  function handleChangeStartDate(date?: string): void {
    setStart(date || '');
  }

  function handleChangeEndDate(date?: string): void {
    setEnd(date || '');
  }

  return (
    <DataPage
      isGroupsSection={true}
      permissions={segment.permissions}
      sectionTitle={segment.name}
      title={'Analytics'}
      width={'wide'}
    >
      {segment.isPending ? (
        <Spinner />
      ) : (
        <>
          <div className={styles.daterange}>
            <div>
              <DatePicker
                endDate={endDate}
                maxDate={endDate}
                onDateStrChange={handleChangeStartDate}
                placeholder={'Range Start'}
                selected={startDate}
                selectsStart={true}
                startDate={startDate}
              />
              <span style={{ margin: '0px 5px', lineHeight: '36px' }}>to</span>
              <DatePicker
                endDate={endDate}
                maxDate={max}
                minDate={startDate}
                onDateStrChange={handleChangeEndDate}
                placeholder={'Range End'}
                selected={endDate}
                selectsEnd={true}
                startDate={startDate}
              />
            </div>
          </div>
          <SegmentReport
            complete={complete!}
            end={endFormatted}
            segmentId={segment.id}
            start={startFormatted}
            store={store}
          />
        </>
      )}
    </DataPage>
  );
}

export default observer(DataSegmentAnalyticsPage);
