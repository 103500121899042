import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import type { RowRenderProps } from 'react-table';

import type { Breadcrumb } from '@feathr/blackbox';
import { Chip } from '@feathr/components';
import { useStore } from '@feathr/extender/state';

interface IProps extends RowRenderProps {
  original: Breadcrumb;
}

function TagChip({ original }: IProps): JSX.Element {
  const tagId = original.get('tag_id');
  const { Tags } = useStore();
  const tag = tagId ? Tags.get(tagId) : undefined;

  if (!tag || tag.isPending) {
    return <span>-</span>;
  }

  return <Chip theme={'tag'}>{tag.get('name')}</Chip>;
}

export default observer(TagChip);
