import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useParams } from 'react-router';

import type { ReferralCampaign } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import TemplateColumnOptions from '@feathr/extender/App/EventsPage/CampaignEditPage/ReferralCampaignEdit/TemplateColumnOptions';
import TemplatesTable from '@feathr/extender/components/TemplatesTable';
import TemplateColumnClicks from '@feathr/extender/components/TemplatesTable/TemplateColumnClicks';
import TemplateColumnLinkedName from '@feathr/extender/components/TemplatesTable/TemplateColumnLinkedName';
import TemplateColumnThumbnail from '@feathr/extender/components/TemplatesTable/TemplateColumnThumbnail';
import TemplateColumnType from '@feathr/extender/components/TemplatesTable/TemplateColumnType';
import TemplateColumnViews from '@feathr/extender/components/TemplatesTable/TemplateColumnViews';
import { useAccount, useStore } from '@feathr/extender/state';
import { logUserEvents } from '@feathr/hooks';

import ReferralCampaignPage from '../ReferralCampaignPage';

function TemplatesReferralCampaignPage(): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();
  const { campaignId } = useParams<{ eventId?: string; campaignId: string }>();
  const { Campaigns } = useStore();
  const campaign = Campaigns.get(campaignId) as ReferralCampaign;

  useEffect(() => {
    // Only fire once if page re renders
    logUserEvents({
      'Viewed invites report templates tab': {
        account_id: account.id,
        campaign_id: campaignId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!campaign.isPending && campaign.get('_cls') !== CampaignClass.Referral) {
    return (
      <Switch>
        <Redirect
          exact={true}
          from={'/:accountId/projects/:eventId/campaigns/:campaignId/templates'}
          to={'/:accountId/projects/:eventId/campaigns/:campaignId'}
        />
      </Switch>
    );
  }

  return (
    <ReferralCampaignPage title={t('Templates')}>
      <TemplatesTable
        columns={[
          TemplateColumnThumbnail,
          TemplateColumnLinkedName,
          TemplateColumnType,
          TemplateColumnViews,
          TemplateColumnClicks,
          TemplateColumnOptions,
        ]}
        model={campaign}
      />
    </ReferralCampaignPage>
  );
}

export default observer(TemplatesReferralCampaignPage);
