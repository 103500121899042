import { computed, makeObservable } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, DisplayModel } from '@feathr/rachis';

export enum APIKeyServiceNames {
  Zapier = 'Zapier',
}
export interface IAPIKey extends IBaseAttributes {
  service_name: APIKeyServiceNames;
  /**
   *  Expected request host using the API key. set in the backend
   *  based on name
   */
  host: string;
  key: string;
  /** Id of the parent class. Most likely the Account_Id */
  parent: string;
  /** Name of the parent class. Most Likely Account */
  parent_kind: string;
  /** True when the key is revoked */
  revoked: boolean;
}

export class APIKey extends DisplayModel<IAPIKey> {
  public readonly className = 'APIKey';

  public get constraints(): TConstraints<IAPIKey> {
    return {
      service_name: {
        presence: {
          message: '^Service name cannot be empty.',
          allowEmpty: false,
        },
      },
    };
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/settings/integrations/api-keys/${this.id}`, pathSuffix);
  }

  constructor(attributes: Partial<IAPIKey> = {}) {
    super(attributes);

    makeObservable(this);
  }

  @computed
  public get name(): string {
    return this.get('service_name').trim() || 'Unnamed API Key';
  }
}

export class APIKeys extends Collection<APIKey> {
  public getClassName(): string {
    return 'api_keys';
  }

  public getModel(attributes: Partial<IAPIKey>): APIKey {
    return new APIKey(attributes);
  }
}
