import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormSummaryItem, Value } from '@feathr/components';

import type { ISummaryProps } from './CampaignSummary.types';

const MetadataSummary = observer(
  ({ campaign, validationErrors }: ISummaryProps): JSX.Element | null => {
    const { t } = useTranslation();
    const hasBannerTemplates = !!campaign.get('banner_templates', []).length;
    const hasPageTemplates = !!campaign.get('page_templates', []).length;

    if (!hasBannerTemplates && !hasPageTemplates) {
      return null;
    }

    const destinationUrl = campaign.get('destination_url');
    const twitterText = campaign.get('twitter_text');
    const twitterHashtags = campaign.get('twitter_hashtags');

    return (
      <FormSummaryItem
        errors={validationErrors?.metadata}
        label={t('Metadata')}
        value={
          <>
            {hasBannerTemplates && <Value label={'Destination URL'} value={destinationUrl} />}
            {hasPageTemplates && (
              <>
                <Value label={t('Social share text')} value={twitterText} />
                <Value label={t('Social share tags')} value={twitterHashtags} />
              </>
            )}
          </>
        }
      />
    );
  },
);

MetadataSummary.displayName = 'MetadataSummary';

export default MetadataSummary;
