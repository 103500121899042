import type { CampaignClass } from '.';
import type { ITTDCampaignAttributes } from './ttd_base';
import { TTDCampaign } from './ttd_base';

// Retargeting
export interface ISegmentCampaignAttributes extends ITTDCampaignAttributes {
  readonly _cls: CampaignClass.Segment;
}

export class SegmentCampaign extends TTDCampaign<ISegmentCampaignAttributes> {}
