import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormSummaryItem, TimeRange } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import type { ISummaryProps } from './CampaignSummary.types';

const DurationSummary = observer(({ campaign, validationErrors }: ISummaryProps): JSX.Element => {
  const { t } = useTranslation();

  const campaignDuration = campaign.isAdCampaign ? (
    <TimeRange
      end={campaign.localEndTime}
      format={TimeFormat.pickerDateTime}
      start={campaign.localStartTime}
    />
  ) : (
    <TimeRange
      end={campaign.get('date_end')}
      format={TimeFormat.shortDate}
      start={campaign.get('date_start')}
    />
  );

  return (
    <FormSummaryItem
      errors={validationErrors?.duration}
      label={t('Duration')}
      value={campaignDuration}
    />
  );
});

DurationSummary.displayName = 'DurationSummary';

export default DurationSummary;
