import type { TFunction } from 'i18next';
import { Observer, observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type {
  CustomField,
  CustomFields as CustomFieldsCollection,
  Importer,
} from '@feathr/blackbox';
import { Button, Spinner, Step, Steps, toast, Wizard } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useAccount, useLocalUrl, useStore } from '@feathr/extender/state';
import { logUserEvents, useRedirect } from '@feathr/hooks';

import DataImportStepOne, { validateStepOne } from './DataImportStepOne';
import DataImportStepThree from './DataImportStepThree';
import DataImportStepTwo, { validateStepTwo } from './DataImportStepTwo';

import styles from './DataImportPage.css';

export interface IImportButtonProps {
  importer: Importer;
  disabled?: boolean;
}

const importerIsValid = (errors: string[][]): boolean => {
  return errors.every((stepErrors) => stepErrors.length === 0);
};

const validateImporter = (importer: Importer, t: TFunction): string[][] => {
  return [validateStepOne(importer), validateStepTwo(importer, t)];
};

export const ImportButton = observer(({ importer, disabled }: IImportButtonProps) => {
  const account = useAccount();
  const { CustomFields } = useStore();
  const localUrl = useLocalUrl();
  const [redirect] = useRedirect();
  const { t } = useTranslation();

  const url = localUrl('/data/imports');
  const validationErrors = validateImporter(importer, t);
  const isValid = importerIsValid(validationErrors);

  async function handleClick(): Promise<void> {
    await onSave(importer, CustomFields, url, redirect, t, account.id);
  }

  const buttonText = importer.isOptOut ? t('Import opt out list') : t('Import data');

  return (
    <Button disabled={disabled || !isValid} onClick={handleClick} type={'primary'}>
      {buttonText}
    </Button>
  );
});

async function saveModel(model: CustomField): Promise<void> {
  if (model.isEphemeral || !model.id) {
    await model.collection!.add(model, { validate: true, refreshApiCache: false });
  } else if (model.isDirty) {
    await model.save();
  }
}

async function handleSave(importer: Importer, CustomFields: CustomFieldsCollection): Promise<void> {
  /*
   * Save custom fields before importer so they are available by the time
   * the importer is ready. We have to save them one at a time because
   * otherwise we introduce a race condition on the backend, thus the
   * recursive async function.
   */
  const customFields = importer.getUsedCustomFields(CustomFields);

  async function saveCustomFields(fields: CustomField[]): Promise<void> {
    const field = fields.pop();
    if (!field) {
      return;
    }
    await saveModel(field);
    await saveCustomFields(fields);
  }

  await saveCustomFields(customFields);
  CustomFields.refreshApiCache();
  await importer.patchDirty();
}

async function onSave(
  importer: Importer,
  customFieldsCollection: CustomFieldsCollection,
  url: string,
  redirect: (defaultRedirect?: string) => void,
  t: TFunction,
  accountId: string,
): Promise<void> {
  try {
    importer.set({ state: 'pending' });
    await handleSave(importer, customFieldsCollection);
    logUserEvents({
      [`Imported ${importer.isOptOut ? 'opt out' : 'contact'} list`]: {
        account_id: accountId,
        importer_id: importer.id,
      },
    });
    redirect(url);
    toast(t('Import in progress. Check back in a few minutes.'), { type: 'success' });

    // If err is instance of Error, it should be of type any.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    const message = e.json ? e.json.message : e.toString();
    toast(t('Something went wrong while uploading the import: {{message}}', { message }));
  }
}

function DataImportPage(): JSX.Element {
  const { CustomFields, Importers } = useStore();
  const { importerId } = useParams<{ importerId: string }>();
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useTranslation();

  const importer = Importers.get(importerId!);

  const importState = importer.get('state');

  const importSteps = [
    <Step key={0} stepIndex={0} title={t('Upload')} />,
    <Step key={1} stepIndex={1} title={t('Map')} />,
    <Step key={2} stepIndex={2} title={t('Review')} />,
  ];

  const steps = (
    <Steps
      completed={importState === 'complete' ? 2 : 0}
      current={currentStep}
      onChange={setCurrentStep}
    >
      {importSteps}
    </Steps>
  );

  function onNext(): void {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
  }

  function onPrev(): void {
    const prevStep = currentStep - 1;
    setCurrentStep(prevStep);
  }

  if (importer.isPending) {
    return <Spinner />;
  }

  async function saveChanges(): Promise<void> {
    await handleSave(importer, CustomFields);
    toast('Changes saved.', { type: 'success' });
  }

  const pageTitle = importer.isOptOut
    ? t('Import Opt Out List')
    : importer.get('name')
      ? importer.get('name')
      : t('Import Data');

  const actions = [
    <Observer key={'draft'}>
      {(): JSX.Element => {
        const isDraft = importState === 'draft';
        return (
          <Button disabled={!isDraft} key={'save'} onClick={saveChanges}>
            {t('Save as draft')}
          </Button>
        );
      }}
    </Observer>,
  ];

  const readOnly = importState === 'complete';

  return (
    <Page title={pageTitle}>
      <Wizard actions={actions} className={styles.wizard} steps={steps}>
        {currentStep === 0 && importer && (
          <DataImportStepOne disabled={readOnly} importer={importer} onNext={onNext} />
        )}
        {currentStep === 1 && (
          <DataImportStepTwo
            disabled={readOnly}
            importer={importer}
            onNext={onNext}
            onPrev={onPrev}
          />
        )}
        {currentStep === 2 && (
          <DataImportStepThree
            disabled={readOnly}
            ImportButton={ImportButton}
            importer={importer}
            onPrev={onPrev}
          />
        )}
      </Wizard>
    </Page>
  );
}

export default observer(DataImportPage);
