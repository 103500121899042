import type { WithT } from 'i18next';
import debounce from 'lodash.debounce';
import type { IObservableArray } from 'mobx';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type {
  Account,
  Campaign,
  Domain,
  EmailVerification,
  EmailVerifications as EmailVerificationsCollection,
  Goal,
  PinpointEmailCampaign,
  Segment,
  Template,
  TFlagsRecord,
} from '@feathr/blackbox';
import { CampaignState, EPinpointRequestStatus, PinpointEmailBaseCampaign } from '@feathr/blackbox';
import type { IWizardStepsCompleted } from '@feathr/components';
import { Step, Steps, useSteps, Wizard } from '@feathr/components';
import { useAccount, useFlags, useStore } from '@feathr/extender/state';
import { DEFAULT_DEBOUNCE_WAIT, flattenError, flattenErrors } from '@feathr/hooks';
import type { Model } from '@feathr/rachis';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import SaveCampaignButton from '../SaveCampaignButton';
import StepGoals, { getGoalSegments, validateStepGoals } from '../StepGoals';
import StepValue, { validateStepValue } from '../StepValue';
import PinpointEmailCampaignStepConfigureDetails, {
  validateStepConfigureDetails,
} from './PinpointEmailCampaignStepConfigureDetails';
import PinpointEmailCampaignStepScheduleSend, {
  validateStepScheduleSend,
} from './PinpointEmailCampaignStepScheduleSend';
import PinpointEmailCampaignStepTest from './PinpointEmailCampaignStepTest';

import * as styles from './PinpointEmailCampaignEdit.css';

interface IProps {
  campaign: PinpointEmailCampaign;
}

export interface IValidatePinpointCampaignProps extends WithT {
  account: Account;
  campaign: PinpointEmailCampaign;
  emailVerification?: EmailVerification;
  goals: IObservableArray<Goal>;
  goalSegments?: Segment[];
  domains?: IObservableArray<Domain>;
  segments?: Segment[];
  template?: Template;
  flags?: Partial<TFlagsRecord>;
}

const getEmailVerifications = debounce(
  (campaign: PinpointEmailCampaign, EmailVerifications: EmailVerificationsCollection) => {
    return campaign.getEmailVerifications(EmailVerifications);
  },
  DEFAULT_DEBOUNCE_WAIT,
  { leading: true },
);

export function getIsVerifiedDomainSender(
  domains: IObservableArray<Domain>,
  emailVerification?: EmailVerification,
  bypassMX?: boolean,
): boolean {
  if (!emailVerification || emailVerification.isEphemeral) {
    /*
     * Return early to ensure we only call .split
     * on a valid email address
     */
    return false;
  }
  const senderDomain = emailVerification.get('email').split('@')[1];
  const verifiedDomains = domains
    .filter((domain) => domain.emailSendingStatus(bypassMX) === EPinpointRequestStatus.Success)
    .map((domain) => domain.get('email_domain'));

  /*
   * Returns true if the sender domain matches or is a subdomain of
   * one of the account's authorized domains
   */
  return verifiedDomains.some((domain) => senderDomain.endsWith(domain));
}

function validate({
  account,
  campaign,
  emailVerification,
  goals,
  goalSegments,
  domains,
  segments,
  template,
  t,
  flags,
}: IValidatePinpointCampaignProps): ICampaignValidationErrors {
  const one = validateStepConfigureDetails(campaign, emailVerification, template);
  const two = campaign.isMonetization
    ? validateStepValue(campaign)
    : validateStepGoals(goals, goalSegments);
  const three = validateStepScheduleSend(campaign);

  const validationErrors: ICampaignValidationErrors = {
    template: one.template ?? [],
    name: one.name ?? [],
    subject: one.subject ?? [],
    from_address: one.from_address ?? [],
    from_name: one.from_name ?? [],
    'address.company_name': one['address.company_name'] ?? [],
    'address.premise1': one['address.premise1'] ?? [],
    'address.locality': one['address.locality'] ?? [],
    'address.administrative_area_name': one['address.administrative_area_name'] ?? [],
    'address.postal_code': one['address.postal_code'] ?? [],
    'address.country_code': one['address.country_code'] ?? [],
    status: one.status ?? [],
    monetization_value: (two.monetization_value as string[]) ?? [],
    goals: (two.goals as string[]) ?? [],
    segments: three.segments ?? [],
    send_schedule: three.send_schedule ?? [],
    date_send_start: three.date_send_start ?? [],
    'consent.has_consent': three['consent.has_consent'] ?? [],
  };
  if (campaign.isMonetization) {
    validationErrors['monetization_value'] = flattenError(two.monetization_value);
  } else {
    validationErrors['goals'] = flattenError(two.goals);
  }

  if (account.get('email_health') === 'suspended') {
    validationErrors.account = [
      t('Your Feathr account is suspended and cannot publish any new email campaigns.'),
    ];
  }

  if (
    emailVerification &&
    segments &&
    !getIsVerifiedDomainSender(domains!, emailVerification, flags?.bypassMX)
  ) {
    const targetedPeople = segments.reduce((total, segment) => {
      return total + (segment.get('stats').num_emails_total || 0);
    }, 0);

    if (targetedPeople > 1000) {
      validationErrors.contacts_limit = [
        t('You cannot target more than 1,000 contacts unless you authorize your sender domain.'),
      ];
    }
  }

  return validationErrors;
}

function PinpointEmailCampaignEdit({ campaign }: IProps): JSX.Element {
  const account = useAccount();
  const flags = useFlags();
  const { Domains, EmailVerifications, Goals, Segments, Templates } = useStore();
  const history = useHistory();
  // Wizard should show loader until first step is determined.
  const [currentStep, setCurrentStep] = useState<number>(-1);
  const [completeStep, setCompleteStep] = useState<number>(-1);
  const [testEmail, setTestEmail] = useState<string | undefined>();
  const [testPersonId, setTestPersonId] = useState<string | undefined>();
  const { t } = useTranslation();

  const domains = Domains.list();

  const goals = Goals.list({
    filters: {
      _parent: campaign.id,
      is_archived__ne: true,
    },
  });
  const segments = campaign
    .get('segments', [])
    .filter(({ id }) => !!id)
    .map(({ id }) => Segments.get(id));
  const templateId = campaign.get('template_id');
  const template = templateId ? Templates.get(templateId) : undefined;
  const templates = template ? [template] : [];
  // Used for validation only.
  const emailVerifications = getEmailVerifications(campaign, EmailVerifications);

  const grandchildModels: Model[] = [...getGoalSegments(goals.models, Segments)];

  // Converting observables back to vanilla JavaScript.
  const childModels: Model[] = [...templates, ...segments, ...goals.models.slice()];

  const getCompletedStepMap = (): IWizardStepsCompleted => {
    const goalSegments = getGoalSegments(goals.models, Segments);
    const emailVerification = campaign.getEmailVerification(emailVerifications);

    return {
      0: !flattenErrors(validateStepConfigureDetails(campaign, emailVerification, template)).length,
      1: campaign.isMonetization
        ? !flattenErrors(validateStepValue(campaign)).length
        : !flattenErrors(validateStepGoals(goals.models, goalSegments)).length,
      // We don't validate the test step because a test send is not necessary to be able to publish a campaign.
      2: true,
      3: !flattenErrors(validateStepScheduleSend(campaign)).length,
    };
  };
  const waitFor: () => boolean = () => {
    return !(
      campaign.isPending ||
      domains.isPending ||
      !emailVerifications ||
      emailVerifications.isPending ||
      goals.isPending ||
      // Only check pending status if templateId is set. Template should also not be ephemeral because it's saved as soon as it is selected.
      (campaign.get('template_id') && template && (template?.isEphemeral || template?.isPending))
    );
  };

  useSteps(
    {
      getCompletedStepMap,
      waitFor,
      setCurrentStep,
      setCompleteStep,
      skipToMaxStep: [
        CampaignState.Published,
        CampaignState.Publishing,
        CampaignState.Stopped,
      ].includes(campaign.get('state', CampaignState.Draft)),
    },
    [template],
  );

  function buttonValidate(): ICampaignValidationErrors {
    const emailVerification = campaign.getEmailVerification(emailVerifications);
    const goalSegments = getGoalSegments(goals.models, Segments);
    return validate({
      account,
      campaign,
      emailVerification,
      goals: goals.models,
      goalSegments,
      domains: domains.models,
      segments,
      t,
      template,
      flags,
    });
  }

  function onNext(): void {
    const nextStep = currentStep + 1;
    setCurrentStep(nextStep);
    history.replace({ ...history.location, hash: `step${nextStep + 1}` });
    if (nextStep > completeStep) {
      setCompleteStep(nextStep);
    }
  }

  function onPrev(): void {
    const prevStep = currentStep - 1;
    history.replace({ ...history.location, hash: `step${prevStep + 1}` });
    setCurrentStep(prevStep);
  }

  function handleChangeStep(step: number): void {
    setCurrentStep(step);
    history.replace({ ...history.location, hash: `step${step + 1}` });
  }

  async function preSave(newCampaign: Campaign): Promise<void> {
    if (newCampaign instanceof PinpointEmailBaseCampaign) {
      const campaignSegments = newCampaign.get('segments');
      await Promise.all(
        campaignSegments.map(async (s) => {
          if (!s.id) {
            return;
          }
          const campaignSegment = Segments.get(s.id);
          await when(() => !campaignSegment.isPending);
          if (campaignSegment.isEphemeral) {
            await Segments.add(campaignSegment);
          }
        }),
      );
    }
  }

  // Set loading while data is fetched and initialized.
  const isLoading = completeStep < 0;

  async function postStateChange(campaign: Campaign, stateChanged?: boolean): Promise<void> {
    if (campaign instanceof PinpointEmailBaseCampaign && stateChanged) {
      // The template's read_only field was toggled on the backend, so we must reload it
      await Promise.all(
        templates.map(async (t) => {
          t.reload();
        }),
      );
    }
  }

  const saveButton = (
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      // The campaign not being editable rule only applies to single send campaigns. Don't override disabled if editable is true
      disabled={!campaign.editable || undefined}
      grandchildModels={grandchildModels}
      key={'changeState'}
      name={'publish_or_stop'}
      postSave={postStateChange}
      preSave={preSave}
      shouldChangeState={true}
      validate={buttonValidate}
    />
  );

  const actions = [
    <SaveCampaignButton
      campaign={campaign}
      childModels={childModels}
      disabled={
        isLoading ||
        campaign.get('state', CampaignState.Draft) === CampaignState.Publishing ||
        !campaign.editable ||
        undefined
      }
      grandchildModels={grandchildModels}
      key={'save'}
      name={'save_changes'}
      preSave={preSave}
    />,
    saveButton,
  ];

  const steps = [
    <Step key={1} stepIndex={0} title={t('Configure Details')} />,
    <Step
      key={2}
      stepIndex={1}
      title={campaign.isMonetization ? t('Set Value') : t('Set Goals')}
    />,
    <Step key={3} stepIndex={2} title={t('Test')} />,
    <Step key={4} stepIndex={3} title={t('Schedule & Send')} />,
  ];

  const wizardProps = {
    campaign,
    disabled: campaign.readOnly,
    domains: domains.models,
    goals: goals.models,
    onNext,
    onPrev,
    validate,
    isVerifiedDomainSender: getIsVerifiedDomainSender(
      domains.models,
      campaign.getEmailVerification(emailVerifications),
      flags.bypassMX,
    ),
    validationErrors: buttonValidate(),
  };

  return (
    <div data-appcues-campaign={campaign.get('_cls')}>
      <Wizard
        actions={actions}
        isFullWidth={true}
        // Set loading while data is fetched and initialized.
        isLoading={completeStep < 0}
        layout={'horizontal'}
        steps={
          <Steps completed={completeStep} current={currentStep} onChange={handleChangeStep}>
            {steps}
          </Steps>
        }
      >
        {/* Email details */}
        {currentStep === 0 && (
          <div className={styles.container}>
            <PinpointEmailCampaignStepConfigureDetails template={template} {...wizardProps} />
          </div>
        )}
        {/* Value or goals, based on if it's a monetization campaign */}
        {currentStep === 1 &&
          (campaign.isMonetization ? (
            <div className={styles.container}>
              <StepValue {...wizardProps} />
            </div>
          ) : (
            <div className={styles.containerWide}>
              <StepGoals {...wizardProps} disabled={false} />
            </div>
          ))}
        {/* Send test email */}
        {currentStep === 2 && (
          <div className={styles.container}>
            <PinpointEmailCampaignStepTest
              {...wizardProps}
              disabled={false}
              setTestEmail={setTestEmail}
              setTestPersonId={setTestPersonId}
              testEmail={testEmail}
              testPersonId={testPersonId}
            />
          </div>
        )}
        {/* Select recipients and schedule send dates/times */}
        {currentStep === 3 && (
          <div className={styles.containerWide}>
            <PinpointEmailCampaignStepScheduleSend {...wizardProps} submitButton={saveButton} />
          </div>
        )}
      </Wizard>
    </div>
  );
}

export default observer(PinpointEmailCampaignEdit);
