import { computed, makeObservable } from 'mobx';

import { moment, TimeFormat } from '@feathr/hooks';
import type { IBaseAttributes } from '@feathr/rachis';
import { DisplayModel } from '@feathr/rachis';

export interface IReportAttributes extends IBaseAttributes {
  date_created?: string;
  report_redirect_ids?: string[];
}

export type TReportKey =
  // Campaign
  | 'c'
  // Event
  | 'e'
  // Flight
  | 'f'
  // Segment
  | 's';

export abstract class ReportModel<
  IAttributes extends IReportAttributes,
> extends DisplayModel<IAttributes> {
  public abstract reportKey: TReportKey;

  constructor(attributes: Partial<IAttributes> = {}) {
    super(attributes);

    makeObservable(this);
  }

  @computed
  public get reportRangeStart(): string {
    return moment.utc(this.get('date_created')).format(TimeFormat.isoDate);
  }

  public get reportRangeEnd(): string {
    return moment.utc().format(TimeFormat.isoDate);
  }
}
