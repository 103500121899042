import classNames from 'classnames';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Account, BaseCampaign } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Popover, TableColumnHeader } from '@feathr/components';
import { useStore } from '@feathr/extender/state';
import { cssVar } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './ParticipantsColumn.css';

import noImg from 'images/no-img.png';

interface IPermissionColumn {
  shouldShow?: (account: Account) => boolean;
}

const ParticipantsColumn: IPermissionColumn & IColumn<BaseCampaign> = {
  id: 'participants',
  checkboxLabel: 'Participants',
  Header: TableColumnHeader({
    title: 'Participants',
  }),
  sortable: false,
  width: 110,
  shouldShow: (account: Account): boolean => account.hasMonetization,
  className: classNames(tableStyles.cell, styles.partners),
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          const { Partners } = useStore();

          if (original.get('_cls') !== CampaignClass.Referral) {
            return <>-</>;
          }

          const partners = Partners.list({
            filters: {
              participation: original.id,
            },
          });

          const remainder = partners.models.length - 10;

          return (
            <>
              {partners.models.slice(0, 10).map((partner) => (
                <Popover key={partner.id} toggle={'onMouseOver'}>
                  <div
                    style={{
                      backgroundImage: `url(${partner.get('logo') || noImg})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundColor: cssVar('--color-bg-primary'),
                      width: '36px',
                      height: '36px',
                      borderRadius: '100%',
                      border: `3px solid ${cssVar('--color-bg-primary')}`,
                      boxShadow: cssVar('--drop-shadow'),
                    }}
                  />
                  <span>{partner.get('name')}</span>
                </Popover>
              ))}
              {remainder > 0 && (
                <span
                  style={{
                    fontSize: '10px',
                    color: cssVar('--color-text-body'),
                    marginLeft: '-15px',
                  }}
                >
                  ... {remainder.toLocaleString()} more
                </span>
              )}
            </>
          );
        }}
      </Observer>
    );
  },
};

export default ParticipantsColumn;
