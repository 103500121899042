import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { APIKey } from '@feathr/blackbox';
import { APIKeyServiceNames } from '@feathr/blackbox';
import { Button, Table, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useLocalUrl, useStore } from '@feathr/extender/state';

import apiKeyColumns from './APIKeyColumns';

function APIKeysPage(): JSX.Element {
  const { t } = useTranslation();
  const { APIKeys } = useStore();
  const history = useHistory();
  const localUrl = useLocalUrl();
  const apiKeysList = APIKeys.list();
  const usedServices = apiKeysList.models.map((model) => model.name);

  const availableServices = Object.values(APIKeyServiceNames).filter((service) => {
    return !usedServices.includes(service);
  });

  function handleAddKey(): void {
    const newApiKey = APIKeys.create();
    history.push(localUrl(localUrl(newApiKey.getItemUrl('add'))));
  }

  const button = (
    <Button
      disabled={apiKeysList.isPending || availableServices.length <= 0}
      onClick={handleAddKey}
      prefix={<FontAwesomeIcon icon={faPlus} />}
      tooltip={availableServices.length <= 0 && t('All available keys have been issued.')}
      tooltipPosition={'bottom-start'}
      type={'primary'}
    >
      {t('Generate new key')}
    </Button>
  );

  return (
    <Page
      actions={<Toolbar>{button}</Toolbar>}
      description={t('Generate API keys to interact with approved third party applications.')}
      title={t('API Keys')}
    >
      <Table<APIKey>
        collection={APIKeys}
        columns={apiKeyColumns({ t })}
        initialPagesize={10}
        isPaginated={true}
        noDataText={t('No API keys have been issued.')}
      />
    </Page>
  );
}

export default observer(APIKeysPage);
