import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ITrackedLink } from '@feathr/blackbox';
import { FormSummaryItem } from '@feathr/components';

import type { ISummaryProps } from '../CampaignSummary.types';

import * as styles from './LinksSummary.css';

const LinksSummary = observer(({ campaign, validationErrors }: ISummaryProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FormSummaryItem
      errors={validationErrors?.redirects}
      label={t('Links')}
      value={
        campaign.get('tracked_links').length > 0 ? (
          <ol className={styles.list}>
            {campaign.get('tracked_links').map((tl: ITrackedLink) => {
              return (
                <li key={tl.redirect_id}>
                  {t('{{-url}} | UTM Source: {{source}} | UTM Medium: {{medium}}', {
                    medium: tl.utm_medium,
                    source: tl.utm_source,
                    url: tl.original_url,
                  })}
                </li>
              );
            })}
          </ol>
        ) : (
          t('(None)')
        )
      }
    />
  );
});

LinksSummary.displayName = 'LinksSummary';

export default LinksSummary;
