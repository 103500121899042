import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Skeleton } from '@feathr/components';
import ErrorPage from '@feathr/extender/App/ErrorPage';
import { useAccount, useLocalUrl, useStore } from '@feathr/extender/state';

function PermissionRequired(): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const account = useAccount();
  const { Users } = useStore();

  const adminUserIds = account.adminUserIds.slice(0, 20);
  const filteredUsers = Users.list({
    filters: { id__in: adminUserIds },
  });

  const adminEmails = filteredUsers.models.map((user) => user.get('email', '')).join(',');

  const description = adminEmails.length ? (
    <Trans t={t}>
      <a href={`mailto:${adminEmails}`} target={'_blank'}>
        Contact an administrator
      </a>{' '}
      or try logging in with a different account.
    </Trans>
  ) : (
    <Skeleton paragraphs={1} width={400} />
  );

  return (
    <ErrorPage description={description} title={t('Permission required')}>
      <Button href={localUrl('/')} type={'primary'}>
        {t('Take me home')}
      </Button>
    </ErrorPage>
  );
}

export default observer(PermissionRequired);
